import React, { useState, useEffect } from "react";
import Success from "../images/success-tick.png";

import { Link, useLocation, useNavigate } from "react-router-dom";

export default function PaymentSuccess() {
  const [isGrowing, setIsGrowing] = useState(true);
  const [updateTime, setUpdateTime] = useState(5);
  const navigate = useNavigate();
  let search = window.location.search;
  let params = new URLSearchParams(search);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setIsGrowing((prevIsGrowing) => !prevIsGrowing);
  //   }, 1000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsGrowing((prevIsGrowing) => !prevIsGrowing);
    }, 1000);

    console.log("SESSION ID", params.get("session_id"));
    setTimeout(() => {
      navigate(`/ride-details?session_id=${params.get("session_id")}`);
    }, 5000);
    // setTimeout(()=>setUpdateTime((prev:any)=>prev-1),1000)
    countDown();
    return () => {
      clearInterval(interval);
    };
  }, []);
  function countDown() {
    setTimeout(() => {
      setUpdateTime((prev) => prev - 1);
      countDown();
    }, 1000);
  }

  const size = isGrowing ? "100px" : "120px";

  return (
    <div className="flex flex-col items-center justify-center mt-10 pt-28">
      <div
        className="image-container"
        style={{
          width: size,
          height: size,
          transition: "width 1s ease, height 1s ease",
        }}
      >
        <img src={Success} className="image" alt="Success Logo" />
      </div>
      <h2 className="text-3xl font-bold text-green-600 mt-8">
        Payment Successful!
      </h2>
      <p className="text-lg text-gray-600 mt-4">
        Thank you for your payment. Your transaction has been successfully
        processed.
      </p>
      <p className="text-base text-gray-600 py-2 text-center">
        You will be redirected in {updateTime} seconds
      </p>
      <button className=" text-white font-bold py-2 px-4 rounded mt-8">
        <Link to="/" className="bg-black btn text-white ">
          Go to Dashboard
        </Link>
      </button>
    </div>
  );
}
