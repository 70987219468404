import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
} from "@react-google-maps/api";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  Input,
  SkeletonText,
  Text,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
export default function MapHero({ origin, destination }) {
  const [loaded, setLoaded] = useState(true);
  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDSvqFVfMDtPftyvZJMrEYeqF5R5dXc6nE",
    libraries: ["places"],
  });

  useEffect(() => {
    tracePath();
  }, []);
  if (!isLoaded) {
    return <SkeletonText />;
  }
  const center = { lat: 51.5072, lng: 0.1276 };
  async function tracePath() {
    // let origin = "Howrah Railway Station, Howrah, West Bengal, India";
    // let destination =
    //   "SEALDAH RAILWAY STATION, Sealdah Flyover, NAWAB SOAP BUILDING, Sealdah, Raja Bazar, Calcutta, West Bengal, India";
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: origin,
      destination: destination,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });
    setDirectionsResponse(results);
  }

  return (
    <>
      <div className="w-screen h-80 bg-no-repeat ">
        <div className="w-full h-full">
          <GoogleMap
            center={center}
            zoom={15}
            mapContainerStyle={{ width: "100%", height: "100%" }}
            options={{
              zoomControl: false,
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
            }}
            onLoad={(map) => setMap(map)}
          >
            {/* <Marker
              position={center}
              icon={{
                // path: google.maps.SymbolPath.CIRCLE,
                url: "https://img.icons8.com/3d-fluency/94/map-pin.png",
                fillColor: "#EB00FF",
                scale: 7,
              }}
            /> */}
            {directionsResponse && (
              <DirectionsRenderer
                directions={directionsResponse}
                // directions_changed={getRoute}
                options={{
                  // draggable: true,
                  markerOptions: {
                    icon: "https://img.icons8.com/3d-fluency/94/map-pin.png",
                  },
                }}
              />
            )}
          </GoogleMap>
        </div>
      </div>
      <div>
        <svg
          className="blog-svg brightness-100"
          xmlns="http://www.w3.org/2000/svg"
          width="1932"
          height="159"
          viewBox="0 0 1932 159"
        >
          <ellipse
            id="Ellipse_360"
            data-name="Ellipse 360"
            cx="966"
            cy="79.5"
            rx="820"
            ry="85"
            fill="#fff"
          />
        </svg>
      </div>
      <div className="pt-20" />
    </>
  );
}
