export class URLConstants {}
// URLConstants.LOCAL_BASE_URL = "http://localhost:8088";//
// URLConstants.BASE_URL = "http://localhost:4000";
//URLConstants.BOOKING_URL = "https://treklerspassserver.api.treklers.in";
//URLConstants.BOOKING_URL = "http://34.228.196.145:3000";
URLConstants.BOOKING_URL = "https://treklerspassserver.treklers.co.uk";
// URLConstants.UAT_BASE_URL = "http://localhost:8088";
// URLConstants.PROD_BASE_URL = "http://localhost:8088";
// URLConstants.BASE_URL = "https://admin-server-dev.treklers.co.uk";
URLConstants.BASE_URL = "https://admin-server.treklers.co.uk";
// URLConstants.BASE_URL = "https://admin-server.treklers.in";
//process.env.NEXT_PUBLIC_BASE_URL || URLConstants.DEV_BASE_URL;
// AUTH ROUTES
URLConstants.login = () => `${URLConstants.BASE_URL}/v1/auth/emaillogin`;

URLConstants.generateEmailOtp = () =>
  `${URLConstants.BASE_URL}/v1/auth/forgot-password`;

URLConstants.verifyEmailOtp = () =>
  `${URLConstants.BASE_URL}/v1/auth/verify-email-otp`;

URLConstants.resetOperatorPassword = () =>
  `${URLConstants.BASE_URL}/v1/auth/reset-password`;

URLConstants.driverLogin = () =>
  `${URLConstants.BASE_URL}/v1/driver/driverLogin`;

URLConstants.getDriverTripDetails = (driverId) =>
  `${URLConstants.BASE_URL}/v1/driver/tripsByDriver/${driverId}`;

URLConstants.getAllTripDetails = () =>
  `${URLConstants.BASE_URL}/v1/admin/fetchPassengerPaymentStatus?limit=100`;

URLConstants.getCancelledTrips = () =>
  `${URLConstants.BASE_URL}/v1/admin/cancelled-trips?limit=100`;

URLConstants.cancelTrip = (tripId, impacted) =>
  `${URLConstants.BASE_URL}/v1/admin/cancel-trip/${tripId}/${impacted}`;

URLConstants.getPassengerTrips = (passengerId) =>
  `${URLConstants.BASE_URL}/v1/admin/passenger-trips/${passengerId}`;

URLConstants.getDriverInfoByMobileNo = (mobileNumber) =>
  `${URLConstants.BASE_URL}/v1/admin/driver/${mobileNumber}`;

URLConstants.getAllTripDetailsByOptDriver = (optId) =>
  `${URLConstants.BASE_URL}/v1/admin/tripsByOptDriver/${optId}`;

URLConstants.getAllDrivers = () =>
  `${URLConstants.BASE_URL}/v1/driver/getDrivers`;

URLConstants.getTripPdf = () => `${URLConstants.BASE_URL}/v1/admin/trip-pdf`;

URLConstants.getDriverByOperatorId = (operatorId) =>
  `${URLConstants.BASE_URL}/v1/driver/getDrivers/${operatorId}`;

URLConstants.createDriverLogIn = () =>
  `${URLConstants.BASE_URL}/v1/driver/createDriverLogIn`;

URLConstants.updateDriverAndUpload = () =>
  `${URLConstants.BASE_URL}/v1/driver/updateDriver`;

URLConstants.assignReassignVehicle = () =>
  `${URLConstants.BASE_URL}/v1/driver/assignReassignVehicle`;

URLConstants.getDriverUpcomingRides = (driverId) =>
  `${URLConstants.BASE_URL}/v1/driver/upcoming-rides/${driverId}`;

URLConstants.getDriverVehicles = (driverId) =>
  `${URLConstants.BASE_URL}/v1/driver/getDriverVehicles/${driverId}`;

URLConstants.getDriverDocument = () =>
  `${URLConstants.BASE_URL}/v1/driver/getDriverDocument`;

URLConstants.blockDriver = (driverId) =>
  `${URLConstants.BASE_URL}/v1/driver/blockDriver/${driverId}`;

URLConstants.unblockDriver = (driverId) =>
  `${URLConstants.BASE_URL}/v1/driver/unblockDriver/${driverId}`;

URLConstants.getAllDriverVehicles = () =>
  `${URLConstants.BASE_URL}/v1/driver/getAllDriverVehicles`;

URLConstants.getOptVehicle = (optId) =>
  `${URLConstants.BASE_URL}/v1/driver/getOptVehicles/${optId}`;

URLConstants.getOperatorList = () =>
  `${URLConstants.BASE_URL}/v1/client/getOperators`;

URLConstants.getPaymentPendingPdf = (fromDate, toDate, optId, status) =>
  `${URLConstants.BASE_URL}/v1/admin/${fromDate}/${toDate}/${optId}/${status}/payment-pending-pdf`;

URLConstants.createOperator = () =>
  `${URLConstants.BASE_URL}/v1/client/createOperator`;

URLConstants.getDistanceAndTime = () =>
  `${URLConstants.BASE_URL}/v1/admin/getDistanceAndTime`;

URLConstants.getVehiclePricing = () =>
  `${URLConstants.BASE_URL}/v1/admin/getVehiclePricing`;

URLConstants.getImpactedVehicleNo = (operatorId) =>
  `${URLConstants.BASE_URL}/v1/admin/vehicles/impacted-trips/${operatorId}`;

URLConstants.getPassengerCustomerId = (mobileNo) =>
  `${URLConstants.BASE_URL}/v1/admin/passenger/stripe-customerId-mobile/${mobileNo}`;

URLConstants.getUpcomingRides = (id, type) =>
  `${URLConstants.BASE_URL}/v1/admin/upcoming-rides-operator/${id}/${type}`;

URLConstants.bookRide = () => `${URLConstants.BASE_URL}/v1/admin/bookRide`;

URLConstants.getOnGoingRides = () =>
  `${URLConstants.BASE_URL}/v1/admin/ongoing-trips`;

URLConstants.disableDriverDevice = (driverId) =>
  `${URLConstants.BASE_URL}/v1/admin/disable-driver-device/${driverId}`;

URLConstants.fetchPassengerInfo = (id) =>
  `${URLConstants.BASE_URL}/v1/admin/passenger/${id}`;

URLConstants.assignDriverToTrip = (tripId) =>
  `${URLConstants.BASE_URL}/v1/admin/trip-request/${tripId}`;

URLConstants.getDriverPaymentStatus = (fromDate, toDate, optId, status) =>
  `${URLConstants.BASE_URL}/v1/admin/${fromDate}/${toDate}/${optId}/${status}/getDriverPaymentStatus`;

URLConstants.getAllTripsByAllDrivers = (fromDate, toDate, status) =>
  `${URLConstants.BASE_URL}/v1/admin/${fromDate}/${toDate}/${status}/getTripDetailsByAllDrivers`;

URLConstants.updateDriverPayStatusFromAdmin = () =>
  `${URLConstants.BASE_URL}/v1/admin/updateDriverPaymentStatusByAdmin`;

URLConstants.getImpactedTrips = (operatorId) =>
  `${URLConstants.BASE_URL}/v1/admin/impacted-trips/${operatorId}`;

URLConstants.getVehicleTypes = () =>
  `${URLConstants.BASE_URL}/v1/admin/vehicle-types`;

URLConstants.getOperatorsNames = () =>
  `${URLConstants.BASE_URL}/v1/client/getOperatorNames`;

URLConstants.getOperatorVehicleNo = (operatorId) =>
  `${URLConstants.BASE_URL}/v1/admin/operator-vehicle/${operatorId}`;

URLConstants.getLocationOfOepratorVehicle = (operatorId) =>
  `${URLConstants.BASE_URL}/v1/admin/operator-vehicle-location/${operatorId}`;

URLConstants.getVehicleLocation = (vehicleNo) =>
  `${URLConstants.BASE_URL}/v1/admin/vehicle-location/${vehicleNo}`;

URLConstants.getPassengerPaymentStatus = () =>
  `${URLConstants.BASE_URL}/v1/admin/fetchPassengerPaymentStatus`;
URLConstants.getDataSubmissionConst = () =>
  `${URLConstants.BASE_URL}/v1/common/constantsets/DataSubmission`;
URLConstants.getXMLFormatsConst = () =>
  `${URLConstants.BASE_URL}/v1/common/constantsets/XMLFormats`;
URLConstants.createClient = () => `${URLConstants.BASE_URL}/v1/admin/client`;
URLConstants.getJobsOfACompany = (clientId) =>
  `${URLConstants.BASE_URL}/v1/users/company/jobs/${clientId}`;
URLConstants.getJobsOfACompanyByFilter = () =>
  `${URLConstants.BASE_URL}/v1/users/company/jobs`;
URLConstants.postAJobOfCompany = (clientId) =>
  `${URLConstants.BASE_URL}/v1/admin/client/${clientId}/job`;
URLConstants.getAllSubscriptions = () =>
  `${URLConstants.BASE_URL}/v1/admin/subscriptionList`;
URLConstants.getUsersOnConditions = (experience, driverType, state) =>
  `${URLConstants.BASE_URL}/v1/users/`;
URLConstants.getJobsOfACompanyByXML = (clientId) =>
  `${URLConstants.BASE_URL}/v1/admin/client/${clientId}/jobByXML`;
URLConstants.getAllDriverTypes = () =>
  `${URLConstants.BASE_URL}/v1/common/constantsets/DriverTypes`;
URLConstants.getAllStates = () =>
  `${URLConstants.BASE_URL}/v1/common/constantsets/States`;
URLConstants.getAllCities = () =>
  `${URLConstants.BASE_URL}/v1/common/constantsets/Cities`;
URLConstants.getLeadsForACompany = (clientId, fromDate, toDate, driverType) =>
  `${URLConstants.BASE_URL}/v1/admin/client/${clientId}/${fromDate}/${toDate}/${driverType}/leads`;
URLConstants.getCompaniesUnderAgency = (agencyId) =>
  `${URLConstants.BASE_URL}/v1/admin/agency/${agencyId}/clientlist`;
URLConstants.getSubsHistForACompany = (clientId) =>
  `${URLConstants.BASE_URL}/v1/admin/client/${clientId}/subscriptions`;
URLConstants.sendMassApplicationToClients = () =>
  `${URLConstants.BASE_URL}/v1/users/apply/companies`;
URLConstants.createBlogOrNews = () =>
  `${URLConstants.BASE_URL}/v1/admin/blogsornews`;
URLConstants.getAllBlogsAndNewsList = () =>
  `${URLConstants.BASE_URL}/v1/admin/blogsornewsList`;
URLConstants.logInEmailOtp = () =>
  `${URLConstants.BASE_URL}/v1/auth/verify/loginemailotp`;
URLConstants.sendVerificationEmail = () =>
  `${URLConstants.BASE_URL}/v1/auth/send-verification-email`;
URLConstants.clientResetPassword = () =>
  `${URLConstants.BASE_URL}/v1/auth/client-reset-password`;
URLConstants.countData = (clientArray) =>
  `${URLConstants.BASE_URL}/v1/admin/leadsTillDate?companyIdList=[${clientArray}]`;

URLConstants.getClientTypes = () =>
  `${URLConstants.BASE_URL}/v1/client/clientTypes`;

URLConstants.getZipAndCity = (state) =>
  `${URLConstants.BASE_URL}/v1/auth/getStateData/${state}`;

URLConstants.getFinalJobPlaces = () =>
  `${URLConstants.BASE_URL}/v1/auth/getQualifiedPlacesForAJob`;
