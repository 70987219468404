import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Table } from "antd";
import { Helmet } from "react-helmet";
import { itemRender, onShowSizeChange } from "./paginationfunction";
import Header from "./header/Header";
import { Leftbar } from "./sidebar/Leftbar";
import Footer from "./footer/Footer";
import { axiosInstance } from "../base/api/axios.util";
import { URLConstants } from "../base/api/url.constants";
import { ClientContext } from "../base/contexts/UserContext";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import Loader from "react-loader";

function PassengerPament() {
  const navigate = useNavigate();
  const [driverPayment, setDriverPayment] = useState([]);
  const { client, setClient } = useContext(ClientContext);
  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    setLoaded(false);
    if (!client) {
      navigate("/");
    } else {
      axiosInstance
        .get(URLConstants.getPassengerPaymentStatus())
        .then((response) => {
          setLoaded(true);

          for (let i = 0; i < response.results.length; i++) {
            if (response.results[i].paymentStatus == 0) {
              response.results[i].paymentStatus = "Failure";
            } else {
              response.results[i].paymentStatus = "Success";
            }
          }
          setDriverPayment(response.results);
        })
        .catch((err) => {
          setLoaded(true);
        });
    }
  }, []);

  const ExportToExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const outputFilename = `${Date.now()}.xls`;

    // reshaping the array
    const customHeadings = driverPayment.map((item) => ({
      "Trip Id": item.tripId,
      "Passenger Name":
        item.passengerDetails.firstName + " " + item.passengerDetails.lastName,
      "Passenger Contact Number": item.passengerDetails.mobileNo,
      "Pickup Location": item.source[0].address,
      // "User ID": item.userId,
      Destination: item.destination[0].address,
      "Total Distance": parseInt(item.totalTravelDist),
      "Total Bill": `£${parseInt(item.totalTripPrice)}`,
      "Payment status": item.paymentStatus,
    }));

    exportToCSV(customHeadings, outputFilename, fileExtension, fileType);
  };

  const exportToCSV = (customHeadings, fileName, fileExtension, fileType) => {
    const ws = XLSX.utils.json_to_sheet(customHeadings);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const columns = [
    {
      title: "Trip Id",
      dataIndex: "tripId",
      sorter: (a, b) => a.tripId.length - b.tripId.length,
    },
    {
      title: "Passenger Name",
      dataIndex: "passengerDetails",
      render: (passengerDetails) =>
        passengerDetails.firstName + " " + passengerDetails.lastName,
    },
    {
      title: "Contact Number",
      dataIndex: "passengerDetails",
      render: (passengerDetails) => passengerDetails.mobileNo,
    },

    {
      title: "Pickup Location",
      dataIndex: "source",
      render: (source) => source[0].address,
    },
    {
      title: "Destination",
      dataIndex: "destination",
      render: (destination) => destination[0].address,
    },
    {
      title: "Total Distance",
      dataIndex: "totalTravelDist",
      sorter: (a, b) => a.totalTravelDist.length - b.totalTravelDist.length,
    },
    {
      title: "Total Amount",
      dataIndex: "totalTripPrice",
      // sorter: (a, b) => a.totalTripPrice.length - b.totalTripPrice.length,
      render: (totalTripPrice) => {
        if (Number.isInteger(parseInt(totalTripPrice))) {
          return Number(parseInt(totalTripPrice));
        } else {
          return 0;
        }
      },
    },
    // {
    //   title: "Payment Type",
    //   dataIndex: "paymentType",
    //   sorter: (a, b) => a.paymentType.length - b.paymentType.length,
    // },
    {
      title: "Payment status",
      dataIndex: "paymentStatus",
      sorter: (a, b) => a.paymentStatus.length - b.paymentStatus.length,
    },
    // {
    //   title: "Action",
    //   render: (text, record) => (
    //     <div>
    //       <div className="dropdown dropdown-action float-end">
    //         <a
    //           href="#"
    //           className="action-icon dropdown-toggle"
    //           data-bs-toggle="dropdown"
    //           aria-expanded="false"
    //         >
    //           <i className="material-icons"></i>
    //         </a>

    //         <div className="row dropdown-menu dropdown-menu-right noLine">
    //           <div className="col-md-12">
    //             <Link
    //               to={{
    //                 pathname: `/editdriver`,
    //                 state: { record },
    //               }}
    //             >
    //               <i /> Edit Payment
    //             </Link>
    //           </div>
    //           <div className="col-md-12">
    //             <Link
    //               to={{
    //                 pathname: "/blockdriver",
    //                 state: { record },
    //               }}
    //             >
    //               <i /> Block Payment
    //             </Link>
    //           </div>
    //           <div className="col-md-12">
    //             <Link
    //               to={{
    //                 pathname: `/editdriver`,
    //                 state: { record },
    //               }}
    //             >
    //               <i /> Disable/Enable Payment
    //             </Link>
    //           </div>
    //           <div />
    //         </div>
    //       </div>
    //     </div>
    //   ),
    // },
  ];

  const paymentrColumns = [
    {
      name: "Michael",
      mobileNumber: 3239303123,
      tripId: "352",
      source: "BTH",
      destination: "The High Line",
      kilometers: "54",
      amount: "30$",
      paymentType: "Cash",
      paymentStatus: "Sucessfull",
    },
    {
      name: "Gilberto",
      mobileNumber: 8336494474,
      tripId: "405",
      source: "JPN",
      destination: "Broadway West side",
      kilometers: "44",
      amount: "45$",
      paymentType: "Card",
      paymentStatus: "Failed",
    },
    {
      name: "Jamie",
      mobileNumber: 18008873010,
      tripId: "508",
      source: "JPN",
      destination: "Rockefeller Plaza",
      kilometers: "22",
      amount: "26$",
      paymentType: "Card",
      paymentStatus: "Sucessfull",
    },
    {
      name: "Brandon",
      mobileNumber: 8002472537,
      tripId: "518",
      source: "BTH",
      destination: "Francisco",
      kilometers: "15",
      amount: "56$",
      paymentType: "Cash",
      paymentStatus: "Failed",
    },
    {
      name: "Michael",
      mobileNumber: 3239303123,
      tripId: "352",
      source: "BTH",
      destination: "Alcatraz",
      kilometers: "10",
      amount: "133$",
      paymentType: "Cash",
      paymentStatus: "Sucessfull",
    },
    {
      name: "Gilberto",
      mobileNumber: 8336494474,
      tripId: "405",
      source: "JPN",
      destination: "Houston",
      kilometers: "11",
      amount: "48$",
      paymentType: "Card",
      paymentStatus: "Sucessfull",
    },
    {
      name: "Jamie",
      mobileNumber: 18008873010,
      tripId: "508",
      source: "BTH",
      destination: "The High Line",
      kilometers: "16.8",
      amount: "11$",
      paymentType: "Card",
      paymentStatus: "Failed",
    },
    {
      name: "Michael",
      mobileNumber: 3239303123,
      tripId: "352",
      source: "BTH",
      destination: "The High Line",
      kilometers: "54",
      amount: "30$",
      paymentType: "Cash",
      paymentStatus: "Sucessfull",
    },
    {
      name: "Gilberto",
      mobileNumber: 8336494474,
      tripId: "405",
      source: "JPN",
      destination: "Broadway West side",
      kilometers: "44",
      amount: "45$",
      paymentType: "Card",
      paymentStatus: "Failed",
    },
    {
      name: "Jamie",
      mobileNumber: 18008873010,
      tripId: "508",
      source: "JPN",
      destination: "Rockefeller Plaza",
      kilometers: "22",
      amount: "26$",
      paymentType: "Card",
      paymentStatus: "Sucessfull",
    },
    {
      name: "Brandon",
      mobileNumber: 8002472537,
      tripId: "518",
      source: "BTH",
      destination: "Francisco",
      kilometers: "15",
      amount: "56$",
      paymentType: "Cash",
      paymentStatus: "Failed",
    },
    {
      name: "Michael",
      mobileNumber: 3239303123,
      tripId: "352",
      source: "BTH",
      destination: "Alcatraz",
      kilometers: "10",
      amount: "133$",
      paymentType: "Cash",
      paymentStatus: "Sucessfull",
    },
    {
      name: "Gilberto",
      mobileNumber: 8336494474,
      tripId: "405",
      source: "JPN",
      destination: "Houston",
      kilometers: "11",
      amount: "48$",
      paymentType: "Card",
      paymentStatus: "Sucessfull",
    },
    {
      name: "Jamie",
      mobileNumber: 18008873010,
      tripId: "508",
      source: "BTH",
      destination: "The High Line",
      kilometers: "16.8",
      amount: "11$",
      paymentType: "Card",
      paymentStatus: "Failed",
    },
  ];

  return (
    <>
      <Header isAdmin />
      <div className="grid lg:grid-cols-6">
        <Leftbar />
        <Helmet>
          <title>Payment List - Treklers - Admin</title>
          <meta name="description" content="Login page" />
        </Helmet>
        <div className="p-8 lg:col-span-5">
          <div className="col-md-12">
            <div className="table-responsive">
              <div className="grid grid-cols-2 pb-4">
                <div>
                  <span className="text-2xl font-bold">Passenger Payment</span>
                </div>
                <div className=" float-end ml-auto">
                  <button
                    className="rounded bg-gray-700 text-white hover:bg-black px-3 py-2 w-auto"
                    onClick={() => ExportToExcel()}
                  >
                    Download Excel
                  </button>
                </div>
              </div>
              {/* <div className="grid grid-cols-1 pb-4">
                <div className="filter-row leads pt-4">
                  <div className="row pb-3 align-items-end">
                    <div className="col-sm-1 col-md pb-3">
                      <p className="mb-2">From Date </p>
                      <input
                        type="date"
                        className="col-12 border p-1 ps-2 rounded"
                        style={{ height: "36px" }}
                      />
                    </div>
                    <div className="col-sm-1 col-md pb-3">
                      <p className="mb-2">To Date </p>
                      <input
                        type="date"
                        className="col-12 border p-1 ps-2 rounded"
                        style={{ height: "36px" }}
                      />
                    </div>

                    <div className="col-sm-1 col-md-2 text-right pb-3 pl-5">
                      <div>
                        <input
                          className="w-auto m-auto px-4 py-2"
                          type="submit"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <Table
                className="table-striped"
                pagination={{
                  total: driverPayment.length,
                  showTotal: (total, range) =>
                    `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                  showSizeChanger: true,
                  onShowSizeChange: onShowSizeChange,
                  itemRender: itemRender,
                }}
                style={{ overflowX: "auto" }}
                columns={columns}
                bordered
                dataSource={driverPayment}
              />
            </div>
          </div>
        </div>
        <div className="spinner">
          <Loader
            loaded={loaded}
            lines={13}
            length={20}
            width={10}
            radius={30}
            corners={1}
            rotate={0}
            direction={1}
            color="#000"
            speed={1}
            trail={60}
            shadow={false}
            hwaccel={false}
            className="spinner"
            zIndex={2e9}
            top="50%"
            left="50%"
            scale={1.0}
            loadedClassName="loadedContent"
          />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PassengerPament;
