import React, { useRef } from "react";
import Header from './header/Header'
import { Helmet } from "react-helmet";
import Footer from './footer/Footer';
import Hero from "./shared/Hero";

const FAQ = () => {
    const titleRef = useRef();
    return (
        <>
            <Header/>
            <Hero title="Frequently Asked Question" />
            <Helmet>
                <title>FAQ - Treklers - Admin</title>
                <meta name="description" content="Login page" />
            </Helmet>
            <div>
                <div className="text-3xl font-bold w-full text-center p-4" ref={titleRef}>Booking FAQ</div>
            </div>
            <div className="w-full md:w-2/3 rounded-lg border-4 border-gray-500 mx-auto">
                <div className="overflow-y-auto p-8">
                    <div className="text-sm mt-0 md:mt-8 text-black line-clamp-1">
                        <div className="text-sm mt-0 md:mt-8 text-black line-clamp-1">
                            {" "}
                            <h2 className="lg:text-lg font-bold text-gray-500 text-left mb-4">
                                FAQ
                            </h2>
                        </div>
                        {" "}
                        <h2 className="text-black text-left mb-4 font-bold">
                            What is Treklers?
                        </h2>
                    </div>
                    <div className="grid gap-4 text-sm ">
                        <p><span className='font-bold'>Treklers</span> lets drivers search for jobs by zip code, which means they find trucking companies with more miles and better hometime where they live. We offer free search tools on our website and through our free Treklers apps, now available for Androids, iPhones, iPads and Tablets.</p>
                        <h2 className="text-black text-left mb-4">
                            <span className='font-bold'>How do I get in contact with</span> <a href="#" className="text-blue-500 underline">treklers.com?</a>
                        </h2>
                        <p>
                            If you’re a driver, you can contact us through <a href="#" className="text-blue-500 underline">treklers@treklers.com</a>. If you’re an agency or a carrier, you can fill out a form under “Advertise with us here” or email <a href="#" className="text-blue-500 underline">Treklers.com</a>and we’ll get into contact with you as soon as possible.
                        </p>
                        <h2 className="text-black font-bold text-left mb-4">
                            Does Treklers have social media accounts?
                        </h2>
                        <p>
                            We do. You can check us out or follow us on Facebook(make a hyperlink to <a href="#" className="text-blue-500 underline"> https://www.facebook.com/Treklers</a>), Twitter(hyperlink to <a href="#" className="text-blue-500 underline">https://twitter.com/treklers</a>) and Youtube(no hyperlink yet).
                        </p>
                        {/* <p className="font-bold">How do we protect your information?</p> */}
                        <h2 className="text-black font-bold text-left mb-4">
                            What kind of runs do you offer drivers?
                        </h2>
                        Our carriers offer Dedicated, Local, Regional and OTR Runs. Go to our “jobs” page to search for jobs by run type.
                        <h2 className="text-black font-bold text-left mb-4">
                            How often are jobs added to your website?
                        </h2>
                        <p className="font-bold">
                            Every day! Which means there are new opportunities waiting for YOU daily.                        </p>
                    </div>
                </div>

            </div>
            <div className='pt-4'>
                <Footer />
            </div>
        </>
    )
}

export default FAQ