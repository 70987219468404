import React, { useEffect, useState, useContext } from "react";
import { Leftbar } from "./sidebar/Leftbar";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import { Table } from "antd";
import { Helmet } from "react-helmet";
import { useForm, Controller } from "react-hook-form";
import { axiosInstance } from "../base/api/axios.util";
import { URLConstants } from "../base/api/url.constants";
import { ClientContext } from "../base/contexts/UserContext";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

import toast, { Toaster } from "react-hot-toast";
import Loader from "react-loader";
import { itemRender, onShowSizeChange } from "./paginationfunction";
import { Fragment } from "react";
function DriverList() {
  const { client, setClient } = useContext(ClientContext);
  const [reloadPage, setReloadPage] = useState(false);
  const [driverData, setDriverData] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [filterByNearExpiry, setFilterExpiry] = useState(false);
  const [regPerformed, setRegPerformed] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [operatorNames, setOperatorsName] = useState([]);
  const [vehicleNos, setVehicleNos] = useState([]);
  let [filterOptions, setFilterOptions] = useState({});
  const [query, setQuery] = useState("");
  let [displayFilterValue, setDisplayFilterValue] = useState({});

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      mobileNo: "",
      gender: "",
    },
  });
  const location = useLocation();

  function ukDateFormatter(date) {
    return date.split("T")[0].split("-").reverse().join("-");
  }

  var driverDataAssignment = {};
  if (location.state != undefined) {
    driverDataAssignment = location.state;
  }

  console.log("LOCAtion value ", location.state);
  let defaultValue = {};

  if (location?.state?._id) {
    defaultValue = { operatorId: location.state._id };
  }
  console.log("DEFUALT VALUE", defaultValue);
  const {
    register: registerFilter,
    handleSubmit: handleSubmitFilter,

    formState: { errors: errorsFilter },
    reset: resetFilter,
  } = useForm({ defaultValues: defaultValue });

  function unblockDriver(driverId) {
    axiosInstance
      .get(URLConstants.unblockDriver(driverId))
      .then((response) => {
        alert("Successfully unblocked driver");
        // setDriverData([]);
        setReloadData(!reloadData);
      })
      .catch((err) => {});
  }

  const onSubmit = (data) => {
    // alert(JSON.stringify(data));

    setLoaded(false);
    var finalData = {
      name: data.firstName + " " + data.lastName,
      mobileNo: data.mobileNo.toString(),
      email: data.email,
      gender: data.gender,
      operatorId: client.loginData.id,
      countryCode: "+44",
    };

    axiosInstance
      .post(URLConstants.createDriverLogIn(), finalData)

      .then((response) => {
        setLoaded(true);

        reset();
        toast.success("Thank You ! Registration Successful ");
        document.getElementById("add_leave").style.display = "none";
        document
          .querySelectorAll(".modal-backdrop")
          .forEach((el) => el.remove());
        setReloadData(!reloadData);
      })
      .catch((err) => {
        setLoaded(true);
        const message = err.response.data.message;
        toast.error(message);
      });
  };

  function disableDeviceForDriver(driverInfo) {
    if (!driverInfo) {
      toast.error("Something went wrong please try again later");
    } else {
      console.log("DRIVER INFO", driverInfo);
      axiosInstance
        .get(URLConstants.disableDriverDevice(driverInfo))
        .then((res) => {
          toast.success("Successfully disable device id");
        })
        .catch((err) => {
          console.log("ERror while removing client", err);
          toast.error("Something went wrong!! Please try again later");
        });
    }
  }

  const getExpiryDateClass = (expiryDateStr) => {
    const expiryDate = new Date(expiryDateStr);
    const today = new Date();
    const oneMonthFromNow = new Date();
    oneMonthFromNow.setMonth(today.getMonth() + 1);
    const twoMonthsFromNow = new Date();
    twoMonthsFromNow.setMonth(today.getMonth() + 2);

    if (expiryDate < today) {
      return "bg-red-600"; // Expired
    } else if (expiryDate < oneMonthFromNow) {
      return "bg-red-600"; // Expires in less than 1 month
    } else if (expiryDate < twoMonthsFromNow) {
      return "bg-yellow-600"; // Expires in less than 2 months
    } else {
      return "bg-green-600"; // No special styling for expiry dates more than 2 months away
    }
  };

  const ExportToExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const outputFilename = `${Date.now()}`;

    const customHeadings = driverData.map((item) => ({
      "Driver Name": item.name,
      "Contact Number": item.mobileNo,
      Email: item.email,

      "VAT Registered": item.vatRegistration ? "Yes" : "No",
    }));

    exportToExcel(customHeadings, outputFilename, fileExtension, fileType);
  };

  const exportToExcel = (customHeadings, fileName, fileExtension, fileType) => {
    const ws = XLSX.utils.json_to_sheet(customHeadings);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const ExportToCsv = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".csv";
    const outputFilename = `${Date.now()}`;

    const customHeadings = driverData.map((item) => ({
      "Driver Name": item.name,
      "Contact Number": item.mobileNo,
      Email: item.email,

      "VAT Registered": item.vatRegistration ? "Yes" : "No",
    }));

    exportToCsv(customHeadings, outputFilename, fileExtension, fileType);
  };

  const exportToCsv = (customHeadings, fileName, fileExtension, fileType) => {
    const ws = XLSX.utils.json_to_sheet(customHeadings);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  useEffect(() => {
    let filter;
    if (location?.state?._id) {
      filter = { operatorId: location?.state?._id };
    }
    console.log("FIlter", filter);
    setLoaded(false);
    if (!client) {
      navigate("/");
    } else if (client?.logInType == "SUPER_ADMIN") {
      axiosInstance
        .get(URLConstants.getOperatorsNames())
        .then((response) => {
          console.log("Test");
          setOperatorsName(response);

          // displayFilterValue.operatorName = response[0].companyName;
          // setDisplayFilterValue(displayFilterValue);
          // filterOptions.operatorId = response[0].operatorId;
          // setFilterOptions(filterOptions);
        })
        .catch((err) => {
          console.log(err);
        });

      axiosInstance
        .get(URLConstants.getOperatorVehicleNo(client?.loginData.client.id))
        .then((response) => {
          console.log("CARd daeta", response);
          // response?.operatorVehicles[0]?.vehicleNumbers.unshift("ALL");

          if (response?.operatorVehicles[0]?.vehicleNumbers) {
            setVehicleNos(response?.operatorVehicles[0]?.vehicleNumbers);
          } else {
            setVehicleNos([]);
          }
          setLoaded(true);
        })
        .catch((err) => {
          console.log("Error Triggered", err);

          setLoaded(true);
          console.log(err);
        });
      axiosInstance
        .post(URLConstants.getAllDrivers(), filter)
        .then((response) => {
          console.log("RESPONSE OF DRIVER LIST", response.results);
          setDriverData(response.results);
          setLoaded(true);
        })
        .catch((err) => {
          setLoaded(true);
        });
    } else if (client?.logInType == "OPERATOR") {
      axiosInstance
        .get(URLConstants.getOperatorVehicleNo(client?.loginData.client.id))
        .then((response) => {
          console.log("CARd daeta", response);
          // response?.operatorVehicles[0]?.vehicleNumbers.unshift("ALL");

          if (response?.operatorVehicles[0]?.vehicleNumbers) {
            setVehicleNos(response?.operatorVehicles[0]?.vehicleNumbers);
          } else {
            setVehicleNos([]);
          }
          setLoaded(true);
        })
        .catch((err) => {
          console.log("Error Triggered", err);

          setLoaded(true);
          console.log(err);
        });
      axiosInstance
        .get(URLConstants.getDriverByOperatorId(client?.loginData?.client.id))
        .then((response) => {
          if (!response?.results) {
            setDriverData([]);
          } else {
            setDriverData(response?.results);
          }

          setLoaded(true);
        })
        .catch((err) => {
          setLoaded(true);
        });
    } else {
      navigate("/notfound");
    }
  }, [reloadData, reloadPage]);
  console.log("selected value of filterOptions", filterOptions);
  const columns = [
    {
      title: "Driver Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Contact Number",
      dataIndex: "mobileNo",
      sorter: (a, b) => a.mobileNo.length - b.mobileNo.length,
    },

    {
      title: "Email Address",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length,
    },

    {
      title: "Gender",
      dataIndex: "gender",
    },
    {
      title: "Driver License",
      dataIndex: "driverLicense",
      render: (text, record) => {
        if (record.driverLicense?.fileName) {
          return (
            <button
              // className="font-bold "
              className="text-white font-bold rounded-md px-3 py-2 w-[80px] text-center"
              onClick={() => {
                setLoaded(false);
                console.log("ANkit");
                axiosInstance
                  .post(URLConstants.getDriverDocument(), {
                    fileName: record.driverLicense?.fileName,
                  })
                  .then((res) => {
                    setLoaded(true);
                    console.log("Response", res);
                    window.open(res, "_blank");
                  })
                  .catch((err) => {
                    toast.error(
                      "Something went wrong please try again later!!"
                    );
                    setLoaded(true);
                    console.log("err", err);
                  });
              }}
              // href={record.driverLicense?.fileName}
              // target="_blank"

              type="submit"
            >
              View
            </button>
          );
        } else {
          return <span className="font-bold">Document not uploaded</span>;
        }
      },
    },
    {
      title: "Expiry Date",
      dataIndex: "driverLicense",
      render: (text, record) =>
        record.driverLicense?.expiryDate ? (
          <div
            className={`${getExpiryDateClass(
              record.driverLicense?.expiryDate
            )} text-white font-bold rounded-md px-3 py-2 w-[120px] text-center`}
          >
            {" "}
            {ukDateFormatter(record.driverLicense?.expiryDate)}
          </div>
        ) : (
          "N.A."
        ),
    },
    {
      title: "TFL Driving License",
      dataIndex: "tflDriverLicense",
      render: (text, record) => {
        if (record.tflDrivingLicense?.fileName) {
          return (
            <button
              // className="font-bold "
              className="text-white font-bold rounded-md px-3 py-2 w-[80px] text-center"
              onClick={() => {
                setLoaded(false);
                axiosInstance
                  .post(URLConstants.getDriverDocument(), {
                    fileName: record.tflDrivingLicense?.fileName,
                  })
                  .then((res) => {
                    setLoaded(true);
                    console.log("Response", res);
                    window.open(res, "_blank");
                  })
                  .catch((err) => {
                    toast.error(
                      "Something went wrong please try again later!!"
                    );
                    setLoaded(true);
                    console.log("err", err);
                  });
              }}
              // href={record.driverLicense?.fileName}
              // target="_blank"

              type="submit"
            >
              View
            </button>
          );
        } else {
          return <span className="font-bold">Document not uploaded</span>;
        }
      },
    },

    {
      title: "Expiry Date",
      dataIndex: "tflDriverLicense",

      render: (text, record) =>
        record.tflDrivingLicense?.expiryDate ? (
          <div
            className={`${getExpiryDateClass(
              record.tflDrivingLicense?.expiryDate
            )} text-white font-bold rounded-md px-3 py-2 w-[120px] text-center`}
          >
            {" "}
            {ukDateFormatter(record.tflDrivingLicense?.expiryDate)}
          </div>
        ) : (
          "N.A."
        ),
    },
    // {
    //   title: "V5 Form",
    //   dataIndex: "v5Form",
    //   render: (text, record) => {
    //     if (record.v5Form?.fileName) {
    //       return (
    //         <button
    //           // className="font-bold "
    //           className="text-white font-bold rounded-md px-3 py-2 w-[80px] text-center"
    //           onClick={() => {
    //             setLoaded(false);
    //             console.log("ANkit");
    //             axiosInstance
    //               .post(URLConstants.getDriverDocument(), {
    //                 fileName: record.v5Form?.fileName,
    //               })
    //               .then((res) => {
    //                 setLoaded(true);
    //                 console.log("Response", res);
    //                 window.open(res, "_blank");
    //               })
    //               .catch((err) => {
    //                 toast.error(
    //                   "Something went wrong please try again later!!"
    //                 );
    //                 setLoaded(true);
    //                 console.log("err", err);
    //               });
    //           }}
    //           // href={record.driverLicense?.fileName}
    //           // target="_blank"

    //           type="submit"
    //         >
    //           View
    //         </button>
    //       );
    //     } else {
    //       return <span className="font-bold">Document not uploaded</span>;
    //     }
    //   },
    // },

    // {
    //   title: "Expiry Date",
    //   dataIndex: "v5Form",

    //   render: (text, record) =>
    //     record.v5Form?.expiryDate ? (
    //       <div
    //         className={`${getExpiryDateClass(
    //           record.v5Form?.expiryDate
    //         )} text-white font-bold rounded-md px-3 py-2 w-[120px] text-center`}
    //       >
    //         {" "}
    //         {ukDateFormatter(record.v5Form?.expiryDate)}
    //       </div>
    //     ) : (
    //       "N.A."
    //     ),
    // },

    {
      title: "Profile Picture",
      dataIndex: "imageUrl",
      render: (text, record) => {
        if (record?.imageUrl) {
          console.log("Record", record?.imageUrl);
          return (
            <button
              // className="font-bold "
              className="text-white font-bold rounded-md px-3 py-2 w-[80px] text-center"
              onClick={() => {
                setLoaded(false);
                console.log("ANkit");
                axiosInstance
                  .post(URLConstants.getDriverDocument(), {
                    fileName: record?.imageUrl,
                    type: "profilePic",
                    id: record._id,
                  })
                  .then((res) => {
                    setLoaded(true);
                    console.log("Response", res);
                    window.open(res, "_blank");
                  })
                  .catch((err) => {
                    toast.error(
                      "Something went wrong please try again later!!"
                    );
                    setLoaded(true);
                    console.log("err", err);
                  });
              }}
              // href={record.driverLicense?.fileName}
              // target="_blank"

              type="submit"
            >
              View
            </button>
          );
        } else {
          return (
            <span className="font-bold">Profile Picture not uploaded</span>
          );
        }
      },
    },

    {
      title: "Action",
      render: (text, record) => (
        <div>
          <div className="dropdown dropdown-action float-end">
            <a
              href="#"
              className="action-icon dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="material-icons"></i>
            </a>

            <div className="row dropdown-menu dropdown-menu-right noLine">
              <div className="col-md-12 text-black hover:bg-gray-500">
                <Link
                  to={{
                    pathname: `/createdriver`,
                  }}
                  state={record}
                >
                  <span>Edit Driver</span>
                </Link>
              </div>
              <div className="col-md-12">
                <Link
                  to={{
                    pathname: `/vehiclelist`,
                  }}
                  state={record}
                >
                  <i /> Assign Vehicle
                </Link>
              </div>

              <div className="col-md-12">
                <Link
                  to={{
                    pathname: "/blockdriver",
                  }}
                  state={record}
                >
                  <i /> Block Driver
                </Link>
              </div>

              <div className="col-md-12">
                <button onClick={() => disableDeviceForDriver(record?._id)}>
                  <i /> Disable Device
                </button>
              </div>

              <div
                className="col-md-12"
                onClick={() => unblockDriver(record._id)}
              >
                {/* <Link
                  to={{
                    pathname: "/blockdriver",
                    state: { record },
                  }}
                > */}
                <i /> Unblock Driver
                {/* </Link> */}
              </div>

              <div />
            </div>
          </div>
        </div>
      ),
    },
  ];

  const filteredOperatorList =
    query === ""
      ? operatorNames
      : operatorNames?.filter((state) => {
          return state?.companyName.toLowerCase().includes(query.toLowerCase());
        });
  console.log("Vehcile nos", vehicleNos);
  const filteredVehicleNoList =
    query === ""
      ? vehicleNos
      : vehicleNos?.filter((state) => {
          return state?.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <>
      <Header isAdmin />
      <div className="grid lg:grid-cols-6">
        <Leftbar />
        <Helmet>
          <title>Driver List - Treklers - Admin</title>
          <meta name="description" content="Login page" />
        </Helmet>
        <div className="p-8 lg:col-span-5">
          <div className="col-md-12">
            <div className="table-responsive">
              <div className="p-4 grid grid-cols-2 pb-8 mb-3">
                <div>
                  <span className="text-2xl font-bold">Driver List</span>
                  <span className="font-bold p-4">
                    <Link to="/">Home</Link>{" "}
                  </span>
                </div>
                <div className=" float-end ml-auto ">
                  <button
                    className="rounded bg-gray-700 text-white hover:bg-black px-3 py-2 w-auto"
                    onClick={() => ExportToExcel()}
                  >
                    Download Excel
                  </button>
                  <button
                    className="rounded bg-gray-700 text-white hover:bg-black px-3 py-2 w-auto ml-3"
                    onClick={() => ExportToCsv()}
                  >
                    Download CSV
                  </button>
                  {/* <button
                    className="rounded bg-gray-700 text-white hover:bg-black px-3 py-2 w-auto ml-3"
                    // onClick={() => generatePdf()}
                  >
                    Download PDF
                  </button> */}
                </div>
              </div>
              <div className="grid grid-cols-1 pb-4">
                <div className="filter-row leads pt-4">
                  <form
                    onSubmit={handleSubmitFilter((data) => {
                      console.log("Before Date", data);
                      if (filterOptions?.operatorId?.length > 0) {
                        data.operatorId = filterOptions?.operatorId;
                      }

                      // if (filterByNearExpiry) {
                      //   filterByNearExpiry = false;
                      // } else {
                      //   filterByNearExpiry = true;
                      // }
                      data.filterByNearExpiry = filterByNearExpiry;
                      console.log("data filterByNearExpiry", data);
                      if (
                        data?.fromDate?.length == 0 ||
                        data?.toDate?.length == 0
                      ) {
                        delete data.fromDate;
                        delete data.toDate;
                      }

                      if (filterOptions?.vehicleNo?.length > 0) {
                        data.vehicleNo = filterOptions?.vehicleNo;
                      }
                      if (data?.driverMobileNo?.length == 0) {
                        delete data.driverMobileNo;
                      }

                      console.log("After Date", data);

                      axiosInstance
                        .post(URLConstants.getAllDrivers(), data)
                        .then((response) => {
                          console.log(
                            "RESPONSE OF DRIVER LIST",
                            response.results
                          );
                          setDriverData(response.results);
                          setLoaded(true);
                        })
                        .catch((err) => {
                          setLoaded(true);
                        });
                    })}
                  >
                    <div className="d-flex pb-3 align-items-end justify-between">
                      {/* <div className="pb-3 w-25">
                        <label className="col-form-label">Operator</label>
                        <div className="w-full border border-1 border-gray-500 rounded py-1 pr-2">
                          <select
                            {...registerFilter("operatorId")}
                            className={`w-full border-0 outline-0 px-2  ${
                              errorsFilter?.operatorId ? "error-select" : ""
                            }`}
                          >
                            <option value="">Select Any</option>
                            {operatorNames.map(
                              ({ operatorId, companyName }, index) => (
                                <option value={operatorId}>
                                  {companyName}
                                </option>
                              )
                            )}
                          </select>
                          <small>{errorsFilter?.optId?.message}</small>
                        </div>
                      </div> */}
                      {!["Passenger", "Driver", "OPERATOR"].includes(
                        client.logInType
                      ) ? (
                        <div className="col-3">
                          <span>Operator</span>
                          <Combobox
                            value={displayFilterValue?.operatorName ?? ""}
                            onChange={(value) => {
                              // filterOptions.operatorId = value;
                              // setFilterOptions(filterOptions);
                              let optName = operatorNames?.filter(
                                (opt) => opt.operatorId == value
                              );
                              // console.log("OPTname", optName);

                              // displayFilterValue["operatorName"] =
                              //   optName[0].companyName;
                              // console.log("display name", displayFilterValue);
                              // setDisplayFilterValue(displayFilterValue);

                              displayFilterValue.operatorName =
                                optName[0].companyName;
                              setDisplayFilterValue(displayFilterValue);
                              filterOptions.operatorId = optName[0].operatorId;
                              setFilterOptions(filterOptions);
                            }}
                          >
                            <div className="relative mt-1">
                              <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md  2 sm:text-sm">
                                <Combobox.Input
                                  className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                  autocomplete="off"
                                  displayValue={(state) => {
                                    console.log(
                                      "state inside state",
                                      displayFilterValue.operatorName
                                    );
                                    return displayFilterValue.operatorName;
                                  }}
                                  onChange={(event) =>
                                    setQuery(event.target.value)
                                  }
                                />
                                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                  <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </Combobox.Button>
                              </div>
                              <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                                afterLeave={() => setQuery("")}
                              >
                                <Combobox.Options
                                  className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg   sm:text-sm"
                                  style={{
                                    padding: "0px",
                                    margin: "0px",
                                    textAlign: "center",
                                    zIndex: "2",
                                    width: "auto",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {filteredOperatorList.length === 0 &&
                                  query !== "" ? (
                                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                      Nothing found.
                                    </div>
                                  ) : (
                                    filteredOperatorList.map(
                                      ({ operatorId, companyName }) => (
                                        <Combobox.Option
                                          key={operatorId}
                                          className={({ active }) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                              active
                                                ? "bg-[#404298] text-white"
                                                : "text-gray-900"
                                            }`
                                          }
                                          style={{ padding: "15px" }}
                                          value={operatorId}
                                        >
                                          {({ selected, active }) => {
                                            return (
                                              <>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    // flexDirection: "column",
                                                  }}
                                                >
                                                  <span
                                                    className={`block truncate ${
                                                      selected
                                                        ? "font-medium"
                                                        : "font-normal"
                                                    }`}
                                                  >
                                                    {companyName}
                                                  </span>
                                                  {operatorId ===
                                                  filterOptions[
                                                    "operatorId"
                                                  ] ? (
                                                    <span
                                                      className={`pl-3 ${
                                                        active
                                                          ? "text-white"
                                                          : "text-teal-600"
                                                      }`}
                                                    >
                                                      <CheckIcon
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                      />
                                                    </span>
                                                  ) : null}
                                                </div>
                                              </>
                                            );
                                          }}
                                        </Combobox.Option>
                                      )
                                    )
                                  )}
                                </Combobox.Options>
                              </Transition>
                            </div>
                          </Combobox>
                        </div>
                      ) : null}
                      {/* <div className="pb-3 w-25">
                        <label className="col-form-label">Vehicle Number</label>
                        <div className="w-full border border-1 border-gray-500 rounded py-1 pr-2">
                          <select
                            {...registerFilter("vehicleNo")}
                            className={`w-full border-0 outline-0 px-2  ${
                              errorsFilter?.vehicleNo ? "error-select" : ""
                            }`}
                            // onChange={(event) => ChooseOfOperator(event)}
                          >
                            <option value="">Select Any</option>
                            {vehicleNos.map((vehicleNo, index) => (
                              <option value={vehicleNo}>{vehicleNo}</option>
                            ))}
                          </select>
                          <small>{errorsFilter?.vehicleNo?.message}</small>
                        </div>
                      </div> */}

                      <div className="col-3">
                        <span>Vehicle Number</span>
                        <Combobox
                          value={displayFilterValue?.vehicleNo ?? ""}
                          onChange={(value) => {
                            filterOptions.vehicleNo = value;
                            setFilterOptions(filterOptions);
                          }}
                        >
                          <div className="relative mt-1">
                            <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md  2 sm:text-sm">
                              <Combobox.Input
                                className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                autocomplete="off"
                                displayValue={(state) => {
                                  console.log(
                                    "state inside state",
                                    filterOptions.vehicleNo
                                  );
                                  return filterOptions.vehicleNo;
                                }}
                                onChange={(event) =>
                                  setQuery(event.target.value)
                                }
                              />
                              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </Combobox.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                              afterLeave={() => setQuery("")}
                            >
                              <Combobox.Options
                                className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg   sm:text-sm"
                                style={{
                                  padding: "0px",
                                  margin: "0px",
                                  textAlign: "center",
                                  zIndex: "2",
                                  width: "auto",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {filteredVehicleNoList.length === 0 &&
                                query !== "" ? (
                                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                    Nothing found.
                                  </div>
                                ) : (
                                  filteredVehicleNoList.map((vehicleNo) => (
                                    <Combobox.Option
                                      key={vehicleNo}
                                      className={({ active }) =>
                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                          active
                                            ? "bg-[#404298] text-white"
                                            : "text-gray-900"
                                        }`
                                      }
                                      style={{ padding: "15px" }}
                                      value={vehicleNo}
                                    >
                                      {({ selected, active }) => {
                                        return (
                                          <>
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                // flexDirection: "column",
                                              }}
                                            >
                                              <span
                                                className={`block truncate ${
                                                  selected
                                                    ? "font-medium"
                                                    : "font-normal"
                                                }`}
                                              >
                                                {vehicleNo}
                                              </span>
                                              {vehicleNo ===
                                              filterOptions["vehicleNo"] ? (
                                                <span
                                                  className={`pl-3 ${
                                                    active
                                                      ? "text-white"
                                                      : "text-teal-600"
                                                  }`}
                                                >
                                                  <CheckIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                              ) : null}
                                            </div>
                                          </>
                                        );
                                      }}
                                    </Combobox.Option>
                                  ))
                                )}
                              </Combobox.Options>
                            </Transition>
                          </div>
                        </Combobox>
                      </div>
                      <div className="col-3">
                        <div className="mb-2">Driver Mobile No </div>
                        <input
                          type="text"
                          className="w-full col-12 border p-1 px-8 rounded"
                          style={{ height: "36px" }}
                          {...registerFilter("driverMobileNo", {
                            required: false,
                          })}
                        />
                      </div>
                      <div className="col-3">
                        {/* <div className="mb-2">Driver Mobile No </div>
                        <input
                          type="text"
                          className="w-full col-12 border p-1 px-8 rounded"
                          style={{ height: "36px" }}
                          {...registerFilter("driverMobileNo", {
                            required: false,
                          })}
                        /> */}

                        <label>Filter by Near Expiry Dates </label>
                        <div className="w-full border border-1 border-dark rounded p-2">
                          <select
                            className="w-full border-0 outline-0"
                            defaultValue={false}
                            // {...registerFilter("filterByNearExpiry", {
                            //   required: false,
                            // })}

                            onChange={(e) => setFilterExpiry(e.target.value)}
                          >
                            <option value={null}>Select</option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                          </select>
                        </div>
                        {errors.filterByNearExpiry && (
                          <p>Gender field is required</p>
                        )}
                      </div>
                    </div>
                    <div className="d-flex pb-3 align-items-end ">
                      <div className="col-4">
                        <div className="mb-2">From Date </div>
                        <input
                          type="date"
                          className="w-full col-12 border p-1 px-8 rounded"
                          style={{ height: "36px" }}
                          {...registerFilter("fromDate")}
                        />
                        {errorsFilter.fromDate && <p>Date is required</p>}
                      </div>
                      <div className="col-4">
                        <div className="mb-2">To Date </div>
                        <input
                          type="date"
                          className="col-12 border p-1 ps-2 rounded"
                          style={{ height: "36px" }}
                          {...registerFilter("toDate")}
                        />
                        {errorsFilter.toDate && <p>Date is required</p>}
                      </div>

                      <div className="col-sm-1 col-md-2 text-right pb-3 pl-5">
                        <div>
                          <input
                            className="w-auto m-auto px-4 py-2"
                            type="submit"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="spinner">
                      <Loader
                        loaded={loaded}
                        lines={13}
                        length={20}
                        width={10}
                        radius={30}
                        corners={1}
                        rotate={0}
                        direction={1}
                        color="#000"
                        speed={1}
                        trail={60}
                        shadow={false}
                        hwaccel={false}
                        className="spinner"
                        zIndex={2e9}
                        top="50%"
                        left="50%"
                        scale={1.0}
                        loadedClassName="loadedContent"
                      />
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-auto float-end ml-auto mb-6 z-50 fixed bottom-10 right-20 pl-4">
                <Link
                  className=" flex justify-center items-center no-underline pl-12 bg-[#404298] p-2 rounded-full scale-150  border-0  focus:outline-none hover:bg-gray-700  text-white "
                  to="/#"
                  data-bs-toggle="modal"
                  data-bs-target="#add_leave"
                  onClick={() => setLoaded(true)}
                >
                  <div>
                    <i className="fa fa-plus m-2 px-1 noLine no-underline" />
                  </div>
                </Link>
              </div>

              <Table
                className="table-striped"
                pagination={{
                  total: driverData.length,
                  showTotal: (total, range) =>
                    `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                  showSizeChanger: true,
                  onShowSizeChange: onShowSizeChange,
                  itemRender: itemRender,
                }}
                style={{ overflowX: "auto" }}
                columns={columns}
                bordered
                dataSource={driverData}
              />
            </div>
          </div>
        </div>
        <div id="add_leave" className="modal custom-modal fade" role="dialog">
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content border-0">
              {/* <div className="">
                <h3 className="text-xl modal-title text-center">
                  Create Driver
                </h3>
                <button
                  type="button"
                  className="close text-white opacity-100"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div> */}

              <div className="modal-header bg-[#404298] text-white text-center">
                <h5 className="modal-title text-center font-bold">
                  Create Driver
                </h5>
                <button
                  type="button"
                  className="close text-white opacity-100"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="modal-body">
                  <div className="grid grid-cols-2 gap-8 gap-y-4 mb-8 p-2">
                    <div>
                      <label>First Name</label>
                      <input {...register("firstName", { required: true })} />
                      {errors.firstName && <p>First Name is required</p>}
                    </div>

                    <div>
                      <label>Last Name</label>
                      <input {...register("lastName", { required: true })} />
                      {errors.lastName && <p>Last Name is required</p>}
                    </div>
                    {/* <div>
                      <label>Gender</label>
                      <input {...register("gender", { required: true })} />
                      {errors.gender && <p>Gender is required</p>}
                    </div> */}
                    <div>
                      <label>Gender</label>
                      <div className="w-full border border-1 border-dark rounded p-2">
                        <select
                          className="w-full border-0 outline-0"
                          {...register("gender", { required: true })}
                        >
                          <option value="Female">Female</option>
                          <option value="Male">Male</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                      {errors.gender && <p>Gender field is required</p>}
                    </div>

                    <div>
                      <label>Email</label>
                      <input
                        type="email"
                        {...register("email", { required: true })}
                      />
                      {errors.email && <p>Email field is required</p>}
                    </div>
                    <div>
                      <label>Mobile Number</label>
                      <input
                        type="number"
                        {...register("mobileNo", {
                          required: true,
                        })}
                      />
                      {errors.mobileNo && <p>Mobile Number is required</p>}
                    </div>
                  </div>
                  <div className="pb-4 text-center mt-2">
                    {/* <div className="submit-section text-center"> */}
                    <button
                      type="submit"
                      className="w-auto m-auto  text-white rounded px-4 py-2"
                      // onClick={() => navigate("/driverlist")}
                    >
                      {" "}
                      Submit
                    </button>
                  </div>
                </div>
                <div className="spinner">
                  <Loader
                    loaded={loaded}
                    lines={13}
                    length={20}
                    width={10}
                    radius={30}
                    corners={1}
                    rotate={0}
                    direction={1}
                    color="#000"
                    speed={1}
                    trail={60}
                    shadow={false}
                    hwaccel={false}
                    className="spinner"
                    zIndex={2e9}
                    top="50%"
                    left="50%"
                    scale={1.0}
                    loadedClassName="loadedContent"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default DriverList;
