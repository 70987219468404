import React, { useRef } from "react";
import { Link } from "react-router-dom";
import treklogo from "../../images/treklers_logo.png";
import googlePlayStoreIcon from "../../images/google-playstore-icon.png";
import Transport4LondonLog from "../../images/transport-for-london-1-logo-black-and-white 1.png";

import appleStoreIcon from "../../images/apple-store-icon.png";
import { BackIcon } from "../shared/icons";
function Footer() {
  const titleRef = useRef();
  function handleClick() {
    titleRef.current.scrollIntoView({ behavior: "smooth" });
  }
  return (
    <footer className="text-gray-600 body-font bg-[#1B1C4F] ">
      <div className="flex-shrink-0 md:mx-0 mx-auto text-center md:text-left px-40  pt-16">
        <a className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
          <Link className="font-bold hover:text-inherit" to="/">
            <img src={treklogo} className="h-10 object-contain" alt="trek" />
          </Link>
        </a>
      </div>
      <div className=" px-20 pt-8 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
        <div className="flex-grow flex flex-wrap md:mt-0 mt-10 md:text-left text-center items-center">
          <div className="md:w-1/2 lg:w-1/4 w-full xs:px-5  md:pl-20 sm:pl-0  pr-0 flex flex-col items-start  text-left">
            <h2 className="title-font font-medium text-lg tracking-widest -mt-1 mb-3 text-white">
              Operator
            </h2>
            <nav className="list-none mb-10">
              <li>
                <a
                  href="javascript:void(0)"
                  className="text-gray-400 hover:text-gray-200"
                >
                  <div className="flex-row flex justify-start gap-2 items-center">
                    {BackIcon}
                    Investors
                  </div>
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  className="text-gray-400 hover:text-gray-200"
                >
                  <div className="flex-row flex justify-start gap-2 items-center">
                    {BackIcon}
                    Blog
                  </div>
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  className="text-gray-400 hover:text-gray-200"
                >
                  <div className="flex-row flex justify-start gap-2 items-center">
                    {BackIcon}
                    Careers
                  </div>
                </a>
              </li>
              <li>
                <a
                  href="https://treklers.betteruptime.com/"
                  className="text-gray-400 hover:text-gray-200"
                  target="_blank"
                >
                  <div className="flex-row flex justify-start gap-2 items-center">
                    {BackIcon}
                    Monitor
                  </div>
                </a>
              </li>
            </nav>
          </div>
          <div className="md:w-1/2 lg:w-1/4 xs:px-5  w-full pl-10 pr-0 sm:pl-0  flex flex-col items-start text-left">
            <h2 className="title-font font-medium text-lg tracking-widest -mt-1 mb-3 text-white">
              Products
            </h2>
            <nav className="list-none mb-10">
              <li>
                <a
                  href="javascript:void(0)"
                  className="text-gray-400 hover:text-gray-200"
                >
                  <div className="flex-row flex justify-start gap-2 items-center">
                    {BackIcon}
                    Ride
                  </div>
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  className="text-gray-400 hover:text-gray-200"
                >
                  <div className="flex-row flex justify-start gap-2 items-center">
                    {BackIcon}
                    Drive
                  </div>
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  className="text-gray-400 hover:text-gray-200"
                >
                  <div className="flex-row flex justify-start gap-2 items-center">
                    {BackIcon}
                    Treklers for Business
                  </div>
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  className="text-gray-400 hover:text-gray-200"
                >
                  <div className="flex-row flex justify-start gap-2 items-center">
                    {BackIcon}
                    Treklers Frieght
                  </div>
                </a>
              </li>
            </nav>
          </div>
          <div className="md:w-1/2 lg:w-1/4 xs:px-5  w-full pl-10 pr-0 sm:pl-0  flex flex-col items-start text-left">
            <h2 className="title-font font-medium text-lg tracking-widest -mt-1 mb-3 text-white">
              More Info
            </h2>
            <nav className="list-none mb-10">
              <li>
                <Link
                  to="/aboutUs"
                  onClick={handleClick}
                  className="text-gray-400 hover:text-gray-200"
                >
                  <div className="flex-row flex justify-start gap-2 items-center">
                    {BackIcon}
                    About Us
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="/privacypolicy"
                  onClick={handleClick}
                  className="text-gray-400 hover:text-gray-200"
                >
                  <div className="flex-row flex justify-start gap-2 items-center">
                    {BackIcon}
                    Privacy Policy
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="/faq"
                  onClick={handleClick}
                  className="text-gray-400 hover:text-gray-200"
                >
                  <div className="flex-row flex justify-start gap-2 items-center">
                    {BackIcon}
                    FAQ
                  </div>
                </Link>
              </li>
            </nav>
          </div>
          {/* <div className="md:w-1/2 lg:w-1/4 xs:px-5 w-full pl-10 pr-0 sm:pl-0  flex flex-col items-start text-left">
            <h2 className="title-font font-medium text-lg tracking-widest -mt-1 mb-3 text-white">
              Download Our App
            </h2>
            <div className="flex  items-center justify-between gap-2 flex-row w-full">
              <div className="flex-1 lg:h-[42px]">
                <img
                  src={appleStoreIcon}
                  className="h-full w-full object-fit contain"
                  alt="apple-store"
                />
              </div>
              <div className="flex-1 lg:h-[42px]">
                <img
                  src={googlePlayStoreIcon}
                  className="h-full w-full object-fit contain"
                  alt="google-playstore"
                />
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <div className="h-[2px] bg-white my-6"></div>
      <div className="flex flex-row justify-between items-center">
        <div className="px-36 pb-3 bg-transparent z-10">
          <p className="text-gray-200 text-sm text-left sm:text-left">
            © 2023 Treklers —
            <a
              href="https://twitter.com"
              rel="noopener noreferrer"
              className="text-gray-200 ml-1"
              target="_blank"
            >
              @Treklers.co.uk
            </a>
          </p>
        </div>
        <div className="px-36 pb-3 bg-transparent z-10">
          <div className="text-gray-200 text-sm text-left sm:text-left flex flex-row items-center space-x-2">
            <span> Licensed by Transport for London </span>
            <span>
              <img src={Transport4LondonLog} />
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}
{
  /* <Link className=" ml-3 font-bold" to="/loginscreen">
    Dashboard
</Link> */
}
export default Footer;
