import React, { useEffect, useState, useContext } from "react";
import { Leftbar } from "./sidebar/Leftbar";
import toast, { Toaster } from "react-hot-toast";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import { Table } from "antd";
import { Helmet } from "react-helmet";
import { itemRender, onShowSizeChange } from "./paginationfunction";
import { axiosInstance } from "../base/api/axios.util";
import { URLConstants } from "../base/api/url.constants";
import { ClientContext } from "../base/contexts/UserContext";
import Loader from "react-loader";
import { useForm, Controller } from "react-hook-form";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { Fragment } from "react";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import convertDate from "../base/utils/EnglishDateTime";
function TripList() {
  const { client, setClient } = useContext(ClientContext);
  const [driverInfo, setDriverInfo] = useState({});
  const [tripData, setTripData] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [loaded, setLoaded] = useState(true);
  const [updateTime, setUpdateTime] = useState(10);
  const [vehicleNos, setVehicleNos] = useState([]);
  const [filter, setFilter] = useState({});
  let [filterOptions, setFilterOptions] = useState({});
  const [query, setQuery] = useState("");
  const [reloadPage, setReloadPage] = useState(false);
  const [error, setError] = useState({});
  const [mobileNumber, setMobileNumber] = useState("");
  let [displayFilterValue, setDisplayFilterValue] = useState({});

  const {
    register: registerFilter,
    handleSubmit: handleSubmitFilter,

    formState: { errors: errorsFilter },
    reset: resetFilter,
  } = useForm({});

  const navigate = useNavigate();

  const ExportToExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const outputFilename = `${Date.now()}`;

    const customHeadings = tripData?.map((item) => ({
      "Trip Id": item.tripId,
      Source: item.source[0]?.address,
      Destination: item.destination[0]?.address,
      Passenger: item.impactedRidesForPassenger.passengerMobileNo,
      "Trip Time": item?.impactedTime ? convertDate(item?.impactedTime) : "-",

      "Driver Name": item.driverDetails.name,
      "Contact Number": item.driverDetails.mobileNo,
    }));

    exportToExcel(customHeadings, outputFilename, fileExtension, fileType);
  };

  const exportToExcel = (customHeadings, fileName, fileExtension, fileType) => {
    const ws = XLSX.utils.json_to_sheet(customHeadings);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const ExportToCsv = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".csv";
    const outputFilename = `${Date.now()}`;

    const customHeadings = tripData?.map((item) => ({
      "Trip Id": item.tripId,
      Source: item.source[0]?.address,
      Destination: item.destination[0]?.address,
      Passenger: item.impactedRidesForPassenger.passengerMobileNo,
      "Trip Time": item?.impactedTime ? convertDate(item?.impactedTime) : "-",

      "Driver Name": item.driverDetails.name,
      "Contact Number": item.driverDetails.mobileNo,
    }));

    exportToCsv(customHeadings, outputFilename, fileExtension, fileType);
  };

  const exportToCsv = (customHeadings, fileName, fileExtension, fileType) => {
    const ws = XLSX.utils.json_to_sheet(customHeadings);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  function searchDriver() {
    setLoaded(false);
    axiosInstance
      .get(URLConstants.getDriverInfoByMobileNo(mobileNumber))
      .then((res) => {
        setDriverInfo(res);
        console.log("DRIVER INFO", res);
        setLoaded(true);
      })
      .catch((err) => {
        setLoaded(true);
        if (err.response.status == 404) {
          setError({ status: true, message: "Driver Not Found" });
        } else if (err.response.status == 400) {
          setError({ status: true, message: "Driver is Blocked" });
        } else {
          setError({
            status: true,
            message: "Something went wrong try again later",
          });
        }
        console.log("err", err.response.status);
      });
  }

  function assignRide() {
    let newRecord = selectedRecord;
    newRecord.driverDetails = driverInfo;
    console.log("FInal body made", newRecord);
    console.log("OLD RECORD", selectedRecord);
    axiosInstance
      .post(URLConstants.assignDriverToTrip(newRecord?.tripId), newRecord)
      .then((res) => {
        console.log("Resoinse after sending request", res);
        toast.success("Driver Assigned Successfully!!");
        reset();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong! Try Again later");
        reset();
      });
  }

  function reset() {
    setError({ status: false, message: "" });
    setMobileNumber("");
    setDriverInfo({});
  }

  useEffect(() => {
    axiosInstance
      .get(URLConstants.getOperatorVehicleNo(client?.loginData.client.id))
      .then((response) => {
        console.log("CARd daeta", response);
        // response?.operatorVehicles[0]?.vehicleNumbers.unshift("ALL");

        if (response?.operatorVehicles[0]?.vehicleNumbers) {
          setVehicleNos(response?.operatorVehicles[0]?.vehicleNumbers);
        } else {
          setVehicleNos([]);
        }
        // setLoaded(true);
      })
      .catch((err) => {
        console.log("Error Triggered", err);

        // setLoaded(true);
        console.log(err);
      });
  }, []);

  useEffect(() => {
    let intervalId; // Variable to hold the interval ID

    function fetchData() {
      console.log("Logged in client", client);
      if (!client || client.logInType == "DRIVER") {
        navigate("/");
      } else {
        console.log("FILTER", filter);

        axiosInstance
          .post(URLConstants.getImpactedTrips(client.loginData.id), filter)
          .then((response) => {
            if (!response?.results) {
              setTripData([]);
            } else {
              console.log("Imoacted trips", response);
              setTripData(response?.results);
            }
            // setLoaded(true);
          })
          .catch((err) => {
            // setLoaded(true);
          });
      }
    }

    // Call fetchData initially
    fetchData();

    // Set up an interval to call fetchData every 10 seconds
    intervalId = setInterval(fetchData, 10000);

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [filter, reloadPage]);

  function cancelTrip(tripInfo) {
    console.log("CANCEL TRIP", tripInfo);

    axiosInstance
      .get(URLConstants.cancelTrip(tripInfo?.tripId, true))
      .then((res) => {
        console.log("response", res);
        toast.success("Trip Cancelled");
        setReloadPage(!reloadPage);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Could not cancel trip please try again later");
      });
  }

  const columns = [
    {
      title: "Trip id",
      dataIndex: "tripId",
    },
    {
      title: "Observed Time",
      dataIndex: "observedTime",
      render: (text, record) =>
        record?.impactedRidesForPassenger?.observedTime
          ? convertDate(record?.impactedRidesForPassenger?.observedTime)
          : "-",
    },
    {
      title: "Description",
      dataIndex: "description",

      render: (text, record) => record?.impactedRidesForPassenger?.description,
    },

    {
      title: "Passenger Mobile Number",
      dataIndex: "passengerMobileNo",
      render: (text, record) =>
        record?.impactedRidesForPassenger.passengerMobileNo,
    },
    {
      title: "Trip Time",
      dataIndex: "passengerMobileNo",
      render: (text, record) =>
        record?.impactedTime ? convertDate(record?.impactedTime) : "-",
    },

    {
      title: "Source",
      dataIndex: "observedTime",
      render: (text, record) => record?.source[0]?.address,
    },
    {
      title: "Destination",
      dataIndex: "observedTime",
      render: (text, record) => record?.destination[0]?.address,
    },
    {
      title: "Driver Name",
      dataIndex: "driverName",
      render: (text, record) =>
        record?.driverDetails?.name
          ? record?.driverDetails?.name
          : "Not Assigned",
    },
    {
      title: "Driver Mobile No.",
      dataIndex: "driverName",
      render: (text, record) =>
        record?.driverDetails?.mobileNo
          ? record?.driverDetails?.mobileNo
          : "Not Assigned",
    },
    {
      title: "Assign Driver",

      render: (text, record) => (
        <Link
          className=""
          to="/#"
          data-bs-toggle="modal"
          data-bs-target="#add_leave"
        >
          <button
            className="w-auto m-auto px-4 py-2 rounded-lg"
            type="submit"
            onClick={() => setSelectedRecord(record)}
          >
            Assign
          </button>
        </Link>
      ),
    },
    {
      title: "Cancel Trip",

      render: (text, record) => (
        <button
          className="w-auto  m-auto px-4 py-2 rounded-lg"
          type="reset"
          onClick={() => cancelTrip(record)}
        >
          Cancel
        </button>
      ),
    },
  ];

  const filteredVehicleNoList =
    query === ""
      ? vehicleNos
      : vehicleNos?.filter((state) => {
          return state?.toLowerCase().includes(query.toLowerCase());
        });
  return (
    <>
      <Header isAdmin />
      <div className="grid lg:grid-cols-6">
        <Leftbar />
        <Helmet>
          <title>Impacted Trips - Treklers - Admin</title>
          <meta name="description" content="Login page" />
        </Helmet>
        <div className="p-8 lg:col-span-5">
          <div className="col-md-12">
            <div className="table-responsive">
              <div className="p-4 grid grid-cols-2 pb-8 mb-3">
                <div>
                  <span className="text-2xl font-bold">Impacted Trips</span>
                  <span className="font-bold p-4">
                    <Link to="/">Home</Link>{" "}
                  </span>
                </div>
                <div className=" float-end ml-auto ">
                  <button
                    className="rounded bg-gray-700 text-white hover:bg-black px-3 py-2 w-auto"
                    onClick={() => ExportToExcel()}
                  >
                    Download Excel
                  </button>
                  <button
                    className="rounded bg-gray-700 text-white hover:bg-black px-3 py-2 w-auto ml-3"
                    onClick={() => ExportToCsv()}
                  >
                    Download CSV
                  </button>
                  {/* <button
                    className="rounded bg-gray-700 text-white hover:bg-black px-3 py-2 w-auto ml-3"
                    // onClick={() => generatePdf()}
                  >
                    Download PDF
                  </button> */}
                </div>
              </div>
              <div className="grid grid-cols-1 pb-4">
                <div className="filter-row leads pt-4">
                  <form
                    onSubmit={handleSubmitFilter((data) => {
                      console.log("Before Date", data);
                      if (data?.operatorId?.length == 0) {
                        delete data.operatorId;
                      }
                      if (
                        data?.fromDate?.length == 0 ||
                        data?.toDate?.length == 0
                      ) {
                        delete data.fromDate;
                        delete data.toDate;
                      }

                      if (filterOptions?.vehicleNo?.length > 0) {
                        data.vehicleNo = filterOptions?.vehicleNo;
                      }
                      if (data?.driverMobileNo?.length == 0) {
                        delete data.driverMobileNo;
                      }
                      if (data?.passengerMobileNo?.length == 0) {
                        delete data.passengerMobileNo;
                      }

                      console.log("After Date", data);
                      setFilter((prevFilter) => ({ ...prevFilter, ...data }));
                      axiosInstance
                        .post(
                          URLConstants.getImpactedTrips(client.loginData.id),
                          data
                        )
                        .then((response) => {
                          if (!response?.results) {
                            setTripData([]);
                          } else {
                            setTripData(response?.results);
                          }
                          // setLoaded(true);
                        })
                        .catch((err) => {
                          // setLoaded(true);
                        });
                    })}
                  >
                    <div className="d-flex pb-3 align-items-end ">
                      {/* <div className="col-4">
                        <label className="col-form-label">Vehicle Number</label>
                        <div className="w-full border border-1 border-gray-500 rounded py-1 pr-2">
                          <select
                            {...registerFilter("vehicleNo")}
                            className={`w-full border-0 outline-0 px-2  ${
                              errorsFilter?.vehicleNo ? "error-select" : ""
                            }`}
                            // onChange={(event) => ChooseOfOperator(event)}
                          >
                            <option value="">Select Any</option>
                            {vehicleNos.map((vehicleNo, index) => (
                              <option value={vehicleNo}>{vehicleNo}</option>
                            ))}
                          </select>
                          <small>{errorsFilter?.vehicleNo?.message}</small>
                        </div>
                      </div> */}

                      <div className="col-4">
                        <span>Vehicle Number</span>
                        <Combobox
                          value={displayFilterValue?.vehicleNo ?? ""}
                          onChange={(value) => {
                            filterOptions.vehicleNo = value;
                            setFilterOptions(filterOptions);
                          }}
                        >
                          <div className="relative mt-1">
                            <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md  2 sm:text-sm">
                              <Combobox.Input
                                className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                autocomplete="off"
                                displayValue={(state) => {
                                  console.log(
                                    "state inside state",
                                    filterOptions.vehicleNo
                                  );
                                  return filterOptions.vehicleNo;
                                }}
                                onChange={(event) =>
                                  setQuery(event.target.value)
                                }
                              />
                              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </Combobox.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                              afterLeave={() => setQuery("")}
                            >
                              <Combobox.Options
                                className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg   sm:text-sm"
                                style={{
                                  padding: "0px",
                                  margin: "0px",
                                  textAlign: "center",
                                  zIndex: "2",
                                  width: "auto",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {(filteredVehicleNoList?.length === 0 ||
                                  filteredVehicleNoList) &&
                                query !== "" ? (
                                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                    Nothing found.
                                  </div>
                                ) : (
                                  filteredVehicleNoList?.map((vehicleNo) => (
                                    <Combobox.Option
                                      key={vehicleNo}
                                      className={({ active }) =>
                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                          active
                                            ? "bg-[#404298] text-white"
                                            : "text-gray-900"
                                        }`
                                      }
                                      style={{ padding: "15px" }}
                                      value={vehicleNo}
                                    >
                                      {({ selected, active }) => {
                                        return (
                                          <>
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                // flexDirection: "column",
                                              }}
                                            >
                                              <span
                                                className={`block truncate ${
                                                  selected
                                                    ? "font-medium"
                                                    : "font-normal"
                                                }`}
                                              >
                                                {vehicleNo}
                                              </span>
                                              {vehicleNo ===
                                              filterOptions["vehicleNo"] ? (
                                                <span
                                                  className={`pl-3 ${
                                                    active
                                                      ? "text-white"
                                                      : "text-teal-600"
                                                  }`}
                                                >
                                                  <CheckIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                              ) : null}
                                            </div>
                                          </>
                                        );
                                      }}
                                    </Combobox.Option>
                                  ))
                                )}
                              </Combobox.Options>
                            </Transition>
                          </div>
                        </Combobox>
                      </div>
                      <div className="col-4">
                        <div className="mb-2">Driver Mobile No </div>
                        <input
                          type="text"
                          className="w-full col-12 border p-1 px-8 rounded"
                          style={{ height: "36px" }}
                          {...registerFilter("driverMobileNo", {
                            required: false,
                          })}
                        />
                      </div>
                      <div className="col-4">
                        <div className="mb-2">Passenger Mobile No </div>
                        <input
                          type="text"
                          className="w-full col-12 border p-1 px-8 rounded"
                          style={{ height: "36px" }}
                          {...registerFilter("passengerMobileNo", {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                    <div className="d-flex pb-3 align-items-end ">
                      <div className="col-4">
                        <div className="mb-2">From Date </div>
                        <input
                          type="date"
                          className="w-full col-12 border p-1 px-8 rounded"
                          style={{ height: "36px" }}
                          {...registerFilter("fromDate", { required: true })}
                        />
                        {errorsFilter.fromDate && <p>Date is required</p>}
                      </div>
                      <div className="col-4">
                        <div className="mb-2">To Date </div>
                        <input
                          type="date"
                          className="col-12 border p-1 ps-2 rounded"
                          style={{ height: "36px" }}
                          {...registerFilter("toDate", { required: true })}
                        />
                        {errorsFilter.toDate && <p>Date is required</p>}
                      </div>

                      <div className="col-sm-1 col-md-2 text-right pb-3 pl-5">
                        <div>
                          <input
                            className="w-auto m-auto px-4 py-2"
                            type="submit"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="spinner">
                      <Loader
                        loaded={loaded}
                        lines={13}
                        length={20}
                        width={10}
                        radius={30}
                        corners={1}
                        rotate={0}
                        direction={1}
                        color="#000"
                        speed={1}
                        trail={60}
                        shadow={false}
                        hwaccel={false}
                        className="spinner"
                        zIndex={2e9}
                        top="50%"
                        left="50%"
                        scale={1.0}
                        loadedClassName="loadedContent"
                      />
                    </div>
                  </form>
                </div>
              </div>

              <Table
                className="table-striped"
                pagination={{
                  total: tripData.length,
                  showTotal: (total, range) =>
                    `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                  showSizeChanger: true,
                  onShowSizeChange: onShowSizeChange,
                  itemRender: itemRender,
                }}
                style={{ overflowX: "auto" }}
                columns={columns}
                bordered
                dataSource={tripData}
                rowKey={(record) => record.id}
              />
            </div>
          </div>
        </div>
        <div className="spinner">
          {loaded && (
            <Loader
              loaded={loaded}
              lines={13}
              length={20}
              width={10}
              radius={30}
              corners={1}
              rotate={0}
              direction={1}
              color="#000"
              speed={1}
              trail={60}
              shadow={false}
              hwaccel={false}
              className="spinner"
              zIndex={2e9}
              top="50%"
              left="50%"
              scale={1.0}
              loadedClassName="loadedContent"
            />
          )}
        </div>

        <div id="add_leave" className="modal custom-modal fade" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content modalSize">
              <div className="">
                <div className="modal-header bg-[#404298] text-white text-center">
                  <h5 className="modal-title text-center font-bold">
                    Assign Driver
                  </h5>
                  <button
                    type="button"
                    className="close text-white opacity-100"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      reset();
                    }}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="form-group mx-4 mt-2">
                  <div className="mb-2 font-bold">
                    Enter Driver Phone Number{" "}
                  </div>
                  <input
                    type="number"
                    className=""
                    value={mobileNumber}
                    onChange={(e) => {
                      setMobileNumber(e.target.value.toString());
                      setError({ status: false, message: "" });
                    }}
                  />
                </div>

                {error.status ? (
                  <span className="ml-4 text-red-600 font-semibold mb-10">
                    No Driver Found with this Number ! Try another number
                  </span>
                ) : null}
                <div className="pb-4 text-center mt-2">
                  {/* <button data-dismiss="modal" type="submit" /> */}
                  <button
                    type="submit"
                    className="w-auto m-auto  text-white rounded px-4 py-2"
                    onClick={() => searchDriver()}
                  >
                    Search
                  </button>
                </div>

                <div className=" flex justify-center items-center">
                  <span className="mb-2 font-bold">OR</span>
                </div>
                <div className="pb-4 text-center mt-2">
                  {/* <button data-dismiss="modal" type="submit" /> */}
                  <button
                    type="submit"
                    className="w-auto m-auto  text-white rounded px-4 py-2"
                    onClick={() => assignRide()}
                  >
                    Open for all
                  </button>
                </div>

                {driverInfo?.name ? (
                  <>
                    <div className="ml-4 flex flex-col justify-center ">
                      <div>
                        <span className="text-[#404298] font-bold mr-2">
                          Driver Name :{" "}
                        </span>
                        <span className=" font-semibold ml-2">
                          {driverInfo.name}
                        </span>
                      </div>
                      <div>
                        <span className="text-[#404298] font-bold mr-2">
                          Email :{" "}
                        </span>
                        <span className=" font-semibold ml-2">
                          {driverInfo.email}
                        </span>
                      </div>
                      {driverInfo?.vehicleDetails?.vehicleNo ? (
                        <div>
                          <span className="text-[#404298] font-bold mr-2">
                            Vehicle Number :{" "}
                          </span>
                          <span className=" font-semibold ml-2">
                            {driverInfo?.vehicleDetails?.vehicleNo}
                          </span>
                        </div>
                      ) : null}
                      {driverInfo?.vehicleDetails?.vehicleName ? (
                        <div>
                          <span className="text-[#404298] font-bold mr-2">
                            Vehicle Name :{" "}
                          </span>
                          <span className=" font-semibold ml-2">
                            {driverInfo?.vehicleDetails?.vehicleName}
                          </span>
                        </div>
                      ) : null}
                      {driverInfo?.vehicleDetails?.noOfSeats ? (
                        <div>
                          <span className="text-[#404298] font-bold mr-2">
                            No. of Seats :{" "}
                          </span>
                          <span className=" font-semibold ml-2">
                            {driverInfo?.vehicleDetails?.noOfSeats}
                          </span>
                        </div>
                      ) : null}
                      {driverInfo?.vehicleDetails?.noOfBuggies ? (
                        <div>
                          <span className="text-[#404298] font-bold mr-2">
                            No. of Buggies :{" "}
                          </span>
                          <span className=" font-semibold ml-2">
                            {driverInfo?.vehicleDetails?.noOfBuggies}
                          </span>
                        </div>
                      ) : null}
                    </div>
                    <div className="pb-4 text-center mt-2">
                      {/* <button data-dismiss="modal" type="submit" /> */}
                      <button
                        type="submit"
                        className="w-auto m-auto  text-white rounded px-4 py-2"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          assignRide();
                        }}
                      >
                        Assign
                      </button>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TripList;
