import React, { useContext } from "react";
import { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import { ClientContext } from "../../base/contexts/UserContext";
import { useAuth } from "../../base/hooks/useAuth.js";
// import JobBooking from "../JobBooking.js";
import treklogo from "../../images/treklers_logo.png";
import herobg from "../../images/hero-bg.png";

const links = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "About Us",
    link: "/about",
  },
  {
    name: "Blog",
    link: "/blog",
  },
  {
    name: "FAQ'S",
    link: "/faq",
  },
  {
    name: "Contact",
    link: "/contact-us",
  },
  {
    name: "Sign In",
    link: "/sign-in",
  },
];

export default function Header(props) {
  const { client, setClient } = useContext(ClientContext);

  const { logout } = useAuth();

  function DriverLogin() {
    props.setName("Driver");
  }

  function EmployerLogin() {
    props.setName("Employer");
  }

  return (
    <>
      <header
        className={`text-white body-font z-[100] ${
          props.isAdmin ? "relative bg-[#080941]" : "fixed bg-white"
        } top-0 left-0 w-screen`}
      >
        <div
          className={`mx-auto ${
            props.isAdmin ? "relative" : "absolute top-0 right-0 left-0"
          } flex flex-wrap lg:px-36 px-12 py-3 flex-row justify-between items-center`}
        >
          <a className="flex title-font font-medium items-center text-gray-900">
            <Link
              className="ml-3 font-bold hover:text-inherit"
              to="/loginscreen"
            >
              <img src={treklogo} className="h-10 object-contain" alt="trek" />
            </Link>
          </a>
          <nav className="md:ml-4 md:py-1 md:pl-4	flex flex-wrap items-center text-base justify-end">
            {links.map((v) => (
              <a
                className="mr-6 uppercase tracking-wide hover:text-white hover:underline"
                href={v.link}
              >
                {v.name}
              </a>
            ))}
            <button className="inline-flex items-center bg-white font-semibold border-0 py-2 px-3 focus:outline-none hover:bg-neutral-700 rounded-full text-black">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21.234"
                height="21.234"
                viewBox="0 0 21.234 21.234"
              >
                <path
                  id="Icon_awesome-globe-africa"
                  data-name="Icon awesome-globe-africa"
                  d="M10.617.563A10.617,10.617,0,1,0,21.234,11.179,10.617,10.617,0,0,0,10.617.563Zm6.85,9.225v.3a.663.663,0,0,1-.367.593l-.659.33a.663.663,0,0,1-.665-.042l-.78-.52a.664.664,0,0,0-.578-.077l-.113.038a.663.663,0,0,0-.342,1l.567.85a.663.663,0,0,0,.552.3h.351a.664.664,0,0,1,.664.664V13.7a.664.664,0,0,1-.133.4l-.8,1.069a.667.667,0,0,0-.121.275l-.184.977a.665.665,0,0,1-.2.366,6.833,6.833,0,0,0-1.07,1.248l-.558.837a1.188,1.188,0,0,1-2.051-.128,3.378,3.378,0,0,1-.357-1.511v-1.28a.664.664,0,0,0-.664-.664H8.845A2.339,2.339,0,0,1,6.507,12.95v-.6a2.34,2.34,0,0,1,.935-1.871l1.181-.886a2.34,2.34,0,0,1,1.4-.468h.038a2.334,2.334,0,0,1,1.046.247l.63.315a.663.663,0,0,0,.506.036l2.025-.675a.663.663,0,0,0-.21-1.293H13.63a.663.663,0,0,1-.469-.194l-.3-.3a.663.663,0,0,0-.469-.194H8.541a.664.664,0,0,1-.664-.664V6.217a.664.664,0,0,1,.5-.644L9,5.419a.665.665,0,0,0,.391-.276l.346-.518a.663.663,0,0,1,.552-.3h1.036a.664.664,0,0,0,.664-.664V2.737a8.574,8.574,0,0,1,6.932,6.388H18.13A.664.664,0,0,0,17.466,9.788Z"
                  transform="translate(0 -0.563)"
                  fill="#080941"
                />
              </svg>
              <span className="pl-2">EN</span>
            </button>
          </nav>
        </div>
        <Toaster />
      </header>
    </>
  );
}

function EditInactiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 13V16H7L16 7L13 4L4 13Z"
        fill="#EDE9FE"
        stroke="#A78BFA"
        strokeWidth="2"
      />
    </svg>
  );
}
