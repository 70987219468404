import Footer from "./footer/Footer";
import Header from "./header/Header";
import Hero from "./shared/Hero";
import customerSecurity from "../images/customer-security.svg";
import customerSupport from "../images/customer-support.svg";
import driveForTrek from "../images/drive-for-trek.svg";
import contactUsCTA from "../images/contact-us-cta.png";
import contactUsInfo from "../images/contact-us-info.png";

const features = [
  {
    image: customerSupport,
    title: "Customer Support",
    description:
      "For support with your bookings and other queries, visit the Support section in the Treklers app.",
  },
  {
    image: driveForTrek,
    title: "Drive for Treklers",
    description:
      "If you are a driver or a fleet manager and want to attach your vehicle with Treklers",
  },
  {
    image: customerSecurity,
    title: "Customer Security",
    description: `If you have security concerns, please report your issues with Treklers at <a href="mailto:support@treklers.co.uk">support@treklers.co.uk</a>`,
  },
];

export default function ContactUsPage() {
  return (
    <div>
      <Header isAdmin />
      <Hero title={"Get in Touch"} />
      <div className="flex flex-col w-full items-center justify-center">
        <h1 className="font-bold text-3xl">Reach the Appropiate Team</h1>
        <div className="flex justify-around items-center mt-6">
          {features.map((v) => (
            <div className="flex flex-col items-center justify-center p-4 max-w-md">
              <img src={v.image} className="h-20" />
              <h1 className="font-bold text-xl mt-4 uppercase">{v.title}</h1>
              <p
                className="text-center text-sm text-gray-700 mt-2"
                dangerouslySetInnerHTML={{
                  __html: v.description,
                }}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col w-full items-center justify-center mt-8 bg-[#F3F3FD] py-6">
        <h1 className="font-bold text-4xl">Enquire Here</h1>
        <p className="mt-1 text-xl">Always here to help</p>
        <form className="flex flex-col w-full max-w-2xl mt-6">
          <div className="flex w-full px-3 bg-white py-1 rounded-lg items-center shadow-lg mt-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
              />
            </svg>
            <input
              className="w-full ml-2 border-none focus:outline-none"
              placeholder="Name"
              type="text"
            />
          </div>
          <div className="flex w-full px-3 bg-white py-1 rounded-lg items-center shadow-lg mt-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25"
              />
            </svg>

            <input
              className="w-full ml-2 border-none focus:outline-none"
              placeholder="Email"
              type="email"
            />
          </div>
          <div className="flex w-full px-3 bg-white py-1 rounded-lg items-center shadow-lg mt-3">
            <textarea
              className="w-full ml-2 pt-2 text-sm border-none focus:outline-none"
              placeholder="Enquiry"
              type="text"
              rows={3}
            />
          </div>
          <button className="my-4 bg-[#404298] text-white py-3 font-bold rounded-lg">
            Send My Enquiry
          </button>
        </form>
      </div>
      {/* <div className="py-6 flex flex-col items-center justify-center">
        <img src={contactUsCTA} className="w-full rounded-lg max-w-5xl mt-6" />
        <img src={contactUsInfo} className="w-full rounded-lg max-w-5xl mt-8" />
      </div> */}
      <Footer />
    </div>
  );
}
