import React, { useEffect, useState, useContext } from "react";
import Header from "./header/Header";
import { Leftbar } from "./sidebar/Leftbar";

import { Link, useLocation } from "react-router-dom";
import { axiosInstance } from "../base/api/axios.util";
import { URLConstants } from "../base/api/url.constants";
import vehicleImage from "../images/vehicleImg1.png";
import Loader from "react-loader";
import { ClientContext } from "../base/contexts/UserContext";
import convertDate from "../base/utils/EnglishDateTime";

const VehicleDesign = ({
  seatsNo,
  vehicleType,
  baggagesNo,
  name,
  price,
  index,
  activeIndex,
  handleOnClick,
}) => {
  return (
    <div
      className={`bg-white shadow-2xl rounded-xl h-auto overflow-visible p-4 hover:border-2 hover:border-black hover:shadow-2xl   ${
        activeIndex === index ? "border-2 border-gray-800 shadow-2xl" : ""
      } `}
    >
      <div className="flex flex-row text-4xl font-bold justify-between overflow-visible">
        <span className="p-8 pl-3 pt-12"> {name}</span>
        <div className="overflow-visible absolute h-52 w-52 z-50 -translate-y-8 translate-x-44 pr-2 ">
          <img src={vehicleImage} className="z-[2000] overflow-visible" />
        </div>
      </div>
      <div className="flex items-center flex-col justify-center">
        <div className="grid grid-cols-2 mt-6 gap-4 ">
          <div className="flex flex-start ml-3">
            <Feature caption={"Number of Seats"} captionValue={seatsNo} />
          </div>
          <div className="flex flex-start ml-3">
            <Feature caption={"Vehicle Type"} captionValue={vehicleType} />
          </div>
        </div>
        <div className="grid grid-cols-2 mt-6 gap-4">
          <div className="flex flex-start ml-3">
            <Feature caption={"No. of baggages"} captionValue={baggagesNo} />
          </div>
          <div className="flex flex-start ml-3">
            <Feature caption={"Price"} captionValue={price} />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center mt-4">
        <button
          className="flex w-[200px] items-center pointer text-white bg-[#080941] border-0 py-2 px-4 mt-4  text-center focus:outline-none hover:bg-[#404298] rounded"
          onClick={() => handleOnClick(index)}
        >
          <div className="flex justify-between items-center">
            <span></span>
            <span> Select Vehicle</span>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-4 h-4 ml-8 "
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </div>
        </button>
      </div>
    </div>
  );
};

const Feature = ({ caption, captionValue }) => {
  return (
    <div className="flex justify-center items-center bg-white  rounded-lg ">
      <span className="bg-[#404298] overflow-hidden text-white rounded-full">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </span>
      <span className="ml-2">
        {" "}
        <div className="flex flex-col">
          <span className="font-semibold">{captionValue}</span>
          <span className="text-sm">{caption}</span>
        </div>{" "}
      </span>{" "}
    </div>
  );
};

const VehicleCard = () => {
  const [tripDetail, setTripDetail] = useState({});
  const [vehicleList, setVehicleListDetail] = useState([]);
  const location = useLocation();
  const [loaded, setLoaded] = useState(true);
  const { client, setClient } = useContext(ClientContext);

  useEffect(() => {
    var registeredClient = {};
    console.log("Vehcile infor received", location);
    if (location.state != undefined) {
      let vehList = location.state.vehicleTypeList;
      registeredClient = location.state;
      setTripDetail(location.state.createTripBody);
      for (let i = 0; i < vehList.length; i++) {
        for (let j = 0; j < vehicleType; j++) {
          if (vehicleType[j].name == vehList[i].name) {
            vehList[i].carImg = vehicleType[j].carImg;
            break;
          }
        }
      }
      setVehicleListDetail(vehList);
    }
  }, []);
  async function bookRide() {
    setLoaded(false);
    let rideDetails = {};
    let selCar = [vehicleList[activeIndex]];
    if (
      client.logInType == "Passenger" &&
      client?.loginData?.client?.mobileNo
    ) {
      const stripeCustomerId = await axiosInstance.get(
        URLConstants.getPassengerCustomerId(client?.loginData?.client.mobileNo)
      );
    }

    let passengerDetails = {
      mobileNo: tripDetail.passengerMobileNo,
      firstName: tripDetail.passengerFirstName,
      lastName: tripDetail.passengerLastName,
    };
    rideDetails.requestId = location.state.requestId;
    rideDetails.passengerDetails = passengerDetails;
    rideDetails.bookingSource = 1;
    rideDetails.tripDateTime = tripDetail.tripTravelDate;
    rideDetails.source = tripDetail.source;
    rideDetails.destination = tripDetail.destination;
    rideDetails.languagesFilter = tripDetail.languagesFilter;
    rideDetails.genderFilter = tripDetail.genderFilter;
    rideDetails.driverOperators = [];
    rideDetails.tripType = 1;
    rideDetails.paymentType = 0;
    rideDetails.stripePayment = "";

    rideDetails.vehicleType = selCar;

    if (client?.logInType == "SUPER_ADMIN" || client?.logInType == "OPERATOR") {
      rideDetails.operatorId = client.loginData.id;
    } else if (client?.logInType == "Passenger") {
      rideDetails.passengerDetails._id = client.loginData.id;
    }

    // rideDetails.driverDetails = {};
    console.log(rideDetails);

    axiosInstance
      .post(URLConstants.bookRide(), rideDetails)
      .then((res) => {
        setLoaded(true);
        console.log("Response", res);
        window.location = res;
      })
      .catch((err) => {
        console.log(err);
        setLoaded(true);
      });
  }
  function convertToComma(arr) {
    let returnData = "";
    for (let i = 0; i < arr?.length; i++) {
      if (i == arr.length - 1) {
        returnData += arr[i];
      } else {
        returnData += arr[i] + " , ";
      }
    }
    return returnData;
  }

  const [activeIndex, setActiveIndex] = React.useState(0);

  const handleOnClick = (index) => {
    setActiveIndex(index);
  };
  const vehicleType = [
    {
      carImg:
        "https://cdn.dnaindia.com/sites/default/files/styles/full/public/2021/01/19/950956-maruti5.jpg",
      details: "",
      name: "Mini",
      seatsNo: "4",
      buggiesNo: "1",
      baggagesNo: "2",
      vehicleType: "2",
      price: "32$",
    },
    {
      cars: "https://imgd.aeplcdn.com/600x337/n/cw/ec/40432/scorpio-n-exterior-right-front-three-quarter-15.jpeg",
      details: "30% discount",
      name: "SUV",
      seatsNo: "6",
      buggiesNo: "2",
      baggagesNo: "6",
      vehicleType: "6",
      price: "42$",
    },
    {
      cars: "https://imgd.aeplcdn.com/0x0/n/cw/ec/106785/exterior-right-front-three-quarter-2.jpeg?isig=0",
      details: "10% discount",
      name: "Sedan",
      seatsNo: "7",
      buggiesNo: "6",
      baggagesNo: "6",
      vehicleType: "4",
      price: "150$",
    },
  ];

  return (
    <>
      <Header isAdmin />
      <div className="grid lg:grid-cols-6">
        <div>
          <Leftbar />
        </div>
        <section className="p-8 lg:col-span-5 vehicle-card text-gray-600 body-font overflow-hidden bg-gray-200">
          <div className="container px-5 py-12 mx-auto">
            <h1 className="sm:text-4xl text-3xl font-medium title-font mb-6 text-gray-900 ">
              Ride Details
            </h1>
            <div className=" text-center w-full mb-10 pt-2 px-4 pb-10 bg-[#404298] rounded-md">
              {/* <div className="left-box">
                <h1 className="sm:text-4xl text-3xl font-medium title-font mb-2 text-gray-900">
                  Best Cab company Treklers
                </h1>
                <p className="leading-relaxed text-base text-gray-500">
                  The company with the right fare
                </p>
                <div className="price-details flex flex-row align-center mt-6">
                  <h1 className="sm:text-4xl text-xl font-medium title-font mb-2 text-gray-500">
                    Pricing
                  </h1>
                  <div className="flex mx-auto border-2 border-black rounded overflow-hidden">
                    <button className="vehiclePlan active py-1 px-10 focus:outline-none">
                      Monthly
                    </button>
                    <button className="vehiclePlan py-1 px-10 focus:outline-none">
                      Annually
                    </button>
                  </div>
                </div>
              </div> */}
              {/* <h1>Select Vehicle</h1> */}

              {tripDetail.source ? (
                <>
                  <div className="  mt-10">
                    {/* <h2 className="text-2xl text-black mb-3 font-bold">
                    Ride Details
                  </h2> */}
                    {/* <div></div> */}
                    <div className="grid grid-cols-1 mt-2 gap-4">
                      <div className="margin">
                        {/* <label className="text-white float-left">Source</label> */}
                        <input
                          name="pickup"
                          title=""
                          //   aria-label="Enter a pickup location: address, city and state required"
                          value={tripDetail.source[0].address}
                          placeholder="Enter pickup location"
                          type="text"
                          className="css-kDPYnB px-10 pointer-events-none w-full"
                        />
                        {/* <div className="arrowloc absolute right-4 top-3">
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          width="16px"
                          height="16px"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          tabindex="0"
                          role="button"
                          aria-label="Locate me"
                          className="pe-location-fetch css-ZSBbY"
                        >
                          <path d="M10.5 13.5L.5 11 21 3l-8 20.5-2.5-10z"></path>
                        </svg>
                      </div> */}
                        {/* </div>
                      <div className="margin"> */}
                        {/* <label className="text-white float-left">
                          Destination
                        </label> */}
                        <input
                          name="destination"
                          title=""
                          value={tripDetail.destination[0].address}
                          placeholder="Enter destination"
                          type="text"
                          className="css- pl-10 pointer-events-none"
                        />
                        <div className="distline"></div>
                      </div>
                    </div>
                    {/* <div
                  dir="ltr"
                  className="actionBtn flex flex-row justify-around mt-4"
                 >
                  <div className="bg-black hover:bg-gray-700 rounded px-4 py-2">
                    <a
                      href="javascript:void(0)"
                      text="Request now"
                      className="text-white hover:no-underline"
                    >
                      Request now
                    </a>
                  </div>
                  <div className="border-1 border-black hover:bg-gray-300 rounded  px-4 py-2">
                    <a
                      href="javascript:void(0)"
                      text="Schedule for later"
                      className="text-black hover:no-underline"
                    >
                      Schedule for later
                    </a>
                  </div>
                 </div> */}
                    {/* <div
                    dir="ltr"
                    className="actionBtn flex flex-row justify-around mt-4"
                  >
                    <div className="bg-black hover:bg-gray-700 rounded px-4 py-2">
                      Passenger Mobile No : {tripDetail.passengerNo}
                    </div>
                  </div> */}
                  </div>

                  <div className="  mt-10">
                    {/* <h2 className="text-2xl text-black mb-3 font-bold">
  Ride Details
</h2> */}
                    {/* <div></div> */}
                    <div className="grid grid-cols-2 mt-2 gap-4">
                      <div className="margin">
                        <label className="text-white float-left">
                          No. of Passenger
                        </label>
                        <input
                          name="pickup"
                          title=""
                          //   aria-label="Enter a pickup location: address, city and state required"
                          value={tripDetail.passengerNo}
                          placeholder="No. of Passenger"
                          type="text"
                          className="css-kDPYnB px-10 pointer-events-none w-full"
                        />
                        {/* <div className="arrowloc absolute right-4 top-3">
      <svg
        aria-hidden="true"
        focusable="false"
        width="16px"
        height="16px"
        fill="currentColor"
        viewBox="0 0 24 24"
        tabindex="0"
        role="button"
        aria-label="Locate me"
        className="pe-location-fetch css-ZSBbY"
      >
        <path d="M10.5 13.5L.5 11 21 3l-8 20.5-2.5-10z"></path>
      </svg>
    </div> */}
                      </div>
                      <div className="margin">
                        <label className="text-white float-left">
                          Passenger Mobile No.
                        </label>
                        <input
                          name="destination"
                          title=""
                          value={tripDetail.passengerMobileNo}
                          placeholder="Passenger Mobile No."
                          type="text"
                          className="css- pl-10 pointer-events-none"
                        />
                      </div>
                      {/* <div className="vline"></div> */}
                    </div>
                  </div>
                  <div className="  mt-10">
                    {/* <h2 className="text-2xl text-black mb-3 font-bold">
                    Ride Details
                  </h2> */}
                    {/* <div></div> */}
                    <div className="grid grid-cols-2 mt-2 gap-4">
                      <div className="margin">
                        <label className="text-white float-left">
                          No. of Buggies
                        </label>
                        <input
                          name="pickup"
                          title=""
                          //   aria-label="Enter a pickup location: address, city and state required"
                          value={tripDetail.noOfBuggies}
                          placeholder="No. of Buggies"
                          type="text"
                          className="css-kDPYnB px-10 pointer-events-none w-full"
                        />
                      </div>
                      <div className="margin">
                        <label className="text-white float-left">
                          No. of Luggage Bags
                        </label>
                        <input
                          name="destination"
                          title=""
                          value={tripDetail.noOfLugbags}
                          placeholder="No. of Luggage Bags"
                          type="text"
                          className="css- pl-10 pointer-events-none"
                        />
                      </div>
                      {/* <div className="vline"></div> */}
                    </div>
                  </div>

                  <div className="  mt-10">
                    {/* <h2 className="text-2xl text-black mb-3 font-bold">
                    Ride Details
                  </h2> */}
                    {/* <div></div> */}
                    <div className="grid grid-cols-2 mt-2 gap-4">
                      <div className="margin">
                        <label className="text-white float-left">
                          No. of Baggages
                        </label>
                        <input
                          name="pickup"
                          title=""
                          //   aria-label="Enter a pickup location: address, city and state required"
                          value={tripDetail.noOfBaggages}
                          placeholder="No. of Baggages"
                          type="text"
                          className="css-kDPYnB px-10 pointer-events-none w-full"
                        />
                      </div>
                      <div className="margin">
                        <label className="text-white float-left">
                          Gender Prefered
                        </label>
                        <input
                          name="destination"
                          title=""
                          value={convertToComma(tripDetail.genderFilter)}
                          placeholder="Gender Prefered"
                          type="text"
                          className="css- pl-10 pointer-events-none"
                        />
                      </div>
                      {/* <div className="vline"></div> */}
                    </div>
                  </div>

                  <div className="  mt-10">
                    {/* <h2 className="text-2xl text-black mb-3 font-bold">
                    Ride Details
                  </h2> */}
                    {/* <div></div> */}
                    <div className="grid grid-cols-2 mt-2 gap-4">
                      <div className="margin">
                        <label className="text-white float-left">
                          Language Preferred
                        </label>
                        <input
                          name="pickup"
                          title=""
                          //   aria-label="Enter a pickup location: address, city and state required"
                          value={convertToComma(tripDetail.languagesFilter)}
                          placeholder="Language Preferred"
                          type="text"
                          className="css-kDPYnB px-10 pointer-events-none w-full"
                        />
                      </div>
                      <div className="margin">
                        <label className="text-white float-left">
                          Trip Type
                        </label>
                        <input
                          name="destination"
                          title=""
                          value={
                            tripDetail.bookingType == 0
                              ? "Personal"
                              : "Corporate"
                          }
                          placeholder="Trip Type"
                          type="text"
                          className="css- pl-10 pointer-events-none"
                        />
                      </div>
                      {/* <div className="vline"></div> */}
                    </div>
                    <div className="grid grid-cols-2 mt-8 gap-4">
                      <div className="margin">
                        <label className="text-white float-left">
                          Time And Date of Travel
                        </label>
                        <input
                          name="pickup"
                          title=""
                          //   aria-label="Enter a pickup location: address, city and state required"
                          value={convertDate(tripDetail.tripTravelDate)}
                          placeholder="  Time And Date of Travel"
                          type="text"
                          className="css-kDPYnB px-10 pointer-events-none w-full"
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4 text-gray-900">
              Select Vehicle
            </h1>
            <div
              className={`images ${
                vehicleList.length > 0 ? "grid grid-cols-2 gap-1" : ""
              } heightCard`}
            >
              {vehicleList.length > 0 ? (
                vehicleList.map((data, index) => (
                  // <div className="p-4">
                  //   <div
                  //     className={`bg-white h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden hover:border-2 hover:border-black hover:shadow-2xl   ${
                  //       activeIndex === index
                  //         ? "border-2 border-gray-800 shadow-2xl"
                  //         : ""
                  //     }`}
                  //   >
                  //     <div
                  //       onClick={() => handleOnClick(index)}
                  //       className={`selectit ${
                  //         activeIndex === index ? "active" : "unactive"
                  //       }`}
                  //     >
                  //       <svg
                  //         width="17"
                  //         height="17"
                  //         xmlns="http://www.w3.org/2000/svg"
                  //         viewBox="0 0 448 512"
                  //       >
                  //         <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                  //       </svg>
                  //     </div>
                  //     <img
                  //       className="w-50 m-auto border-2 border-solid border-gray-400"
                  //       // src={data.carImg}
                  //       src={
                  //         "https://www.uber-assets.com/image/upload/f_auto,q_auto:eco,c_fill,w_956,h_537/v1569012661/assets/19/dea9bc-88d6-461e-a233-17ed4d8cdc09/original/Taxi.png"
                  //       }
                  //     />
                  //     <p className="flex items-center text-gray-600 mb-2 mt-4">
                  //       <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-black text-white rounded-full flex-shrink-0">
                  //         <svg
                  //           fill="none"
                  //           stroke="currentColor"
                  //           stroke-linecap="round"
                  //           stroke-linejoin="round"
                  //           stroke-width="2.5"
                  //           className="w-3 h-3"
                  //           viewBox="0 0 24 24"
                  //         >
                  //           <path d="M20 6L9 17l-5-5"></path>
                  //         </svg>
                  //       </span>
                  //       Vehicle name: {data.name}
                  //     </p>
                  //     <p className="flex items-center text-gray-600 mb-2">
                  //       <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-black text-white rounded-full flex-shrink-0">
                  //         <svg
                  //           fill="none"
                  //           stroke="currentColor"
                  //           stroke-linecap="round"
                  //           stroke-linejoin="round"
                  //           stroke-width="2.5"
                  //           className="w-3 h-3"
                  //           viewBox="0 0 24 24"
                  //         >
                  //           <path d="M20 6L9 17l-5-5"></path>
                  //         </svg>
                  //       </span>
                  //       Number of seats : {data.seatsNo}
                  //     </p>
                  //     <p className="flex items-center text-gray-600 mb-2">
                  //       <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-black text-white rounded-full flex-shrink-0">
                  //         <svg
                  //           fill="none"
                  //           stroke="currentColor"
                  //           stroke-linecap="round"
                  //           stroke-linejoin="round"
                  //           stroke-width="2.5"
                  //           className="w-3 h-3"
                  //           viewBox="0 0 24 24"
                  //         >
                  //           <path d="M20 6L9 17l-5-5"></path>
                  //         </svg>
                  //       </span>
                  //       Vehicle Type : {data.vehicleType}
                  //     </p>
                  //     <p className="flex items-center text-gray-600 mb-2">
                  //       <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-black text-white rounded-full flex-shrink-0">
                  //         <svg
                  //           fill="none"
                  //           stroke="currentColor"
                  //           stroke-linecap="round"
                  //           stroke-linejoin="round"
                  //           stroke-width="2.5"
                  //           className="w-3 h-3"
                  //           viewBox="0 0 24 24"
                  //         >
                  //           <path d="M20 6L9 17l-5-5"></path>
                  //         </svg>
                  //       </span>
                  //       Price : {data.price}
                  //     </p>
                  //     {/* <p className="flex items-center text-gray-600 mb-2">
                  //     <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-black text-white rounded-full flex-shrink-0">
                  //       <svg
                  //         fill="none"
                  //         stroke="currentColor"
                  //         stroke-linecap="round"
                  //         stroke-linejoin="round"
                  //         stroke-width="2.5"
                  //         className="w-3 h-3"
                  //         viewBox="0 0 24 24"
                  //       >
                  //         <path d="M20 6L9 17l-5-5"></path>
                  //       </svg>
                  //     </span>
                  //     Number of Buggies : {data.buggiesNo}
                  //   </p> */}
                  //     <p className="flex items-center text-gray-600 mb-2">
                  //       <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-black text-white rounded-full flex-shrink-0">
                  //         <svg
                  //           fill="none"
                  //           stroke="currentColor"
                  //           stroke-linecap="round"
                  //           stroke-linejoin="round"
                  //           stroke-width="2.5"
                  //           className="w-3 h-3"
                  //           viewBox="0 0 24 24"
                  //         >
                  //           <path d="M20 6L9 17l-5-5"></path>
                  //         </svg>
                  //       </span>
                  //       Number of Baggages : {data.baggagesNo}
                  //     </p>
                  //     <button
                  //       className="flex items-center text-white bg-black border-0 py-2 px-4 mt-4 w-full text-center focus:outline-none hover:bg-gray-500 rounded"
                  //       onClick={() => handleOnClick(index)}
                  //     >
                  //       Select
                  //     </button>
                  //   </div>
                  // </div>
                  <div className="flex justify-around mb-20">
                    <VehicleDesign
                      seatsNo={data.seatsNo}
                      price={data.price}
                      baggagesNo={data.baggagesNo}
                      name={data.name}
                      vehicleType={data.vehicleType}
                      index={index}
                      activeIndex={activeIndex}
                      handleOnClick={handleOnClick}
                    />
                  </div>
                ))
              ) : (
                <div className="flex justify-center items-center mt-8 flex-col w-full">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZfRvVyo2Gl0JWOLabFPQAedq5TqKy_dDjKQ&usqp=CAU" />
                  <h3 className="sm:text-4xl text-3xl font-medium title-font mb-4 text-gray-900 mt-12">
                    No vehicles found :( !! Please try again later
                  </h3>
                </div>
              )}
              {/* <div className="bg-white shadow-2xl rounded-xl h-auto">
                <div className="flex flex-row text-3xl font-bold justify-between ">
                  <span className="p-8 pt-12"> V Class</span>
                  <div className="absolute h-48 w-48 right-24 -translate-y-8">
                    <img src={vehicleImage} />
                  </div>
                </div>
                <div className="flex items-center flex-col justify-center">
                  <div className="grid grid-cols-2 mt-6 gap-4 ">
                    <div className="flex flex-start ml-3">
                      <Feature caption={"Number of Seats"} captionValue={"7"} />
                    </div>
                    <div className="flex flex-start ml-3">
                      <Feature caption={"Vehicle Type"} captionValue={"4"} />
                    </div>
                  </div>
                  <div className="grid grid-cols-2 mt-6 gap-4">
                    <div className="flex flex-start ml-3">
                      <Feature caption={"No. of baggages"} captionValue={"4"} />
                    </div>
                    <div className="flex flex-start ml-3">
                      <Feature caption={"Price"} captionValue={"28.67"} />
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-center mt-4">
                  <button
                    className="flex w-[200px] items-center pointer text-white bg-[#080941] border-0 py-2 px-4 mt-4  text-center focus:outline-none hover:bg-[#404298] rounded"
                    // onClick={() => handleOnClick(index)}
                  >
                    <div className="flex justify-between items-center">
                      <span></span>
                      <span> Select Vehicle</span>

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-4 h-4 ml-8 "
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M8.25 4.5l7.5 7.5-7.5 7.5"
                        />
                      </svg>
                    </div>
                  </button>
                </div>
              </div> */}
            </div>
          </div>
          {/* <button type="submit"  className=""> */}
          <input
            className="fullGrid w-auto mx-auto px-4 mt-16 py-2"
            type="submit"
            value="Submit"
            onClick={() => bookRide()}
          />
          {/* </button> */}
        </section>
        <div className="spinner">
          <Loader
            loaded={loaded}
            lines={13}
            length={20}
            width={10}
            radius={30}
            corners={1}
            rotate={0}
            direction={1}
            color="#000"
            speed={1}
            trail={60}
            shadow={false}
            hwaccel={false}
            className="spinner"
            zIndex={2e9}
            top="50%"
            left="50%"
            scale={1.0}
            loadedClassName="loadedContent"
          />
        </div>
      </div>
    </>
  );
};

export default VehicleCard;
