import React, { useRef } from "react";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  const titleRef = useRef();
  return (
    <>
      <Header isAdmin />
      <Helmet>
        <title>Privacy Policy - Treklers - Admin</title>
        <meta name="description" content="Login page" />
      </Helmet>
      <div>
        <div
          className="text-3xl font-bold w-full text-center p-4"
          ref={titleRef}
        >
          Privacy Policy
        </div>
      </div>
      <div className="w-full md:w-2/3 border-2 rounded-lg border-4 border-gray-500 mx-auto	">
        <div className="overflow-y-auto p-8">
          <div className="text-sm mt-0 md:mt-8 text-black line-clamp-1">
            {" "}
            <h2 className="lg:text-lg font-bold text-gray-500 text-left mb-4">
              Privacy Policy
            </h2>
          </div>
          <div className="grid gap-4 text-sm ">
            <p>Effective Date: 16th November 2023</p>
            <p>
              Treklers ("we," "us," or "our") respects the privacy of our users
              ("you"). This Privacy Policy explains how we collect, use,
              disclose, and safeguard your information when you use our mobile
              application ("App"). Please read this privacy policy carefully. If
              you do not agree with the terms of this privacy policy, please do
              not access the App.
            </p>
            <p>
              We reserve the right to make changes to this Privacy Policy at any
              time and for any reason. We will alert you about any changes by
              updating the "Effective Date" of this Privacy Policy. You are
              encouraged to periodically review this Privacy Policy to stay
              informed of updates. You will be deemed to have been made aware
              of, will be subject to, and will be deemed to have accepted the
              changes in any revised Privacy Policy by your continued use of the
              App after the date such revised Privacy Policy is posted.
            </p>

            <p className="font-bold">Collection of Your Information</p>
            <p>
              We may collect information about you in a variety of ways. The
              information we may collect via the App includes:
            </p>
            <ul className="list-disc">
              <li>
                {" "}
                <span className="font-bold">Personal Data : </span>
                <span>
                  {" "}
                  Personally identifiable information, such as your name,
                  shipping address, email address, and telephone number, that
                  you voluntarily give to us when you register with the App or
                  book a ride.{" "}
                </span>
              </li>
              <br />
              <li>
                {" "}
                <span className="font-bold">Derivative Data : </span>
                <span>
                  {" "}
                  Information our servers automatically collect when you access
                  the App, such as your native actions that are integral to the
                  App, including booking a cab.{" "}
                </span>
              </li>
              <br />
              <li>
                {" "}
                <span className="font-bold">Financial Data : </span>
                <span>
                  {" "}
                  Financial information, such as data related to your payment
                  method (e.g., valid credit card number, card brand, expiration
                  date) that we may collect when you book a ride.{" "}
                </span>
              </li>
              <br />
              <li>
                {" "}
                <span className="font-bold">Geo-Location Information : </span>
                <span>
                  {" "}
                  We may request access or permission to and track
                  location-based information from your mobile device, either
                  continuously or while you are using our App, to provide
                  location-based services.{" "}
                </span>
              </li>
              <br />
              <li>
                {" "}
                <span className="font-bold">Mobile Device Access : </span>
                <span>
                  {" "}
                  We may request access or permission to certain features from
                  your mobile device, including your mobile device’s Bluetooth,
                  calendar, camera, contacts, microphone, reminders, sensors,
                  SMS messages, social media accounts, and other features.{" "}
                </span>
              </li>
              <br />
            </ul>
            <p className="font-bold">Use of Your Information</p>
            <p>
              Having accurate information about you permits us to provide you
              with a smooth, efficient, and customized experience. Specifically,
              we may use information collected about you via the App to:
            </p>
            <ul className="list-disc">
              <li>Create and manage your account.</li>
              <li>Process your bookings and payments.</li>
              <li>Notify you of updates to the App.</li>
              <li>Increase the efficiency and operation of the App.</li>
              <li>
                Monitor and analyze usage and trends to improve your experience
                with the App.
              </li>
              <li>
                Prevent fraudulent transactions, monitor against theft, and
                protect against criminal activity.
              </li>
              <li>Respond to customer service requests.</li>
              <li>Solicit support for the App.</li>
            </ul>
            <p className="font-bold">Disclosure of Your Information</p>
            <p>
              We may share information we have collected about you in certain
              situations. Your information may be disclosed as follows:
            </p>
            <ul className="list-disc">
              <li>
                {" "}
                <span className="font-bold">
                  {" "}
                  By Law or to Protect Rights :{" "}
                </span>
                <span>
                  {" "}
                  If we believe the release of information about you is
                  necessary to respond to legal process, to investigate or
                  remedy potential violations of our policies, or to protect the
                  rights, property, and safety of others, we may share your
                  information as permitted or required by any applicable law,
                  rule, or regulation.{" "}
                </span>
              </li>
              <br />
              <li>
                {" "}
                <span className="font-bold">
                  Third-Party Service Providers :{" "}
                </span>
                <span>
                  {" "}
                  We may share your information with third parties that perform
                  services for us or on our behalf, including payment
                  processing, data analysis, email delivery, hosting services,
                  customer service, and marketing assistance.{" "}
                </span>
              </li>
              <br />
              <li>
                {" "}
                <span className="font-bold">Marketing Communications : </span>
                <span>
                  {" "}
                  With your consent, or with an opportunity for you to withdraw
                  consent, we may share your information with third parties for
                  marketing purposes, as permitted by law.{" "}
                </span>
              </li>
              <br />
              <li>
                {" "}
                <span className="font-bold">Online Postings : </span>
                <span>
                  When you post comments, contributions, or other content to the
                  App, your posts may be viewed by all users and may be publicly
                  distributed outside the App.{" "}
                </span>
              </li>
              <br />
              <li>
                {" "}
                <span className="font-bold">Business Transfers : </span>
                <span>
                  {" "}
                  We may share or transfer your information in connection with,
                  or during negotiations of, any merger, sale of company assets,
                  financing, or acquisition of all or a portion of our business
                  to another company.{" "}
                </span>
              </li>
              <br />
              <li>
                {" "}
                <span className="font-bold">Other Third Parties : </span>
                <span>
                  {" "}
                  We may share your information with investors and other parties
                  for business analysis or to market our App. We may share your
                  information with such third parties for the purpose of
                  conducting general business analysis.{" "}
                </span>
              </li>
              <br />
            </ul>
            <p className="font-bold">Tracking Technologies</p>
            <p>
              <span className="font-bold">Cookies and Web Beacons:</span> We may
              use cookies, web beacons, tracking pixels, and other tracking
              technologies on the App to help customize the App and improve your
              experience. When you access the App, your personal information is
              not collected through the use of tracking technology. Most
              browsers are set to accept cookies by default. You can remove or
              reject cookies but be aware that such action could affect the
              availability and functionality of the App.
            </p>
            <p className="font-bold">Security of Your Information</p>
            <p>
              We use administrative, technical, and physical security measures
              to help protect your personal information. While we have taken
              reasonable steps to secure the personal information you provide to
              us, please be aware that despite our efforts, no security measures
              are perfect or impenetrable, and no method of data transmission
              can be guaranteed against any interception or other type of
              misuse. Any information disclosed online is vulnerable to
              interception and misuse by unauthorized parties. Therefore, we
              cannot guarantee complete security if you provide personal
              information.
            </p>
            <p className="font-bold">Policy for Children</p>
            <p>
              We do not knowingly solicit information from or market to children
              under the age of 18. If you become aware of any data we have
              collected from children under age 18, please contact us using the
              contact information provided below.
            </p>

            <p className="font-bold">Options Regarding Your Information</p>

            <p>
              You may at any time review or change the information in your
              account or terminate your account by:
            </p>

            <ul className="list-disc">
              <li>
                {" "}
                Logging into your account settings and updating your account; or
              </li>
              <li>
                {" "}
                Contacting us using the contact information provided below.
              </li>
            </ul>

            <p>
              Upon your request to terminate your account, we will deactivate or
              delete your account and information from our active databases.
              However, some information may be retained in our files to prevent
              fraud, troubleshoot problems, assist with any investigations,
              and/or comply with legal requirements.
            </p>

            <p className="font-bold">Emails and Communications</p>

            <p>
              If you no longer wish to receive correspondence, emails, or other
              communications from us, you may opt-out by:
            </p>

            <ul className="list-disc">
              <li>
                {" "}
                Noting your preferences at the time you register your account
                with the App; or
              </li>
              <li>
                {" "}
                Logging into your account settings and updating your
                preferences; or
              </li>
              <li>
                {" "}
                Contacting us using the contact information provided below.
              </li>
            </ul>

            <p>
              If you no longer wish to receive correspondence, emails, or other
              communications from third parties, you are responsible for
              contacting the third party directly.
            </p>

            <p className="font-bold">Contacting Us</p>
            <p>
              If there are any questions regarding this privacy policy you may
              contact us using the information below.
            </p>
            <div className="addRess">
              <p>Neil Cross</p>
              <p>+44 7787 120 633</p>
              <p>neil@treklers.co.uk</p>
              <p>TREKLERS Limited</p>
              <p> 35a, High Street,</p>
              <p>Ruislip,England . HA4 7AU</p>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-4">
        <Footer />
      </div>
    </>
  );
};

export default PrivacyPolicy;
