export default function convertDate(date) {
  console.log("Date received", date);
  const finalDate = new Date(date).toISOString();
  let newDate = finalDate.split("T")[0];
  //1900-12-24
  return (
    newDate.substring(8, 10) +
    "-" +
    newDate.substring(5, 7) +
    "-" +
    newDate.substring(0, 4)
  );
}
