import axios from "axios";
import { AuthTokenHandler } from "./auth-token.util";
// const BASE_URL = "https://jsonplaceholder.typicode.com";
// baseURL: BASE_URL,
const AxiosInstance = axios.create({});
AxiosInstance.interceptors.request.use((req) => {
  const accessToken = AuthTokenHandler.getAccessToken();
  const authToken = AuthTokenHandler.getAuthToken();
  req.headers.Authorization = `${accessToken || ""}`;
  req.headers.AuthToken = `${authToken || ""}`;
  return req;
});
AxiosInstance.interceptors.response.use(
  (res) => {
    if (res.headers["x-auth-token"]) {
      AuthTokenHandler.setAccessToken(res.headers["x-auth-token"]);
    }
    return res.data;
  },
  (err) => {
    throw err;
  }
);
function verifyExpiryOfToken() {}
export const axiosInstance = AxiosInstance;
