import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ClientContext } from "../../base/contexts/UserContext";
import building from "../../images/building.png";
import corporate from "../../images/corporate.png";
import explorebg from "../../images/explore-bg.png";
import TrekLogo from "../../images/treklers_logo.png";
import Trek4Business from "../../images/Trek4BusinessBanner.svg";
import SignIn from "../../images/SignIn.svg";
import location from "../../images/location.png";
import personal from "../../images/personal.png";
import rental from "../../images/rental.png";
import ride1 from "../../images/ride1.png";
import ride2 from "../../images/ride2.png";
import ride3 from "../../images/ride3.png";
import services from "../../images/services.png";
import starGroup from "../../images/star-group.png";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import IconBox from "../shared/IconBox";
import TextwithStroke from "../shared/TextwithStroke";
import TestimonialCard from "./TestimonialCard";
import CardBox from "../shared/CardBox";
import { Login } from "../loginPage/Login";
import TravelBanner from "../../images/TravelBanner.svg";
import HomePage1 from "../../images/HomePage1.svg";
import HomePage2 from "../../images/HomePage2.svg";
import HomePage3 from "../../images/HomePage3.svg";
import AppSelectionComponent from "../AppSelectionComponent";

const slides = [
  { title: "Slide 1", image: ride1 },
  { title: "Slide 2", image: ride2 },
  { title: "Slide 3", image: ride3 },
  { title: "Slide 4", image: ride3 },
  // Add more slides as needed
];

function HomeContent(props) {
  const { client, setClient } = useContext(ClientContext);
  const navigate = useNavigate();
  const [name, setName] = useState("Driver");
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  useEffect(() => {
    if (client?.logInType) {
      navigate("/loginscreen");
    }
  }, []);
  return (
    <>
      <div
        className="hero-container w-full flex justify-start items-center flex-1  bg-[#080941] min-h-[80vh] bg-cover bg-no-repeat"
        style={{
          backgroundImage: `url(${SignIn})`,
        }}
      >
        <Header setName={setName} />
        {/* <p className="text-white w-1/2 md:leading-[70px] md:text-5xl xs:text-4xl font-bold tracking-wider ml-20 mt-2 ">
          Enjoy your comfortable trip with TREKLERS Cab
        </p> */}
      </div>

      <div className="w-full h-40 relative rounded-t-[50%] -mt-20 bg-white z-10">
        <Login name={name} />
      </div>

      <div className="w-full mt-40 ">
        {/* <div className="flex flex-row justify-center items-center mt-10 gap-3 sm:gap-1">
          <div className="inline sm:text-2xl md:text-4xl font-bold">
            Ride With
          </div>
          <TextwithStroke
            strokeColor="#000"
            style={`md:text-4xl sm:text-2xl uppercase font-bold tracking-wide`}
            text="Treklers"
          />
        </div> */}
        {/* <div className="mb-16 scrollbar-hide mt-10 flex lg:mx-36 mx-12 overflow-x-scroll flex-row gap-5 justify-start items-center">
          <IconBox icon={corporate} text="Corporate" />
          <IconBox icon={personal} text="Personal" />
          <IconBox icon={services} text="Services" />
          <IconBox icon={rental} text="Rental" />
          <IconBox icon={rental} text="intercity" />
        </div> */}
        {/* <div className="mx-32 flex items-center justify-center mb-16 ">
          <img src={TravelBanner} />
        </div> */}
        {/* <div
          className="lg:mx-36 mx-12 my-12 bg-[#353535] lg:h-[302px]  rounded-lg bg-cover bg-no-repeat bg-center justify-center gap-2 px-16 py-4 lg:py-0
          items-start flex flex-col"
          style={{
            backgroundImage: `url(${Trek4Business})`,
          }}
        >
          <div className="lg:text-3xl md:text-xl  sm:text-lg font-bold lg:leading-2 leading-1 text-white flex flex-row items-center">
            <span className="pr-3">
              <img src={TrekLogo} className="h-10 object-contain" />{" "}
            </span>
            <span className=""> for Business</span>
          </div>
          <p className="lg:text-sm text-xs font-normal tracking-widest lg:leading-2 leading-1 text-white">
            Transform the way your company moves and feels its people.
          </p>
          <button
            type="button"
            className="inline-flex items-center mt-2 rounded-full px-8 lg:py-2 sm:py-1  lg:text-sm text-xs font-medium  text-white bg-[#080941] hover:bg-[#6264e2] "
          >
            Get Started
          </button>
        </div> */}

        {/* <div className="flex flex-row items-center justify-center rounded-lg mx-10 my-16 relative ">
          <div className="bg-[#1517A7] z-[99] absolute left-0 top-0">
            <div className="bg-[#353535] h-full w-full pr-10">
              {" "}
              <div className="lg:text-3xl md:text-xl  sm:text-lg font-bold lg:leading-2 leading-1 text-white flex flex-row items-center">
                <span className="pr-3">
                  <img src={TrekLogo} className="h-10 object-contain" />{" "}
                </span>
                <span className=""> for Business</span>
              </div>
              <p className="lg:text-sm text-xs font-normal tracking-widest lg:leading-2 leading-1 text-white">
                Transform the way your company moves and feels its people.
              </p>
              <button
                type="button"
                className="inline-flex items-center mt-2 rounded-full px-8 lg:py-2 sm:py-1  lg:text-sm text-xs font-medium  text-white bg-[#080941] hover:bg-[#6264e2] "
              >
                Get Started
              </button>
            </div>
          </div>
          <div className=" right-0">
            <img src={TestBanner} />
          </div>
        </div> */}

        {/* <div className="flex flex-row items-center justify-center rounded-lg mx-32 my-16 relative overflow-hidden ">
          <div className="relative ">
            <img src={Trek4Business} />
            <div className="bg-[#1517A7] w-60 min-h-full banner-design inset-y-0 right-0  z-1 bg-opacity-40 rounded-tr-[70px]  "></div>
            <div className="bg-[#353535] w-60 min-h-full banner-design-2 inset-y-0 right-0  z-1 rounded-tr-[70px] border-1 border-black "></div>
          </div>
          <div className="bg-[#353535]  z-10 absolute left-0 top-0 min-h-full rounded-l-3xl flex items-center justify-center overflow-hidden ">
            <div className=" pl-20 space-y-2 min-h-full rounded-l-3xl">
              {" "}
              <div className="lg:text-3xl md:text-xl  sm:text-lg font-bold lg:leading-2 leading-1 text-white flex flex-row items-center">
                <span className="pr-3">
                  <img src={TrekLogo} className="h-10 object-contain" />{" "}
                </span>
                <span className=""> for Business</span>
              </div>
              <p className="lg:text-sm text-xs font-normal tracking-widest lg:leading-2 leading-1 text-white">
                Transform the way your company moves and feels its people.
              </p>
              <button
                type="button"
                className="inline-flex items-center mt-4 rounded-full px-8 lg:py-2 sm:py-1  lg:text-sm text-xs font-medium  text-white bg-[#080941] hover:bg-[#6264e2] "
              >
                Get Started
              </button>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center space-y-10 bg-[#ECECEC] px-24 py-20">
          <h2 className="text-2xl md:text-3xl md:px-36 px-12 text-left tracking-wide font-bold">
            From Rides to Tasks – We’ve Got You Covered
          </h2>
          <div>
            <div className="flex flex-row items-center justify-center space-x-4">
              <div className="flex flex-col items-center w-1/2">
                <img src={HomePage1} className="w-[573px] h-[417px]" />
              </div>
              <div className="flex flex-col text-right w-1/2">
                <h2 className="text-[50px] tracking-wide text-right font-bold ">
                  Corporate Vehicles
                </h2>
                <div className="text-lg tracking-wide text-right font-normal ">
                  Our fleet of corporate vehicles ensures your business travels
                  are smooth and professional. Enjoy premium services tailored
                  to meet your corporate needs.
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row items-center justify-center space-x-4">
            <div className="flex flex-col text-right w-1/2">
              <h2 className="text-[50px] tracking-wide text-left font-bold ">
                Airport Transfers
              </h2>
              <div className="text-lg tracking-wide text-left font-normal ">
                Travel comfortably with our airport transfer service, offering
                flexible options and competitive rates. Choose the perfect
                vehicle to meet your travel needs and enjoy a smooth,
                hassle-free ride.
              </div>
            </div>
            <div className="flex flex-col items-center w-1/2">
              <img src={HomePage2} className="w-[573px] h-[417px]" />
            </div>
          </div>
          <div className="flex flex-row items-center justify-center space-x-4">
            <div className="flex flex-col items-center w-1/2">
              <img src={HomePage3} className="w-[573px] h-[417px]" />
            </div>
            <div className="flex flex-col text-right w-1/2">
              <h2 className="text-[50px] tracking-wide text-right font-bold ">
                Day-to-Day Services
              </h2>
              <div className="text-lg tracking-wide text-right font-normal ">
                From plumbing to cooking to carpentry, our day-to-day services
                cover all your household needs. Book a professional today and
                get your tasks done efficiently.
              </div>
            </div>
          </div>
        </div> */}

        <div>
          <AppSelectionComponent />
        </div>

        {/* Community Section */}
        {/* <div
          className="bg-pink-100 w-full bg-gradient-to-b 
        from-[#f3f3fd]  to-[#fff] rounded-t-[80px] py-5"
        >
          <div className="flex flex-col justify-center mt-3 items-center gap-3">
            <div className="inline md:text-3xl text-2xl font-bold">
              Global mobile ecosystem
            </div>
            <div className="inline md:text-3xl text-2xl font-bold">
              driving{" "}
              <TextwithStroke
                strokeColor="#404298"
                style={`md:text-3xl sm:text-2xl font-bold text-[#404298] tracking-wide`}
                text="communities"
              />{" "}
              forward
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-around items-center md:px-36 px-12 my-12">
            <TestimonialCard
              iconPath={building}
              title={"Cities covered"}
              description={
                "Lorem Ipsum passages, and more recently with desktop publishing software like."
              }
            />
            <TestimonialCard
              iconPath={starGroup}
              title={"Happy customer"}
              description={
                "Lorem Ipsum passages, and more recently with desktop publishing software like."
              }
            />
            <TestimonialCard
              iconPath={location}
              title={"Kilometer"}
              description={
                "Lorem Ipsum passages, and more recently with desktop publishing software like."
              }
            />
          </div>
        </div>
        <div className="w-full">
          <h2 className="text-2xl md:text-3xl md:px-36 px-12 text-left tracking-wide font-bold">
            Enjoy your journey with TREKLERS
          </h2>
          <div className="mb-16 flex md:mx-36 mx-12 overflow-x-scroll flex-row md:flex-col gap-5 justify-start items-center">
            <CardBox
              icon={ride1}
              text="Corporate"
              description={"dummy description of rides"}
            />
            <CardBox
              icon={ride2}
              text="Personal"
              description={"dummy description of rides"}
            />
            <CardBox
              icon={ride3}
              text="Services"
              description={"dummy description of rides"}
            />
            <CardBox
              icon={ride3}
              text="Rental"
              description={"dummy description of rides"}
            />
          </div>
        </div> */}
        {/* <div className='w-full h-full bg-[url("https://www.uber-assets.com/image/upload/v1613106985/assets/0e/47aa71-35cb-459a-a975-78c61ea300e2/original/HP-U4B-NYC-bkg.png")] bg-cover bg-center'>
          <div className="px-8 sm:px-32 md:px-32 lg:px-44">
            <div className="innerContainer">
              <h2 className="text-4xl py-8 font-bold">TREKLERS for Business</h2>
              <div className="grid grid-rows gap-4 w-full">
                <div className="grid md:grid-cols-2 gap-4 pb-8">
                  Transform the way your company moves and feeds its people.
                </div>
              </div>
              <button className="rounded bg-black text-white font-bold px-8 py-2">
                See how
              </button>
            </div>
          </div>
        </div> */}
      </div>
      {/* <div className="px-8 sm:px-32 md:px-32">
        <div className="innerContainer py-8 pb-0">
          <h2 className="text-4xl font-bold mb-8 ">Ride with TREKLERS</h2>
          <div className="grid grid-rows gap-4 w-full">
            <div className="grid md:grid-cols-2 gap-4">
              <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-4 p-8">
                <div className="flex justify-center">
                  <div className='w-36 h-36 bg-[url("https://www.uber-assets.com/image/upload/f_auto,q_auto:eco,c_fill,w_188,h_188/v1630531077/assets/38/494083-cc23-4cf7-801c-0deed7d9ca55/original/uber-hourly.png")] bg-cover bg-center'>
                    {" "}
                  </div>
                </div>
                <div>
                  <div className="text-2xl font-bold">Treklers Rentals</div>
                  <p className="pt-2">
                    Book Rentals to save time with one car and driver for your
                    multi-stop trips.
                  </p>
                </div>{" "}
              </div>
              <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-4 p-8">
                <div className="flex justify-center">
                  <div className='w-36 h-36 bg-[url("https://www.uber-assets.com/image/upload/f_auto,q_auto:eco,c_fill,w_188,h_188/v1649914597/assets/f7/7f583f-447a-4cf7-8da6-6ad254f0a66b/original/Intercity-150X150p4x.png")] bg-cover bg-center'>
                    {" "}
                  </div>
                </div>
                <div>
                  <div className="text-2xl font-bold">Treklers Intercity</div>
                  <p className="pt-2">
                    Book Intercity to head outstation anytime in convenient and
                    affordable cars.
                  </p>
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Footer />
    </>
  );
}

export default HomeContent;
