export const BackIcon = <svg
  xmlns="http://www.w3.org/2000/svg"
  width="4.55"
  height="7.982"
  viewBox="0 0 4.55 7.982"
>
  <g id="arrow-point-to-right" transform="translate(-97.138 0)">
    <path
      id="Path_39490"
      data-name="Path 39490"
      d="M101.525,4.386,98.093,7.818a.559.559,0,0,1-.79-.79l3.037-3.037L97.3.954a.559.559,0,0,1,.79-.79L101.525,3.6a.559.559,0,0,1,0,.79Z"
      fill="#fff"
    />
  </g>
</svg>;
