import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Table } from "antd";
import { Helmet } from "react-helmet";
import { itemRender, onShowSizeChange } from "./paginationfunction";
import Loader from "react-loader";
import Header from "./header/Header";
import { Leftbar } from "./sidebar/Leftbar";
import Footer from "./footer/Footer";
import { axiosInstance } from "../base/api/axios.util";
import { URLConstants } from "../base/api/url.constants";
import { useForm, Controller, set } from "react-hook-form";
import { ClientContext } from "../base/contexts/UserContext";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import axios, * as others from "axios";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Fragment } from "react";

function DriverPament() {
  const [driverPayment, setDriverPayment] = useState([]);
  const [fromDate, setFromDate] = useState("2000-01-01");
  const [allDriversTrips, setAllDriversTrips] = useState([]);
  const [driversTrips, setDriversTrips] = useState([]);
  const [finalTrips, setFinalTrips] = useState([]);
  const [finalBill, setFinalBill] = useState(0);
  const [reloadPage, setReloadPage] = useState(false);
  const [operator, setOperator] = useState("All");
  const [operatorNames, setOperatorsName] = useState([]);
  const { client, setClient } = useContext(ClientContext);
  let [filterOptions, setFilterOptions] = useState({});
  const [query, setQuery] = useState("");
  let [displayFilterValue, setDisplayFilterValue] = useState({});
  const navigate = useNavigate();

  const [loaded, setLoaded] = useState(true);
  const date = new Date();

  var currentDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  var day = currentDate.getDate();
  var month = currentDate.getMonth() + 1;
  var year = currentDate.getFullYear();
  const [toDate, setToDate] = useState(year + "-" + month + "-" + day);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: { toDate: "", fromDate: "" },
  });

  function allotTripOfDriver(driverId) {
    axiosInstance
      .post(URLConstants.getDriverTripDetails(driverId), {
        adminPaymentStatus: false,
      })
      .then((res) => {
        console.log("Results", res);
        setDriversTrips(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  useEffect(() => {
    setLoaded(false);
    if (!client) {
      navigate("/");
    } else {
      axiosInstance
        .get(URLConstants.getOperatorsNames())
        .then((response) => {
          setOperatorsName(response);
        })
        .catch((err) => {});
      axiosInstance
        .get(
          URLConstants.getDriverPaymentStatus(
            fromDate,
            toDate,
            operator,
            "false"
          )
        )
        .then((response) => {
          console.log("NEW ARRAY", response);
          setLoaded(true);

          setDriverPayment(response.totalTripPrice);
        })
        .catch((err) => {
          setLoaded(true);
        });

      axiosInstance
        .get(URLConstants.getAllTripsByAllDrivers(fromDate, toDate, false))
        .then((response) => {
          console.loog("RESPONSE FROM DRIVER PAYMENT", response);
          setAllDriversTrips(response);
        })
        .catch((err) => {});
    }
  }, [toDate, fromDate, reloadPage, finalTrips]);

  function addTripId(tripId) {
    finalTrips.push(tripId);
  }

  function removeTripId(tripId) {
    const newTrip = finalTrips.filter((trip) => {
      if (trip !== tripId) {
        return trip;
      }
    });
    setFinalTrips(newTrip);
  }

  function updatePayment() {
    const updateBody = { tripId: finalTrips, status: true };
    axiosInstance
      .post(URLConstants.updateDriverPayStatusFromAdmin(), updateBody)
      .then((res) => {
        setDriversTrips([]);
        setReloadPage(false);
        setFinalTrips([]);
        setFinalBill(0);
        setAllDriversTrips([]);
      })
      .catch((err) => {});
  }

  const tripColumns = [
    {
      title: "Trip Id",

      render: (text, record) => record.tripId,
    },
    {
      title: "Driver Name",
      dataIndex: "driverDetails",
      render: (driverDetails) => driverDetails.name,
    },
    {
      title: "Driver Mobile No",
      dataIndex: "driverDetails",
      render: (driverDetails) => driverDetails.mobileNo,
    },

    {
      title: "Pickup Location",
      dataIndex: "source",
      render: (source) => source[0].address,
    },

    {
      title: "Destination",
      dataIndex: "destination",
      render: (destination) => destination[0].address,
    },
    {
      title: "Total Distance",
      // dataIndex: "totalTravelDist",
      render: (text, record) => parseInt(record.totalTravelDist),
    },
    {
      title: "Total Bill",
      // dataIndex: "totalTripPrice",
      render: (text, record) => `£${parseInt(record.totalTripPrice)}`,
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Pay",
      // dataIndex: "pay",
      // sorter: (a, b) => a.pay.length - b.pay.length,
      render: (text, record) => (
        <input
          type="checkbox"
          onChange={(event) => {
            if (event.target.checked) {
              addTripId(record.tripId);
              let billAmount;
              {
                Number.isInteger(parseInt(record.totalTripPrice))
                  ? (billAmount = Number(parseInt(record.totalTripPrice)))
                  : (billAmount = 0);
              }

              setFinalBill((prev) => prev + billAmount);
            } else {
              removeTripId(record.tripId);

              let billAmount;
              {
                Number.isInteger(parseInt(record.totalTripPrice))
                  ? (billAmount = Number(parseInt(record.totalTripPrice)))
                  : (billAmount = 0);
              }
              setFinalBill((prev) => prev - billAmount);
            }
          }}
        />
      ),
    },
  ];

  const columns = [
    {
      title: "Driver Name",
      dataIndex: "driverInfo",
      render: (text, record) => record.driverInfo.name,
    },
    {
      title: "Contact Number",
      dataIndex: "driverInfo",
      render: (text, record) => record.driverInfo.mobileNo,
    },

    {
      title: "Email Address",
      dataIndex: "driverInfo",
      render: (text, record) => record.driverInfo.email,
    },
    {
      title: "VAT Registered",
      dataIndex: "vatRegistration",

      render: (text, record) =>
        record?.driverInfo?.vatRegistration ? "Yes" : "No",
    },

    {
      title: "No.of Trips",
      dataIndex: "totalTrips",
      sorter: (a, b) => a.totalTrips.length - b.totalTrips.length,
      render: (text, record) => (
        <div onClick={() => allotTripOfDriver(record._id)}>
          <Link
            className=""
            to="/#"
            data-bs-toggle="modal"
            data-bs-target="#add_leave"
          >
            {record.totalTrips}
          </Link>
        </div>
      ),
    },
    {
      title: "Total kms",
      // dataIndex: "totalDistance",
      render: (text, record) => parseInt(record.totalDistance),
      sorter: (a, b) => a.totalDistance.length - b.totalDistance.length,
    },
    {
      title: "Job Earning",
      // dataIndex: "totalEarning",
      render: (text, record) =>
        record?.driverInfo?.vatRegistration
          ? Math.round(record.totalEarning * 100) / 100
          : Math.round(record.totalEarning * 0.8 * 100) / 100,
      sorter: (a, b) => a.totalEarning.length - b.totalEarning.length,
    },
    {
      title: "VAT",

      // dataIndex: 100,
      // sorter: (a, b) => a.vat.length - b.vat.length,
      render: (text, record) =>
        record?.driverInfo?.vatRegistration
          ? 0
          : Math.round(((20 * Number(record.totalEarning)) / 100) * 100) / 100,
    },

    {
      title: "Total Earning",

      // dataIndex: 100,
      // sorter: (a, b) => a.vat.length - b.vat.length,
      // render: (text, record) =>
      //   record.vatRegistration
      //     ? Math.round((0 + record.totalEarning) * 100) / 100
      //     : Math.round(
      //         ((20 * Number(record.totalEarning)) / 100 + record.totalEarning) *
      //           100
      //       ) / 100,
      render: (text, record) => Math.round(record.totalEarning * 100) / 100,
      sorter: (a, b) => a.totalEarning.length - b.totalEarning.length,
    },
    // {
    //   title: "Pay",
    //   dataIndex: "pay",
    //   sorter: (a, b) => a.pay.length - b.pay.length,
    // },
    // {
    //   title: "Action",
    //   render: (text, record) => (
    //     <div>
    //       <div className="dropdown dropdown-action float-end">
    //         <a
    //           href="#"
    //           className="action-icon dropdown-toggle"
    //           data-bs-toggle="dropdown"
    //           aria-expanded="false"
    //         >
    //           <i className="material-icons"></i>
    //         </a>

    //         <div className="row dropdown-menu dropdown-menu-right">
    //           <div className="col-md-12">
    //             <Link
    //               to={{
    //                 pathname: `/editdriver`,
    //                 state: { record },
    //               }}
    //             >
    //               <i /> Edit Payment
    //             </Link>
    //           </div>
    //           <div className="col-md-12">
    //             <Link
    //               to={{
    //                 pathname: "/blockdriver",
    //                 state: { record },
    //               }}
    //             >
    //               <i /> Block Payment
    //             </Link>
    //           </div>
    //           <div className="col-md-12">
    //             <Link
    //               to={{
    //                 pathname: `/editdriver`,
    //                 state: { record },
    //               }}
    //             >
    //               <i /> Disable/Enable Payment
    //             </Link>
    //           </div>
    //           <div />
    //         </div>
    //       </div>
    //     </div>
    //   ),
    // },
  ];

  const ExportToExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const outputFilename = `${Date.now()}`;

    const customHeadings = driverPayment.map((item) => ({
      "Driver Name": item.driverInfo.name,
      "Contact Number": item.driverInfo.mobileNo,
      Email: item.driverInfo.email,
      "No. of Trips": item.totalTrips,
      "VAT Registered": item.driverInfo.vatRegistration,
      "Total kms": parseInt(item.totalDistance),
      "Total Trip Earning": parseInt(item.totalEarning),
      VAT: item.vatRegistration ? 0 : (20 * Number(item.totalEarning)) / 100,
      "Total Earning": item.vatRegistration
        ? 0 + item.totalEarning
        : (20 * Number(item.totalEarning)) / 100 + item.totalEarning,
    }));

    exportToExcel(customHeadings, outputFilename, fileExtension, fileType);
  };

  const exportToExcel = (customHeadings, fileName, fileExtension, fileType) => {
    const ws = XLSX.utils.json_to_sheet(customHeadings);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const ExportToCsv = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".csv";
    const outputFilename = `${Date.now()}`;

    const customHeadings = driverPayment.map((item) => ({
      "Driver Name": item.driverInfo.name,
      "Contact Number": item.driverInfo.mobileNo,
      Email: item.driverInfo.email,
      "No. of Trips": item.totalTrips,
      "VAT Registered": item.vatRegistration,
      "Total kms": parseInt(item.totalDistance),
      "Total Trip Earning": parseInt(item.totalEarning),
      VAT: item.vatRegistration ? 0 : (20 * Number(item.totalEarning)) / 100,
      "Total Earning": item.vatRegistration
        ? 0 + item.totalEarning
        : (20 * Number(item.totalEarning)) / 100 + item.totalEarning,
    }));

    exportToCsv(customHeadings, outputFilename, fileExtension, fileType);
  };

  const exportToCsv = (customHeadings, fileName, fileExtension, fileType) => {
    const ws = XLSX.utils.json_to_sheet(customHeadings);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const generatePdf = async () => {
    setLoaded(false);

    const response = await axios.get(
      URLConstants.getPaymentPendingPdf(fromDate, toDate, operator, "false"),
      {
        responseType: "blob", // Set responseType to 'blob'
      }
    );
    const blob = new Blob([response.data], { type: "application/pdf" });
    FileSaver.saveAs(blob, `${Number(new Date())}.pdf`);
    setLoaded(true);

    // const blob = new Blob([response], { type: "application/pdf" });
    // FileSaver(blob, Number(new Date()) + ".pdf");
  };

  const ChooseOfOperator = (event) => {
    const optId = event.target.value;
    setOperator(optId);
  };

  const filteredOperatorList =
    query === ""
      ? operatorNames
      : operatorNames?.filter((state) => {
          return state?.companyName.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <>
      <Header isAdmin />
      <div className="grid lg:grid-cols-6">
        <Leftbar />
        <Helmet>
          <title>Payment List - Treklers - Admin</title>
          <meta name="description" content="Login page" />
        </Helmet>
        <div className="p-8 lg:col-span-5">
          <div className="col-md-12">
            <div className="table-responsive">
              <div className="grid grid-cols-2 pb-4">
                <div>
                  <span className="text-2xl font-bold">
                    Driver Payment Outstanding
                  </span>
                </div>
                <div className=" float-end ml-auto">
                  <button
                    className="rounded bg-gray-700 text-white hover:bg-black px-3 py-2 w-auto"
                    onClick={() => ExportToExcel()}
                  >
                    Download Excel
                  </button>
                  <button
                    className="rounded bg-gray-700 text-white hover:bg-black px-3 py-2 w-auto ml-3"
                    onClick={() => ExportToCsv()}
                  >
                    Download CSV
                  </button>
                  {/* <button
                    className="rounded bg-gray-700 text-white hover:bg-black px-3 py-2 w-auto ml-3"
                    onClick={() => generatePdf()}
                  >
                    Download PDF
                  </button> */}
                </div>
              </div>

              <div className="grid grid-cols-1 pb-4">
                <div className="filter-row leads pt-4">
                  <form
                    onSubmit={handleSubmit((data) => {
                      setToDate(data.toDate);
                      setFromDate(data.fromDate);
                    })}
                  >
                    <div className="d-flex pb-3 align-items-end ">
                      {/* <div className="pb-3 w-25">
                        <label className="col-form-label">Operator</label>
                        <div className="w-full border border-1 border-gray-500 rounded py-1 pr-2">
                          <select
                            {...register("optId")}
                            className={`w-full border-0 outline-0 px-2  ${
                              errors?.optId ? "error-select" : ""
                            }`}
                            onChange={(event) => ChooseOfOperator(event)}
                          >
                            <option value="">Select Any</option>
                            {operatorNames.map(
                              ({ operatorId, companyName }, index) => (
                                <option value={operatorId}>
                                  {companyName}
                                </option>
                              )
                            )}
                          </select>
                          <small>{errors?.optId?.message}</small>
                        </div>
                      </div> */}

                      <div className="col-4">
                        <span>Operator</span>
                        <Combobox
                          value={displayFilterValue?.operatorName ?? ""}
                          onChange={(value) => {
                            // filterOptions.operatorId = value;
                            // setFilterOptions(filterOptions);
                            let optName = operatorNames?.filter(
                              (opt) => opt.operatorId == value
                            );
                            // console.log("OPTname", optName);

                            // displayFilterValue["operatorName"] =
                            //   optName[0].companyName;
                            // console.log("display name", displayFilterValue);
                            // setDisplayFilterValue(displayFilterValue);

                            displayFilterValue.operatorName =
                              optName[0].companyName;
                            setDisplayFilterValue(displayFilterValue);
                            filterOptions.operatorId = optName[0].operatorId;
                            setOperator(optName[0].operatorId);
                            setFilterOptions(filterOptions);
                          }}
                        >
                          <div className="relative mt-1">
                            <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md  2 sm:text-sm">
                              <Combobox.Input
                                className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                autocomplete="off"
                                displayValue={(state) => {
                                  console.log(
                                    "state inside state",
                                    displayFilterValue.operatorName
                                  );
                                  return displayFilterValue.operatorName;
                                }}
                                onChange={(event) =>
                                  setQuery(event.target.value)
                                }
                              />
                              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </Combobox.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                              afterLeave={() => setQuery("")}
                            >
                              <Combobox.Options
                                className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg   sm:text-sm"
                                style={{
                                  padding: "0px",
                                  margin: "0px",
                                  textAlign: "center",
                                  zIndex: "2",
                                  width: "auto",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {filteredOperatorList.length === 0 &&
                                query !== "" ? (
                                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                    Nothing found.
                                  </div>
                                ) : (
                                  filteredOperatorList.map(
                                    ({ operatorId, companyName }) => (
                                      <Combobox.Option
                                        key={operatorId}
                                        className={({ active }) =>
                                          `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                            active
                                              ? "bg-[#404298] text-white"
                                              : "text-gray-900"
                                          }`
                                        }
                                        style={{ padding: "15px" }}
                                        value={operatorId}
                                      >
                                        {({ selected, active }) => {
                                          return (
                                            <>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                  // flexDirection: "column",
                                                }}
                                              >
                                                <span
                                                  className={`block truncate ${
                                                    selected
                                                      ? "font-medium"
                                                      : "font-normal"
                                                  }`}
                                                >
                                                  {companyName}
                                                </span>
                                                {operatorId ===
                                                filterOptions["operatorId"] ? (
                                                  <span
                                                    className={`pl-3 ${
                                                      active
                                                        ? "text-white"
                                                        : "text-teal-600"
                                                    }`}
                                                  >
                                                    <CheckIcon
                                                      className="h-5 w-5"
                                                      aria-hidden="true"
                                                    />
                                                  </span>
                                                ) : null}
                                              </div>
                                            </>
                                          );
                                        }}
                                      </Combobox.Option>
                                    )
                                  )
                                )}
                              </Combobox.Options>
                            </Transition>
                          </div>
                        </Combobox>
                      </div>
                    </div>
                    <div className="d-flex pb-3 align-items-end ">
                      <div className="col-4">
                        <div className="mb-2">From Date </div>
                        <input
                          type="date"
                          className="w-full col-12 border p-1 px-8 rounded"
                          style={{ height: "36px" }}
                          {...register("fromDate", { required: true })}
                        />
                        {errors.fromDate && <p>Date is required</p>}
                      </div>
                      <div className="col-4">
                        <div className="mb-2">To Date </div>
                        <input
                          type="date"
                          className="col-12 border p-1 ps-2 rounded"
                          style={{ height: "36px" }}
                          {...register("toDate", { required: true })}
                        />
                        {errors.toDate && <p>Date is required</p>}
                      </div>

                      <div className="col-sm-1 col-md-2 text-right pb-3 pl-5">
                        <div>
                          <input
                            className="w-auto m-auto px-4 py-2"
                            type="submit"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="spinner">
                      <Loader
                        loaded={loaded}
                        lines={13}
                        length={20}
                        width={10}
                        radius={30}
                        corners={1}
                        rotate={0}
                        direction={1}
                        color="#000"
                        speed={1}
                        trail={60}
                        shadow={false}
                        hwaccel={false}
                        className="spinner"
                        zIndex={2e9}
                        top="50%"
                        left="50%"
                        scale={1.0}
                        loadedClassName="loadedContent"
                      />
                    </div>
                  </form>
                </div>
              </div>

              <Table
                className="table-striped"
                pagination={{
                  total: driverPayment.length,
                  showTotal: (total, range) =>
                    `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                  showSizeChanger: true,
                  onShowSizeChange: onShowSizeChange,
                  itemRender: itemRender,
                }}
                style={{ overflowX: "auto" }}
                columns={columns}
                bordered
                dataSource={driverPayment}
              />

              <div
                id="add_leave"
                className="modal custom-modal fade"
                role="dialog"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content modalSize">
                    <div className="p-2">
                      <div className="modal-header bg-gray-600 text-white text-center">
                        <h5 className="modal-title text-center">
                          Number Of Trips
                        </h5>
                        <button
                          type="button"
                          className="close text-white opacity-100"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <Table
                        className="table-striped"
                        pagination={{
                          total: driversTrips.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        style={{ overflowX: "auto" }}
                        columns={tripColumns}
                        bordered
                        dataSource={driversTrips}
                      />

                      <div className="text-center font-bold">
                        Final Amount : {parseInt(finalBill)}
                      </div>

                      <div className="pb-4 text-center mt-2">
                        {/* <button data-dismiss="modal" type="submit" /> */}
                        <button
                          onClick={() => updatePayment()}
                          type="button"
                          className="w-auto m-auto bg-gray-500 text-white rounded px-4 py-1"
                          data-bs-dismiss="modal"
                        >
                          Approve
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default DriverPament;
