import React, { useContext, useEffect, useState } from "react";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  useNavigate,
  BrowserRouter as Router,
  Route,
  Link,
} from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { axiosInstance } from "../../base/api/axios.util";
import { URLConstants } from "../../base/api/url.constants";
import { useAuth } from "../../base/hooks/useAuth.js";
import Loader from "react-loader";

let eMail = "";

const schemaLogIn = yup.object({
  email: yup.string().required("Email is required").trim(),
  password: yup.string().required("Password is required").trim(),
});

const schemaPaasengerLogIn = yup.object({
  email: yup.string().required("Email is required").trim(),
});
const schemaEmail = yup.object({
  email: yup
    .string()
    .matches("Email is required")
    .required("Email is required")
    .trim(),
});
const schemaOtp = yup.object({
  otp: yup.string().required("Otp is required").trim(),
});

const schemaResetPassword = yup
  .object({
    password: yup
      .string()
      .min(6, "Password must be at 6 char long")

      .required("Password is required")
      .trim(),

    repeatPassword: yup.string().required("ConfirmPassword is required").trim(),
    repeatPassword: yup
      .string()
      .required("Password is mendatory")
      .oneOf([yup.ref("password")], "Passwords does not match"),
  })
  .required();

export const Login = (props) => {
  const navigate = useNavigate();

  const [eye, seteye] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loaded, setLoaded] = useState(true);
  // const history = useHistory();
  const { login } = useAuth();
  const [step1, setstep1] = useState(true);
  const [step2, setstep2] = useState(false);
  const [step3, setstep3] = useState(false);
  const [mobileInput, setShowMobileInput] = useState(true);
  const [otpInput, setShowOtpInput] = useState(false);
  const [otpValue, setOtp] = useState({});
  const [messagePopUp, setMessagePopUp] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const loginType = ["Driver", "Employer", "Passenger"];
  const [errorMsg, setErrorMsg] = useState("");
  const {
    handleSubmit: handleLogIn,
    control: controlLogIn,
    setError,
    clearErrors,
    setValue,
    formState: { errors: errorsLogIn },
  } = useForm({});

  const {
    handleSubmit: handlePassengerLogIn,
    control: controlPassengerLogIn,
    setError: setPassengerError,
    register,
    formState: { errors: errorsPassengerLogIn },
  } = useForm({});
  const {
    handleSubmit: handleEmail,
    control: controlEmail,
    formState: { errors: errorsEmail },
  } = useForm({});
  const {
    handleSubmit: handleOtp,
    control: controlOtp,
    formState: { errors: errorsOtp },
  } = useForm({});
  const {
    handleSubmit: handleResetPassword,
    control: controlResetPassword,
    formState: { errors: errorsResetPassword },
  } = useForm({});
  console.log("OTP VALUE STORED", otpValue);

  function onSubmitEmail(data) {
    setLoaded(false);
    console.log(data);
    eMail = data.email;
    console.log("eMail", eMail);
    axiosInstance
      .post(URLConstants.generateEmailOtp(), {
        email: data.email,
      })
      .then((response) => {
        setLoaded(true);
        console.log(response.message);
        console.log(response);
        setErrorMsg("");

        setstep1(false);
        setstep2(true);
      })
      .catch((err) => {
        setErrorMsg(err.response.data.message);
        setLoaded(true);
        console.log(err);
      });
  }
  function onSubmitOtp(data) {
    setLoaded(false);
    console.log(data);
    axiosInstance
      .post(URLConstants.verifyEmailOtp(), {
        email: eMail,
        otp: data.otp,
      })
      .then((response) => {
        setLoaded(true);
        console.log(response);
        console.log(response.message);
        console.log(response.statusCode);
        setErrorMsg("");

        setMessagePopUp("Reset Password");
        setstep2(false);
        setstep3(true);
      })
      .catch((err) => {
        setErrorMsg(err.response.data.message);
        setLoaded(true);
        console.log(err);
      });
  }
  function onSubmitResetPassword(data) {
    setLoaded(false);
    console.log(data);
    axiosInstance
      .post(URLConstants.resetOperatorPassword(), {
        email: eMail,
        password: data.password,
      })
      .then((response) => {
        setLoaded(true);
        setErrorMsg("");
        toast.success("Reset Password Successful!!");
        console.log("MEssage", response.message);
        setMessagePopUp(response.message);
        setstep3(false);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setErrorMsg(err.response.data.message);

        setLoaded(true);
        console.log(err);
      });
  }

  const onSubmitLogIn = (data) => {
    setLoaded(false);
    login(
      {
        email: data.email,
        password: data.password,
      },
      loginType[activeTab]
    )
      .then((response) => {
        setLoaded(true);
        navigate("/loginscreen");
      })
      .catch((errorMsg) => {
        setLoaded(true);
        setError("password", {
          message: errorMsg,
        });
      });
  };

  const onSubmitPassengerLogIn = (data) => {
    console.log("Data in passenger login", data);
    setLoaded(false);
    if (data.otp) {
      login(data, loginType[activeTab])
        .then((response) => {
          console.log("Respnse received", response);
          setLoaded(true);
          navigate("/loginscreen");
        })
        .catch((errorMsg) => {
          setLoaded(true);
          setPassengerError("mobileNo", {
            message: errorMsg.statusMessage,
          });
        });
    } else {
      axiosInstance
        .get(
          `${URLConstants.BOOKING_URL}/v1/passenger/getotp?mobileno=${data.mobileNo}`
        )
        .then((response) => {
          console.log("Respnse received", response);
          setLoaded(true);
          setShowOtpInput(true);
          setShowMobileInput(false);
          setOtp({ mobileNo: data.mobileNo, otp: response.otp });
        })
        .catch((errorMsg) => {
          setLoaded(true);
          setPassengerError("mobileNo", {
            message: errorMsg.statusMessage,
          });
        });
    }
  };

  // function onSubmitEmail(data) {

  //   eMail = data.email;
  //   axiosInstance
  //     .post(URLConstants.sendVerificationEmail(), {
  //       userId: data.email,
  //     })
  //     .then((response) => {
  //       setMessagePopUp(response.message);
  //       if (response.statusCode === 200) {
  //         setstep1(false);
  //         setstep2(true);
  //       }
  //     })
  //     .catch((err) => {});
  // }
  // function onSubmitOtp(data) {
  //   axiosInstance
  //     .post(URLConstants.logInEmailOtp(), {
  //       email: eMail,
  //       otp: data.otp,
  //     })
  //     .then((response) => {
  //       if (response.statusCode === 200) {
  //         setMessagePopUp("Reset Password");
  //         setstep2(false);
  //         setstep3(true);
  //       } else {
  //         setMessagePopUp(response.message);
  //       }
  //     })
  //     .catch((err) => {});
  // }
  // function onSubmitResetPassword(data) {
  //   axiosInstance
  //     .post(URLConstants.clientResetPassword(), {
  //       email: eMail,
  //       password: data.password,
  //     })
  //     .then((response) => {
  //       setMessagePopUp(response.message);
  //       setstep3(false);
  //       window.location.reload();
  //     })
  //     .catch((err) => {});
  // }

  const onEyeClick = () => {
    seteye(!eye);
  };
  return (
    <div className="w-2/3 mx-auto  -mt-20 ">
      <div className="flex-col rounded-xl bg-white w-full p-4 shadow-xl">
        <div className="flex flex-row justify-between ">
          <div>
            <button
              className={`px-4 py-2 rounded-3xl ${
                activeTab === 0 ? "bg-[#080941] text-white" : "bg-gray-200"
              }`}
              onClick={() => setActiveTab(0)}
            >
              Driver Login
            </button>

            <button
              className={`px-4 py-2 rounded-3xl ml-2 ${
                activeTab === 2 ? "bg-[#080941] text-white" : "bg-gray-200"
              }`}
              onClick={() => setActiveTab(2)}
            >
              Passenger Login
            </button>
          </div>
          <div>
            <button
              className={`px-4 py-2 rounded-3xl   ${
                activeTab === 1 ? "bg-[#080941] text-white" : "bg-gray-200"
              }`}
              onClick={() => setActiveTab(1)}
            >
              Employee Login
            </button>
          </div>
        </div>
        {activeTab == 2 ? (
          <form
            className="flex my-3 md:flex-row xs:flex-col md:items-center  justify-evenly w-full gap-3"
            onSubmit={handlePassengerLogIn(onSubmitPassengerLogIn)}
          >
            {mobileInput ? (
              <div className="flex-1">
                {/* <Controller
                  name="mobileNo"
                  control={controlPassengerLogIn}
                  render={({ field }) => ( */}
                <div className="rounded-lg flex flex-row items-center justify-start px-4 py-0 border-[#C9C9C9] border-1">
                  {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#000"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                        />
                      </svg> */}

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                    />
                  </svg>

                  <input
                    className={`border-0 outline-none rounded w-full p-2  ${
                      errorsPassengerLogIn?.mobileNo
                        ? "border-red-500 "
                        : "border-gray-500"
                    }`}
                    type="text"
                    // value={value}
                    // onChange={onChange}
                    placeHolder="Mobile Number"
                    autoComplete="false"
                    // {...field}
                    name="mobileNo"
                    {...register("mobileNo")}
                  />
                </div>
                {/* )}
                  defaultValue=""
                />
                <small className="text-red-500">
                  {errorsPassengerLogIn?.mobileNo?.message}
                </small> */}
              </div>
            ) : null}
            {otpInput ? (
              <div className="flex-1">
                {/* <Controller
                  name="otp"
                  control={controlPassengerLogIn}
                  render={({ field }) => ( */}
                <div className="rounded-lg flex flex-row  items-center justify-start px-4 py-0 border-[#C9C9C9] border-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                    />
                  </svg>

                  <div className="pass-group w-full relative">
                    <input
                      type="text"
                      className={`border-none outline-none rounded w-full p-2 ${
                        errorsPassengerLogIn?.otp
                          ? "border-red-500 "
                          : "border-gray-500"
                      }`}
                      placeHolder="OTP"
                      autoComplete="false"
                      name="otp"
                      {...register("otp")}
                      // {...field}
                    />
                  </div>
                </div>
                {/* )}
                /> */}
                <small className="text-red-500">
                  {errorsPassengerLogIn?.otp?.message}
                </small>
              </div>
            ) : null}
            <div className="text-center">
              <button
                className="bg-black rounded text-white  px-4 py-2 md:px-16"
                type="submit"
              >
                {mobileInput ? "Send Otp" : "Verify Otp"}
              </button>
              <Loader
                loaded={loaded}
                lines={13}
                length={20}
                width={10}
                radius={30}
                corners={1}
                rotate={0}
                direction={1}
                color="#000"
                speed={1}
                trail={60}
                shadow={false}
                hwaccel={false}
                className="spinner"
                zIndex={2e9}
                top="50%"
                left="50%"
                scale={1.0}
                loadedClassName="loadedContent"
              />
            </div>
          </form>
        ) : (
          <form
            className="flex my-3 md:flex-row xs:flex-col md:items-center  justify-evenly w-full gap-3"
            onSubmit={handleLogIn(onSubmitLogIn)}
          >
            <div className="flex-1">
              <Controller
                name="email"
                control={controlLogIn}
                render={({ field: { value, onChange } }) => (
                  <div className="rounded-lg flex flex-row items-center justify-start px-4 py-0 border-[#C9C9C9] border-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="#000"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                      />
                    </svg>

                    <input
                      className={`border-0 outline-none rounded w-full p-2  ${
                        errorsLogIn?.email
                          ? "border-red-500 "
                          : "border-gray-500"
                      }`}
                      type="text"
                      value={value}
                      onChange={onChange}
                      placeHolder="Email Address"
                      autoComplete="false"
                    />
                  </div>
                )}
                defaultValue=""
              />
              <small className="text-red-500">
                {errorsLogIn?.email?.message}
              </small>
            </div>
            <div className="flex-1">
              <Controller
                name="password"
                control={controlLogIn}
                render={({ field: { value, onChange } }) => (
                  <div className="rounded-lg flex flex-row  items-center justify-start px-4 py-0 border-[#C9C9C9] border-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                      />
                    </svg>

                    <div className="pass-group w-full relative">
                      <input
                        type={eye ? "password" : "text"}
                        className={`border-none outline-none rounded w-full p-2 ${
                          errorsLogIn?.password
                            ? "border-red-500 "
                            : "border-gray-500"
                        }`}
                        value={value}
                        placeHolder="Password"
                        onChange={onChange}
                        autoComplete="false"
                      />
                    </div>
                    <span
                      onClick={onEyeClick}
                      className={`fa toggle-password right-3 top-2 ${
                        eye ? "fa-eye-slash" : "fa-eye"
                      }`}
                    />
                  </div>
                )}
              />
              <small className="text-red-500">
                {errorsLogIn?.password?.message}
              </small>
            </div>
            <div className="text-center">
              <button
                className="bg-black rounded text-white  px-4 py-2 md:px-16"
                type="submit"
              >
                Login
              </button>
              <Loader
                loaded={loaded}
                lines={13}
                length={20}
                width={10}
                radius={30}
                corners={1}
                rotate={0}
                direction={1}
                color="#000"
                speed={1}
                trail={60}
                shadow={false}
                hwaccel={false}
                className="spinner"
                zIndex={2e9}
                top="50%"
                left="50%"
                scale={1.0}
                loadedClassName="loadedContent"
              />
            </div>
          </form>
        )}
      </div>

      {activeTab == 1 ? (
        <div className="row h5 m-0">
          <div className="col-md-7">
            {/*  <p className="newMember m-0">
                    New Member?
                    <span className="orangeColor"> Sign up now </span>
                  </p>
                        */}
          </div>
          <div className="col-md-5">
            <p className="newMember orangeColor m-0 text-right">
              <Link
                className=""
                to="/#"
                data-bs-toggle="modal"
                data-bs-target="#add_leave"
              >
                <button className="w-auto m-auto px-4 py-2 rounded-lg">
                  Reset Password
                </button>
              </Link>
              {/* <a data-bs-target="#add_leave" data-bs-toggle="modal">
              Forgot / Reset Password ?
            </a> */}
            </p>
          </div>
        </div>
      ) : null}
      <div
        id="add_leave"
        className="modal custom-modal fade z-50"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content modalSize">
            <div className="">
              <div className="modal-header bg-[#404298] text-white text-center">
                <h5 className="modal-title text-center font-bold">
                  Reset Password
                </h5>
                <button
                  type="button"
                  className="close text-white opacity-100"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body pt-0">
                {step1 ? (
                  <form onSubmit={handleEmail(onSubmitEmail)}>
                    <div className="form-group mb-0">
                      <label>
                        Email Address <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="email"
                        control={controlEmail}
                        render={({ field }) => (
                          <input
                            placeholder="Enter Email Address"
                            className={`form-control  ${
                              errorsEmail?.email ? "error-input" : ""
                            }`}
                            type="text"
                            autoComplete="true"
                            {...field}
                          />
                        )}
                        defaultValue=""
                      />
                      <small className="font11 absolute">
                        {errorsEmail?.email?.message}
                      </small>
                    </div>
                    <h6 className="text-center mb-4 text-danger">
                      {messagePopUp}
                    </h6>

                    <div className="text-center">
                      <button
                        className="bg-black rounded text-white  px-4 py-2 md:px-16"
                        type="submit"
                      >
                        Send Otp
                      </button>
                    </div>
                    <div className="text-sm text-danger my-4">{errorMsg}</div>
                  </form>
                ) : (
                  ""
                )}
                {step2 ? (
                  <form onSubmit={handleOtp(onSubmitOtp)}>
                    <div className="form-group">
                      <label>
                        OTP <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="otp"
                        control={controlOtp}
                        render={({ field: { value, onChange } }) => (
                          <input
                            placeholder="Enter OTP"
                            className={`form-control  ${
                              errorsOtp?.otp ? "error-input" : ""
                            }`}
                            type="text"
                            value={value}
                            onChange={onChange}
                            autoComplete="false"
                          />
                        )}
                        defaultValue=""
                      />
                      <small className="font11 absolute">
                        {errorsOtp?.otp?.message}
                      </small>
                      <div className="text-center mt-4">
                        <button
                          className="bg-black rounded text-white  px-4 py-2 md:px-16"
                          type="submit"
                        >
                          Verify Otp
                        </button>
                      </div>
                    </div>
                    <div className="text-sm text-danger my-4">{errorMsg}</div>
                  </form>
                ) : (
                  ""
                )}
                {step3 ? (
                  <form onSubmit={handleResetPassword(onSubmitResetPassword)}>
                    <div className="form-group">
                      <label>
                        Password <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="password"
                        control={controlResetPassword}
                        render={({ field: { value, onChange } }) => (
                          <div className="pass-group">
                            <input
                              placeholder="Enter Password"
                              className={`form-control  ${
                                errorsResetPassword?.password
                                  ? "error-input"
                                  : ""
                              }`}
                              type={eye ? "password" : "text"}
                              value={value}
                              onChange={onChange}
                              autoComplete="false"
                            />
                            <span
                              onClick={onEyeClick}
                              className={`fa toggle-password" ${
                                eye ? "fa-eye-slash" : "fa-eye"
                              }`}
                            />
                          </div>
                        )}
                        defaultValue=""
                      />
                      <small className="font11">
                        {errorsResetPassword?.password?.message}
                      </small>
                    </div>
                    <div className="form-group">
                      <label>
                        Verify Password <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="repeatPassword"
                        control={controlResetPassword}
                        render={({ field: { value, onChange } }) => (
                          <div className="pass-group">
                            <input
                              placeholder="Verify Password"
                              className={`form-control  ${
                                errorsResetPassword?.password
                                  ? "error-input"
                                  : ""
                              }`}
                              type={eye ? "password" : "text"}
                              value={value}
                              onChange={onChange}
                              autoComplete="false"
                            />
                            <span
                              onClick={onEyeClick}
                              className={`fa toggle-password" ${
                                eye ? "fa-eye-slash" : "fa-eye"
                              }`}
                            />
                          </div>
                        )}
                        defaultValue=""
                      />
                      <small className="font11">
                        {errorsResetPassword?.password?.message}
                      </small>
                    </div>

                    <div className="text-center">
                      {/* <Button
                        type="submit"
                        className="btn btn-primary py-1 w-auto"
                      >
                        {" "}
                        Submit
                      </Button> */}

                      <button
                        className=" rounded text-white  px-4 py-2 md:px-16"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                    <div className="text-sm text-danger my-4">{errorMsg}</div>
                  </form>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
