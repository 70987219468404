import React, { useEffect, useState, useContext } from "react";
import { Leftbar } from "./sidebar/Leftbar";
import { Link, useNavigate } from "react-router-dom";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import { Table } from "antd";
import { Helmet } from "react-helmet";
import { itemRender, onShowSizeChange } from "./paginationfunction";
import { axiosInstance } from "../base/api/axios.util";
import { URLConstants } from "../base/api/url.constants";
import { ClientContext } from "../base/contexts/UserContext";
import toast, { Toaster } from "react-hot-toast";
import { useForm, Controller } from "react-hook-form";
import Loader from "react-loader";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
function OperatorList() {
  const [operatorList, setOperatorList] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [operatorNames, setOperatorsName] = useState([]);
  const [vehicleNos, setVehicleNos] = useState([]);

  const { client, setClient } = useContext(ClientContext);
  console.log("OPerator list", operatorList);
  const navigate = useNavigate();

  const {
    register: registerFilter,
    handleSubmit: handleSubmitFilter,

    formState: { errors: errorsFilter },
    reset: resetFilter,
  } = useForm({});
  function ukDateFormatter(date) {
    return date.split("T")[0].split("-").reverse().join("-");
  }

  const getExpiryDateClass = (expiryDateStr) => {
    const expiryDate = new Date(expiryDateStr);
    const today = new Date();
    const oneMonthFromNow = new Date();
    oneMonthFromNow.setMonth(today.getMonth() + 1);
    const twoMonthsFromNow = new Date();
    twoMonthsFromNow.setMonth(today.getMonth() + 2);

    if (expiryDate < today) {
      return "bg-red-600"; // Expired
    } else if (expiryDate < oneMonthFromNow) {
      return "bg-red-600"; // Expires in less than 1 month
    } else if (expiryDate < twoMonthsFromNow) {
      return "bg-yellow-600"; // Expires in less than 2 months
    } else {
      return "bg-green-600"; // No special styling for expiry dates more than 2 months away
    }
  };

  const ExportToExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const outputFilename = `${Date.now()}`;

    const customHeadings = operatorList?.map((item) => ({
      "Operator Name": item.companyName,
      Address: item.address,
      City: item.city,
      "Email Address": item.email,
      "Mobile No": item.mobileNo,
      "VAT Registered": item?.driverInfo?.vatRegistration ? "Yes" : "No",
      "Landline Number": item.optLandline,
      "Postal Code": item.zipCode,
      "Contact Person": item.contactPerson,
    }));

    exportToExcel(customHeadings, outputFilename, fileExtension, fileType);
  };

  const exportToExcel = (customHeadings, fileName, fileExtension, fileType) => {
    const ws = XLSX.utils.json_to_sheet(customHeadings);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const ExportToCsv = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".csv";
    const outputFilename = `${Date.now()}`;

    const customHeadings = operatorList?.map((item) => ({
      "Operator Name": item.companyName,
      Address: item.address,
      City: item.city,
      "Email Address": item.email,
      "Mobile No": item.mobileNo,
      "VAT Registered": item?.driverInfo?.vatRegistration ? "Yes" : "No",
      "Landline Number": item.optLandline,
      "Postal Code": item.zipCode,
      "Contact Person": item.contactPerson,
    }));

    exportToCsv(customHeadings, outputFilename, fileExtension, fileType);
  };

  const exportToCsv = (customHeadings, fileName, fileExtension, fileType) => {
    const ws = XLSX.utils.json_to_sheet(customHeadings);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  useEffect(() => {
    setLoaded(false);
    if (!client) {
      navigate("/");
    } else if (client?.logInType == "SUPER_ADMIN") {
      axiosInstance
        .post(URLConstants.getOperatorList(), {})
        .then((response) => {
          setOperatorList(response.results);
          setLoaded(true);
        })
        .catch((err) => {
          setLoaded(true);
        });
    } else {
      navigate("/notfound");
    }
  }, []);
  const columns = [
    {
      title: "Operator Name",
      dataIndex: "companyName",
      sorter: (a, b) => a.companyName.length - b.companyName.length,
    },
    {
      title: "Address",
      dataIndex: "address",
      sorter: (a, b) => a.address.length - b.address.length,
    },
    {
      title: "City",
      dataIndex: "city",
      sorter: (a, b) => a.city.length - b.city.length,
    },

    {
      title: "Email Address",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length,
    },

    {
      title: "Mobile No.",
      dataIndex: "mobileNo",
      sorter: (a, b) => a.mobileNo.length - b.mobileNo.length,
    },

    {
      title: "VAT Registered",
      dataIndex: "vatRegistration",

      render: (text, record) =>
        record?.driverInfo?.vatRegistration ? "Yes" : "No",
    },

    {
      title: "Landline Number",
      dataIndex: "optLandline",
      sorter: (a, b) => a.optLandline.length - b.optLandline.length,
    },
    {
      title: "Postal Code",
      dataIndex: "zipCode",
      sorter: (a, b) => a.zipCode.length - b.zipCode.length,
    },

    {
      title: "Contact Person",
      dataIndex: "contactPerson",
      sorter: (a, b) => a.contactPerson.length - b.contactPerson.length,
    },

    {
      title: "Operator License 1",
      dataIndex: "companyLicense1",
      // sorter: (a, b) => a.otherDoc.length - b.otherDoc.length,
      render: (text, record) => {
        if (record.companyLicense1?.fileName) {
          return (
            <button
              // className="font-bold "
              className="text-white font-bold rounded-md px-3 py-2 w-[80px] text-center"
              onClick={() => {
                setLoaded(false);
                console.log("ANkit");
                axiosInstance
                  .post(URLConstants.getDriverDocument(), {
                    fileName: record.companyLicense1?.fileName,
                  })
                  .then((res) => {
                    setLoaded(true);
                    console.log("Response", res);
                    window.open(res, "_blank");
                  })
                  .catch((err) => {
                    toast.error(
                      "Something went wrong please try again later!!"
                    );
                    setLoaded(true);
                    console.log("err", err);
                  });
              }}
              // href={record.driverLicense?.fileName}
              // target="_blank"

              type="submit"
            >
              View
            </button>
          );
        } else {
          return <span className="font-bold">Document not uploaded</span>;
        }
      },
    },
    {
      title: "Expiry Date",
      dataIndex: "companyLicense1",
      // render: (text, record) =>
      //   (text = record.otherDoc?.expiryDate
      //     ? record.otherDoc?.expiryDate
      //     : "N.A."),

      render: (text, record) =>
        record.companyLicense1?.expiryDate ? (
          <div
            className={`${getExpiryDateClass(
              record.companyLicense1?.expiryDate
            )} text-white font-bold rounded-md px-3 py-2 w-[120px] text-center`}
          >
            {" "}
            {ukDateFormatter(record.companyLicense1?.expiryDate)}
          </div>
        ) : (
          "N.A."
        ),
    },
    {
      title: "Operator License 2",
      dataIndex: "companyLicense2",
      // sorter: (a, b) => a.otherDoc.length - b.otherDoc.length,
      render: (text, record) => {
        if (record.companyLicense2?.fileName) {
          return (
            <button
              // className="font-bold "
              className="text-white font-bold rounded-md px-3 py-2 w-[80px] text-center"
              onClick={() => {
                setLoaded(false);
                console.log("ANkit");
                axiosInstance
                  .post(URLConstants.getDriverDocument(), {
                    fileName: record.companyLicense2?.fileName,
                  })
                  .then((res) => {
                    setLoaded(true);
                    console.log("Response", res);
                    window.open(res, "_blank");
                  })
                  .catch((err) => {
                    toast.error(
                      "Something went wrong please try again later!!"
                    );
                    setLoaded(true);
                    console.log("err", err);
                  });
              }}
              // href={record.driverLicense?.fileName}
              // target="_blank"

              type="submit"
            >
              View
            </button>
          );
        } else {
          return <span className="font-bold">Document not uploaded</span>;
        }
      },
    },
    {
      title: "Expiry Date",
      dataIndex: "companyLicense2",
      // render: (text, record) =>
      //   (text = record.otherDoc?.expiryDate
      //     ? record.otherDoc?.expiryDate
      //     : "N.A."),

      render: (text, record) =>
        record.companyLicense2?.expiryDate ? (
          <div
            className={`${getExpiryDateClass(
              record.companyLicense2?.expiryDate
            )} text-white font-bold rounded-md px-3 py-2 w-[120px] text-center`}
          >
            {" "}
            {ukDateFormatter(record.companyLicense2?.expiryDate)}
          </div>
        ) : (
          "N.A."
        ),
    },
    {
      title: "Operator Insurance",
      dataIndex: "companyInsurance",
      // sorter: (a, b) => a.otherDoc.length - b.otherDoc.length,
      render: (text, record) => {
        if (record.companyInsurance?.fileName) {
          return (
            <button
              // className="font-bold "
              className="text-white font-bold rounded-md px-3 py-2 w-[80px] text-center"
              onClick={() => {
                setLoaded(false);
                console.log("ANkit");
                axiosInstance
                  .post(URLConstants.getDriverDocument(), {
                    fileName: record.companyInsurance?.fileName,
                  })
                  .then((res) => {
                    setLoaded(true);
                    console.log("Response", res);
                    window.open(res, "_blank");
                  })
                  .catch((err) => {
                    toast.error(
                      "Something went wrong please try again later!!"
                    );
                    setLoaded(true);
                    console.log("err", err);
                  });
              }}
              // href={record.driverLicense?.fileName}
              // target="_blank"

              type="submit"
            >
              View
            </button>
          );
        } else {
          return <span className="font-bold">Document not uploaded</span>;
        }
      },
    },
    {
      title: "Expiry Date",
      dataIndex: "companyInsurance",
      // render: (text, record) =>
      //   (text = record.otherDoc?.expiryDate
      //     ? record.otherDoc?.expiryDate
      //     : "N.A."),

      render: (text, record) =>
        record.companyInsurance?.expiryDate ? (
          <div
            className={`${getExpiryDateClass(
              record.companyInsurance?.expiryDate
            )} text-white font-bold rounded-md px-3 py-2 w-[120px] text-center`}
          >
            {" "}
            {ukDateFormatter(record.companyInsurance?.expiryDate)}
          </div>
        ) : (
          "N.A."
        ),
    },
    {
      title: "Action",
      render: (text, record) => (
        <div>
          <div className="dropdown dropdown-action float-end">
            <a
              href="#"
              className="action-icon dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="material-icons"></i>
            </a>

            <div className="row dropdown-menu dropdown-menu-right noLine">
              <div className="col-md-12 text-black hover:bg-gray-500">
                <Link
                  to={{
                    pathname: `/triplist`,
                  }}
                  state={record}
                >
                  <span>Trip List</span>
                </Link>
              </div>
              {/* <div className="col-md-12 text-black hover:bg-gray-500">
                <Link
                  to={{
                    pathname: `/vehiclelist`,
                  }}
                  state={record}
                >
                  <span>Vehicle List</span>
                </Link>
              </div> */}
              <div className="col-md-12 text-black hover:bg-gray-500">
                <Link
                  to={{
                    pathname: `/driverlist`,
                  }}
                  state={record}
                >
                  <span>Driver List</span>
                </Link>
              </div>
              <div className="col-md-12 text-black hover:bg-gray-500">
                <Link
                  to={{
                    pathname: `/createoperator`,
                  }}
                  state={record}
                >
                  <span>Edit Operator</span>
                </Link>
              </div>
              <div />
            </div>
          </div>
        </div>
      ),
    },
  ];
  // const operatorColumns = [
  //   {
  //     companyName: "Richael Trucking",
  //     address: "Ave Phoenix AZ",
  //     city: "Paris",
  //     mobileNo: 5465678888,
  //     email: "richael@partners.com",
  //     zipCode: "2435",
  //     operatorId: "34",
  //     companyDoc1Url: "button will come",
  //     companyDoc2Url: "button",
  //     contactPerson: "9877777777",
  //   },
  //   {
  //     companyName: "Best Yet Freight",
  //     address: "Ventura Bedford qwZ",
  //     city: "London",
  //     mobileNo: 9877776565,
  //     email: "best@partners.com",
  //     zipCode: "4567",
  //     operatorId: "987",
  //     companyDoc1Url: "https://monoca.truckspot.com",
  //     companyDoc2Url: "https://monoca.trucking.com",
  //     contactPerson: "7676767689",
  //   },
  //   {
  //     companyName: "Move Forward Transportation",
  //     address: "East Rd  Point Park",
  //     city: "Dubai",
  //     mobileNo: 5478789900,
  //     email: "transportation@partners.com",
  //     zipCode: "9876",
  //     operatorId: "321",
  //     companyDoc1Url: "https://jonny.trucking.com",
  //     companyDoc2Url: "https://jhone.travelling.com",
  //     contactPerson: "46576588787",
  //   },
  //   {
  //     companyName: "Rock & Roll Trucking",
  //     address: "EastLake Point Park USA",
  //     city: "Singapore",
  //     mobileNo: 6577898765,
  //     email: "rock@partners.com",
  //     zipCode: "3245",
  //     operatorId: "111",
  //     companyDoc1Url: "https://blogs.hubspot.com",
  //     companyDoc2Url: "https://shubaiz.truckspot.com",
  //     contactPerson: "9876767898",
  //   },

  // ];
  return (
    <>
      <Header isAdmin />
      <div className="grid lg:grid-cols-6">
        <Leftbar />
        <Helmet>
          <title>Operator List - Treklers - Admin</title>
          <meta name="description" content="Login page" />
        </Helmet>
        <div className="p-8 lg:col-span-5">
          <div className="col-md-12">
            <div className="table-responsive">
              <div className="p-4 grid grid-cols-2 pb-8 mb-3">
                <div>
                  <span className="text-2xl font-bold">Operator List</span>
                  <span className="font-bold p-4">
                    <Link to="/">Home</Link>{" "}
                  </span>
                </div>

                <div className=" float-end ml-auto mb-6">
                  <button
                    className="rounded bg-gray-700 text-white hover:bg-black px-3 py-2 w-auto"
                    onClick={() => ExportToExcel()}
                  >
                    Download Excel
                  </button>
                  <button
                    className="rounded bg-gray-700 text-white hover:bg-black px-3 py-2 w-auto ml-3"
                    onClick={() => ExportToCsv()}
                  >
                    Download CSV
                  </button>
                  {/* <button
                    className="rounded bg-gray-700 text-white hover:bg-black px-3 py-2 w-auto ml-3"
                    // onClick={() => generatePdf()}
                  >
                    Download PDF
                  </button> */}
                </div>
              </div>

              <div className="grid grid-cols-1 pb-4">
                <div className="filter-row leads pt-4">
                  <form
                    onSubmit={handleSubmitFilter((data) => {
                      console.log("Before Date", data);

                      if (
                        data?.fromDate?.length == 0 ||
                        data?.toDate?.length == 0
                      ) {
                        delete data.fromDate;
                        delete data.toDate;
                      }

                      if (data?.operatorMobileNo?.length == 0) {
                        delete data.operatorMobileNo;
                      }
                      if (data?.operatorName?.length == 0) {
                        delete data.operatorName;
                      }
                      if (data?.operatorEmail?.length == 0) {
                        delete data.operatorEmail;
                      } else {
                        data.operatorEmail = data.operatorEmail.replaceAll(
                          " ",
                          ""
                        );
                      }

                      console.log("After Date", data);

                      axiosInstance
                        .post(URLConstants.getOperatorList(), data)
                        .then((response) => {
                          setOperatorList(response.results);
                          setLoaded(true);
                        })
                        .catch((err) => {
                          setLoaded(true);
                        });
                    })}
                  >
                    <div className="d-flex pb-3 align-items-end ">
                      {/* <div className="pb-3 w-25">
                        <label className="col-form-label">Operator</label>
                        <div className="w-full border border-1 border-gray-500 rounded py-1 pr-2">
                          <select
                            {...registerFilter("operatorId")}
                            className={`w-full border-0 outline-0 px-2  ${
                              errorsFilter?.operatorId ? "error-select" : ""
                            }`}
                          >
                            <option value="">Select Any</option>
                            {operatorNames.map(
                              ({ operatorId, companyName }, index) => (
                                <option value={operatorId}>
                                  {companyName}
                                </option>
                              )
                            )}
                          </select>
                          <small>{errorsFilter?.optId?.message}</small>
                        </div>
                      </div>
                      <div className="pb-3 w-25">
                        <label className="col-form-label">Vehicle Number</label>
                        <div className="w-full border border-1 border-gray-500 rounded py-1 pr-2">
                          <select
                            {...registerFilter("vehicleNo")}
                            className={`w-full border-0 outline-0 px-2  ${
                              errorsFilter?.vehicleNo ? "error-select" : ""
                            }`}
                            // onChange={(event) => ChooseOfOperator(event)}
                          >
                            <option value="">Select Any</option>
                            {vehicleNos.map((vehicleNo, index) => (
                              <option value={vehicleNo}>{vehicleNo}</option>
                            ))}
                          </select>
                          <small>{errorsFilter?.vehicleNo?.message}</small>
                        </div>
                      </div> */}
                      <div className="col-4">
                        <div className="mb-2">Operator Mobile Number </div>
                        <input
                          type="number"
                          className="w-full col-12 border p-1 px-8 rounded"
                          style={{ height: "36px" }}
                          {...registerFilter("operatorMobileNo", {
                            required: false,
                          })}
                        />
                      </div>
                      <div className="col-4">
                        <div className="mb-2">Operator Name </div>
                        <input
                          type="text"
                          className="w-full col-12 border p-1 px-8 rounded"
                          style={{ height: "36px" }}
                          {...registerFilter("operatorName", {
                            required: false,
                          })}
                        />
                      </div>

                      <div className="col-4">
                        <div className="mb-2">Operator Email </div>
                        <input
                          type="text"
                          className="w-full col-12 border p-1 px-8 rounded"
                          style={{ height: "36px" }}
                          {...registerFilter("operatorEmail", {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                    .
                    <div className="d-flex pb-3 align-items-end it">
                      <div className="col-4">
                        <div className="mb-2">From Date </div>
                        <input
                          type="date"
                          className="w-full col-12 border p-1 px-8 rounded"
                          style={{ height: "36px" }}
                          {...registerFilter("fromDate", { required: true })}
                        />
                        {errorsFilter.fromDate && <p>Date is required</p>}
                      </div>
                      <div className="col-4">
                        <div className="mb-2">To Date </div>
                        <input
                          type="date"
                          className="col-12 border p-1 ps-2 rounded"
                          style={{ height: "36px" }}
                          {...registerFilter("toDate", { required: true })}
                        />
                        {errorsFilter.toDate && <p>Date is required</p>}
                      </div>

                      <div className="col-sm-1 col-md-2 text-right pb-3 pl-5">
                        <div>
                          <input
                            className="w-auto m-auto px-4 py-2"
                            type="submit"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="spinner">
                      <Loader
                        loaded={loaded}
                        lines={13}
                        length={20}
                        width={10}
                        radius={30}
                        corners={1}
                        rotate={0}
                        direction={1}
                        color="#000"
                        speed={1}
                        trail={60}
                        shadow={false}
                        hwaccel={false}
                        className="spinner"
                        zIndex={2e9}
                        top="50%"
                        left="50%"
                        scale={1.0}
                        loadedClassName="loadedContent"
                      />
                    </div>
                  </form>
                </div>
              </div>

              <div className="col-auto float-end ml-auto mb-6">
                <Link
                  className="inline-flex items-center bg-[#404298] font-semibold border-0 py-1 px-3 focus:outline-none hover:bg-gray-700 rounded-full text-white md:mt-0"
                  to="/createoperator"
                >
                  <i className="fa fa-plus mr-2" /> Create Operator
                </Link>
              </div>

              <Table
                className="table-striped"
                pagination={{
                  total: operatorList.length,
                  showTotal: (total, range) =>
                    `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                  showSizeChanger: true,
                  onShowSizeChange: onShowSizeChange,
                  itemRender: itemRender,
                }}
                style={{ overflowX: "auto" }}
                columns={columns}
                bordered
                dataSource={operatorList}
              />
            </div>
          </div>
        </div>
        <div className="spinner">
          <Loader
            loaded={loaded}
            lines={13}
            length={20}
            width={10}
            radius={30}
            corners={1}
            rotate={0}
            direction={1}
            color="#000"
            speed={1}
            trail={60}
            shadow={false}
            hwaccel={false}
            className="spinner"
            zIndex={2e9}
            top="50%"
            left="50%"
            scale={1.0}
            loadedClassName="loadedContent"
          />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default OperatorList;
