import { useContext } from "react";
import { ClientContext, UserContext } from "../contexts/UserContext";
import { axiosInstance } from "../api/axios.util";
import { URLConstants } from "../api/url.constants";
import { AuthTokenHandler } from "../api/auth-token.util";
import { useNavigate } from "react-router-dom";
import PassengerInfo from "../utils/PassengerInfo";

export const useAuth = () => {
  const { user, setUser } = useContext(UserContext);
  const { client, setClient } = useContext(ClientContext);
  const navigate = useNavigate();

  const logout = () => {
    setUser(undefined);
    setClient(undefined);
    localStorage.clear();
    navigate("/");
  };

  const login = (loginData, type) => {
    if (type == "Employer") {
      return new Promise((resolve, reject) => {
        axiosInstance
          .post(URLConstants.login(), loginData)
          .then((response) => {
            try {
              const data = response;
              const user = data?.user;
              const client = data;
              client.logInType = client.loginData.type;
              AuthTokenHandler.setAccessToken(data?.tokens?.access);
              AuthTokenHandler.setAuthToken(data?.tokens?.authToken);
              setUser(user);
              setClient(client);
            } catch (error) {
              console.error(error);
            }
            resolve({ user, client });
          })
          .catch((err) => {
            const errorMsg = err?.response?.data?.message;
            reject(errorMsg);
          });
      });
    } else if (type == "Passenger") {
      console.log("IN passenger and logindata is ", loginData);
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(
            `${URLConstants.BOOKING_URL}/v1/passenger/verifyotp?mobileno=${loginData.mobileNo}&otp=${loginData.otp}`
          )
          .then(async (response) => {
            try {
              const data = response;
              const user = response?.passengerDetails;
              // const client = data;
              // const passengerInfo = await PassengerInfo(
              //   response?.passengerDetails?._id
              // );

              let client = {
                logInType: "Passenger",
                loginData: {
                  client: response.passengerDetails,
                  id: response?.passengerDetails?._id,
                  type: "Passenger",
                },
                tokens: response.tokens,
              };

              AuthTokenHandler.setAccessToken(data?.tokens?.access);
              AuthTokenHandler.setAuthToken(data?.tokens?.refreshToken);
              setUser(user);
              setClient(client);
            } catch (error) {
              console.error(error);
            }
            resolve({ user, client });
          })
          .catch((err) => {
            const errorMsg = err?.response?.data?.message;
            reject(errorMsg);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        axiosInstance
          .post(URLConstants.driverLogin(), loginData)
          .then((response) => {
            try {
              const data = response;
              const user = data?.user;
              const client = data;
              client.logInType = "Driver";
              AuthTokenHandler.setAccessToken(data?.tokens?.access);
              AuthTokenHandler.setAuthToken(data?.tokens?.authToken);
              setUser(user);
              setClient(client);
            } catch (error) {
              console.error(error);
            }
            resolve({ user, client });
          })
          .catch((err) => {
            const errorMsg = err?.response?.data?.message;
            reject(errorMsg);
          });
      });
    }
  };

  return {
    logout,
    login,
  };
};
