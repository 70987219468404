import React, { useState, useEffect } from "react";
import Failed from "../images/failure-cross.png";
import { Link } from "react-router-dom";

export default function PaymentFailed() {
  const [isGrowing, setIsGrowing] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsGrowing((prevIsGrowing) => !prevIsGrowing);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const size = isGrowing ? "100px" : "120px";

  return (
    <div className="flex flex-col items-center justify-center mt-10 pt-20">
      <div
        className="image-container"
        style={{
          width: size,
          height: size,
          transition: "width 1s ease, height 1s ease",
        }}
      >
        <img src={Failed} className="image" alt="Transaction Failed" />
      </div>
      <h2 className="text-3xl font-bold text-red-600 mt-8">Payment Failed</h2>
      <p className="text-lg text-gray-600 mt-4">
        We're sorry, but your payment was not successful. Please try again.
      </p>
      <div className="flex ">
        <button className=" text-white font-bold py-2 px-4 rounded mt-8">
          <Link to="/" className="bg-black btn text-white ">
            Go to Dashboard
          </Link>
        </button>
        <button className=" text-white font-bold py-2 px-4 rounded mt-8">
          <Link to="/" className="bg-black btn text-white ">
            Try Again
          </Link>
        </button>
      </div>
    </div>
  );
}
