import React, { useEffect, useState, useContext } from "react";
import Header from "./header/Header";
import { Leftbar } from "./sidebar/Leftbar";
import { Helmet } from "react-helmet";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "./footer/Footer";
import { useForm, Controller, set } from "react-hook-form";
import { axiosInstance } from "../base/api/axios.util";
import { URLConstants } from "../base/api/url.constants";
import axios, * as others from "axios";
import { ClientContext } from "../base/contexts/UserContext";
import { MultiSelect } from "react-multi-select-component";
import Loader from "react-loader";
var FormData = require("form-data");

const DeleteAccount = () => {
  const [record, setRecord] = useState({});
  const [loaded, setLoaded] = useState(true);
  const { client, setClient } = useContext(ClientContext);
  const [selectedLanguageData, setLanguageData] = useState([]);

  const optionsLang = [
    { label: "English", value: "English" },
    { label: "Hindi", value: "Hindi" },
    { label: "Chinese", value: "Chinese" },
    { label: "Russian", value: "Russian" },
    { label: "French", value: "French" },
    { label: "Spanish", value: "Spanish" },
  ];

  const navigate = useNavigate();

  let defaultValue = {
    name: "",
    email: "",
    gender: "",

    mobileNo: "",
  };

  console.log("default value 1234", defaultValue);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValue,
  });

  useEffect(() => {}, []);
  return (
    <>
      <Header isAdmin />
      <div className="grid lg:grid-cols-6">
        <Helmet>
          <title>Delete Account - Treklers - Admin</title>
          <meta name="description" content="Login page" />
        </Helmet>
        <div className="p-8 lg:col-span-5">
          <div className="col-md-12">
            <div className="table-responsive">
              <div className="p-4 grid grid-cols-2 pb-8">
                <div>
                  <span className="text-2xl font-bold">Delete Account</span>
                  <span className="font-bold p-4">
                    <Link to="/">
                      <i /> Home
                    </Link>
                  </span>
                </div>
                <div className="col-auto float-end ml-auto"></div>
              </div>
              <form>
                <div className="grid grid-cols-2 gap-8 gap-y-4 mb-8">
                  <div>
                    <label>Name</label>
                    <input value={defaultValue.name} />
                  </div>

                  <div>
                    <label>Email</label>
                    <input type="email" value={defaultValue.email} />
                    {errors.email && <p>Email field is required</p>}
                  </div>

                  <div>
                    <label>Gender</label>
                    <div className="w-full border border-1 border-dark rounded p-2">
                      <select
                        className="w-full border-0 outline-0"
                        value={defaultValue.gender}
                      >
                        <option value="Female">Female</option>
                        <option value="Male">Male</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    {errors.gender && <p>Gender field is required</p>}
                  </div>

                  <div>
                    <label>Mobile Number</label>
                    <input type="number" value={defaultValue.mobileNo} />
                    {errors.mobileNo && <p>Mobile Number is required</p>}
                  </div>
                </div>

                <div>
                  <input className="w-auto m-auto" type="submit" />
                </div>
                <div className="spinner">
                  <Loader
                    loaded={loaded}
                    lines={13}
                    length={20}
                    width={10}
                    radius={30}
                    corners={1}
                    rotate={0}
                    direction={1}
                    color="#000"
                    speed={1}
                    trail={60}
                    shadow={false}
                    hwaccel={false}
                    className="spinner"
                    zIndex={2e9}
                    top="50%"
                    left="50%"
                    scale={1.0}
                    loadedClassName="loadedContent"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DeleteAccount;
