import React, { useState, useEffect, useContext } from "react";
import { Leftbar } from "./sidebar/Leftbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import { Table } from "antd";
import { Helmet } from "react-helmet";
import { useForm, Controller } from "react-hook-form";
import { axiosInstance } from "../base/api/axios.util";
import { URLConstants } from "../base/api/url.constants";
import { ClientContext } from "../base/contexts/UserContext";
import toast, { Toaster } from "react-hot-toast";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

import Loader from "react-loader";

import { itemRender, onShowSizeChange } from "./paginationfunction";
function VehicleList() {
  const { client, setClient } = useContext(ClientContext);
  const navigate = useNavigate();
  const [record, setRecord] = useState({});
  const [driverVehicles, setDriverVehicles] = useState([]);
  const [operatorNames, setOperatorsName] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const location = useLocation();
  var driverDataAssignment = {};
  if (location.state != undefined) {
    driverDataAssignment = location.state;
  }
  console.log("Driver datqa assignment", driverDataAssignment);
  var defaultValue = {
    driverMobileNo: "",
    vehilceNo: "",
  };
  if (driverDataAssignment) {
    defaultValue.driverMobileNo = driverDataAssignment.mobileNo;
  }

  const {
    register: registerFilter,
    handleSubmit: handleSubmitFilter,

    formState: { errors: errorsFilter },
    reset: resetFilter,
  } = useForm({
    defaultValues: defaultValue,
  });

  const ExportToExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const outputFilename = `${Date.now()}`;

    const customHeadings = driverVehicles?.map((item) => ({
      "Start Date": item.createdAt,
      "End Date": item.endDate,
      "Vehicle No.": item.vehicleNo,
      "Vehicle Name": item.vehicleName,
      "Vehicle Type": item?.vehicleInfo[0]?.name,
      "No. of Buggies": item.noOfBuggies,
      "No. of Seats": item.noOfSeats,
    }));

    exportToExcel(customHeadings, outputFilename, fileExtension, fileType);
  };

  const exportToExcel = (customHeadings, fileName, fileExtension, fileType) => {
    const ws = XLSX.utils.json_to_sheet(customHeadings);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const ExportToCsv = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".csv";
    const outputFilename = `${Date.now()}`;

    const customHeadings = driverVehicles?.map((item) => ({
      "Start Date": item.createdAt,
      "End Date": item.endDate,
      "Vehicle No.": item.vehicleNo,
      "Vehicle Name": item.vehicleName,
      "Vehicle Type": item?.vehicleInfo[0]?.name,
      "No. of Buggies": item.noOfBuggies,
      "No. of Seats": item.noOfSeats,
    }));

    exportToCsv(customHeadings, outputFilename, fileExtension, fileType);
  };

  const exportToCsv = (customHeadings, fileName, fileExtension, fileType) => {
    const ws = XLSX.utils.json_to_sheet(customHeadings);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  useEffect(() => {
    setLoaded(false);
    setRecord(driverDataAssignment);
    if (!client?.logInType) {
      navigate("/");
    } else if (client?.logInType == "SUPER_ADMIN") {
      if (location.state != null) {
        axiosInstance
          .get(URLConstants.getDriverVehicles(location.state._id))
          .then((response) => {
            console.log("VEhicles", response);
            setLoaded(true);
            if (response?.length > 0) {
              for (let i = 0; i < response.length; i++) {
                if (response[i].endDate !== 0) {
                  const d = new Date(response[i]?.endDate);
                  const dat = new Date(response[i]?.createdAt);
                  response[i].endDate = d.toDateString();
                  response[i].createdAt = dat.toDateString();
                } else if (response[i].endDate == 0) {
                  response[i].endDate = "Present";
                  const dat = new Date(response[i]?.createdAt);
                  response[i].createdAt = dat.toDateString();
                }
              }
              setDriverVehicles(response);
            } else {
              setDriverVehicles([]);
            }
          })
          .catch((err) => {
            setLoaded(true);
          });
      } else {
        axiosInstance
          .get(URLConstants.getOperatorsNames())
          .then((response) => {
            setOperatorsName(response);
          })
          .catch((err) => {
            console.log(err);
          });
        axiosInstance
          .post(URLConstants.getAllDriverVehicles(), {})
          .then((response) => {
            setLoaded(true);
            console.log("Response info", response);
            if (response?.results.length > 0) {
              for (let i = 0; i < response.results.length; i++) {
                if (response.results[i].endDate !== 0) {
                  const d = new Date(response.results[i]?.endDate);
                  const dat = new Date(response.results[i]?.createdAt);
                  response.results[i].endDate = d.toDateString();
                  response.results[i].createdAt = dat.toDateString();
                } else if (response.results[i].endDate == 0) {
                  response.results[i].endDate = "Present";
                  const dat = new Date(response.results[i].createdAt);
                  response.results[i].createdAt = dat.toDateString();
                }
              }
              setDriverVehicles(response.results);
            } else {
              setDriverVehicles([]);
            }
          })
          .catch((err) => {
            setLoaded(true);
          });
      }
    } else if (client?.logInType == "Driver") {
      axiosInstance
        .get(URLConstants.getDriverVehicles(client?.loginData?._id))
        .then((response) => {
          setLoaded(true);
          if (response?.length > 0) {
            for (let i = 0; i < response.length; i++) {
              if (response[i].endDate !== 0) {
                const d = new Date(response[i].endDate);
                const dat = new Date(response[i].createdAt);
                response[i].endDate = d.toDateString();
                response[i].createdAt = dat.toDateString();
              } else if (response[i].endDate == 0) {
                response[i].endDate = "Present";
                const dat = new Date(response[i].createdAt);
                response[i].createdAt = dat.toDateString();
              }
            }
            setDriverVehicles(response);
          } else {
            setDriverVehicles([]);
          }
        })
        .catch((err) => {
          setLoaded(true);
        });
    } else if (client?.logInType == "OPERATOR") {
      if (location.state != null) {
        axiosInstance
          .get(URLConstants.getDriverVehicles(location.state._id))
          .then((response) => {
            setLoaded(true);
            if (response?.length > 0) {
              for (let i = 0; i < response.length; i++) {
                if (response[i].endDate !== 0) {
                  const d = new Date(response[i]?.endDate);
                  const dat = new Date(response[i]?.createdAt);
                  response[i].endDate = d.toDateString();
                  response[i].createdAt = dat.toDateString();
                } else if (response[i].endDate == 0) {
                  response[i].endDate = "Present";
                  const dat = new Date(response[i]?.createdAt);
                  response[i].createdAt = dat.toDateString();
                }
              }

              setDriverVehicles(response);
            } else {
              setDriverVehicles([]);
            }
          })
          .catch((err) => {
            setLoaded(true);
          });
      } else {
        axiosInstance
          .get(URLConstants.getOptVehicle(client?.loginData?.id))
          .then((response) => {
            setLoaded(true);
            if (response?.length > 0) {
              for (let i = 0; i < response.length; i++) {
                if (response[i].endDate !== 0) {
                  const d = new Date(response[i].endDate);
                  const dat = new Date(response[i].createdAt);
                  response[i].endDate = d.toDateString();
                  response[i].createdAt = dat.toDateString();
                } else if (response[i]?.endDate == 0) {
                  response[i].endDate = "Present";
                  const dat = new Date(response[i].createdAt);
                  response[i].createdAt = dat.toDateString();
                }
              }
              setDriverVehicles(response);
            } else {
              setDriverVehicles([]);
            }
          })
          .catch((err) => {
            setLoaded(true);
          });
      }
    }
  }, []);

  const getExpiryDateClass = (expiryDateStr) => {
    const expiryDate = new Date(expiryDateStr);
    const today = new Date();
    const oneMonthFromNow = new Date();
    oneMonthFromNow.setMonth(today.getMonth() + 1);
    const twoMonthsFromNow = new Date();
    twoMonthsFromNow.setMonth(today.getMonth() + 2);

    if (expiryDate < today) {
      return "bg-red-600"; // Expired
    } else if (expiryDate < oneMonthFromNow) {
      return "bg-red-600"; // Expires in less than 1 month
    } else if (expiryDate < twoMonthsFromNow) {
      return "bg-yellow-600"; // Expires in less than 2 months
    } else {
      return "bg-green-600"; // No special styling for expiry dates more than 2 months away
    }
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      mobileNo: "",
    },
  });

  function ukDateFormatter(date) {
    return date.split("T")[0].split("-").reverse().join("-");
  }

  const columns = [
    {
      title: "Driver Name",

      render: (text, record) =>
        record?.matchedDriver?.length > 0 ? record?.matchedDriver[0].name : "",
    },
    {
      title: "Driver Mobile No",

      render: (text, record) =>
        record?.matchedDriver?.length > 0
          ? record?.matchedDriver[0].mobileNo
          : "",
    },
    {
      title: "Vehicle Type",
      dataIndex: "vehicleInfo",
      render: (text, record) => record?.vehicleInfo[0]?.name,
    },
    {
      title: "Start Date",
      dataIndex: "createdAt",
      // sorter: (a, b) => a.createdAt.length - b.createdAt.length,
    },
    // {
    //   title: "Driver ID",
    //   dataIndex: "driverId",
    //   sorter: (a, b) => a.driverId.length - b.driverId.length,
    // },
    {
      title: "End Date",
      dataIndex: "endDate",
      // sorter: (a, b) => a.endDate.length - b.endDate.length,
    },
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   sorter: (a, b) => a.id.length - b.id.length,
    // },

    {
      title: "Vehicle No.",
      dataIndex: "vehicleNo",
      // sorter: (a, b) => a.vehicleNo.length - b.vehicleNo.length,
    },

    {
      title: "Vehicle Name",
      dataIndex: "vehicleName",
      // sorter: (a, b) => a.vehicleName.length - b.vehicleName.length,
    },
    {
      title: "Vehicle Type",
      dataIndex: "vehicleInfo",
      render: (text, record) => record?.vehicleInfo[0]?.name,
    },
    {
      title: "No. of Buggies",
      dataIndex: "noOfBuggies",
      // sorter: (a, b) => a.noOfBuggies.length - b.noOfBuggies.length,
    },
    {
      title: "No. of Seats",
      dataIndex: "noOfSeats",
      // sorter: (a, b) => a.noOfSeats.length - b.noOfSeats.length,
    },

    {
      title: "Vehicle Image",
      dataIndex: "vehicleImages",
      render: (text, record) => {
        if (record?.vehicleImages) {
          return (
            <button
              // className="font-bold "
              className="text-white font-bold rounded-md px-3 py-2 w-[80px] text-center"
              onClick={() => {
                setLoaded(false);
                console.log("ANkit");
                axiosInstance
                  .post(URLConstants.getDriverDocument(), {
                    fileName: record?.vehicleImages,
                  })
                  .then((res) => {
                    setLoaded(true);
                    console.log("Response", res);
                    window.open(res, "_blank");
                  })
                  .catch((err) => {
                    toast.error(
                      "Something went wrong please try again later!!"
                    );
                    setLoaded(true);
                    console.log("err", err);
                  });
              }}
              // href={record.driverLicense?.fileName}
              // target="_blank"

              type="submit"
            >
              View
            </button>
          );
        } else {
          return (
            <span className="font-bold">Profile Picture not uploaded</span>
          );
        }
      },
    },

    {
      title: "TFL Vehicle License",
      dataIndex: "tflVehicleLicense",
      // sorter: (a, b) => a.otherDoc.length - b.otherDoc.length,
      render: (text, record) => {
        if (record.tflVehicleLicense?.fileName) {
          return (
            <button
              // className="font-bold "
              className="text-white font-bold rounded-md px-3 py-2 w-[80px] text-center"
              onClick={() => {
                setLoaded(false);
                console.log("ANkit");
                axiosInstance
                  .post(URLConstants.getDriverDocument(), {
                    fileName: record.tflVehicleLicense?.fileName,
                  })
                  .then((res) => {
                    setLoaded(true);
                    console.log("Response", res);
                    window.open(res, "_blank");
                  })
                  .catch((err) => {
                    toast.error(
                      "Something went wrong please try again later!!"
                    );
                    setLoaded(true);
                    console.log("err", err);
                  });
              }}
              // href={record.driverLicense?.fileName}
              // target="_blank"

              type="submit"
            >
              View
            </button>
          );
        } else {
          return <span className="font-bold">Document not uploaded</span>;
        }
      },
    },
    {
      title: "Expiry Date",
      dataIndex: "tflVehicleLicense",
      // render: (text, record) =>
      //   (text = record.otherDoc?.expiryDate
      //     ? record.otherDoc?.expiryDate
      //     : "N.A."),

      render: (text, record) =>
        record.tflVehicleLicense?.expiryDate ? (
          <div
            className={`${getExpiryDateClass(
              record.tflVehicleLicense?.expiryDate
            )} text-white font-bold rounded-md px-3 py-2 w-[120px] text-center`}
          >
            {" "}
            {ukDateFormatter(record.tflVehicleLicense?.expiryDate)}
          </div>
        ) : (
          "N.A."
        ),
    },

    {
      title: "V5 Form",
      dataIndex: "v5Forme",
      // sorter: (a, b) => a.otherDoc.length - b.otherDoc.length,
      render: (text, record) => {
        if (record.v5Form?.fileName) {
          return (
            <button
              // className="font-bold "
              className="text-white font-bold rounded-md px-3 py-2 w-[80px] text-center"
              onClick={() => {
                setLoaded(false);
                console.log("ANkit");
                axiosInstance
                  .post(URLConstants.getDriverDocument(), {
                    fileName: record.v5Form?.fileName,
                  })
                  .then((res) => {
                    setLoaded(true);
                    console.log("Response", res);
                    window.open(res, "_blank");
                  })
                  .catch((err) => {
                    toast.error(
                      "Something went wrong please try again later!!"
                    );
                    setLoaded(true);
                    console.log("err", err);
                  });
              }}
              // href={record.driverLicense?.fileName}
              // target="_blank"

              type="submit"
            >
              View
            </button>
          );
        } else {
          return <span className="font-bold">Document not uploaded</span>;
        }
      },
    },
    {
      title: "Expiry Date",
      dataIndex: "v5Form",
      // render: (text, record) =>
      //   (text = record.otherDoc?.expiryDate
      //     ? record.otherDoc?.expiryDate
      //     : "N.A."),

      render: (text, record) =>
        record.v5Form?.expiryDate ? (
          <div
            className={`${getExpiryDateClass(
              record.v5Form?.expiryDate
            )} text-white font-bold rounded-md px-3 py-2 w-[120px] text-center`}
          >
            {" "}
            {ukDateFormatter(record.v5Form?.expiryDate)}
          </div>
        ) : (
          "N.A."
        ),
    },
    {
      title: "Road Tax",
      dataIndex: "roadTax",
      // sorter: (a, b) => a.otherDoc.length - b.otherDoc.length,
      render: (text, record) => {
        if (record.roadTax?.fileName) {
          return (
            <button
              // className="font-bold "
              className="text-white font-bold rounded-md px-3 py-2 w-[80px] text-center"
              onClick={() => {
                setLoaded(false);
                console.log("ANkit");
                axiosInstance
                  .post(URLConstants.getDriverDocument(), {
                    fileName: record.roadTax?.fileName,
                  })
                  .then((res) => {
                    setLoaded(true);
                    console.log("Response", res);
                    window.open(res, "_blank");
                  })
                  .catch((err) => {
                    toast.error(
                      "Something went wrong please try again later!!"
                    );
                    setLoaded(true);
                    console.log("err", err);
                  });
              }}
              // href={record.driverLicense?.fileName}
              // target="_blank"

              type="submit"
            >
              View
            </button>
          );
        } else {
          return <span className="font-bold">Document not uploaded</span>;
        }
      },
    },
    {
      title: "Expiry Date",
      dataIndex: "roadTax",
      // render: (text, record) =>
      //   (text = record.otherDoc?.expiryDate
      //     ? record.otherDoc?.expiryDate
      //     : "N.A."),

      render: (text, record) =>
        record.roadTax?.expiryDate ? (
          <div
            className={`${getExpiryDateClass(
              record.roadTax?.expiryDate
            )} text-white font-bold rounded-md px-3 py-2 w-[120px] text-center`}
          >
            {" "}
            {ukDateFormatter(record.roadTax?.expiryDate)}
          </div>
        ) : (
          "N.A."
        ),
    },
    {
      title: "Insurance",
      dataIndex: "insurance",
      // sorter: (a, b) => a.otherDoc.length - b.otherDoc.length,
      render: (text, record) => {
        if (record.insurance?.fileName) {
          return (
            <button
              // className="font-bold "
              className="text-white font-bold rounded-md px-3 py-2 w-[80px] text-center"
              onClick={() => {
                setLoaded(false);
                console.log("ANkit");
                axiosInstance
                  .post(URLConstants.getDriverDocument(), {
                    fileName: record.insurance?.fileName,
                  })
                  .then((res) => {
                    setLoaded(true);
                    console.log("Response", res);
                    window.open(res, "_blank");
                  })
                  .catch((err) => {
                    toast.error(
                      "Something went wrong please try again later!!"
                    );
                    setLoaded(true);
                    console.log("err", err);
                  });
              }}
              // href={record.driverLicense?.fileName}
              // target="_blank"

              type="submit"
            >
              View
            </button>
          );
        } else {
          return <span className="font-bold">Document not uploaded</span>;
        }
      },
    },
    {
      title: "Expiry Date",
      dataIndex: "insurance",
      // render: (text, record) =>
      //   (text = record.otherDoc?.expiryDate
      //     ? record.otherDoc?.expiryDate
      //     : "N.A."),

      render: (text, record) =>
        record.insurance?.expiryDate ? (
          <div
            className={`${getExpiryDateClass(
              record.insurance?.expiryDate
            )} text-white font-bold rounded-md px-3 py-2 w-[120px] text-center`}
          >
            {" "}
            {ukDateFormatter(record.insurance?.expiryDate)}
          </div>
        ) : (
          "N.A."
        ),
    },
    {
      title: "MOT",
      dataIndex: "mot",
      // sorter: (a, b) => a.otherDoc.length - b.otherDoc.length,
      render: (text, record) => {
        if (record.mot?.fileName) {
          return (
            <button
              // className="font-bold "
              className="text-white font-bold rounded-md px-3 py-2 w-[80px] text-center"
              onClick={() => {
                setLoaded(false);
                console.log("ANkit");
                axiosInstance
                  .post(URLConstants.getDriverDocument(), {
                    fileName: record.mot?.fileName,
                  })
                  .then((res) => {
                    setLoaded(true);
                    console.log("Response", res);
                    window.open(res, "_blank");
                  })
                  .catch((err) => {
                    toast.error(
                      "Something went wrong please try again later!!"
                    );
                    setLoaded(true);
                    console.log("err", err);
                  });
              }}
              // href={record.driverLicense?.fileName}
              // target="_blank"

              type="submit"
            >
              View
            </button>
          );
        } else {
          return <span className="font-bold">Document not uploaded</span>;
        }
      },
    },
    {
      title: "Expiry Date",
      dataIndex: "mot",
      // render: (text, record) =>
      //   (text = record.otherDoc?.expiryDate
      //     ? record.otherDoc?.expiryDate
      //     : "N.A."),

      render: (text, record) =>
        record.mot?.expiryDate ? (
          <div
            className={`${getExpiryDateClass(
              record.mot?.expiryDate
            )} text-white font-bold rounded-md px-3 py-2 w-[120px] text-center`}
          >
            {" "}
            {ukDateFormatter(record.mot?.expiryDate)}
          </div>
        ) : (
          "N.A."
        ),
    },

    // {
    //   title: "Length",
    //   dataIndex: "length",
    //   sorter: (a, b) => a.length.length - b.length.length,
    //   render: (text, record) => (
    //     <a className="font-bold" href={record.length?.fileName} target="_blank">
    //       View
    //     </a>
    //   ),
    // },
    {
      title: "Action",
      render: (text, record) => (
        <div>
          <div className="dropdown dropdown-action float-end">
            <a
              href="#"
              className="action-icon dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="material-icons"></i>
            </a>

            <div className="row dropdown-menu dropdown-menu-right noLine">
              <div className="col-md-12">
                <Link
                  // to="/addvehicle"
                  // data-bs-toggle="modal"
                  // data-bs-target="#add_leave"
                  to={{
                    pathname: `/addvehicle`,
                  }}
                  state={record}
                >
                  <i /> Edit Vehicle
                </Link>
              </div>

              <div />
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <Header isAdmin />
      <div className="grid lg:grid-cols-6">
        <Leftbar />
        <Helmet>
          <title>Vehicle List - Treklers - Admin</title>
          <meta name="description" content="Login page" />
        </Helmet>
        <div className="p-8 lg:col-span-5">
          <div className="col-md-12">
            <div className="table-responsive">
              <div className="p-4 grid grid-cols-2 pb-8 mb-3">
                <div>
                  <span className="text-2xl font-bold">Vehicle List</span>
                  <span className="font-bold p-4">
                    <Link to="/">Home</Link>{" "}
                  </span>
                </div>

                <div className=" float-end ml-auto mb-6">
                  <button
                    className="rounded bg-gray-700 text-white hover:bg-black px-3 py-2 w-auto"
                    onClick={() => ExportToExcel()}
                  >
                    Download Excel
                  </button>
                  <button
                    className="rounded bg-gray-700 text-white hover:bg-black px-3 py-2 w-auto ml-3"
                    onClick={() => ExportToCsv()}
                  >
                    Download CSV
                  </button>
                  {/* <button
                    className="rounded bg-gray-700 text-white hover:bg-black px-3 py-2 w-auto ml-3"
                    // onClick={() => generatePdf()}
                  >
                    Download PDF
                  </button> */}
                </div>
              </div>
              {location.state ? (
                <>
                  {client?.logInType == "SUPER_ADMIN" ||
                  client?.logInType == "OPERATOR" ? (
                    <div className="col-auto float-end ml-auto mb-6">
                      <Link
                        className="inline-flex items-center bg-black font-semibold border-0 py-1 px-3 focus:outline-none hover:bg-gray-700 rounded-full text-white md:mt-0"
                        to={{
                          pathname: `/addvehicle`,
                        }}
                        state={location.state}
                      >
                        <i className="fa fa-plus mr-2" /> Add Vehicle
                      </Link>
                    </div>
                  ) : null}
                </>
              ) : null}
              <>
                <div className="grid grid-cols-1 pb-4">
                  <div className="filter-row leads pt-4">
                    <form
                      onSubmit={handleSubmitFilter((data) => {
                        console.log("Before Date", data);
                        // if (data?.operatorId?.length == 0) {
                        //   delete data.operatorId;
                        // }
                        // if (
                        //   data?.fromDate?.length == 0 ||
                        //   data?.toDate?.length == 0
                        // ) {
                        //   delete data.fromDate;
                        //   delete data.toDate;
                        // }

                        if (data?.vehicleNo?.length == 0) {
                          delete data.vehicleNo;
                        }
                        if (data?.driverMobileNo?.length == 0) {
                          delete data.driverMobileNo;
                        }

                        console.log("After Date", data);

                        axiosInstance
                          .post(URLConstants.getAllDriverVehicles(), data)
                          .then((response) => {
                            setLoaded(true);
                            if (response?.results.length > 0) {
                              for (
                                let i = 0;
                                i < response.results.length;
                                i++
                              ) {
                                if (response.results[i].endDate !== 0) {
                                  const d = new Date(
                                    response.results[i]?.endDate
                                  );
                                  const dat = new Date(
                                    response.results[i]?.createdAt
                                  );
                                  response.results[i].endDate =
                                    d.toDateString();
                                  response.results[i].createdAt =
                                    dat.toDateString();
                                } else if (response.results[i].endDate == 0) {
                                  response.results[i].endDate = "Present";
                                  const dat = new Date(
                                    response.results[i].createdAt
                                  );
                                  response.results[i].createdAt =
                                    dat.toDateString();
                                }
                              }
                              setDriverVehicles(response.results);
                            } else {
                              setDriverVehicles([]);
                            }
                          })
                          .catch((err) => {
                            setLoaded(true);
                          });
                      })}
                    >
                      <div className="d-flex pb-3 align-items-end justify-between">
                        {/* <div className="pb-3 w-25">
                            <label className="col-form-label">Operator</label>
                            <div className="w-full border border-1 border-gray-500 rounded py-1 pr-2">
                              <select
                                {...registerFilter("operatorId")}
                                className={`w-full border-0 outline-0 px-2  ${
                                  errorsFilter?.operatorId ? "error-select" : ""
                                }`}
                              >
                                <option value="">Select Any</option>
                                {operatorNames.map(
                                  ({ operatorId, companyName }, index) => (
                                    <option value={operatorId}>
                                      {companyName}
                                    </option>
                                  )
                                )}
                              </select>
                              <small>{errorsFilter?.optId?.message}</small>
                            </div>
                          </div> */}

                        {location.state ? null : (
                          <div className="pb-3 col-sm-5 ">
                            {/* <div className="col-md-4 text-right pb-3 pl-5"> */}
                            <div className="mb-2">Vehicle Number </div>
                            <input
                              type="text"
                              className="w-full col-12 border p-1 px-8 rounded"
                              style={{ height: "36px" }}
                              {...registerFilter("vehicleNo", {
                                required: false,
                              })}
                            />
                          </div>
                        )}

                        <div className="pb-3 col-sm-5">
                          {/* <div className="col-md-4 text-right pb-3 pl-5"> */}
                          <div className="mb-2">Driver Mobile No </div>
                          <input
                            type="text"
                            className="w-full col-12 border p-1 px-8 rounded"
                            style={{ height: "36px" }}
                            {...registerFilter("driverMobileNo", {
                              required: false,
                            })}
                          />
                        </div>
                        {/* 
                          <div className="pb-3 w-25">
                            <div className="mb-2">From Date </div>
                            <input
                              type="date"
                              className="w-full col-12 border p-1 px-8 rounded"
                              style={{ height: "36px" }}
                              {...registerFilter("fromDate", {
                                required: true,
                              })}
                            />
                            {errorsFilter.fromDate && <p>Date is required</p>}
                          </div>
                          <div className="pb-3 w-25">
                            <div className="mb-2">To Date </div>
                            <input
                              type="date"
                              className="col-12 border p-1 ps-2 rounded"
                              style={{ height: "36px" }}
                              {...registerFilter("toDate", {
                                required: true,
                              })}
                            />
                            {errorsFilter.toDate && <p>Date is required</p>}
                          </div> */}

                        <div className="col-sm-1 col-md-2 text-right pb-3 pl-5">
                          <div>
                            <input
                              className="w-auto m-auto px-4 py-2"
                              type="submit"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="spinner">
                        <Loader
                          loaded={loaded}
                          lines={13}
                          length={20}
                          width={10}
                          radius={30}
                          corners={1}
                          rotate={0}
                          direction={1}
                          color="#000"
                          speed={1}
                          trail={60}
                          shadow={false}
                          hwaccel={false}
                          className="spinner"
                          zIndex={2e9}
                          top="50%"
                          left="50%"
                          scale={1.0}
                          loadedClassName="loadedContent"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </>

              <Table
                className="table-striped"
                pagination={{
                  total: driverVehicles.length,
                  showTotal: (total, range) =>
                    `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                  showSizeChanger: true,
                  onShowSizeChange: onShowSizeChange,
                  itemRender: itemRender,
                }}
                style={{ overflowX: "auto" }}
                columns={columns}
                bordered
                dataSource={driverVehicles}
              />
            </div>
          </div>
        </div>
        <div className="spinner">
          <Loader
            loaded={loaded}
            lines={13}
            length={20}
            width={10}
            radius={30}
            corners={1}
            rotate={0}
            direction={1}
            color="#000"
            speed={1}
            trail={60}
            shadow={false}
            hwaccel={false}
            className="spinner"
            zIndex={2e9}
            top="50%"
            left="50%"
            scale={1.0}
            loadedClassName="loadedContent"
          />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default VehicleList;
