import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Table } from "antd";
import { Helmet } from "react-helmet";
import { itemRender, onShowSizeChange } from "./paginationfunction";
import Loader from "react-loader";
import Header from "./header/Header";
import { Leftbar } from "./sidebar/Leftbar";
import Footer from "./footer/Footer";
import { axiosInstance } from "../base/api/axios.util";
import { URLConstants } from "../base/api/url.constants";
import { useForm, Controller, set } from "react-hook-form";
import { ClientContext } from "../base/contexts/UserContext";
import { Fragment } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  OverlayView,
  DirectionsRenderer,
} from "@react-google-maps/api";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  Input,
  SkeletonText,
  Text,
} from "@chakra-ui/react";

function ukDateFormatter(date) {
  return date.split("T")[0].split("-").reverse().join("-");
}

const Maps = ({ foundVehicle }) => {
  console.log("FOund vehilce", foundVehicle);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDSvqFVfMDtPftyvZJMrEYeqF5R5dXc6nE",
    libraries: ["places"],
  });
  const center = useMemo(
    () => ({ lat: 12.969016141570492, lng: 77.62609273195267 }),
    []
  );
  const occupiedCar = {
    path: "M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759   c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z    M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713   v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336   h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805",
    fillColor: "black",
    fillOpacity: 2,
    strokeWeight: 1,
    rotation: 0,
    scale: 1,
  };
  const unoccupiedCar = {
    path: "M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759   c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z    M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713   v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336   h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805",
    fillColor: "white",
    fillOpacity: 2,
    strokeWeight: 1,
    rotation: 0,
    scale: 1,
  };
  const [map, setMap] = useState(/** @type google.maps.Map */ (null));

  /** @type React.MutableRefObject<HTMLInputElement> */
  const originRef = useRef();
  /** @type React.MutableRefObject<HTMLInputElement> */
  const destiantionRef = useRef();

  console.log("I AM GETTING TRIGGEERED", foundVehicle);
  const getPixelPositionOffset = (offsetWidth, offsetHeight, labelAnchor) => {
    return {
      x: offsetWidth + labelAnchor.x,
      y: offsetHeight + labelAnchor.y,
    };
  };
  if (!isLoaded) {
    return <SkeletonText />;
  }
  return (
    <GoogleMap
      center={center}
      zoom={15}
      mapContainerStyle={{ width: "100%", height: "100%" }}
      options={{
        zoomControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
      }}
      onLoad={(map) => setMap(map)}
    >
      {/* <Marker
position={center}
icon={{
// path: google.maps.SymbolPath.CIRCLE,
url: "https://img.icons8.com/3d-fluency/94/map-pin.png",
fillColor: "#EB00FF",
scale: 7,
}}
/> */}
      {foundVehicle.length > 0 &&
        foundVehicle.map((path, index) => {
          return (
            <>
              <Marker key={index} position={path.location} icon={occupiedCar} />
              <OverlayView
                key={index}
                position={path.location}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                // getPixelPositionOffset={(x, y) =>
                //   getPixelPositionOffset(x, y, { x: -30, y: -15 })
                // }
              >
                <div
                  style={{
                    background: `#203254`,
                    padding: `7px 12px`,
                    fontSize: "11px",
                    color: `white`,
                    borderRadius: "4px",
                    position: "absolute",
                    top: "-30px", // Adjust this value to position the label above the icon
                    left: "-15px", // Adjust this value to center the label horizontally
                    whiteSpace: "nowrap", // Prevent text from wrapping
                  }}
                >
                  {" "}
                  {path.vehicleNo}
                  <br />
                  Last updated at {ukDateFormatter(path.lastUpdatedTime)}
                </div>
              </OverlayView>
            </>
          );
        })}
    </GoogleMap>
  );
};

function VehicleLocation() {
  // const { isLoaded } = useJsApiLoader({
  //   googleMapsApiKey: "AIzaSyDSvqFVfMDtPftyvZJMrEYeqF5R5dXc6nE",
  //   libraries: ["places"],
  // });
  // const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [vehicleNumber, setVehicleNumber] = useState("ALL");
  const [vehicleList, setVehicleList] = useState([]);
  const [allVehicleList, setVehicleAllList] = useState([]);
  const [impactedVehicleList, setVehicleImpactedList] = useState([]);
  const { client, setClient } = useContext(ClientContext);
  const [foundVehicle, setVehicleLocationFound] = useState([]);
  // const [notFoundVehicle, setVehicleLocationNotFound] = useState([]);
  const [query, setQuery] = useState("");
  const [selectedOption, setSelectedOption] = useState("0");
  const navigate = useNavigate();

  const [loaded, setLoaded] = useState(true);
  const date = new Date();

  // /** @type React.MutableRefObject<HTMLInputElement> */
  // const originRef = useRef();
  // /** @type React.MutableRefObject<HTMLInputElement> */
  // const destiantionRef = useRef();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const [toDate, setToDate] = useState(year + "-" + month + "-" + day);
  // const center = { lat: 12.972442, lng: 77.580643 };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: { toDate: "", fromDate: "" },
  });
  console.log("Vehilce locaiton found", foundVehicle);
  // function getVehicleLocation() {
  //   axiosInstance
  //     .post(
  //       URLConstants.getLocationOfOepratorVehicle(client?.loginData.client.id),
  //       { vehicleList: [] }
  //     )
  //     .then((response) => {
  //       console.log("CARd daeta", response);
  //       response?.operatorVehicles[0]?.vehicleNumbers.unshift("ALL");
  //       setVehicleLocations(response?.operatorVehicles[0]?.vehicleNumbers);
  //       setLoaded(true);
  //     })
  //     .catch((err) => {
  //       setLoaded(true);
  //       console.log(err);
  //     });
  // }
  console.log("VEhicle Numbers which is found", vehicleList);
  useEffect(() => {
    console.log("LOGGEd IN CLIENT", client);
    setLoaded(false);
    if (!client) {
      navigate("/");
    } else {
      axiosInstance
        .get(URLConstants.getOperatorVehicleNo(client?.loginData.client.id))
        .then((response) => {
          console.log("CARd daeta", response);
          // response?.operatorVehicles[0]?.vehicleNumbers.unshift("ALL");
          // setVehicleAllList(response?.operatorVehicles[0]?.vehicleNumbers);
          // setVehicleList(response?.operatorVehicles[0]?.vehicleNumbers);
          response?.operatorVehicles[0]?.vehicleNumbers.unshift("ALL");
          setVehicleList(response?.operatorVehicles[0]?.vehicleNumbers);
          setLoaded(true);
        })
        .catch((err) => {
          console.log("Error Triggered", err);

          setLoaded(true);
          console.log(err);
        });

      // axiosInstance
      //   .get(URLConstants.getImpactedVehicleNo(client?.loginData.client.id))
      //   .then((response) => {
      //     console.log("CARd daeta", response);
      //     // response?.operatorVehicles[0]?.vehicleNumbers.unshift("ALL");
      //     setVehicleImpactedList(response?.vehicleNos);
      //     setLoaded(true);
      //   })
      //   .catch((err) => {
      //     console.log("Error Triggered", err);

      //     setLoaded(true);
      //     console.log(err);
      //   });
    }
  }, []);

  const filteredVehicleNoList =
    query === ""
      ? vehicleList
      : vehicleList?.filter((state) => {
          return state?.toLowerCase().includes(query.toLowerCase());
        });
  console.log(
    "Veh  filteredVehicleNoList ))))^^^^^^",
    filteredVehicleNoList,
    vehicleList
  );

  // useEffect(() => {
  //   console.log("TRIGGERED", vehicleNumber);
  //   let finalVehicle = [];
  //   if (vehicleNumber == "ALL") {
  //     finalVehicle = vehicleList;
  //     finalVehicle.shift();
  //     console.log("Vehicle LIst", vehicleList);
  //     console.log("Final vehicle alist on selection of ALL", vehicleList);
  //   } else {
  //     finalVehicle = [vehicleNumber];
  //   }

  //   axiosInstance
  //     .post(
  //       URLConstants.getLocationOfOepratorVehicle(client?.loginData.client.id),
  //       { vehicleList: finalVehicle }
  //     )
  //     .then((response) => {
  //       console.log("CARd daeta", response);
  //       // let loc = { lat: 22.5726, lng: 88.3639 };
  //       // response.locationExist.push({ location: loc });
  //       setVehicleLocationFound(response?.locationExist);

  //       setLoaded(true);
  //     })
  //     .catch((err) => {
  //       setLoaded(true);
  //       console.log(err);
  //     });
  // }, [vehicleNumber]);

  useEffect(() => {
    console.log("TRIGGERED", vehicleNumber);
    let finalVehicle = [];
    if (vehicleNumber == "ALL") {
      // if (vehicleList.length == 0) {
      //   const vehicleData = await axiosInstance.get(
      //     URLConstants.getOperatorVehicleNo(client?.loginData.client.id)
      //   );
      //   console.log("Vehicle data", vehicleData);
      //   vehicleData?.operatorVehicles[0]?.vehicleNumbers.unshift("ALL");
      //   setVehicleList(vehicleData?.operatorVehicles[0]?.vehicleNumbers);
      //   finalVehicle = vehicleData?.operatorVehicles[0]?.vehicleNumbers;
      // } else {
      //   finalVehicle = vehicleList;
      // }
      // finalVehicle.shift();
      // console.log("Vehicle LIst", vehicleList);
      // console.log("Final vehicle alist on selection of ALL", vehicleList);
    } else {
      finalVehicle = [vehicleNumber];
    }
    for (let i = 0; i < finalVehicle.length; i++) {
      console.log("Vehilce value", finalVehicle);
      axiosInstance
        .get(URLConstants.getVehicleLocation(finalVehicle[i]))
        .then((response) => {
          console.log("CARd daeta", response);
          // let loc = { lat: 22.5726, lng: 88.3639 };
          // response.locationExist.push({ location: loc });

          if (response?.vehicleLocation) {
            let arr = {
              vehicleNo: finalVehicle[i],
              location: {
                lat: Number(
                  response.vehicleLocation.vehicleLocation[0]?.latitude
                ),
                lng: Number(
                  response.vehicleLocation.vehicleLocation[0]?.longitude
                ),
              },
              lastUpdatedTime: new Date(
                Number(response.vehicleLocation.lastUpdatedTime)
              ).toISOString(),
            };
            console.log("Arr being pushed", arr);
            if (vehicleNumber !== "ALL") {
              setVehicleLocationFound([arr]);
              // setVehicleLocationNotFound(response.locationNotExist);
              setLoaded(true);
            } else {
              let finalArr = foundVehicle;
              finalArr.push(arr);
              setVehicleLocationFound(finalArr);
              //  setVehicleLocationNotFound(response.locationNotExist);
              setLoaded(true);
            }
          }
        })
        .catch((err) => {
          setLoaded(true);
          console.log(err);
        });
    }
  }, [vehicleNumber]);

  console.log("Vehilce number", vehicleNumber);
  console.log("Final OTP Id ", vehicleNumber);
  const chooseVehicleNumber = (event) => {
    console.log("EVENT ", event);
    const optId = event;

    setVehicleNumber(optId);
  };
  console.log("Vehoicle number", vehicleNumber);

  return (
    <>
      <Header isAdmin />
      <div className="grid lg:grid-cols-6">
        <Leftbar />
        <Helmet>
          <title>Payment List - Treklers - Admin</title>
          <meta name="description" content="Login page" />
        </Helmet>
        <div className="p-8 lg:col-span-5">
          <div className="col-md-12">
            <div className="table-responsive">
              <div className="grid grid-cols-2 pb-4">
                <div>
                  <span className="text-2xl font-bold">Vehicle Location</span>
                </div>
              </div>

              <div className="grid grid-cols-1 pb-4">
                <div className="filter-row leads pt-4">
                  <form>
                    <div className="d-flex pb-3 align-items-end justify-between">
                      {/* <div className="pb-3 w-25">
                        <label className="col-form-label">
                          Choose an option
                        </label>
                        <div className="w-full border border-1 border-gray-500 rounded py-1 pr-2">
                          <select
                            className={`w-full border-0 outline-0 px-2  ${
                              errors?.optId ? "error-select" : ""
                            }`}
                            onChange={(event) => {
                              setSelectedOption(event.target.value);
                              setVehicleAllList([]);
                              if (event.target.value == "0") {
                                setVehicleList(allVehicleList);
                              } else if (event.target.value == "1") {
                                {
                                  setVehicleList(impactedVehicleList);
                                }
                              }
                            }}
                          >
                            <option value="0">ALL</option>
                            <option value="1">Impacted Vehicles</option>
                          </select>
                          <small>{errors?.optId?.message}</small>
                        </div>
                      </div> */}
                      {/* <div className="pb-3 w-25">
                        <label className="col-form-label">Vehicle Number</label>
                        <div className="w-full border border-1 border-gray-500 rounded py-1 pr-2">
                          <select
                            className={`w-full border-0 outline-0 px-2  ${
                              errors?.optId ? "error-select" : ""
                            }`}
                            onChange={(event) => chooseVehicleNumber(event)}
                          >
                            <option value="">Select Any</option>
                            {vehicleList?.length > 0 &&
                              vehicleList.map((num, index) => (
                                <option value={num}>{num}</option>
                              ))}
                          </select>
                          <small>{errors?.optId?.message}</small>
                        </div>
                      </div> */}

                      <div className="col-4">
                        <span>Vehicle Number</span>
                        <Combobox
                          value={vehicleNumber ?? ""}
                          onChange={(event) => chooseVehicleNumber(event)}
                        >
                          <div className="relative mt-1">
                            <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md  2 sm:text-sm">
                              <Combobox.Input
                                className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                autocomplete="off"
                                displayValue={(state) => {
                                  console.log(
                                    "state inside state",
                                    vehicleNumber
                                  );
                                  return vehicleNumber;
                                }}
                                onChange={(event) =>
                                  setQuery(event.target.value)
                                }
                              />
                              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </Combobox.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                              afterLeave={() => setQuery("")}
                            >
                              <Combobox.Options
                                className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg   sm:text-sm"
                                style={{
                                  padding: "0px",
                                  margin: "0px",
                                  textAlign: "center",
                                  zIndex: "2",
                                  width: "auto",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {(filteredVehicleNoList?.length === 0 ||
                                  !filteredVehicleNoList) &&
                                query !== "" ? (
                                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                    Nothing found.
                                  </div>
                                ) : (
                                  filteredVehicleNoList?.map((vehicleNo) => (
                                    <Combobox.Option
                                      key={vehicleNo}
                                      className={({ active }) =>
                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                          active
                                            ? "bg-[#404298] text-white"
                                            : "text-gray-900"
                                        }`
                                      }
                                      style={{ padding: "15px" }}
                                      value={vehicleNo}
                                    >
                                      {({ selected, active }) => {
                                        return (
                                          <>
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                // flexDirection: "column",
                                              }}
                                            >
                                              <span
                                                className={`block truncate ${
                                                  selected
                                                    ? "font-medium"
                                                    : "font-normal"
                                                }`}
                                              >
                                                {vehicleNo}
                                              </span>
                                              {vehicleNo === vehicleNumber ? (
                                                <span
                                                  className={`pl-3 ${
                                                    active
                                                      ? "text-white"
                                                      : "text-teal-600"
                                                  }`}
                                                >
                                                  <CheckIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                              ) : null}
                                            </div>
                                          </>
                                        );
                                      }}
                                    </Combobox.Option>
                                  ))
                                )}
                              </Combobox.Options>
                            </Transition>
                          </div>
                        </Combobox>
                      </div>
                    </div>
                    {/* Google Map Box */}

                    <div className="w-screen h-80 bg-no-repeat ">
                      <div className="w-full h-full">
                        {vehicleList?.length == 0 || !vehicleList ? (
                          <div className="text-red-500 font-bold text-2xl">
                            No Vehicles Found
                          </div>
                        ) : (
                          <Maps foundVehicle={foundVehicle} />
                        )}
                      </div>
                    </div>

                    <div className="spinner">
                      <Loader
                        loaded={loaded}
                        lines={13}
                        length={20}
                        width={10}
                        radius={30}
                        corners={1}
                        rotate={0}
                        direction={1}
                        color="#000"
                        speed={1}
                        trail={60}
                        shadow={false}
                        hwaccel={false}
                        className="spinner"
                        zIndex={2e9}
                        top="50%"
                        left="50%"
                        scale={1.0}
                        loadedClassName="loadedContent"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default VehicleLocation;
