import React from "react";

export default function PdfContainer() {
    return (
        <div className="flex flex-col items-center w-full py-8">
            <div className="max-w-3xl w-full">
                <h1 className="text-3xl font-bold text-center mb-4">Trip Invoice</h1>
                <div className="flex flex-row w-full justify-between">
                    <div>
                        <img src="/logo512.png" alt="logo" className="w-20 h-20" />
                        <p className="font-bold mt-4">Trip Id: 43545533</p>
                        <p className="font-bold mt-1">Invoice Id: INV-43545533</p>
                    </div>
                    <p>
                        61 Sundeala Close, Sunbury-on-Thames, <br />
                        Middlesex, TW16 5BE, <br />
                        Phn No: ++442085380120 <br />
                        Email: support@treklers.co.uk <br />
                        Website: www.treklers.in
                    </p>
                </div>
                <div className="h-[2px] w-full bg-gray-300 my-4" />
                <h1 className="font-bold mb-2">Trip Details</h1>
                <div className="flex flex-row w-full justify-between">
                    <div>
                        <p className="">Passenger Name: Arya Shah</p>
                        <p className="mt-1">Driver Name: Arya Shah</p>
                        <p className="mt-1">Vehicle No: WB-AS-B234</p>
                    </div>
                    <div>
                        <p className="">Pickup Time: January 11, 2024, 12:16 PM</p>
                        <p className="mt-1">Drop off Time: January 13, 2024, 12:17 PM</p>
                        <p className="mt-1">Date of Invoice: January 13, 2024</p>
                    </div>
                </div>
                <h1 className="font-bold mb-2 mt-4">Travel Summary</h1>
                <div className="flex flex-row w-full justify-between">
                    <div>
                        <p className="">Source Address: Howrah, West Bengal, India</p>
                        <p className="mt-1">Destination Address: Dumdum, West Bengal, India</p>
                    </div>
                </div>
                <h1 className="font-bold mb-2 mt-4">Payment Details</h1>
                <div className="flex flex-row w-full justify-between">
                    <div>
                        <p className="">Base Fare:</p>
                        <p className="mt-1">Distance Fare:</p>
                        <p className="mt-1">Waiting Fare:</p>
                        <p className="mt-1">Toll Fare:</p>
                        <p className="mt-1">Discount:</p>
                        <p className="mt-1">Total Fare:</p>
                    </div>
                    <div>
                        <p className="">$ 10.00</p>
                        <p className="mt-1">$ 10.00</p>
                        <p className="mt-1">$ 10.00</p>
                        <p className="mt-1">$ 10.00</p>
                        <p className="mt-1">$ 10.00</p>
                        <p className="mt-1">$ 10.00</p>
                    </div>
                </div>
                <div className="h-[2px] w-full bg-gray-300 my-4" />
                <h2 className="font-bold mb-2 mt-4">Trip is verified by the passenger with pickup & drop OTP: Passenger signature is not required</h2>
                <p>Created by Treklers on 30th December, 2023</p>
            </div>
        </div>
    );
}