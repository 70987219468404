import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  BrowserRouter,
} from "react-router-dom";
import { ContextWrapper } from "./base/contexts";
import HomeContent from "./components/homeContainer/HomeContent";
import DriverList from "./components/DriverList";
import EmployerDetails from "./components/EmployerDetails";
import OperatorList from "./components/OperatorList";
import CreateDriver from "./components/EditDriver";
import CreateOperator from "./components/CreateOperator";
import TripList from "./components/TripList";
import EditDriver from "./components/EditDriver";
import EditProfile from "./components/EditProfile";
import BlockDriver from "./components/BlockDriver";
import DriverPament from "./components/DriverPament";
import PassengerPament from "./components/PassengerPament ";
import VehicleList from "./components/VehicleList";
import AddVehicle from "./components/AddVehicle";
import LoginScreen from "./loginScreen/LoginScreen";
import JobBooking from "./components/JobBooking";
import DriverPamentApproved from "./components/DriverPamentApproved";
import FeedBack from "./components/FeedBack";
import VehicleCard from "./components/VehicleCard";
import AboutUs from "./components/AboutUs";
import PrivacyPolicy from "./components/PrivacyPolicy";
import FAQ from "./components/FAQ";
import NotFound from "./components/NotFound";
import JobBook from "./components/JobBook";
import PaymentSuccess from "./components/PaymentSuccess";
import PaymentFailed from "./components/PaymentFailed";
import BlogPage from "./components/BlogPage";
import ContactUsPage from "./components/ContactUsPage";
import ImpactedTrips from "./components/ImpactedTrips";
import VehicleLocation from "./components/VehicleLocation";
import UpcomingRides from "./components/UpcomingRides";
import OngoingRides from "./components/OnGoingRides";
import CardList from "./components/CardList";
import PdfContainer from "./components/PdfContainer";
import CancelledTrips from "./components/CancelledTrips";
import VehicleNewLocation from "./components/VehicleNewLocation";
import DeleteAccount from "./components/DeleteAccount";
import Base from "./components/Base";

const App = () => (
  <ContextWrapper>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Base />} />
        <Route path="/sign-in" element={<HomeContent />} />
        <Route path="/pdf" element={<PdfContainer />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/homecontent" element={<HomeContent />} />
        <Route path="/operatorlist" element={<OperatorList />} />
        <Route path="/driverlist" element={<DriverList />} />
        <Route path="/delete-account" element={<DeleteAccount />} />
        <Route path="/card-list" element={<CardList />} />
        <Route path="/triplist" element={<TripList />} />
        <Route path="/createdriver" element={<CreateDriver />} />{" "}
        <Route path="/employerdetails" element={<EmployerDetails />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/vehicle-location" element={<VehicleLocation />} />
        <Route path="/ongoing-rides" element={<OngoingRides />} />
        {/* < Route path = "/controllerlist" element = { < ControllerList /> } /> */}
        {/* <Route path="/createcontroller" element={<CreateController />} /> */}
        <Route path="/createoperator" element={<CreateOperator />} />{" "}
        {/* < Route path = "/createjob"
element = { < CreateJob /> }
/> */}{" "}
        {/* <Route path="/requestchart" element={<RequestChart />} />
            <Route path="/paymentchart" element={<PaymentChart />} />
            <Route path="/earningchart" element={<EarningChart />} />
            <Route path="/requestchart" element={<RequestChart />} /> */}{" "}
        <Route path="/editdriver" element={<EditDriver />} />{" "}
        <Route path="/edit-profile" element={<EditProfile />} />{" "}
        {/* < Route path = "/changepassword"
element = { < ChangePassword /> }
/> */}{" "}
        <Route path="/blockdriver" element={<BlockDriver />} />{" "}
        <Route path="/driverpayment" element={<DriverPament />} />{" "}
        <Route path="/passengerpayment" element={<PassengerPament />} />{" "}
        <Route path="/impactedtrips" element={<ImpactedTrips />} />
        <Route path="/vehiclelist" element={<VehicleList />} />{" "}
        <Route path="/vehicle-new-location" element={<VehicleNewLocation />} />{" "}
        <Route path="/ride-details" element={<UpcomingRides />} />{" "}
        <Route path="/addvehicle" element={<AddVehicle />} />{" "}
        {/* < Route path = "/passengerlist"
element = { < PassengerList /> }
/> */}{" "}
        {/* <Route path="/blockpassenger" element={<BlockPassenger />} /> */}{" "}
        <Route path="/loginscreen" element={<LoginScreen />} />{" "}
        <Route path="/payment-success" element={<PaymentSuccess />} />{" "}
        <Route path="/payment-failed" element={<PaymentFailed />} />{" "}
        <Route path="/jobbooking" element={<JobBooking />} />{" "}
        <Route
          path="/driverpaymentapproved"
          element={<DriverPamentApproved />}
        />
        {/* < Route path = "/staffdetails"
element = { < StaffDetails /> }
/> */}
        <Route path="/cancelled-trips" element={<CancelledTrips />} />{" "}
        <Route path="/feedback" element={<FeedBack />} />{" "}
        <Route path="/vehiclecard" element={<VehicleCard />} />{" "}
        <Route path="/about" element={<AboutUs />} />{" "}
        <Route path="/trip-book" element={<JobBook />} />{" "}
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />{" "}
        <Route path="/faq" element={<FAQ />} />{" "}
        <Route path="/notfound" element={<NotFound />} />
        <Route path="/blogpage" element={<BlogPage />} />
      </Routes>
    </BrowserRouter>
  </ContextWrapper>
);
export default App;
