import React, { useEffect, useState, useContext } from "react";
import { Leftbar } from "./sidebar/Leftbar";

import Header from "./header/Header";
import Footer from "./footer/Footer";
import { Table } from "antd";
import { Helmet } from "react-helmet";
import { itemRender, onShowSizeChange } from "./paginationfunction";
import { axiosInstance } from "../base/api/axios.util";
import { URLConstants } from "../base/api/url.constants";
import { ClientContext } from "../base/contexts/UserContext";
import Loader from "react-loader";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";

import { useForm, Controller, set } from "react-hook-form";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import axios, * as others from "axios";
import { Fragment } from "react";
import convertDate from "../base/utils/EnglishDateTime";

function CancelledTrips() {
  const { client, setClient } = useContext(ClientContext);
  const [tripData, setTripData] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [fromDate, setFromDate] = useState("2000-01-01");
  const [operatorNames, setOperatorsName] = useState([]);
  const [vehicleNos, setVehicleNos] = useState([]);
  const [operator, setOperator] = useState("All");
  const date = new Date();
  const location = useLocation();

  let [filterOptions, setFilterOptions] = useState({});
  const [query, setQuery] = useState("");
  let [displayFilterValue, setDisplayFilterValue] = useState({});

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const [toDate, setToDate] = useState(year + "-" + month + "-" + day);

  const navigate = useNavigate();
  function getMonthRange() {
    const today = new Date();

    // Get the first day of the current month
    const fromDate = new Date(today.getFullYear(), today.getMonth(), 1);

    // Get today's date
    const toDate = today;

    return {
      fromDate,
      toDate,
    };
  }

  var driverDataAssignment = {};
  if (location.state != undefined) {
    driverDataAssignment = location.state;
  }

  console.log("LOCAtion value ", location.state);
  let defaultValue = {
    toDate: getMonthRange().toDate,
    fromDate: getMonthRange().fromDate,
  };

  if (location?.state?._id) {
    defaultValue.operatorId = location.state._id;
  }

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValue,
  });
  useEffect(() => {
    setLoaded(false);
    let filter = {
      fromDate: getMonthRange().fromDate,
      toDate: getMonthRange().toDate,
    };
    if (location?.state?._id) {
      filter.operatorId = location?.state?._id;
    }
    console.log("FIlter", filter);
    console.log("Logged in client", client);

    if (!client) {
      navigate("/");
    } else if (client?.logInType == "Driver") {
      filter.driverId = client?.loginData?._id;
      axiosInstance
        .post(URLConstants.getDriverTripDetails(client?.loginData?._id))
        // axiosInstance
        //   .post(URLConstants.getAllTripDetails(), filter)
        .then((response) => {
          console.log("Resoinse in trip driver", response);
          if (response?.length == 0) {
            setTripData([]);
          } else {
            setTripData(response);
          }
          setLoaded(true);
        })
        .catch((err) => {
          setLoaded(true);
        });
    } else if (client?.logInType == "SUPER_ADMIN") {
      setLoaded(false);
      axiosInstance
        .get(URLConstants.getOperatorsNames())
        .then((response) => {
          setOperatorsName(response);
        })
        .catch((err) => {
          setLoaded(true);
          console.log(err);
        });
      axiosInstance
        .get(URLConstants.getOperatorVehicleNo(client?.loginData.client.id))
        .then((response) => {
          console.log("CARd daeta", response);
          // response?.operatorVehicles[0]?.vehicleNumbers.unshift("ALL");
          if (response?.operatorVehicles[0]?.vehicleNumbers) {
            setVehicleNos(response?.operatorVehicles[0]?.vehicleNumbers);
          } else {
            setVehicleNos([]);
          }
        })
        .catch((err) => {
          console.log("Error Triggered", err);

          setLoaded(true);
          console.log(err);
        });
      axiosInstance
        .post(URLConstants.getCancelledTrips(), filter)
        .then((response) => {
          if (!response?.results) {
            setTripData([]);
          } else {
            console.log("TRIP DATA", response.results);
            setTripData(response?.results);
          }
          setLoaded(true);
        })
        .catch((err) => {
          setLoaded(true);
        })
        .finally(() => {
          setLoaded(true);
        });
    } else if (client?.logInType == "OPERATOR") {
      axiosInstance
        .get(URLConstants.getAllTripDetailsByOptDriver(client?.loginData?.id))
        .then((response) => {
          if (!response?.results) {
            setTripData([]);
          } else {
            setTripData(response?.results);
          }
          setLoaded(true);
        })
        .catch((err) => {
          setLoaded(true);
        });

      axiosInstance
        .get(URLConstants.getOperatorVehicleNo(client?.loginData.client.id))
        .then((response) => {
          console.log("CARd daeta", response);
          // response?.operatorVehicles[0]?.vehicleNumbers.unshift("ALL");
          if (response?.operatorVehicles[0]?.vehicleNumbers) {
            setVehicleNos(response?.operatorVehicles[0]?.vehicleNumbers);
          } else {
            setVehicleNos([]);
          }
          setLoaded(true);
        })
        .catch((err) => {
          console.log("Error Triggered", err);

          setLoaded(true);
          console.log(err);
        });
    } else if (client?.logInType == "Passenger") {
      axiosInstance
        .post(URLConstants.getPassengerTrips(client?.loginData?.id))
        .then((response) => {
          console.log("From truo data in passenger", response);
          if (response?.length == 0) {
            setTripData([]);
          } else {
            for (let i = 0; i < response.passengerTrips.results; i++) {
              if (
                response.passengerTrips.results[i].totalTripPrice == undefined
              ) {
                response.passengerTrips.results[i].totalTripPrice =
                  "Trip not yet completed";
              }
            }
            setTripData(response.passengerTrips.results);
          }
          setLoaded(true);
        })
        .catch((err) => {
          setLoaded(true);
        });
    } else {
      navigate("/notfound");
    }
  }, []);

  const filteredOperatorList =
    query === ""
      ? operatorNames
      : operatorNames?.filter((state) => {
          return state?.companyName.toLowerCase().includes(query.toLowerCase());
        });

  const filteredVehicleNoList =
    query === ""
      ? vehicleNos
      : vehicleNos?.filter((state) => {
          return state?.toLowerCase().includes(query.toLowerCase());
        });
  console.log("FILTERED VEHICLE LIST", filteredVehicleNoList);
  const columns = [
    {
      title: "Trip Id",
      dataIndex: "tripId",
    },
    {
      title: "Driver Name",
      dataIndex: "driverDetails",
      render: (driverDetails) => driverDetails?.name,
    },
    {
      title: "Driver Mobile No",
      dataIndex: "driverDetails",
      render: (driverDetails) => driverDetails?.mobileNo,
    },

    {
      title: "Pickup Location",
      dataIndex: "source",
      render: (source) => source[0]?.address,
    },

    {
      title: "Destination",
      dataIndex: "destination",
      render: (destination) => destination[0]?.address,
    },
    {
      title: "Total Distance",
      dataIndex: "totalTravelDist",
    },
    {
      title: "Payment status",
      dataIndex: "paymentStatus",
      sorter: (a, b) => a.paymentStatus?.length - b.paymentStatus?.length,
    },
    {
      title: "Trip Date ",

      render: (tripDateTime) => {
        console.log("Trip date", convertDate(tripDateTime.tripDateTime));
        return convertDate(tripDateTime.tripDateTime);
      },
    },
    {
      title: "Trip Time ",

      render: (tripDateTime) => {
        console.log(
          "Trip date",
          new Date(tripDateTime.tripDateTime).toISOString()
        );
        return new Date(tripDateTime.tripDateTime)
          .toISOString()
          ?.split("T")[1]
          .substring(0, 5);
      },
    },
    {
      title: "Total Bill",
      dataIndex: "totalTripPrice",
      render: (totalTripPrice) => {
        if (Number.isInteger(parseInt(totalTripPrice))) {
          return `£+${Number(parseInt(totalTripPrice))}`;
        } else {
          return 0;
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
    },
  ];
  const ExportToExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const outputFilename = `${Date.now()}`;

    const customHeadings = tripData?.map((item) => ({
      "Driver Name": item.driverDetails?.name,
      "Contact Number": item.driverDetails?.mobileNo,
      Source: item.source[0]?.address,
      Destination: item.destination[0]?.address,
      "Total Trip distance": item.totalTravelDist,
      "Payment Status": item.paymentStatus,
      "Total Trip Price": Number.isInteger(parseInt(item.totalTripPrice))
        ? Number(parseInt(item.totalTripPrice))
        : 0,
    }));

    exportToExcel(customHeadings, outputFilename, fileExtension, fileType);
  };

  const exportToExcel = (customHeadings, fileName, fileExtension, fileType) => {
    const ws = XLSX.utils.json_to_sheet(customHeadings);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const ExportToCsv = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".csv";
    const outputFilename = `${Date.now()}`;

    const customHeadings = tripData?.map((item) => ({
      "Driver Name": item.driverInfo.name,
      "Contact Number": item.driverInfo.mobileNo,
      Source: item.source[0]?.address,
      Destination: item.destination[0]?.address,
      "Total Trip distance": item.totalTravelDist,
      "Payment Status": item.paymentStatus,
      "Total Trip Price": Number.isInteger(parseInt(item.totalTripPrice))
        ? Number(parseInt(item.totalTripPrice))
        : 0,
    }));

    exportToCsv(customHeadings, outputFilename, fileExtension, fileType);
  };

  const exportToCsv = (customHeadings, fileName, fileExtension, fileType) => {
    const ws = XLSX.utils.json_to_sheet(customHeadings);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const generatePdf = async () => {
    const headings = [
      "Driver Name",
      "Contact Number",
      "Email",
      "No. of Trips",
      "VAT Registered",
      "Total kms",
      "Total Earning",
    ];
    const response = await axios.get(
      URLConstants.getTripPdf(),

      {
        responseType: "blob", // Set responseType to 'blob'
      }
    );
    const blob = new Blob([response.data], { type: "application/pdf" });
    FileSaver.saveAs(blob, `${Number(new Date())}.pdf`);

    // const blob = new Blob([response], { type: "application/pdf" });
    // FileSaver(blob, Number(new Date()) + ".pdf");
  };

  const ChooseOfOperator = (event) => {
    const optId = event.target.value;
    setOperator(optId);
  };

  return (
    <>
      <Header isAdmin />
      <div className="grid lg:grid-cols-6">
        <Leftbar />
        <Helmet>
          <title>Cancelled Trips - Treklers - Admin</title>
          <meta name="description" content="Login page" />
        </Helmet>
        <div className="p-8 lg:col-span-5">
          <div className="col-md-12">
            <div className="table-responsive">
              <div className="p-4 grid grid-cols-2 pb-8 mb-3">
                <div>
                  <span className="text-2xl font-bold">Cancelled Trips</span>
                  <span className="font-bold p-4">
                    <Link to="/">Home</Link>{" "}
                  </span>
                </div>

                <div className=" float-end ml-auto">
                  <button
                    className="rounded bg-gray-700 text-white hover:bg-black px-3 py-2 w-auto"
                    onClick={() => ExportToExcel()}
                  >
                    Download Excel
                  </button>
                  <button
                    className="rounded bg-gray-700 text-white hover:bg-black px-3 py-2 w-auto ml-3"
                    onClick={() => ExportToCsv()}
                  >
                    Download CSV
                  </button>
                  {/* <button
                    className="rounded bg-gray-700 text-white hover:bg-black px-3 py-2 w-auto ml-3"
                    onClick={() => generatePdf()}
                  >
                    Download PDF
                  </button> */}
                </div>
              </div>

              <div className="grid grid-cols-1 pb-4">
                <div className="filter-row leads pt-4">
                  <form
                    onSubmit={handleSubmit((data) => {
                      console.log("Before Date", data);
                      if (filterOptions?.operatorId?.length > 0) {
                        data.operatorId = filterOptions?.operatorId;
                      }
                      if (
                        data?.fromDate?.length == 0 ||
                        data?.toDate?.length == 0
                      ) {
                        delete data.fromDate;
                        delete data.toDate;
                      }

                      if (filterOptions?.vehicleNo?.length > 0) {
                        data.vehicleNo = filterOptions?.vehicleNo;
                      }
                      if (data?.driverMobileNo?.length == 0) {
                        delete data.driverMobileNo;
                      }

                      console.log("After Date", data);
                      setLoaded(false);
                      if (client.logInType == "Passenger") {
                        axiosInstance
                          .post(
                            URLConstants.getPassengerTrips(
                              client?.loginData?.id
                            ),
                            data
                          )
                          .then((response) => {
                            console.log(
                              "From truo data in passenger",
                              response
                            );
                            if (response?.length == 0) {
                              setTripData([]);
                            } else {
                              setTripData(response.passengerTrips.results);
                            }
                            setLoaded(true);
                          })
                          .catch((err) => {
                            setLoaded(true);
                          });
                      } else {
                        axiosInstance
                          .post(URLConstants.getCancelledTrips(), data)
                          .then((response) => {
                            if (!response?.results) {
                              setTripData([]);
                            } else {
                              console.log("TRIP DATA", response.results);
                              setTripData(response?.results);
                            }
                            setLoaded(true);
                          })
                          .then((err) => {
                            setLoaded(true);
                          });
                      }
                    })}
                  >
                    {/* <div className="d-flex pb-3 align-items-end justify-between">
                      {client?.logInType !== "Passenger" &&
                      client?.logInType !== "Driver" ? (
                        <>
                          <div className="pb-3 w-25">
                            <label className="col-form-label">Operator</label>
                            <div className="w-full border border-1 border-gray-500 rounded py-1 pr-2">
                              <select
                                {...register("operatorId")}
                                className={`w-full border-0 outline-0 px-2  ${
                                  errors?.optId ? "error-select" : ""
                                }`}
                              >
                                <option value="">Select Any</option>
                                {operatorNames.map(
                                  ({ operatorId, companyName }, index) => (
                                    <option value={operatorId}>
                                      {companyName}
                                    </option>
                                  )
                                )}
                              </select>
                              <small>{errors?.optId?.message}</small>
                            </div>
                          </div>
                          <div className="pb-3 w-25">
                            <label className="col-form-label">
                              Vehicle Number
                            </label>
                            <div className="w-full border border-1 border-gray-500 rounded py-1 pr-2">
                              <select
                                {...register("vehicleNo")}
                                className={`w-full border-0 outline-0 px-2  ${
                                  errors?.optId ? "error-select" : ""
                                }`}
                                // onChange={(event) => ChooseOfOperator(event)}
                              >
                                <option value="">Select Any</option>
                                {vehicleNos.map((vehicleNo, index) => (
                                  <option value={vehicleNo}>{vehicleNo}</option>
                                ))}
                              </select>
                              <small>{errors?.vehicleNo?.message}</small>
                            </div>
                          </div>
                          <div className="pb-3 w-25">
                            <div className="mb-2">Driver Mobile No </div>
                            <input
                              type="text"
                              className="w-full col-12 border p-1 px-8 rounded"
                              style={{ height: "36px" }}
                              {...register("driverMobileNo", {
                                required: false,
                              })}
                            />
                          </div>
                        </>
                      ) : null}

                      <div className="pb-3 w-25">
                        <div className="mb-2">From Date </div>
                        <input
                          type="date"
                          className="w-full col-12 border p-1 px-8 rounded"
                          style={{ height: "36px" }}
                          {...register("fromDate", { required: true })}
                        />
                        {errors.fromDate && <p>Date is required</p>}
                      </div>
                      <div className="pb-3 w-25">
                        <div className="mb-2">To Date </div>
                        <input
                          type="date"
                          className="col-12 border p-1 ps-2 rounded"
                          style={{ height: "36px" }}
                          {...register("toDate", { required: true })}
                        />
                        {errors.toDate && <p>Date is required</p>}
                      </div> */}

                    <div className="d-flex pb-3 align-items-end justify-between">
                      {/* <div className="pb-3 w-25">
                        <label className="col-form-label">Operator</label>
                        <div className="w-full border border-1 border-gray-500 rounded py-1 pr-2">
                          <select
                            {...register("operatorId")}
                            className={`w-full border-0 outline-0 px-2  ${
                              errors?.operatorId ? "error-select" : ""
                            }`}
                          >
                            <option value="">Select Any</option>
                            {operatorNames.map(
                              ({ operatorId, companyName }, index) => (
                                <option value={operatorId}>
                                  {companyName}
                                </option>
                              )
                            )}
                          </select>
                          <small>{errors?.optId?.message}</small>
                        </div>
                      </div> */}
                      {!["Passenger", "Driver", "OPERATOR"].includes(
                        client.logInType
                      ) ? (
                        <div className="col-4">
                          <span>Operator</span>
                          <Combobox
                            value={displayFilterValue?.operatorName ?? ""}
                            onChange={(value) => {
                              // filterOptions.operatorId = value;
                              // setFilterOptions(filterOptions);
                              let optName = operatorNames?.filter(
                                (opt) => opt.operatorId == value
                              );
                              // console.log("OPTname", optName);

                              // displayFilterValue["operatorName"] =
                              //   optName[0].companyName;
                              // console.log("display name", displayFilterValue);
                              // setDisplayFilterValue(displayFilterValue);

                              displayFilterValue.operatorName =
                                optName[0].companyName;
                              setDisplayFilterValue(displayFilterValue);
                              filterOptions.operatorId = optName[0].operatorId;
                              setFilterOptions(filterOptions);
                            }}
                          >
                            <div className="relative mt-1">
                              <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md  2 sm:text-sm">
                                <Combobox.Input
                                  className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                  autocomplete="off"
                                  displayValue={(state) => {
                                    console.log(
                                      "state inside state",
                                      displayFilterValue.operatorName
                                    );
                                    return displayFilterValue.operatorName;
                                  }}
                                  onChange={(event) =>
                                    setQuery(event.target.value)
                                  }
                                />
                                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                  <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </Combobox.Button>
                              </div>
                              <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                                afterLeave={() => setQuery("")}
                              >
                                <Combobox.Options
                                  className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg   sm:text-sm"
                                  style={{
                                    padding: "0px",
                                    margin: "0px",
                                    textAlign: "center",
                                    zIndex: "2",
                                    width: "auto",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {filteredOperatorList?.length === 0 &&
                                  query !== "" ? (
                                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                      Nothing found.
                                    </div>
                                  ) : (
                                    filteredOperatorList?.map(
                                      ({ operatorId, companyName }) => (
                                        <Combobox.Option
                                          key={operatorId}
                                          className={({ active }) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                              active
                                                ? "bg-[#404298] text-white"
                                                : "text-gray-900"
                                            }`
                                          }
                                          style={{ padding: "15px" }}
                                          value={operatorId}
                                        >
                                          {({ selected, active }) => {
                                            return (
                                              <>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    // flexDirection: "column",
                                                  }}
                                                >
                                                  <span
                                                    className={`block truncate ${
                                                      selected
                                                        ? "font-medium"
                                                        : "font-normal"
                                                    }`}
                                                  >
                                                    {companyName}
                                                  </span>
                                                  {operatorId ===
                                                  filterOptions[
                                                    "operatorId"
                                                  ] ? (
                                                    <span
                                                      className={`pl-3 ${
                                                        active
                                                          ? "text-white"
                                                          : "text-teal-600"
                                                      }`}
                                                    >
                                                      <CheckIcon
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                      />
                                                    </span>
                                                  ) : null}
                                                </div>
                                              </>
                                            );
                                          }}
                                        </Combobox.Option>
                                      )
                                    )
                                  )}
                                </Combobox.Options>
                              </Transition>
                            </div>
                          </Combobox>
                        </div>
                      ) : null}
                      {/* <div className="pb-3 w-25">
                        <label className="col-form-label">Vehicle Number</label>
                        <div className="w-full border border-1 border-gray-500 rounded py-1 pr-2">
                          <select
                            {...register("vehicleNo")}
                            className={`w-full border-0 outline-0 px-2  ${
                              errors?.vehicleNo ? "error-select" : ""
                            }`}
                            // onChange={(event) => ChooseOfOperator(event)}
                          >
                            <option value="">Select Any</option>
                            {vehicleNos.map((vehicleNo, index) => (
                              <option value={vehicleNo}>{vehicleNo}</option>
                            ))}
                          </select>
                          <small>{errors?.vehicleNo?.message}</small>
                        </div>
                      </div> */}
                      {client?.logInType !== "Driver" && (
                        <div className="col-4">
                          <span>Vehicle Number</span>
                          <Combobox
                            value={displayFilterValue?.vehicleNo ?? ""}
                            onChange={(value) => {
                              filterOptions.vehicleNo = value;
                              setFilterOptions(filterOptions);
                            }}
                          >
                            <div className="relative mt-1">
                              <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md  2 sm:text-sm">
                                <Combobox.Input
                                  className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                  autocomplete="off"
                                  displayValue={(state) => {
                                    console.log(
                                      "state inside state",
                                      filterOptions.vehicleNo
                                    );
                                    return filterOptions.vehicleNo;
                                  }}
                                  onChange={(event) =>
                                    setQuery(event.target.value)
                                  }
                                />
                                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                  <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </Combobox.Button>
                              </div>
                              <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                                afterLeave={() => setQuery("")}
                              >
                                <Combobox.Options
                                  className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg   sm:text-sm"
                                  style={{
                                    padding: "0px",
                                    margin: "0px",
                                    textAlign: "center",
                                    zIndex: "2",
                                    width: "auto",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {filteredVehicleNoList?.length === 0 &&
                                  query !== "" ? (
                                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                      Nothing found.
                                    </div>
                                  ) : (
                                    filteredVehicleNoList.map((vehicleNo) => (
                                      <Combobox.Option
                                        key={vehicleNo}
                                        className={({ active }) =>
                                          `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                            active
                                              ? " bg-[#404298] text-white"
                                              : "text-gray-900"
                                          }`
                                        }
                                        style={{ padding: "15px" }}
                                        value={vehicleNo}
                                      >
                                        {({ selected, active }) => {
                                          return (
                                            <>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                  // flexDirection: "column",
                                                }}
                                              >
                                                <span
                                                  className={`block truncate ${
                                                    selected
                                                      ? "font-medium"
                                                      : "font-normal"
                                                  }`}
                                                >
                                                  {vehicleNo}
                                                </span>
                                                {vehicleNo ===
                                                filterOptions["vehicleNo"] ? (
                                                  <span
                                                    className={`pl-3 ${
                                                      active
                                                        ? "text-white"
                                                        : "text-teal-600"
                                                    }`}
                                                  >
                                                    <CheckIcon
                                                      className="h-5 w-5"
                                                      aria-hidden="true"
                                                    />
                                                  </span>
                                                ) : null}
                                              </div>
                                            </>
                                          );
                                        }}
                                      </Combobox.Option>
                                    ))
                                  )}
                                </Combobox.Options>
                              </Transition>
                            </div>
                          </Combobox>
                        </div>
                      )}

                      {client?.logInType !== "Driver" ? (
                        <div className="col-4">
                          <div className="mb-2">Driver Mobile No </div>
                          <input
                            type="text"
                            className="w-full col-12 border p-1 px-8 rounded"
                            style={{ height: "36px" }}
                            {...register("driverMobileNo", {
                              required: false,
                            })}
                          />
                        </div>
                      ) : null}
                    </div>
                    <div className="d-flex pb-3 align-items-end ">
                      <div className="col-4">
                        <div className="mb-2">From Date </div>
                        <input
                          type="date"
                          className="w-full col-12 border p-1 px-8 rounded"
                          style={{ height: "36px" }}
                          {...register("fromDate")}
                        />
                        {errors.fromDate && <p>Date is required</p>}
                      </div>
                      <div className="col-4">
                        <div className="mb-2">To Date </div>
                        <input
                          type="date"
                          className="col-12 border p-1 ps-2 rounded"
                          style={{ height: "36px" }}
                          {...register("toDate")}
                        />
                        {errors.toDate && <p>Date is required</p>}
                      </div>

                      <div className="col-sm-1 col-md-2 text-right pb-3 pl-5">
                        <div>
                          <input
                            className="w-auto m-auto px-4 py-2"
                            type="submit"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="spinner">
                      <Loader
                        loaded={loaded}
                        lines={13}
                        length={20}
                        width={10}
                        radius={30}
                        corners={1}
                        rotate={0}
                        direction={1}
                        color="#000"
                        speed={1}
                        trail={60}
                        shadow={false}
                        hwaccel={false}
                        className="spinner"
                        zIndex={2e9}
                        top="50%"
                        left="50%"
                        scale={1.0}
                        loadedClassName="loadedContent"
                      />
                    </div>
                  </form>
                </div>
              </div>

              <Table
                className="table-striped"
                pagination={{
                  total: tripData?.length,
                  showTotal: (total, range) =>
                    `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                  showSizeChanger: true,
                  onShowSizeChange: onShowSizeChange,
                  itemRender: itemRender,
                }}
                style={{ overflowX: "auto" }}
                columns={columns}
                bordered
                dataSource={tripData}
                rowKey={(record) => record.id}
              />
            </div>
          </div>
        </div>
        {/* <div className="spinner">
          <Loader
            loaded={loaded}
            lines={13}
            length={20}
            width={10}
            radius={30}
            corners={1}
            rotate={0}
            direction={1}
            color="#000"
            speed={1}
            trail={60}
            shadow={false}
            hwaccel={false}
            className="spinner"
            zIndex={2e9}
            top="50%"
            left="50%"
            scale={1.0}
            loadedClassName="loadedContent"
          />
        </div> */}
      </div>
      <Footer />
    </>
  );
}

export default CancelledTrips;
