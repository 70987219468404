import React, { useEffect, useState, useContext, useMemo } from "react";
import { Helmet } from "react-helmet";
import Header from "./header/Header";
import { Leftbar } from "./sidebar/Leftbar";
import Footer from "./footer/Footer";
import { useForm } from "react-hook-form";
import { MultiSelect } from "react-multi-select-component";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { axiosInstance } from "../base/api/axios.util";
import { URLConstants } from "../base/api/url.constants";
import { ClientContext } from "../base/contexts/UserContext";
//var axios = require("axios");
import axios, * as others from "axios";
import { useNavigate } from "react-router-dom";
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import { FaSourcetree } from "react-icons/fa";
import MapWithAMarker from "./GoogleMap";
import { useLocation } from "react-router-dom";
import MapHero from "./shared/MapHero";
import Loader from "react-loader";

function JobBooking() {
  const [source, setSource] = useState("");
  const [destination, setDestination] = useState("");
  const [finalSource, setFinalSource] = useState({});
  const [finalDestination, setFinalDestination] = useState({});
  const [selectedGender, setGender] = useState([]);
  const [selectedLanguages, setLanguages] = useState([]);
  const [selectedGenderData, setGenderData] = useState([]);
  const [selectedLanguageData, setLanguageData] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [finalMarker, setFinalMarker] = useState({
    lat: 22.57687,
    lng: 88.35047,
  });
  const [sourceMarker, setSourceMarker] = useState({});
  const [destinationMarker, setDestinationMarker] = useState({});
  const location = useLocation();
  console.log("LOcation in Jobbboking", location);

  const { client, setClient } = useContext(ClientContext);
  const navigate = useNavigate();
  // const [suggestedSource, setSuggestedSource] = useState("");
  function handleSourceChange(event) {
    console.log("Event i source", event);
    setSource(event);
  }

  const convertToMiles = (km) => {
    return km * 0.621371;
  };

  async function getTravelDistAndTime() {
    console.log("Fianl source", finalSource);
    console.log("Fianl destinaltion", finalDestination);
    let response = await axiosInstance.post(URLConstants.getDistanceAndTime(), {
      finalSource,
      finalDestination,
    });
    return response[0].elements[0];
  }

  useEffect(() => {
    if (location?.state?.source) {
      setSource(location?.state?.source);
      handleSourceSelect(location?.state?.source);
    }
    if (location?.state?.destination) {
      setDestination(location?.state?.destination);
      handleDestinationSelect(location?.state?.destination);
    }
  }, []);
  function handleSourceSelect(suggestedSource) {
    console.log("Suggested souirce", suggestedSource);
    setSourceMarker({});
    console.log("Selected address", suggestedSource);
    var testSource = {};
    geocodeByAddress(suggestedSource)
      .then((results) => {
        testSource.source = results[0].formatted_address;
        setSource(results[0].formatted_address);
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        console.log("Selected address for lat and long", latLng);
        setSourceMarker(latLng);
        setFinalMarker(latLng);
        testSource.latLong = latLng;
        setFinalSource(testSource);
      })
      .catch((error) => console.error("Error", error));
  }

  function handleDestinationChange(event) {
    setDestination(event);
  }

  function handleDestinationSelect(suggestDestination) {
    setDestinationMarker({});
    var testDestination = {};
    geocodeByAddress(suggestDestination)
      .then((results) => {
        testDestination.destination = results[0].formatted_address;
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        setDestinationMarker(latLng);
        setFinalMarker(latLng);
        testDestination.latLong = latLng;
        setFinalDestination(testDestination);
      })
      .catch((error) => console.error("Error", error));
  }

  const getAddress = (address, lng, lat) => {
    return {
      address: address,
      coordinates: [lng, lat],
    };
  };

  async function testSubmit() {
    const val = await getTravelDistAndTime();
  }

  const optionsLang = [
    { label: "English", value: "English" },
    { label: "Hindi", value: "Hindi" },
    { label: "Chinese", value: "Chinese" },
    { label: "Russian", value: "Russian" },
    { label: "French", value: "French" },
    { label: "Spanish", value: "Spanish" },
  ];

  const optionsGender = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },

    { label: "Others", value: "Others" },
  ];
  let valueAssignment = {};
  if (client.logInType == "Passenger" && client?.loginData?.client?.mobileNo) {
    valueAssignment.passMobileNo = client?.loginData?.client?.mobileNo;

    valueAssignment.passengerFirstName = client?.loginData?.client?.firstName;
    valueAssignment.passengerLastName = client?.loginData?.client?.lastName;
  }

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: valueAssignment,
  });

  //handle submit

  const differenceInHours = async (advDate) => {
    const date = new Date();
    const date1 = new Date(advDate);
    var diff = (date1.getTime() - date.getTime()) / 3600000;
    return Math.abs(Math.round(diff));
  };
  const convertDateToNum = async (hours) => {
    const date = new Date();
    return date.setHours(date.getHours() + hours);
  };
  const onSubmit = async (data) => {
    setLoaded(false);
    for (let i = 0; i < selectedGenderData.length; i++) {
      selectedGender.push(selectedGenderData[i].value);
    }
    for (let i = 0; i < selectedLanguageData.length; i++) {
      selectedLanguages.push(selectedLanguageData[i].value);
    }

    const val = await getTravelDistAndTime();
    const distance = val.distance.value;
    const time = val.duration.value;
    let createTripBody = {};
    createTripBody.noOfBuggies = data.noOfBuggies;
    createTripBody.passengerMobileNo = data.passMobileNo;
    createTripBody.passengerFirstName = data.passengerFirstName;
    createTripBody.passengerLastName = data.passengerLastName;
    createTripBody.noOfLugbags = data.noOfLugbags;
    createTripBody.noOfBaggages = data.noOfBaggages;
    createTripBody.passengerNo = data.noOfPassenger;
    console.log("COnsole to checck type for ", createTripBody);
    createTripBody.totalTravelDist = convertToMiles(distance / 1000);
    createTripBody.source = [
      getAddress(
        finalSource.source,
        finalSource.latLong.lng,
        finalSource.latLong.lat
      ),
    ];
    createTripBody.destination = [
      getAddress(
        finalDestination.destination,
        finalDestination.latLong.lng,
        finalDestination.latLong.lat
      ),
    ];
    createTripBody.totalTravelTime = time;
    createTripBody.tripType = time;

    // if (data.advanceCurrent == "Advance") {
    createTripBody.tripType = 1;
    const hours = await differenceInHours(data.dateTime);
    createTripBody.tripTravelDate = await convertDateToNum(hours);
    // } else {
    //   createTripBody.tripType = 0;
    //   createTripBody.tripTravelDate = await convertDateToNum(0);
    // }
    if (data.tripType == "Personal") {
      createTripBody.bookingType = 0;
    } else {
      createTripBody.bookingType = 1;
    }
    createTripBody.genderFilter = selectedGender;
    createTripBody.languagesFilter = selectedLanguages;

    let params = {
      method: "POST",
      url: `${URLConstants.BOOKING_URL}/v1/admin/vehiclepricing/OPT1`,
      data: createTripBody,
    };
    console.log("Trip body", createTripBody);

    let response1 = await axiosInstance.post(URLConstants.getVehiclePricing(), {
      createTripBody,
    });
    console.log("Fianl resposnse with pricing data", response1);

    const finalBody = { ...response1, createTripBody };
    setLoaded(true);
    navigate("/vehiclecard", {
      state: finalBody,
    });
  };

  useEffect(() => {
    if (!client) {
      navigate("/");
    }
  }, []);

  const now = new Date();

  let advanceHours = 6;

  // Add 6 hours to it (6 hours * 60 minutes * 60 seconds * 1000 milliseconds)
  const sixHoursLater = new Date(now.getTime() + advanceHours * 60 * 60 * 1000);

  // Convert to ISO string and slice to remove seconds and timezone information
  const formattedTime = sixHoursLater
    .toISOString()
    .slice(0, sixHoursLater.toISOString().lastIndexOf(":"));
  console.log("formateted time", formattedTime);
  console.log("SPurce", source);
  let futureTime = sixHoursLater.getTime();
  console.log("Futute time", futureTime);
  // const MapWithAMarker = withGoogleMap((props) => (
  //   <GoogleMap
  //     defaultZoom={15}
  //     defaultCenter={{ lat: 22.57687, lng: 88.35047 }}
  //     onClick={(ev) => {
  //       console.log("latitide = ", ev.latLng.lat());
  //       console.log("longitude = ", ev.latLng.lng());
  //     }}
  //   >
  //     <Marker
  //       position={{ lat: 22.57687, lng: 88.35047 }}
  //       className="h-10 w-10"
  //     />
  //   </GoogleMap>
  // ));

  // const MapWithAMarker = () => {
  //   const mapComponent = useMemo(
  //     () => (
  //       <GoogleMap
  //         defaultZoom={15}
  //         defaultCenter={{ lat: 22.57687, lng: 88.35047 }}
  //         onClick={(ev) => {
  //           console.log("latitude = ", ev.latLng.lat());
  //           console.log("longitude = ", ev.latLng.lng());
  //         }}
  //       >
  //         <Marker
  //           position={{ lat: 22.57687, lng: 88.35047 }}
  //           className="h-10 w-10"
  //         />
  //       </GoogleMap>
  //     ),
  //     []
  //   ); // Empty dependency array ensures the component is memoized and reused

  //   return mapComponent;
  // };

  return (
    <>
      <div>
        <Header isAdmin />
        <MapHero
          origin={location?.state?.source}
          destination={location?.state?.destination}
        />

        <div className=" flex items-center justify-center ">
          <div className="grid lg:grid-cols-4 job-booking bg-white z-50 rounded-2xl w-[70%] py-10 mb-40 -translate-y-56 shadow-2xl opacity-100">
            <div className="p-8 lg:col-span-5">
              <div className="col-md-12">
                <div>
                  <div className="grid grid-cols-1">
                    <div className="filter-row leads w-100 m-auto">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        {/* <div className="grid gap-4 mb-2 m-auto px-16">
                      <MapWithAMarker
                        containerElement={<div style={{ height: `400px` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                        location={finalMarker}
                        destinationMarker={destinationMarker}
                        sourceMarker={sourceMarker}
                      />
                    </div> */}

                        <div className="grid gap-4 mb-2 m-auto px-16">
                          <div className="relative grid gap-4">
                            <PlacesAutocomplete
                              value={source}
                              onChange={(event) => handleSourceChange(event)}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <div className="inputbox">
                                  <input
                                    {...getInputProps({
                                      placeholder: "Enter Pickup Location",
                                      className: "location-search-input px-10",
                                    })}
                                  />
                                  <div class="arrowloc absolute right-4 top-3">
                                    <svg
                                      aria-hidden="true"
                                      focusable="false"
                                      width="16px"
                                      height="16px"
                                      fill="currentColor"
                                      viewBox="0 0 24 24"
                                      tabindex="0"
                                      role="button"
                                      aria-label="Locate me"
                                      class="pe-location-fetch css-ZSBbY"
                                    >
                                      <path d="M10.5 13.5L.5 11 21 3l-8 20.5-2.5-10z"></path>
                                    </svg>
                                  </div>
                                  <div className="autocomplete-dropdown-container px-10">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion) => {
                                      const className = suggestion.active
                                        ? "suggestion-item--active"
                                        : "suggestion-item";
                                      const style = suggestion.active
                                        ? {
                                            backgroundColor: "#fafafa",
                                            cursor: "pointer",
                                          }
                                        : {
                                            backgroundColor: "#ffffff",
                                            cursor: "pointer",
                                          };
                                      return (
                                        <div
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              className,
                                              style,
                                            }
                                          )}
                                        >
                                          <span
                                            onClick={() =>
                                              handleSourceSelect(
                                                suggestion.description
                                              )
                                            }
                                          >
                                            {suggestion.description}
                                          </span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                            <PlacesAutocomplete
                              value={destination}
                              onChange={(event) =>
                                handleDestinationChange(event)
                              }
                              // onSelect={() => handleDestinationSelect()}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <div className="">
                                  {/* <div className="mb-2">Destination </div> */}
                                  <input
                                    {...getInputProps({
                                      placeholder: "Enter Destination",
                                      className: "location-search-input px-10",
                                    })}
                                  />
                                  <div className="autocomplete-dropdown-container px-10">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion) => {
                                      const className = suggestion.active
                                        ? "suggestion-item--active"
                                        : "suggestion-item";
                                      // inline style for demonstration purpose
                                      const style = suggestion.active
                                        ? {
                                            backgroundColor: "#fafafa",
                                            cursor: "pointer",
                                          }
                                        : {
                                            backgroundColor: "#ffffff",
                                            cursor: "pointer",
                                          };
                                      return (
                                        <div
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              className,
                                              style,
                                            }
                                          )}
                                        >
                                          <span
                                            onClick={() =>
                                              // handleSuggestionSource(
                                              //   suggestion.description
                                              // )
                                              handleDestinationSelect(
                                                suggestion.description
                                              )
                                            }
                                          >
                                            {suggestion.description}
                                          </span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                            <div class="vline"></div>
                          </div>
                        </div>

                        <div className="grid grid-cols-2 mt-10 gap-4">
                          <div className="margin">
                            <label>No. of Passenger</label>

                            <input
                              type="number"
                              className="col-12 py-2.2"
                              {...register("noOfPassenger", {
                                valueAsNumber: true,
                                required: "No of Passengers is required",
                              })}
                            />

                            <div className="text-xs text-red-500">
                              {errors.noOfPassenger && (
                                <span>{errors.noOfPassenger.message}</span>
                              )}
                            </div>
                          </div>

                          <div className="margin">
                            <div className="mb-2">Passenger Mobile No. </div>
                            <input
                              type="text"
                              className="col-12 py-2.2"
                              {...register("passMobileNo", {
                                required: "Passenger Mobile No is required",
                              })}
                              // onChange={(event)=>handleSourceChange(event)}
                            />
                            <div className="text-xs text-red-500">
                              {errors.passMobileNo && (
                                <span>{errors.passMobileNo.message}</span>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="grid grid-cols-2 mt-10 gap-4">
                          <div className="margin">
                            <label>Passenger First Name</label>

                            <input
                              type="text"
                              className="col-12 py-2.2"
                              {...register("passengerFirstName", {
                                required: "Paasenger First Name is required",
                              })}
                            />

                            <div className="text-xs text-red-500">
                              {errors.passengerFirstName && (
                                <span>{errors.passengerFirstName.message}</span>
                              )}
                            </div>
                          </div>
                          <div className="margin">
                            <label>Pasenger Last Name</label>

                            <input
                              type="text"
                              className="col-12 py-2.2"
                              {...register("passengerLastName", {
                                required: "Paasenger Last Name is required",
                              })}
                            />

                            <div className="text-xs text-red-500">
                              {errors.passengerLastName && (
                                <span>{errors.passengerLastName.message}</span>
                              )}
                            </div>
                          </div>

                          <div className="margin">
                            <label>No. of Buggies</label>

                            <input
                              type="number"
                              className="col-12 py-2.2"
                              {...register("noOfBuggies", {
                                valueAsNumber: true,
                                required: "No of Passengers is required",
                              })}
                            />

                            <div className="text-xs text-red-500">
                              {errors.noOfBuggies && (
                                <span>{errors.noOfBuggies.message}</span>
                              )}
                            </div>
                          </div>

                          <div className="margin">
                            <label>No. of Luggage Bags</label>

                            <input
                              type="number"
                              className="col-12 py-2.2"
                              {...register("noOfLugbags", {
                                valueAsNumber: true,
                                required: "No of Luggage is required",
                              })}
                            />

                            <div className="text-xs text-red-500">
                              {errors.noOfLugbags && (
                                <span>{errors.noOfLugbags.message}</span>
                              )}
                            </div>
                          </div>

                          <div className="margin">
                            <label>No. of Baggages</label>

                            <input
                              type="number"
                              className="col-12 py-2.2"
                              {...register("noOfBaggages", {
                                valueAsNumber: true,
                                required: "No of Baggages is required",
                              })}
                            />

                            <div className="text-xs text-red-500">
                              {errors.noOfBaggages && (
                                <span>{errors.noOfBaggages.message}</span>
                              )}
                            </div>
                          </div>

                          <div className="margin">
                            <label>Language Preferred</label>
                            <MultiSelect
                              className="multi-select"
                              options={optionsLang}
                              value={selectedLanguageData}
                              onChange={setLanguageData}

                              // {...register("language", { required: 'Language is required' })}
                            />
                            <div className="text-xs text-red-500">
                              {/* {errors.language && <span>{errors.language.message}</span>} */}
                            </div>
                          </div>
                          <div className="margin">
                            <label>Gender Preferred</label>
                            <MultiSelect
                              className="multi-select "
                              options={optionsGender}
                              value={selectedGenderData}
                              onChange={setGenderData}

                              // {...register("language", { required: 'Language is required' })}
                            />
                            <div className="text-xs text-red-500">
                              {/* {errors.language && <span>{errors.language.message}</span>} */}
                            </div>
                          </div>

                          {/* <div className="grid grid-cols-2 margin">
                         <h2 className="fullGrid font-bold mb-1">Driver Type</h2>
                         <div className="d-flex items-center">
                          <label className="m-0 mr-4" for="male">
                            Male
                          </label>
                          <input
                            className="w-4 h-4"
                            type="radio"
                            id="male"
                            name="gender"
                            value="Male"
                            {...register("driverType", { required: true })}
                          />

                          <div className="text-xs text-red-500"></div>
                         </div>
                         <div className="d-flex items-center">
                          <label className="m-0 mr-4" for="female">
                            Female
                          </label>
                          <input
                            className="w-4 h-4"
                            type="radio"
                            id="female"
                            name="gender"
                            value="Female"
                            {...register("driverType", { required: true })}
                          />
                         </div>
                          </div> */}
                          <div className="grid grid-cols-2 margin ">
                            <h2 className="fullGrid font-bold mb-1">
                              Trip Type
                            </h2>
                            <div className="d-flex items-center">
                              <label className="m-0 pr-2" for="personal">
                                Personal
                              </label>
                              <input
                                className="w-4 h-4"
                                type="radio"
                                id="personal"
                                name="action"
                                value="Personal"
                                {...register("tripType", { required: true })}
                              />
                            </div>
                            <div className="d-flex items-center">
                              <label className="m-0 pr-2" for="corporate">
                                Corporate
                              </label>
                              <input
                                className="w-4 h-4"
                                type="radio"
                                id="corporate"
                                name="action"
                                value="Corporate"
                                {...register("tripType", { required: true })}
                              />
                            </div>
                          </div>
                          {/* <div className="grid grid-cols-2 margin1">
                        <h2 className="fullGrid font-bold mb-1 items-center">
                          Please select one:
                        </h2>
                        <div className="d-flex items-center">
                          <label className="m-0 pr-2" for="html">
                            Advance
                          </label>
                          <input
                            className="w-4 h-4"
                            type="radio"
                            id="html"
                            name="action1"
                            value="Advance"
                            {...register("advanceCurrent", { required: true })}
                          />
                        </div>
                        <div className="d-flex items-center">
                          <label className="m-0 pr-2" for="css">
                            Current
                          </label>
                          <input
                            className="w-4 h-4"
                            type="radio"
                            id="css"
                            name="action1"
                            value="Current"
                            {...register("advanceCurrent", { required: true })}
                          />
                        </div>
                      </div> */}
                          <div className="grid margin">
                            <label className="font-bold mb-1">Time</label>
                            <div className="w-full border border-1 border-dark rounded">
                              <input
                                type="datetime-local"
                                className="w-full border-0 outline-0"
                                // min={new Date()
                                //   .toISOString()
                                //   .slice(
                                //     0,
                                //     new Date().toISOString().lastIndexOf(":")
                                //   )}

                                min={formattedTime}
                                {...register("dateTime", { required: true })}
                              />
                            </div>
                          </div>
                          {/* <div>
                        <div className="grid margin1">
                          <label className="font-bold mb-1">Favorite Driver</label>
                          <div className="grid grid-cols-4">
                            <div className="w-full border border-1 border-dark rounded col-start-1 col-end-4">
                              <input
                                type="input"
                                className="w-full border-0 outline-0"
                              />
                            </div>
                            <button className="col-start-4 col-end-5 bg-black text-white rounded mx-4 hover:bg-gray-700">Search</button>
                          </div>
                        </div>
                      </div> */}
                          {/* <div className="grid margin1">
                        <label className="font-bold mb-1">Details :</label>
                        <div>
                          <span>Name : </span><span className="font-bold">Amarnath Reddy </span>
                        </div>
                        <div>
                          <span>Mobile No : </span><span className="font-bold">9787650055</span>
                        </div>
                        <div>
                          <span>Gender : </span><span className="font-bold">Male</span>
                        </div>
                        <div>
                          <span>Location : </span><span className="font-bold">Bengalore</span>
                        </div>
                        <div>
                          <span>Cab Type : </span><span className="font-bold">Luxary</span>
                        </div>
                      </div> */}
                        </div>
                        <input
                          className="fullGrid w-auto mx-auto px-4 mt-16 py-2"
                          type="submit"
                          value="Submit"
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <div className="spinner">
          <Loader
            loaded={loaded}
            lines={13}
            length={20}
            width={10}
            radius={30}
            corners={1}
            rotate={0}
            direction={1}
            color="#000"
            speed={1}
            trail={60}
            shadow={false}
            hwaccel={false}
            className="spinner"
            zIndex={2e9}
            top="50%"
            left="50%"
            scale={1.0}
            loadedClassName="loadedContent"
          />
        </div>
      </div>

      {/* <Footer /> */}
    </>
  );
}

export default JobBooking;
