import React, { useState } from "react";
import { Leftbar } from "./sidebar/Leftbar";
import { Link } from "react-router-dom";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import { Table } from "antd";
import { Helmet } from "react-helmet";
import { itemRender, onShowSizeChange } from "./paginationfunction";
import { useForm } from "react-hook-form";
import Loader from "react-loader";

const FeedBack = () => {
  const [staffData, setStaffData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      mobileNo: "",
      feedbackType: "",
      description: "",
      userType: "",
    },
  });

  const onSubmit = (data) => {
    alert(JSON.stringify(data));
  };

  const columns = [
    {
      title: "User Type",
      dataIndex: "userType",
      sorter: (a, b) => a.userType.length - b.userType.length,
    },
    {
      title: "Feedback Type",
      dataIndex: "feedbackType",
      sorter: (a, b) => a.controllerName.length - b.controllerName.length,
    },
    {
      title: "Mobile Number",
      dataIndex: "mobileNo",
      sorter: (a, b) => a.mobileNo.length - b.mobileNo.length,
    },

    {
      title: "Description",
      dataIndex: "description",
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => a.date.length - b.date.length,
    },
  ];
  const passengerColumns = [
    {
      userType: "Driver",
      feedbackType: "Testimonial",
      mobileNo: "235467898",
      description: "View",
      date: "26-10-2022",
    },
    {
      userType: "Passenger",
      feedbackType: "Complaints",
      mobileNo: "879876548",
      description: "View",
      date: "21-3-2022",
    },
    {
      userType: "Driver",
      feedbackType: "Lost/Found",
      mobileNo: "5676540098",
      description: "View",
      date: "5-6-2012",
    },
    {
      userType: "Passenger",
      feedbackType: "Testimonial",
      mobileNo: "9876787677",
      description: "View",
      date: "8-6-2022",
    },
  ];
  return (
    <>
      <Header isAdmin />
      <div className="grid lg:grid-cols-6">
        <Leftbar />
        <Helmet>
          <title>Controller List - Treklers - Admin</title>
          <meta name="description" content="Login page" />
        </Helmet>
        <div className="p-8 lg:col-span-5 ">
          <div className="col-md-12">
            <div className="table-responsive">
              <div className="p-4 mb-3 grid grid-cols-2 pb-8">
                <div>
                  <span className="text-2xl font-bold">
                    Feedback and Complaints
                  </span>
                </div>
                <div className="col-auto float-end ml-auto">
                  <Link
                    className="inline-flex items-center bg-black font-semibold border-0 py-1 px-3 focus:outline-none hover:bg-gray-700 rounded-full text-white md:mt-0"
                    to="/#"
                    data-bs-toggle="modal"
                    data-bs-target="#add_leave"
                    onClick={() => setLoaded(true)}
                  >
                    <i className="mr-2 noLine" /> Feedback and Complaints
                  </Link>
                </div>
              </div>
              <Table
                className="table-striped"
                pagination={{
                  total: passengerColumns.length,
                  showTotal: (total, range) =>
                    `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                  showSizeChanger: true,
                  onShowSizeChange: onShowSizeChange,
                  itemRender: itemRender,
                }}
                style={{ overflowX: "auto" }}
                columns={columns}
                bordered
                dataSource={passengerColumns}
              />
            </div>
          </div>
        </div>
        <div id="add_leave" className="modal custom-modal fade" role="dialog">
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content border-0">
              <div className="modal-header bg-gray-600 text-white text-center">
                <h3 className="text-xl modal-title text-center">
                  Feedback and Complaints
                </h3>
                <button
                  type="button"
                  className="close text-white opacity-100"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="modal-body">
                  <div className="grid grid-cols-1 gap-8 gap-y-4 mb-8 p-2">
                    <div>
                      <label>Mobile Number</label>
                      <input
                        type="number"
                        {...register("mobileNo", {
                          required: true,
                        })}
                      />
                      {errors.mobileNo && <p>Mobile Number is required</p>}
                    </div>
                    <div>
                      <label>FeedBack Type</label>
                      <div className="w-full border border-1 border-dark rounded p-2">
                        <select className="w-full border-0 outline-0">
                          <option>Choose one</option>
                          <option>Testimonial</option>
                          <option>Complaints</option>
                          <option>Lost/Found</option>
                        </select>
                      </div>
                      {errors.feedbackType && (
                        <p>feedbackType field is required</p>
                      )}
                    </div>

                    <div>
                      <label>Description</label>
                      <textarea
                        className="block w-full border-1 border-black rounded h-20 p-2"
                        type="text"
                      />
                      {errors.description && (
                        <p>Description field is required</p>
                      )}
                    </div>
                    <div>
                      <label>User Type</label>
                      <div className="w-full border border-1 border-dark rounded p-2">
                        <select className="w-full border-0 outline-0">
                          <option>Choose one</option>
                          <option>Driver</option>
                          <option>Passenger</option>
                        </select>
                      </div>
                      {errors.userType && <p>User Type field is required</p>}
                    </div>
                  </div>
                  <div className="submit-section text-center">
                    <button
                      type="submit"
                      className="btn btn-primary py-1 w-auto"
                    >
                      Submit
                    </button>
                  </div>
                </div>
                <div className="spinner">
                  <Loader
                    loaded={loaded}
                    lines={13}
                    length={20}
                    width={10}
                    radius={30}
                    corners={1}
                    rotate={0}
                    direction={1}
                    color="#000"
                    speed={1}
                    trail={60}
                    shadow={false}
                    hwaccel={false}
                    className="spinner"
                    zIndex={2e9}
                    top="50%"
                    left="50%"
                    scale={1.0}
                    loadedClassName="loadedContent"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FeedBack;
