import { useState } from "react";
import TrekLogo from "../images/treklers_logo.png";
import Trek4Business from "../images/Trek4BusinessBanner.svg";
import PassengerBanner from "../images/PassengerBanner.svg";
import DriverBanner from "../images/DriverBanner.svg";

export default function AppSelectionComponent() {
  const [selectedButton, setSelectedButton] = useState("passenger");

  return (
    <div className="flex flex-col items-center justify-center space-y-2 bg-[#ECECEC] px-24 py-20">
      <h2 className="text-2xl md:text-3xl md:px-36 px-12 text-left tracking-wide font-bold">
        Experience our services at their finest by downloading our apps.
      </h2>
      <div className="space-x-10 ">
        <button
          type="button"
          className={`inline-flex items-center mt-4 rounded-full px-8 lg:py-2 sm:py-1 cursor-pointer lg:text-sm text-xs font-medium  ${
            selectedButton == "passenger"
              ? "text-white bg-[#080941] "
              : "text-black hover:text-white hover:bg-[#080941] border-1 border-black "
          }`}
          onClick={() => setSelectedButton("passenger")}
        >
          Passenger App
        </button>
        <button
          type="button"
          className={`inline-flex items-center mt-4 rounded-full px-8 lg:py-2 sm:py-1 cursor-pointer lg:text-sm text-xs font-medium  ${
            selectedButton == "driver"
              ? "text-white bg-[#080941] "
              : "text-black hover:text-white hover:bg-[#080941] border-1 border-black"
          }`}
          onClick={() => setSelectedButton("driver")}
        >
          Driver App
        </button>
      </div>

      {selectedButton === "passenger" ? (
        <div className="flex flex-row items-center justify-center rounded-lg mx-32  relative overflow-hidden ">
          <div className="relative ">
            <img src={PassengerBanner} />
            <div className="bg-[#1517A7] w-60 min-h-full banner-design inset-y-0 right-0  z-1 bg-opacity-40 rounded-tr-[70px]  "></div>
            <div className="bg-[#353535] w-60 min-h-full banner-design-2 inset-y-0 right-0  z-1 rounded-tr-[70px] border-1 border-black "></div>
          </div>
          <div className="bg-[#353535] w-[45%] z-10 absolute left-0 top-0 min-h-full rounded-l-3xl flex items-center justify-center overflow-hidden ">
            <div className=" pl-20 space-y-2 min-h-full rounded-l-3xl w-full">
              {" "}
              <div className="lg:text-3xl md:text-xl  sm:text-lg font-bold lg:leading-2 leading-1 text-white w-full flex flex-row items-center">
                <span className="pr-3">
                  <img src={TrekLogo} className="h-10 object-contain" />{" "}
                </span>
                <span className="w-80"> Passenger App</span>
              </div>
              <p className="lg:text-sm text-xs font-normal tracking-widest lg:leading-2 leading-1 text-white w-[400px]">
                Book executive cabs, airport transfers, intercity rides, and
                complete various tasks with our diverse services and more.
              </p>
              <button
                type="button"
                className="inline-flex items-center mt-4 rounded-full px-8 lg:py-2 sm:py-1  lg:text-sm text-xs font-medium  text-white bg-[#080941] hover:bg-[#6264e2] "
              >
                Click Here
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {selectedButton === "driver" ? (
        <div className="flex flex-row items-center justify-center rounded-lg mx-32  relative overflow-hidden ">
          <div className="bg-[#353535] w-[45%] z-10 absolute right-0 top-0 min-h-full rounded-l-3xl flex items-center justify-end overflow-hidden ">
            <div className=" pr-20 space-y-2 min-h-full rounded-l-3xl">
              {" "}
              <div className="lg:text-3xl md:text-xl  sm:text-lg font-bold lg:leading-2 leading-1 text-white flex flex-row items-center">
                <span className="pr-3">
                  <img src={TrekLogo} className="h-10 object-contain" />{" "}
                </span>
                <span className=""> Driver App</span>
              </div>
              <p className="lg:text-sm text-xs font-normal tracking-widest lg:leading-2 leading-1 text-white w-[400px]">
                Sign up as a driver to accept rides, track your earnings, and
                earn incentives.
              </p>
              <button
                type="button"
                className="inline-flex items-center mt-4 rounded-full px-8 lg:py-2 sm:py-1  lg:text-sm text-xs font-medium  text-white bg-[#080941] hover:bg-[#6264e2] "
              >
                Click Here
              </button>
            </div>
          </div>
          <div className="relative ">
            <img src={DriverBanner} />
            <div className="bg-[#1517A7] w-60 min-h-full banner-design-driver inset-y-0 right-0  z-1 bg-opacity-40 rounded-bl-[70px]  "></div>
            <div className="bg-[#353535] w-60 min-h-full banner-design-driver-2 inset-y-0 right-0  z-1 rounded-bl-[70px] border-1 border-black "></div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
