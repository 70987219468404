import React, { useEffect, useState, useContext } from "react";
import Header from "./header/Header";
import { Leftbar } from "./sidebar/Leftbar";
import { Helmet } from "react-helmet";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "./footer/Footer";
import { useForm, Controller, set } from "react-hook-form";
import { axiosInstance } from "../base/api/axios.util";
import { URLConstants } from "../base/api/url.constants";
import axios, * as others from "axios";
import { ClientContext } from "../base/contexts/UserContext";
import { MultiSelect } from "react-multi-select-component";
import Loader from "react-loader";
var FormData = require("form-data");

const EditDriver = () => {
  const [record, setRecord] = useState({});
  const [loaded, setLoaded] = useState(true);
  const { client, setClient } = useContext(ClientContext);
  const [selectedLanguageData, setLanguageData] = useState([]);

  const optionsLang = [
    { label: "English", value: "English" },
    { label: "Hindi", value: "Hindi" },
    { label: "Chinese", value: "Chinese" },
    { label: "Russian", value: "Russian" },
    { label: "French", value: "French" },
    { label: "Spanish", value: "Spanish" },
  ];

  const navigate = useNavigate();

  let defaultValue = {
    name: "",
    email: "",
    gender: "",

    mobileNo: "",
    vatRegistration: false,
    nationalInsuranceNo: "",
    expiryDateDriverLicense: null,
    issueDateDriverLicense: null,
    issueDateTflDrivingLicense: null,
    expiryDatetfissueDateTflDrivingLicense: null,
  };
  var driverDataAssignment = {};
  const location = useLocation();
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  if (location.state != undefined) {
    defaultValue = location.state;
    defaultValue.expiryDateDriverLicense = location.state?.driverLicense
      ?.expiryDate
      ? new Date(location.state.driverLicense.expiryDate)
          .toISOString()
          .substring(0, 10)
      : null;
    defaultValue.issueDateDriverLicense = location.state?.driverLicense
      ?.issueDate
      ? new Date(location.state.driverLicense.issueDate)
          .toISOString()
          .substring(0, 10)
      : null;
    defaultValue.expiryDateTflDrivingLicense = location.state?.tflDrivingLicense
      ?.expiryDate
      ? new Date(location.state.tflDrivingLicense.expiryDate)
          .toISOString()
          .substring(0, 10)
      : null;
    defaultValue.issueDateTflDrivingLicense = location.state?.tflDrivingLicense
      ?.issueDate
      ? new Date(location.state.tflDrivingLicense.issueDate)
          .toISOString()
          .substring(0, 10)
      : null;
    console.log("LCOaiton", location.state);
    console.log("default value", defaultValue);
    if (location.state.mobileNo.includes("+")) {
      console.log("MOBILE", defaultValue.mobileNo);
      defaultValue.mobileNo = defaultValue.mobileNo.substring(
        3,
        defaultValue.mobileNo.length
      );
    }

    driverDataAssignment = location.state;
    console.log("LOCaiton.stea", location.state);
    console.log("Driver past", defaultValue);

    // const fields = ["driverLicense", "tflDrivingLicense", "v5Form"];
    // for (let field in fields) {
    //   if (field == "driverLicense") {
    //     console.log("ENTered", field);
    //     driverDataAssignment.driverLicenseNo = fields["driverLicenseNo"];
    //   }
    //   if (location.state[field]) {
    //     driverDataAssignment[`issueDate${capitalizeFirstLetter(field)}`] =
    //       field["issueDate"];
    //     driverDataAssignment[`expiryDate${capitalizeFirstLetter(field)}`] =
    //       field["expiryDate"];
    //   }
    // }
    // console.log("Driver past", driverDataAssignment);
  }

  console.log("default value 1234", defaultValue);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValue,
  });

  useEffect(() => {
    console.log(
      "Selected lang",
      selectedLanguageData,
      location.state?.languages
    );

    if (location.state?.languages && selectedLanguageData.length == 0) {
      // if (selectedLanguageData.length > 0) {

      // }
      let langArr = [];
      for (let i = 0; i < location?.state?.languages.length; i++) {
        let findPos = optionsLang.find((lang) =>
          lang.value == location?.state?.languages[i] ? lang : null
        );
        console.log("Find POS", findPos);
        if (findPos) {
          langArr.push(findPos);
        }
      }
      setLanguageData(langArr);

      console.log("Langauages", selectedLanguageData);
      // location.state.languages = [];
    }

    if (location.state?.languages.length == 0) {
      // selectedLanguageData.push({ label: "English", value: "English" });
      setLanguageData([{ label: "English", value: "English" }]);
    }

    if (!client) {
      navigate("/");
    } else if (
      client?.logInType == "Driver"
      // client?.logInType !== "OPERATOR"
    ) {
      navigate("/notfound");
    }
  }, []);
  return (
    <>
      <Header isAdmin />
      <div className="grid lg:grid-cols-6">
        <Leftbar />
        <Helmet>
          <title>Edit Driver - Treklers - Admin</title>
          <meta name="description" content="Login page" />
        </Helmet>
        <div className="p-8 lg:col-span-5">
          <div className="col-md-12">
            <div className="table-responsive">
              <div className="p-4 grid grid-cols-2 pb-8">
                <div>
                  <span className="text-2xl font-bold">Edit Driver</span>
                  <span className="font-bold p-4">
                    <Link to="/">
                      <i /> Home
                    </Link>
                  </span>
                </div>
                <div className="col-auto float-end ml-auto"></div>
              </div>
              <form
                onSubmit={handleSubmit((data) => {
                  setLoaded(false);
                  console.log("DATA VALUE", data);
                  var formData = new FormData();
                  formData.append("_id", location.state._id);

                  if (data.tflDrivingLicense[0]?.size) {
                    formData.append(
                      "tflDrivingLicense",
                      data.tflDrivingLicense[0]
                    );
                  }
                  formData.append(
                    "nationalInsuranceNo",
                    data.nationalInsuranceNo
                  );
                  formData.append("vatRegistration", data.vatRegistration);

                  formData.append("driverLicenseNo", data.driverLicenseNo);
                  formData.append(
                    "tflDrivingLicenseNo",
                    data.tflDrivingLicenseNo
                  );
                  if (data.driverLicense[0]?.size) {
                    formData.append("driverLicense", data.driverLicense[0]);
                  }
                  if (data.expiryDateDriverLicense) {
                    formData.append(
                      "expiryDateDriverLicense",
                      data.expiryDateDriverLicense
                    );
                  }
                  if (data.expiryDateTflDrivingLicense) {
                    formData.append(
                      "expiryDateTflDrivingLicense",
                      data.expiryDateTflDrivingLicense
                    );
                  }
                  if (data.issueDateDriverLicense) {
                    formData.append(
                      "issueDateDriverLicense",
                      data.issueDateDriverLicense
                    );
                  }
                  if (data.issueDateTflDrivingLicense) {
                    formData.append(
                      "issueDateTflDrivingLicense",
                      data.issueDateTflDrivingLicense
                    );
                  }
                  //disabled driver image upload
                  console.log("Image", data.imageUrl);
                  if (data.imageUrl[0]?.size) {
                    formData.append("imageUrl", data.imageUrl[0]);
                  }

                  formData.append(
                    "languages",
                    JSON.stringify(selectedLanguageData.map((val) => val.value))
                  );

                  formData.append("issueDateV5Form", data.issueDateV5Form);
                  //test commit
                  var config = {
                    method: "post",
                    url: URLConstants.updateDriverAndUpload(),
                    data: formData,
                  };

                  axios(config)
                    .then(function (response) {
                      reset();
                      setLoaded(true);
                      navigate("/driverlist");
                    })
                    .catch(function (error) {
                      setLoaded(true);
                    });
                })}
              >
                <div className="grid grid-cols-2 gap-8 gap-y-4 mb-8">
                  <div>
                    <label>Name</label>
                    <input value={defaultValue.name} />
                  </div>

                  <div>
                    <label>Email</label>
                    <input type="email" value={defaultValue.email} />
                    {errors.email && <p>Email field is required</p>}
                  </div>

                  <div>
                    <label>Gender</label>
                    <div className="w-full border border-1 border-dark rounded p-2">
                      <select
                        className="w-full border-0 outline-0"
                        value={defaultValue.gender}
                      >
                        <option value="Female">Female</option>
                        <option value="Male">Male</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    {errors.gender && <p>Gender field is required</p>}
                  </div>

                  <div>
                    <label>VAT Registered</label>
                    <div className="w-full border border-1 border-dark rounded p-2">
                      <select
                        className="w-full border-0 outline-0"
                        defaultValue={defaultValue.vatRegistration}
                        {...register("vatRegistration")}
                      >
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>
                    {errors.vatRegistration && (
                      <p>VAT Registration field is required</p>
                    )}
                  </div>

                  <div>
                    <label>Mobile Number</label>
                    <input type="number" value={defaultValue.mobileNo} />
                    {errors.mobileNo && <p>Mobile Number is required</p>}
                  </div>

                  <div>
                    <label>National Insurance Number</label>
                    <input
                      type="text"
                      defaultValue={defaultValue.nationalInsuranceNo}
                      {...register("nationalInsuranceNo", { required: true })}
                    />
                    {errors.nationalInsuranceNo && (
                      <p>National Insurance Number is required</p>
                    )}
                  </div>
                  <div className="margin">
                    <label>Languages</label>
                    <MultiSelect
                      className="multi-select"
                      options={optionsLang}
                      value={selectedLanguageData}
                      onChange={setLanguageData}

                      // {...register("language", { required: 'Language is required' })}
                    />
                    <div className="text-xs text-red-500">
                      {/* {errors.language && <span>{errors.language.message}</span>} */}
                    </div>
                  </div>
                </div>

                <div className="grid  gap-8 gap-y-4 mb-8">
                  <div className="flex flex-row justify-between gap-4">
                    <div className="w-1/3">
                      <label>Driver License</label>
                      <input
                        type="file"
                        // ref={register}
                        placeholder="Driver License"
                        {...register("driverLicense", {})}
                      />
                      {errors.driverLicense && (
                        <p>Driver license is required</p>
                      )}
                    </div>

                    <div className="w-1/3 ">
                      <label>Issue Date</label>
                      <input
                        type="date"
                        className="pt-[12px] pb-[14px]"
                        placeholder="Issue Date"
                        defaultValue={
                          driverDataAssignment?.driverLicense?.issueDate
                            ? // new Date(
                              driverDataAssignment?.driverLicense?.issueDate
                            : // )
                              null
                        }
                        max={new Date().toISOString().split("T")[0]}
                        {...register("issueDateDriverLicense", {})}
                      />
                      {errors.issueDateDriverLicense && (
                        <p>Issue Date is required</p>
                      )}
                    </div>
                    <div className="w-1/3 ">
                      <label>Expiry Date</label>
                      <input
                        type="date"
                        // ref={register}
                        className="pt-[12px] pb-[14px]"
                        placeholder="Expiry Date"
                        defaultValue={
                          driverDataAssignment?.driverLicense?.expiryDate
                            ? // new Date(
                              driverDataAssignment?.driverLicense?.expiryDate
                            : // )
                              null
                        }
                        min={new Date().toISOString().split("T")[0]}
                        {...register("expiryDateDriverLicense", {})}
                      />
                      {errors.expiryDateDriverLicense && (
                        <p>Expiry Date is required</p>
                      )}
                    </div>
                    <div className="w-1/3">
                      <label>License Number</label>
                      <input
                        type="text"
                        // ref={register}
                        className="pt-[12px] pb-[14px]"
                        placeholder="License Number"
                        defaultValue={
                          driverDataAssignment?.driverLicense?.driverLicenseNo
                            ? driverDataAssignment?.driverLicense
                                ?.driverLicenseNo
                            : null
                        }
                        {...register("driverLicenseNo", {})}
                      />
                      {errors.driverLicense && (
                        <p>License Number is required</p>
                      )}
                    </div>
                  </div>
                  {/* //disabling driver profile picture input */}
                  <div className="flex flex-row gap-4">
                    <div className="w-3/4">
                      <label>Profile Picture</label>
                      <input
                        type="file"
                        // ref={register}
                        accept="image/png, image/jpeg"
                        placeholder="Profile Picture"
                        {...register("imageUrl", {})}
                      />
                      {errors.imageUrl && <p>Profile Picture is required</p>}
                    </div>
                  </div>
                  <div className="flex flex-row gap-4">
                    <div className="w-3/4">
                      <label>TFL Driving License</label>
                      <input
                        type="file"
                        // ref={register}
                        placeholder="TFL Driving License"
                        {...register("tflDrivingLicense", {})}
                      />
                      {errors.tflDrivingLicense && (
                        <p>TFL Driving License is required</p>
                      )}
                    </div>
                    <div className="w-1/3 ">
                      <label>Issue Date</label>
                      <input
                        type="date"
                        // ref={register}
                        className="pt-[12px] pb-[14px]"
                        defaultValue={
                          driverDataAssignment?.tflDrivingLicense?.issueDate
                            ? new Date(
                                driverDataAssignment?.tflDrivingLicense?.issueDate
                              ).toISOString()
                            : null
                        }
                        placeholder="Issue Date"
                        max={new Date().toISOString().split("T")[0]}
                        {...register("issueDateTflDrivingLicense", {})}
                      />
                      {errors.issueDateTflDrivingLicense && (
                        <p>Issue Date is required</p>
                      )}
                    </div>
                    <div className="w-1/4">
                      <label>Expiry Date</label>
                      <input
                        type="date"
                        // ref={register}
                        className="pt-[12px] pb-[14px]"
                        defaultValue={
                          driverDataAssignment?.tflDrivingLicense?.expiryDate
                            ? new Date(
                                driverDataAssignment?.tflDrivingLicense?.expiryDate
                              )
                            : null
                        }
                        placeholder="Expiry Date"
                        min={new Date().toISOString().split("T")[0]}
                        {...register("expiryDateTflDrivingLicense", {})}
                      />
                      {errors.tflDrivingLicense && (
                        <p>Expiry Date is required</p>
                      )}
                    </div>
                    <div className="w-1/3">
                      <label>TFL Driving License Number</label>
                      <input
                        type="text"
                        // ref={register}
                        className="pt-[12px] pb-[14px]"
                        placeholder="License Number"
                        defaultValue={
                          driverDataAssignment?.tflDrivingLicense
                            ?.tflDrivingLicenseNo
                            ? driverDataAssignment?.tflDrivingLicense
                                ?.tflDrivingLicenseNo
                            : null
                        }
                        {...register("tflDrivingLicenseNo", {})}
                      />
                      {errors.driverLicense && (
                        <p>TFL Driving License Number is required</p>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <input className="w-auto m-auto" type="submit" />
                </div>
                <div className="spinner">
                  <Loader
                    loaded={loaded}
                    lines={13}
                    length={20}
                    width={10}
                    radius={30}
                    corners={1}
                    rotate={0}
                    direction={1}
                    color="#000"
                    speed={1}
                    trail={60}
                    shadow={false}
                    hwaccel={false}
                    className="spinner"
                    zIndex={2e9}
                    top="50%"
                    left="50%"
                    scale={1.0}
                    loadedClassName="loadedContent"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EditDriver;
