import { useState, useContext, useEffect } from "react";
import { Leftbar } from "./sidebar/Leftbar";
import { Helmet } from "react-helmet";
import axios, * as others from "axios";
import { useForm, Controller } from "react-hook-form";
import { AiOutlineCamera } from "react-icons/ai";
import { axiosInstance } from "../base/api/axios.util";
import toast, { Toaster } from "react-hot-toast";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { AuthTokenHandler } from "../base/api/auth-token.util";
import { ClientContext } from "../base/contexts/UserContext";

import PassengerInfo from "../base/utils/PassengerInfo";
import { URLConstants } from "../base/api/url.constants";
var FormData = require("form-data");
// import Loader from "react-loader";
export default function EditProfile(props) {
  const [loaded, setLoaded] = useState(true);
  const { client, setClient } = useContext(ClientContext);

  const [profileImage, setProfileImage] = useState(undefined);
  const [passengerInfo, setPassengerInfo] = useState({});
  let valueAssignment;
  if (client?.loginData?.client) {
    valueAssignment = client.loginData.client;
  }
  console.log("VALUE ASSIGNMENT", valueAssignment);
  const {
    handleSubmit,
    register,
    control,
    setError,
    clearErrors,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: valueAssignment,
  });

  const onError = (errors) => console.log(errors);
  console.log("CLIENT STORED", client.loginData.id);
  async function updateClient() {
    let passInfo = await PassengerInfo(client?.loginData?.id);
    if (passInfo) {
      setPassengerInfo(passInfo);
      let newClient = client;
      newClient.loginData.client = passInfo;
      setClient(newClient);
    } else {
      console.log("eErr", passInfo);
      toast.error("Could not fetch Passenger Login info");
    }
  }

  useEffect(() => {
    if (client?.loginData?.client?.profilePicUrl) {
      setProfileImage(client?.loginData?.client?.profilePicUrl);
    }
  }, []);
  console.log("Client", client);

  const onSubmit = async (data) => {
    setLoaded(false);

    if (data.corporateEmail) {
      data.corporateEmail = [data.corporateEmail];
    }
    console.log("Data 123", data);
    var formData = new FormData();

    if (data.profile_image[0]?.size) {
      console.log("Update profile pic", data.profile_image[0]);
      formData.append("profilePic", data.profile_image[0]);
      // formData.append("profilePic", {
      //   name: data.profile_image[0].name,
      //   uri: data.profile_image[0],
      //   type: data.profile_image[0].type,
      // });
      // formData.append("profilePic", data.profile_image.files[0], "gloves.jpeg");

      let headers = {};
      const accessToken = AuthTokenHandler.getAccessToken();

      headers["Authorization"] = `Bearer ${accessToken}`;
      headers["content-type"] = `multipart/form-data`;

      var requestOptions = {
        // method: "POST",
        // headers: headers,
        // body: formData,
        // redirect: "follow",
        // // url: `http://34.228.196.145:3000/v1/passenger/upload/${client?.loginData?.id}`,
        // url: `${URLConstants.BOOKING_URL}/v1/passenger/upload/${client?.loginData?.id}`,
        method: "post",
        url: `${URLConstants.BOOKING_URL}/v1/passenger/upload/${client?.loginData?.id}`,
        headers: {
          headers: { "content-type": "multipart/form-data" },
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
      };

      axios(requestOptions)
        .then((response) => {
          setLoaded(true);
          console.log("Response after uploading image", response);
        })
        .catch((err) => {
          setLoaded(true);
          console.log(err);
        });
    }
    console.log("DATA SENT IN form", data);
    axiosInstance
      .post(
        `${URLConstants.BOOKING_URL}/v1/passenger/${client.loginData.id}`,
        data
      )
      .then((response) => {
        toast.success("Successfully updated profile!!");
        console.log("Response from edit profile", response);
        setLoaded(true);
        let newClient = client;
        newClient.loginData.client = data;
        setClient(newClient);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Could not update profile. Please try again later!!");
        setLoaded(true);
      });
  };

  return (
    <>
      <Header isAdmin />
      <div className="grid lg:grid-cols-6">
        <Leftbar />
        <Helmet>
          <title>Edit Profile - Treklers - Admin</title>
          <meta name="description" content="Login page" />
        </Helmet>

        <div className="p-8 lg:col-span-5">
          <div className="col-md-12">
            <div className="table-responsive">
              <div className="p-4 grid grid-cols-2 pb-8 mb-3">
                <div>
                  <span className="text-2xl font-bold">Edit Profile</span>
                  <span className="font-bold p-4">
                    <Link to="/">Home</Link>{" "}
                  </span>
                </div>
              </div>

              {/* <div className="flex flex-col justify-center items-center"> */}
              <form onSubmit={handleSubmit(onSubmit, onError)} className="">
                <div className="ml-4 mt-4 mb-8">
                  <img
                    className="rounded-full h-32 w-32"
                    src={
                      profileImage
                        ? profileImage
                        : `https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png`
                    }
                  />

                  <span className=" rounded-full h-10 w-10 absolute flex justify-center items-center translate-x-24 -translate-y-10">
                    <label
                      htmlFor="fileInput"
                      className="rounded-full h-10 w-10  bg-[#404298]  pb-0.5 flex justify-center items-center cursor-pointer"
                    >
                      <input
                        type="file"
                        id="fileInput"
                        placeholder=""
                        className="hidden"
                        {...register("profile_image", {
                          onChange: (e) => {
                            setProfileImage(
                              URL.createObjectURL(e.target.files[0])
                            );
                            console.log("Ankit");
                          },
                        })}
                        // onChange={handleFileChange}
                      />
                      <AiOutlineCamera className="text-white  scale-110" />
                    </label>
                  </span>
                </div>

                <div className="grid grid-cols-2 gap-8 gap-y-4 mb-8">
                  <div className="mr-2 ml-2 ">
                    <Controller
                      name="firstName"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <input
                          type="text"
                          className="rounded-lg w-11/12"
                          placeholder="First Name"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                  </div>
                  <div className="">
                    <Controller
                      name="lastName"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <input
                          type="text"
                          className="rounded-lg w-11/12"
                          placeholder="Last Name"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-8 gap-y-4 mb-8">
                  <div className="mr-2 ml-2 ">
                    <Controller
                      name="personalEmail"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <input
                          type="email"
                          className="rounded-lg w-11/12"
                          placeholder="Personal Email"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                  </div>
                  <div className="">
                    <Controller
                      name="corporateEmail"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <input
                          type="email"
                          className="rounded-lg w-11/12"
                          placeholder="Corporate Email"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-8 gap-y-4 mb-8">
                  <div className="mr-2 ml-2 ">
                    <Controller
                      name="gender"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <input
                          type="text"
                          className="rounded-lg w-11/12"
                          placeholder="Gender"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                  </div>
                  <div className="mr-2 ml-2 ">
                    <Controller
                      name="mobileNo"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <input
                          type="text"
                          className="rounded-lg w-11/12"
                          placeholder="Mobile Number"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                  </div>
                </div>
                {/* <div className="flex justify-center">
              <button
                onClick={() => console.log("Handle submit")}
                type="submit"
              >
                Edit
              </button>
            </div> */}

                <div className="pb-4 text-center mt-2">
                  {/* <button data-dismiss="modal" type="submit" /> */}
                  <button
                    type="submit"
                    className="w-auto m-auto  text-white rounded px-4 py-2"
                    data-bs-dismiss="modal"
                  >
                    Update
                  </button>
                </div>
              </form>
              {/* </div> */}
              <div className="spinner">
                {/* <Loader
             loaded={loaded}
             lines={13}
             length={20}
             width={10}
             radius={30}
             corners={1}
             rotate={0}
             direction={1}
             color="#000"
             speed={1}
             trail={60}
             shadow={false}
             hwaccel={false}
             className="spinner"
             zIndex={2e9}
             top="50%"
             left="50%"
             scale={1.0}
             loadedClassName="loadedContent"
           /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
