import React from "react";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import Hero from "./shared/Hero";

export default function BlogPage() {
  return (
    <div>
      <Header isAdmin />
      <Hero title={"Our Blog"} />
      <div className="row ml-10 p-5 bg-opacity-75">
        <div className="col-4 pb-5">
          <div>
            <div className='w-96 h-96 bg-[url("images/mati3gl2.jpg")] bg-cover bg-center'>
              {" "}
            </div>
          </div>
          <div>
            <p className="text-blue-700 pt-3">November 12, 2016</p>
            <p className="text-2xl text-slate-700 font-medium py-1">
              lorem ipsum is simply dummy text of the printing and typesetting.
            </p>
            <p className="text-xl text-slate-500 font-medium">
              Reference more about lorem ipsum. lorem ipsum is simply dummy text
              of.
            </p>
          </div>
        </div>{" "}
        <div className="col-4 ">
          <div>
            <div className='w-96 h-96 bg-[url("images/zjtceb6r.jpg")] bg-cover bg-center'>
              {" "}
            </div>{" "}
          </div>
          <div>
            <p className="text-blue-700 pt-3">November 12, 2016</p>
            <p className="text-2xl text-slate-700 font-medium py-1">
              lorem ipsum is simply dummy text of the printing and typesetting.
            </p>
            <p className="text-xl text-slate-500 font-medium">
              Reference more about lorem ipsum. lorem ipsum is simply dummy text
              of.
            </p>
          </div>
        </div>{" "}
        <div className="col-4 ">
          <div>
            <div className='w-96 h-96 bg-[url("images/547y7xel.jpg")] bg-cover bg-center'>
              {" "}
            </div>{" "}
          </div>
          <div>
            <p className="text-blue-700 pt-3">November 12, 2016</p>
            <p className="text-2xl text-slate-700 font-medium py-1">
              lorem ipsum is simply dummy text of the printing and typesetting.
            </p>
            <p className="text-xl text-slate-500 font-medium">
              Reference more about lorem ipsum. lorem ipsum is simply dummy text
              of.
            </p>
          </div>
        </div>{" "}
        <div className="col-4 ">
          <div>
            <div className='w-96 h-96 bg-[url("images/9ncdbrot.jpg")] bg-cover bg-center'>
              {" "}
            </div>{" "}
          </div>
          <div>
            <p className="text-blue-700 pt-3">November 12, 2016</p>
            <p className="text-2xl text-slate-700 font-medium py-1">
              lorem ipsum is simply dummy text of the printing and typesetting.
            </p>
            <p className="text-xl text-slate-500 font-medium">
              Reference more about lorem ipsum. lorem ipsum is simply dummy text
              of.
            </p>
          </div>
        </div>{" "}
        <div className="col-4 ">
          <div>
            <div className='w-96 h-96 bg-[url("images/r268vcub.jpg")] bg-cover bg-center'>
              {" "}
            </div>{" "}
          </div>
          <div>
            <p className="text-blue-700 pt-3">November 12, 2016</p>
            <p className="text-2xl text-slate-700 font-medium py-1">
              lorem ipsum is simply dummy text of the printing and typesetting.
            </p>
            <p className="text-xl text-slate-500 font-medium">
              Reference more about lorem ipsum. lorem ipsum is simply dummy text
              of.
            </p>
          </div>
        </div>{" "}
        <div className="col-4 ">
          <div>
            <div className='w-96 h-96 bg-[url("images/klyum5w0.jpg")] bg-cover bg-center'>
              {" "}
            </div>{" "}
          </div>
          <div>
            <p className="text-blue-700 pt-3">November 12, 2016</p>
            <p className="text-2xl text-slate-700 font-medium py-1">
              lorem ipsum is simply dummy text of the printing and typesetting.
            </p>
            <p className="text-xl text-slate-500 font-medium">
              Reference more about lorem ipsum. lorem ipsum is simply dummy text
              of.
            </p>
          </div>
        </div>
      </div>
      <div>
        {" "}
        <div className='w-96 h-10 bg-[url("C:\Users\Harsha\Downloads\Group 156402@2x.png")] '>
          {" "}
        </div>{" "}
      </div>

      <Footer />
    </div>
  );
}
