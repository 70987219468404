import React, { useState, useEffect, useContext } from "react";
import Header from "./header/Header";
import { Leftbar } from "./sidebar/Leftbar";
import { Helmet } from "react-helmet";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "./footer/Footer";
import { useForm, Controller, set } from "react-hook-form";
import { URLConstants } from "../base/api/url.constants";
import axios, * as others from "axios";
import Loader from "react-loader";
import { ClientContext } from "../base/contexts/UserContext";
import { axiosInstance } from "../base/api/axios.util";
import { MultiSelect } from "react-multi-select-component";
var FormData = require("form-data");

const AddVehicle = () => {
  const [record, setRecord] = useState({});
  const navigate = useNavigate();
  const { client, setClient } = useContext(ClientContext);
  const [loaded, setLoaded] = useState(true);
  const [vehilceNameTypes, setVehicleTypeNames] = useState([]);
  const [selectedVehicleName, setSelectedVehicleName] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  var defaultValue = {
    vehicleNo: "",
    vehicleName: "",
    vehicleType: 1,
    noOfSeats: "",
    noOfBuggies: "",
  };
  var driverDataAssignment = {};
  const location = useLocation();

  if (location.state != undefined) {
    console.log(":Ocation value", location.state);
    defaultValue = location.state;
    console.log(
      "Date value",
      (defaultValue.expiryDateRoadTax = new Date(
        location.state.roadTax?.expiryDate
      ))
    );

    defaultValue.expiryDateRoadTax = location.state.roadTax?.expiryDate
      ? new Date(location.state.roadTax?.expiryDate)
          .toISOString()
          .substring(0, 10)
      : new Date().toISOString().substring(0, 10);
    defaultValue.issueDateRoadTax = location.state.roadTax?.issueDate
      ? new Date(location.state.roadTax?.issueDate)
          .toISOString()
          .substring(0, 10)
      : new Date().toISOString().substring(0, 10);

    defaultValue.expiryDateV5Form = location.state.v5Form?.expiryDate
      ? new Date(location.state.v5Form?.expiryDate)
          .toISOString()
          .substring(0, 10)
      : new Date().toISOString().substring(0, 10);
    defaultValue.issueDateV5Form = location.state.v5Form?.issueDate
      ? new Date(location.state.v5Form?.issueDate)
          .toISOString()
          .substring(0, 10)
      : new Date().toISOString().substring(0, 10);

    defaultValue.expiryDateInsurance = location.state.insurance?.expiryDate
      ? new Date(location.state.insurance?.expiryDate)
          .toISOString()
          .substring(0, 10)
      : new Date().toISOString().substring(0, 10);
    defaultValue.issueDateInsurance = location.state.insurance?.issueDate
      ? new Date(location.state.insurance?.issueDate)
          .toISOString()
          .substring(0, 10)
      : new Date().toISOString().substring(0, 10);
    defaultValue.expiryDateRoadTax = location.state.roadTax?.expiryDate
      ? new Date(location.state.roadTax?.expiryDate)
          .toISOString()
          .substring(0, 10)
      : new Date().toISOString().substring(0, 10);
    defaultValue.issueDateRoadTax = location.state.roadTax?.issueDate
      ? new Date(location.state.roadTax?.issueDate)
          .toISOString()
          .substring(0, 10)
      : new Date().toISOString().substring(0, 10);

    defaultValue.expiryDateTflVehicleLicense = location.state.tflVehicleLicense
      ?.expiryDate
      ? new Date(location.state.tflVehicleLicense?.expiryDate)
          .toISOString()
          .substring(0, 10)
      : new Date().toISOString().substring(0, 10);
    defaultValue.issueDateTflVehicleLicense = location.state.tflVehicleLicense
      ?.issueDate
      ? new Date(location.state.tflVehicleLicense.issueDate)
          .toISOString()
          .substring(0, 10)
      : new Date().toISOString().substring(0, 10);
    defaultValue.expiryDateMot = location.state.mot?.expiryDate
      ? new Date(location.state.mot?.expiryDate).toISOString().substring(0, 10)
      : new Date().toISOString().substring(0, 10);
    defaultValue.issueDateMot = location.state.mot?.issueDate
      ? new Date(location.state.mot?.issueDate).toISOString().substring(0, 10)
      : new Date().toISOString().substring(0, 10);

    driverDataAssignment = location.state;
  }
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValue,
  });

  useEffect(() => {
    // setRecord(driverDataAssignment);
    if (!client) {
      navigate("/");
    }
    axiosInstance
      .get(URLConstants.getVehicleTypes())
      .then((res) => {
        console.log("Response from vehicle types", res);
        let arr = [],
          selectedArr = [];

        console.log("Arr", arr);

        for (let i = 0; i < res.length; i++) {
          if (
            defaultValue?.vehicleType &&
            defaultValue?.vehicleType !== res[i].vehicleType
          ) {
            arr.push({ value: res[i].vehicleType, label: res[i].name });
          }
          if (!defaultValue?.vehicleType) {
            arr.push({ value: res[i].vehicleType, label: res[i].name });
          }
          if (
            location.state?.vehicleTripTypes?.length > 0 &&
            location.state?.vehicleTripTypes.includes(res[i].vehicleType)
          ) {
            selectedArr.push({ value: res[i].vehicleType, label: res[i].name });
          }
        }
        setSelectedVehicleName(selectedArr);
        setVehicleTypeNames(arr);

        setVehicleTypes(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Header isAdmin />
      <div className="grid lg:grid-cols-6">
        <Leftbar />
        <Helmet>
          <title>Add Vehicle - Treklers - Admin</title>
          <meta name="description" content="Login page" />
        </Helmet>
        <div className="p-8 lg:col-span-5">
          <div className="col-md-12">
            <div className="table-responsive">
              <div className="p-4 grid grid-cols-2 pb-8">
                <div>
                  <span className="text-2xl font-bold">Add Vehicle</span>
                </div>
                <div className="col-auto float-end ml-auto"></div>
              </div>
              <form
                onSubmit={handleSubmit((data) => {
                  setLoaded(false);
                  // alert(JSON.stringify(data));
                  var formData = new FormData();
                  if (location?.state?.email) {
                    formData.append("_id", location.state._id);
                  } else {
                    formData.append("_id", location.state.driverId);
                  }

                  formData.append("vehicleNo", data.vehicleNo);
                  formData.append("vehicleName", data.vehicleName);
                  formData.append("noOfSeats", Number(data.noOfSeats));
                  formData.append("noOfBuggies", Number(data.noOfBuggies));
                  formData.append("vehicleType", Number(data.vehicleType));
                  if (data.insurance[0]?.size) {
                    formData.append("insurance", data.insurance[0]);
                  }
                  if (data.mot[0]?.size) {
                    formData.append("mot", data.mot[0]);
                  }
                  if (data.vehicleImages[0]?.size) {
                    formData.append("vehicleImages", data.vehicleImages[0]);
                  }
                  if (data.tflVehicleLicense[0]?.size) {
                    formData.append(
                      "tflVehicleLicense",
                      data.tflVehicleLicense[0]
                    );
                  }
                  if (data.roadTax[0]?.size) {
                    formData.append("roadTax", data.roadTax[0]);
                  }
                  if (data.v5Form[0]?.size) {
                    formData.append("v5Form", data.v5Form[0]);
                  }
                  formData.append(
                    "expiryDateTflVehicleLicense",
                    data.expiryDateTflVehicleLicense
                  );
                  formData.append("expiryDateRoadTax", data.expiryDateRoadTax);
                  formData.append("expiryDateMot", data.expiryDateMot);
                  formData.append("expiryDateV5Form", data.expiryDateV5Form);
                  formData.append(
                    "expiryDateInsurance",
                    data.expiryDateInsurance
                  );

                  formData.append(
                    "issueDateTflVehicleLicense",
                    data.issueDateTflVehicleLicense
                  );
                  formData.append("issueDateRoadTax", data.issueDateRoadTax);
                  formData.append("issueDateMot", data.issueDateMot);
                  formData.append("issueDateV5Form", data.issueDateV5Form);

                  formData.append(
                    "issueDateInsurance",
                    data.issueDateInsurance
                  );

                  //adding default vehicle Type to vehicleTyipTypes array
                  let finalVehicleTripType = selectedVehicleName.map(
                    (selVeh) => selVeh.value
                  );
                  finalVehicleTripType.push(data.vehicleType);
                  formData.append(
                    "vehicleTripTypes",
                    JSON.stringify(finalVehicleTripType)
                  );

                  var config = {
                    method: "post",
                    url: URLConstants.assignReassignVehicle(),
                    headers: {
                      headers: { "content-type": "multipart/form-data" },
                    },
                    data: formData,
                  };

                  axios(config)
                    .then(function (response) {
                      setLoaded(true);
                      reset();

                      navigate("/vehiclelist");
                    })
                    .catch(function (error) {
                      setLoaded(true);
                    });
                })}
              >
                <div className="grid grid-cols-2 gap-8 gap-y-4 mb-8">
                  <div>
                    <label>Vehicle No.</label>
                    <input
                      type="string"
                      {...register("vehicleNo", { required: true })}
                    />
                    {errors.vehicleNo && <p>Vehicle No. is required</p>}
                  </div>

                  <div>
                    <label>Vehicle Name</label>
                    <input
                      type="string"
                      {...register("vehicleName", { required: true })}
                    />
                    {errors.vehicleName && <p>Vehicle Name is required</p>}
                  </div>

                  {/* <div>
                    <label>Vehicle Type</label>
                    <input
                      type="number"
                      {...register("vehicleType", { required: true })}
                    />
                    {errors.vehicleType && (
                      <p>Vehicle Type field is required</p>
                    )}
                  </div> */}

                  {vehicleTypes?.length > 0 && (
                    <div>
                      <label>Original Vehicle Type</label>
                      <div className="w-full border border-1 border-dark rounded p-2">
                        <select
                          className="w-full border-0 outline-0"
                          defaultValue={Number(defaultValue.vehicleType)}
                          {...register("vehicleType", {
                            required: true,
                            onChange: (e) => {
                              console.log("Vehicl Before types", vehicleTypes);
                              let arr = [];
                              vehicleTypes.map((veh) => {
                                console.log("e.target.value", e.target.value);
                                if (veh.vehicleType != e.target.value) {
                                  arr.push({
                                    value: veh.vehicleType,
                                    label: veh.name,
                                  });
                                }
                              });
                              console.log("Vehicl After types", vehicleTypes);
                              // console.log(
                              //   "Vehicle Type Names",
                              //   arr,
                              //   e.target.value
                              // );
                              console.log("Arr in onchange", arr);
                              setVehicleTypeNames(arr);
                            },
                          })}
                        >
                          {vehicleTypes.map((vehicleType) => (
                            <option value={vehicleType?.vehicleType}>
                              {vehicleType?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {errors.vehicleType && (
                        <p>Vehicle Type field is required</p>
                      )}
                    </div>
                  )}
                  <div className="margin">
                    <label>Optional Vehicle Trip Type</label>
                    <MultiSelect
                      className="multi-select"
                      options={vehilceNameTypes}
                      value={selectedVehicleName}
                      onChange={setSelectedVehicleName}

                      // {...register("language", { required: 'Language is required' })}
                    />
                    <div className="text-xs text-red-500">
                      {/* {errors.language && <span>{errors.language.message}</span>} */}
                    </div>
                  </div>

                  <div>
                    <label>No Of Seats</label>
                    <input
                      type="number"
                      {...register("noOfSeats", { required: true })}
                    />
                    {errors.noOfSeats && <p>No. Of Seats are required</p>}
                  </div>

                  <div className="">
                    <label>No. Of Buggies</label>
                    <input
                      type="number"
                      {...register("noOfBuggies", { required: true })}
                    />
                    {errors.noOfBuggies && <p>No Of Buggies are required</p>}
                  </div>

                  {/* <div>
                    <label>MOT Registration</label>
                    <input
                      type="file"
                      placeholder="MOT"
                      {...register("mot", { required: true })}
                    />
                    {errors.mot && <p>MOT field is required</p>}
                  </div>
                  <div>
                    <label>Expiry Date</label>
                    <input
                      type="date"
                      // ref={register}
                      placeholder="Expiry Date"
                      {...register("expiryDateMot", {
                        required: true,
                      })}
                    />
                    {errors.expiryDateMot && <p>MOT is required</p>}
                  </div>
                  <div>
                    <label>Road Tax</label>
                    <input
                      type="file"
                      placeholder="Image URL"
                      {...register("roadTax", {
                        required: true,
                      })}
                    />
                    {errors.roadTax && <p>Road text field is required</p>}
                  </div>
                  <div>
                    <label>Expiry Date</label>
                    <input
                      type="date"
                      // ref={register}
                      placeholder="Expiry Date"
                      {...register("expiryDateRoadTax", {
                        required: true,
                      })}
                    />
                    {errors.expiryDateRoadTax && <p>Road Tax is required</p>}
                  </div>
                  <div>
                    <label>PCO</label>
                    <input
                      type="file"
                      placeholder="Image URL"
                      {...register("pco", { required: true })}
                    />
                    {errors.pco && <p>PCO field is required</p>}
                  </div>

                  <div>
                    <label>Expiry Date</label>
                    <input
                      type="date"
                      // ref={register}
                      placeholder="Expiry Date"
                      {...register("expiryDatePco", {
                        required: true,
                      })}
                    />
                    {errors.expiryDatePco && <p>PCO is required</p>}
                  </div>
                  <div>
                    <label>Insurance</label>
                    <input
                      type="file"
                      placeholder="Image URL"
                      {...register("insurance", {
                        required: true,
                      })}
                    />
                    {errors.insurance && <p>Insurance field is required</p>}
                  </div>
                  <div>
                    <label>Expiry Date</label>
                    <input
                      type="date"
                      // ref={register}
                      placeholder="Expiry Date"
                      {...register("expiryDateInsurance", {
                        required: true,
                      })}
                    />
                    {errors.expiryDateInsurance && <p>Insurance is required</p>}
                  </div>
                  <div>
                    <label>Add Document</label>
                    <input
                      type="file"
                      placeholder="Image URL"
                      {...register("otherDoc", { required: true })}
                    />
                    {errors.otherDoc && <p>Add document field is required</p>}
                  </div>
                  <div>
                    <label>Expiry Date</label>
                    <input
                      type="date"
                      // ref={register}
                      placeholder="Expiry Date"
                      {...register("expiryDateOtherDoc", {
                        required: true,
                      })}
                    />
                    {errors.expiryDateOtherDoc && (
                      <p>Other Document is required</p>
                    )}
                  </div> */}
                </div>

                <div className="flex flex-row justify-between gap-4 ">
                  <div className="w-3/4">
                    <label>Vehicle Image</label>
                    <input
                      type="file"
                      // ref={register}
                      accept="image/png, image/jpeg"
                      placeholder="Vehicle Image"
                      {...register("vehicleImages", {})}
                    />
                    {errors.vehicleImages && <p>Vehicle Images is required</p>}
                  </div>
                </div>

                <div className="flex flex-row justify-between gap-4 ">
                  <div className="w-3/4">
                    <label>V5 Form</label>
                    <input
                      type="file"
                      // ref={register}
                      placeholder="V5 Form"
                      {...register("v5Form", {})}
                    />
                    {errors.v5Form && <p>V5 Form is required</p>}
                  </div>

                  <div className="w-1/3 ">
                    <label>Issue Date</label>
                    <input
                      type="date"
                      // ref={register}
                      className="pt-[12px] pb-[14px]"
                      max={new Date().toISOString().split("T")[0]}
                      placeholder="Issue Date"
                      {...register("issueDateV5Form", {})}
                    />
                    {errors.issueDateV5Form && <p>Issue Date is required</p>}
                  </div>
                  <div className="w-1/4">
                    <label>Expiry Date</label>
                    <input
                      type="date"
                      min={new Date().toISOString().split("T")[0]}
                      // ref={register}
                      className="pt-[12px] pb-[14px]"
                      placeholder="Expiry Date"
                      {...register("expiryDateV5Form", {})}
                    />
                    {errors.tflDrivingLicense && <p>Expiry Date is required</p>}
                  </div>
                </div>
                <div className="flex flex-row justify-between gap-4 mt-4">
                  <div className="w-3/4">
                    <label>Road Tax</label>
                    <input
                      type="file"
                      // ref={register}
                      placeholder="Road Tax"
                      {...register("roadTax", {})}
                    />
                    {errors.roadTax && <p>Road Tax is required</p>}
                  </div>

                  <div className="w-1/3 ">
                    <label>Issue Date</label>
                    <input
                      type="date"
                      // ref={register}
                      className="pt-[12px] pb-[14px]"
                      max={new Date().toISOString().split("T")[0]}
                      placeholder="Issue Date"
                      {...register("issueDateRoadTax", {})}
                    />
                    {errors.issueDateRoadTax && <p>Issue Date is required</p>}
                  </div>
                  <div className="w-1/4">
                    <label>Expiry Date</label>
                    <input
                      type="date"
                      min={new Date().toISOString().split("T")[0]}
                      // ref={register}
                      className="pt-[12px] pb-[14px]"
                      placeholder="Expiry Date"
                      {...register("expiryDateRoadTax", {})}
                    />
                    {errors.expiryDateRoadTax && <p>Expiry Date is required</p>}
                  </div>
                </div>

                <div className="flex flex-row justify-between gap-4 mt-4">
                  <div className="w-3/4">
                    <label>MOT Registration</label>
                    <input
                      type="file"
                      // ref={register}
                      placeholder="MOT Registration"
                      {...register("mot", {})}
                    />
                    {errors.mot && <p>MOT Registration is required</p>}
                  </div>

                  <div className="w-1/3 ">
                    <label>Issue Date</label>
                    <input
                      type="date"
                      // ref={register}
                      className="pt-[12px] pb-[14px]"
                      max={new Date().toISOString().split("T")[0]}
                      placeholder="Issue Date"
                      {...register("issueDateMot", {})}
                    />
                    {errors.issueDateMot && <p>Issue Date is required</p>}
                  </div>
                  <div className="w-1/4">
                    <label>Expiry Date</label>
                    <input
                      type="date"
                      min={new Date().toISOString().split("T")[0]}
                      // ref={register}
                      className="pt-[12px] pb-[14px]"
                      placeholder="Expiry Date"
                      {...register("expiryDateMot", {})}
                    />
                    {errors.expiryDateMot && <p>Expiry Date is required</p>}
                  </div>
                </div>
                <div className="flex flex-row justify-between gap-4 mt-4">
                  <div className="w-3/4">
                    <label>TFL License</label>
                    <input
                      type="file"
                      // ref={register}
                      placeholder="TFL License"
                      {...register("tflVehicleLicense", {})}
                    />
                    {errors.tflVehicleLicense && (
                      <p>TFL VehicleLicense is required</p>
                    )}
                  </div>

                  <div className="w-1/3 ">
                    <label>Issue Date</label>
                    <input
                      type="date"
                      // ref={register}
                      className="pt-[12px] pb-[14px]"
                      max={new Date().toISOString().split("T")[0]}
                      placeholder="Issue Date"
                      {...register("issueDateTflVehicleLicense", {})}
                    />
                    {errors.issueDateTflVehicleLicense && (
                      <p>Issue Date is required</p>
                    )}
                  </div>
                  <div className="w-1/4">
                    <label>Expiry Date</label>
                    <input
                      type="date"
                      min={new Date().toISOString().split("T")[0]}
                      // ref={register}
                      className="pt-[12px] pb-[14px]"
                      placeholder="Expiry Date"
                      {...register("expiryDateTflVehicleLicense", {})}
                    />
                    {errors.expiryDateTflVehicleLicense && (
                      <p>Expiry Date is required</p>
                    )}
                  </div>
                </div>
                <div className="flex flex-row justify-between gap-4 mt-4">
                  <div className="w-3/4">
                    <label>Insurance</label>
                    <input
                      type="file"
                      // ref={register}
                      placeholder="Insurance"
                      {...register("insurance", {})}
                    />
                    {errors.insurance && <p>V5 Form is required</p>}
                  </div>

                  <div className="w-1/3 ">
                    <label>Issue Date</label>
                    <input
                      type="date"
                      // ref={register}
                      className="pt-[12px] pb-[14px]"
                      max={new Date().toISOString().split("T")[0]}
                      placeholder="Issue Date"
                      {...register("issueDateInsurance", {})}
                    />
                    {errors.issueDateInsurance && <p>Issue Date is required</p>}
                  </div>
                  <div className="w-1/4">
                    <label>Expiry Date</label>
                    <input
                      type="date"
                      min={new Date().toISOString().split("T")[0]}
                      // ref={register}
                      className="pt-[12px] pb-[14px]"
                      placeholder="Expiry Date"
                      {...register("expiryDateInsurance", {})}
                    />
                    {errors.expiryDateInsurance && (
                      <p>Expiry Date is required</p>
                    )}
                  </div>
                </div>

                <div className="mt-8">
                  <input className="w-auto m-auto " type="submit" />
                </div>
                <div className="spinner">
                  <Loader
                    loaded={loaded}
                    lines={13}
                    length={20}
                    width={10}
                    radius={30}
                    corners={1}
                    rotate={0}
                    direction={1}
                    color="#000"
                    speed={1}
                    trail={60}
                    shadow={false}
                    hwaccel={false}
                    className="spinner"
                    zIndex={2e9}
                    top="50%"
                    left="50%"
                    scale={1.0}
                    loadedClassName="loadedContent"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AddVehicle;
