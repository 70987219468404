import React, { useContext, useEffect } from "react";
import { Leftbar } from "../components/sidebar/Leftbar";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { PaymentChart } from "../components/PaymentChart";
import { EarningChart } from "../components/EarningChart";
import { RequestChart } from "../components/RequestChart";
import { ClientContext } from "../base/contexts/UserContext";
import { useNavigate } from "react-router-dom";

function LoginScreen() {
  const { client, setClient } = useContext(ClientContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!client?.logInType) {
      navigate("/");
    }
  }, []);
  return (
    <>
      <div>
        <Header isAdmin />
        <div className="grid lg:grid-cols-6">
          <Leftbar />
          <div className="p-8 lg:col-span-5">
            <div className="text-2xl font-bold">
              Welcome,{" "}
              {client?.logInType == "SUPER_ADMIN" ? "Super Admin" : null}
              {client?.logInType == "OPERATOR"
                ? client.loginData.client.companyName
                : null}
              {client?.logInType == "Driver" ? client.loginData?.name : null}
              {client?.logInType == "Passenger"
                ? client.loginData?.client?.firstName
                : null}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default LoginScreen;
