import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import React, { useEffect, useState, useContext, useMemo } from "react";
const MapWithAMarker = (props) => {
  console.log("PRosp received in google maps", props);
  const mapComponent = useMemo(
    () => (
      <GoogleMap
        defaultZoom={12}
        defaultCenter={props.location}
        // defaultCenter={props.location?.lat ? props.location : null}
        onClick={(ev) => {
          console.log("latitude = ", ev.latLng.lat());
          console.log("longitude = ", ev.latLng.lng());
        }}
      >
        {props.sourceMarker.lat ? (
          <Marker
            //   position={{ lat: 22.57687, lng: 88.35047 }}
            position={props.sourceMarker}
            className="h-10 w-10"
          />
        ) : null}
        {props.destinationMarker.lat ? (
          <Marker
            //   position={{ lat: 22.57687, lng: 88.35047 }}
            position={props.destinationMarker}
            className="h-10 w-10"
          />
        ) : null}
      </GoogleMap>
    ),
    [props.location, props.sourceMarker, props.destinationMarker]
  ); // Empty dependency array ensures the component is memoized and reused

  return mapComponent;
};

export default withGoogleMap(MapWithAMarker);
