import React, { useEffect, useState, useContext } from "react";
import { Leftbar } from "./sidebar/Leftbar";

import Header from "./header/Header";
import Footer from "./footer/Footer";
import { Table } from "antd";
import { Helmet } from "react-helmet";
import { itemRender, onShowSizeChange } from "./paginationfunction";
import { axiosInstance } from "../base/api/axios.util";
import { URLConstants } from "../base/api/url.constants";
import { ClientContext } from "../base/contexts/UserContext";
import Loader from "react-loader";
import toast, { Toaster } from "react-hot-toast";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";

export default function RUpcomingRides() {
  const { client, setClient } = useContext(ClientContext);
  const [tripData, setTripData] = useState([]);
  const [mobileNumber, setMobileNumber] = useState("");
  const [driverInfo, setDriverInfo] = useState({});
  const [selectedRecord, setSelectedRecord] = useState({});
  const [loaded, setLoaded] = useState(true);
  const [error, setError] = useState({});
  let search = window.location.search;
  let params = new URLSearchParams(search);
  const navigate = useNavigate();
  useEffect(() => {
    console.log("Logged in client", client);
    setLoaded(false);
    if (!client) {
      navigate("/");
    } else {
      let type = client.logInType;
      if (client.logInType == "SUPER_ADMIN") {
        type = "OPERATOR";
      }
      axiosInstance
        .get(URLConstants.getUpcomingRides(client.loginData.id, type))
        .then((response) => {
          console.log("Response in upcoming rides", response);
          setTripData(response);
          setLoaded(true);
        })
        .catch((err) => {
          setLoaded(true);
        });
    }
  }, []);

  function reset() {
    setError({ status: false, message: "" });
    setMobileNumber("");
    setDriverInfo({});
  }

  function searchDriver() {
    setLoaded(false);
    axiosInstance
      .get(URLConstants.getDriverInfoByMobileNo(mobileNumber))
      .then((res) => {
        setDriverInfo(res);
        console.log("DRIVER INFO", res);
        setLoaded(true);
      })
      .catch((err) => {
        setLoaded(true);
        if (err.response.status == 404) {
          setError({ status: true, message: "Driver Not Found" });
        } else if (err.response.status == 400) {
          setError({ status: true, message: "Driver is Blocked" });
        } else {
          setError({
            status: true,
            message: "Something went wrong try again later",
          });
        }
        console.log("err", err.response.status);
      });
  }

  function assignRide() {
    let newRecord = selectedRecord;
    newRecord.driverDetails = driverInfo;
    console.log("FInal body made", newRecord);
    console.log("OLD RECORD", selectedRecord);
    axiosInstance
      .post(URLConstants.assignDriverToTrip(newRecord?.tripId), newRecord)
      .then((res) => {
        console.log("Resoinse after sending request", res);
        toast.success("Driver Assigned Successfully!!");
        reset();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong! Try Again later");
        reset();
      });
  }

  const columns = [
    {
      title: "Trip id",
      dataIndex: "tripId",
    },

    {
      title: "Passenger Name",
      dataIndex: "passengerDetails",
      render: (source) => source?.firstName + " " + source?.lastName,
    },
    {
      title: "Passenger Mobile Number",
      dataIndex: "passengerDetails",
      render: (source) => source?.mobileNo,
    },
    {
      title: "Pickup Location",
      dataIndex: "source",
      render: (source) => source[0]?.address,
    },

    {
      title: "Destination",
      dataIndex: "destination",
      render: (destination) => destination[0]?.address,
    },
    {
      title: "Status",
      dataIndex: "message",
    },

    {
      title: "Trip Date And Time",
      dataIndex: "tripDate",
    },
    {
      title: "Driver Name",
      dataIndex: "driverDetails",
      render: (source) => (source?.name ? source?.name : "Not assigned"),
    },
    {
      title: "Driver Mobile Number",
      dataIndex: "driverDetails",
      render: (source) =>
        source?.mobileNo ? source?.mobileNo : "Not assigned",
    },
  ];
  let assingDriver = {
    title: "Assign Driver",

    render: (text, record) => (
      <Link
        className=""
        to="/#"
        data-bs-toggle="modal"
        data-bs-target="#add_leave"
      >
        <button
          className="w-auto m-auto px-4 py-2 rounded-lg"
          type="submit"
          onClick={() => setSelectedRecord(record)}
        >
          Assign
        </button>
      </Link>
    ),
  };

  if (client.logInType == "SUPER_ADMIN") {
    columns.push(assingDriver);
  }
  return (
    <>
      <Header isAdmin />
      <div className="grid lg:grid-cols-6">
        <Leftbar />
        <Helmet>
          <title>Upcoming Rides - Treklers - Admin</title>
          <meta name="description" content="Login page" />
        </Helmet>
        <div className="p-8 lg:col-span-5">
          <div className="col-md-12">
            <div className="table-responsive">
              <div className="p-4 grid grid-cols-2 pb-8 mb-3">
                <div>
                  <span className="text-2xl font-bold">Upcoming Rides</span>
                  <span className="font-bold p-4">
                    <Link to="/">Home</Link>{" "}
                  </span>
                </div>
              </div>

              <div className=" float-end ml-auto mb-6">
                <button
                  className="rounded bg-gray-700 text-white hover:bg-black px-3 py-2 w-auto"
                  // onClick={() => ExportToExcel()}
                >
                  Download Excel
                </button>
                <button
                  className="rounded bg-gray-700 text-white hover:bg-black px-3 py-2 w-auto ml-3"
                  // onClick={() => ExportToCsv()}
                >
                  Download CSV
                </button>
                {/* <button
                  className="rounded bg-gray-700 text-white hover:bg-black px-3 py-2 w-auto ml-3"
                  // onClick={() => generatePdf()}
                >
                  Download PDF
                </button> */}
              </div>

              <Table
                className="table-striped"
                pagination={{
                  total: tripData.length,
                  showTotal: (total, range) =>
                    `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                  showSizeChanger: true,
                  onShowSizeChange: onShowSizeChange,
                  itemRender: itemRender,
                }}
                style={{ overflowX: "auto" }}
                columns={columns}
                bordered
                dataSource={tripData}
                rowKey={(record) => record.id}
              />
            </div>
          </div>
        </div>
        <div className="spinner">
          {loaded && (
            <Loader
              loaded={loaded}
              lines={13}
              length={20}
              width={10}
              radius={30}
              corners={1}
              rotate={0}
              direction={1}
              color="#000"
              speed={1}
              trail={60}
              shadow={false}
              hwaccel={false}
              className="spinner"
              zIndex={2e9}
              top="50%"
              left="50%"
              scale={1.0}
              loadedClassName="loadedContent"
            />
          )}
        </div>

        <div id="add_leave" className="modal custom-modal fade" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content modalSize">
              <div className="">
                <div className="modal-header bg-[#404298] text-white text-center">
                  <h5 className="modal-title text-center font-bold">
                    Assign Driver
                  </h5>
                  <button
                    type="button"
                    className="close text-white opacity-100"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      reset();
                    }}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="form-group mx-4 mt-2">
                  <div className="mb-2 font-bold">
                    Enter Driver Phone Number{" "}
                  </div>
                  <input
                    type="number"
                    className=""
                    value={mobileNumber}
                    onChange={(e) => {
                      setMobileNumber(e.target.value.toString());
                      setError({ status: false, message: "" });
                    }}
                  />
                </div>
                {error.status ? (
                  <span className="ml-4 text-red-600 font-semibold mb-10">
                    No Driver Found with this Number ! Try another number
                  </span>
                ) : null}
                <div className="pb-4 text-center mt-2">
                  {/* <button data-dismiss="modal" type="submit" /> */}
                  <button
                    type="submit"
                    className="w-auto m-auto  text-white rounded px-4 py-2"
                    onClick={() => searchDriver()}
                  >
                    Search
                  </button>
                </div>

                {driverInfo?.name ? (
                  <>
                    <div className="ml-4 flex flex-col justify-center ">
                      <div>
                        <span className="text-[#404298] font-bold mr-2">
                          Driver Name :{" "}
                        </span>
                        <span className=" font-semibold ml-2">
                          {driverInfo.name}
                        </span>
                      </div>
                      <div>
                        <span className="text-[#404298] font-bold mr-2">
                          Email :{" "}
                        </span>
                        <span className=" font-semibold ml-2">
                          {driverInfo.email}
                        </span>
                      </div>
                      {driverInfo?.vehicleDetails?.vehicleNo ? (
                        <div>
                          <span className="text-[#404298] font-bold mr-2">
                            Vehicle Number :{" "}
                          </span>
                          <span className=" font-semibold ml-2">
                            {driverInfo?.vehicleDetails?.vehicleNo}
                          </span>
                        </div>
                      ) : null}
                      {driverInfo?.vehicleDetails?.vehicleName ? (
                        <div>
                          <span className="text-[#404298] font-bold mr-2">
                            Vehicle Name :{" "}
                          </span>
                          <span className=" font-semibold ml-2">
                            {driverInfo?.vehicleDetails?.vehicleName}
                          </span>
                        </div>
                      ) : null}
                      {driverInfo?.vehicleDetails?.noOfSeats ? (
                        <div>
                          <span className="text-[#404298] font-bold mr-2">
                            No. of Seats :{" "}
                          </span>
                          <span className=" font-semibold ml-2">
                            {driverInfo?.vehicleDetails?.noOfSeats}
                          </span>
                        </div>
                      ) : null}
                      {driverInfo?.vehicleDetails?.noOfBuggies ? (
                        <div>
                          <span className="text-[#404298] font-bold mr-2">
                            No. of Buggies :{" "}
                          </span>
                          <span className=" font-semibold ml-2">
                            {driverInfo?.vehicleDetails?.noOfBuggies}
                          </span>
                        </div>
                      ) : null}
                    </div>
                    <div className="pb-4 text-center mt-2">
                      {/* <button data-dismiss="modal" type="submit" /> */}
                      <button
                        type="submit"
                        className="w-auto m-auto  text-white rounded px-4 py-2"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          assignRide();
                        }}
                      >
                        Assign
                      </button>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
