// import React from 'react'

// const RequestChart = () => {
//   return (
//     <div>RequestChart</div>
//   )
// }

// export default RequestChart

import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
// import faker from 'faker';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export const options = {
    responsive: true,
    // plugins: {
    //     legend: {
    //         position: 'top' as const,
    //     },
    //     title: {
    //         display: true,
    //         text: 'Chart.js Line Chart',
    //     },
    // },
};

const labels = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const data = {
    labels,
    datasets: [
        {
            fill: true,
            label: 'Weekly Update',
            // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
    ],
};

export function RequestChart() {
    return <Line options={options} data={data} />;
}

