import React, { useRef } from "react";
import Header from "./header/Header";
import { Helmet } from "react-helmet";
import Footer from "./footer/Footer";
import Hero from "./shared/Hero";

const AboutUs = () => {
  const titleRef = useRef();
  return (
    <>
      <Header isAdmin />
      <Hero title="About Us" />
      <Helmet>
        <title>About Us - Treklers Limited</title>
        <meta name="description" content="About Us page" />
      </Helmet>
      <div>
        <div
          className="text-3xl font-bold w-full text-center p-4"
          ref={titleRef}
        >
          About Us
        </div>
      </div>
      <div className="w-full md:w-2/3 border-2 rounded-lg border-gray-500 mx-auto">
        <div className="overflow-y-auto p-8">
          <div className="text-sm mt-0 md:mt-8 text-black line-clamp-1">
            {" "}
            <h2 className="lg:text-lg font-bold text-gray-500 text-left mb-4">
              About Us
            </h2>
          </div>
          <div className="grid gap-4 text-sm ">
            <p>
              At TREKLERS, we embody the art of movement. It&#39;s not just our
              passion; it&#39;s our essence. Every journey we undertake is a
              symphony of elegance and sophistication, meticulously crafted to
              exceed your expectations.
            </p>
            <p className="font-bold">Your privacy, our priority</p>
            <p>
              Rest assured; your privacy is sacred to us. We employ a myriad of
              state-of-the-art security measures to safeguard your personal
              information, ensuring your peace of mind throughout your journey
              with us.
            </p>
            <p className="font-bold">Sustainability: Pioneering the way</p>
            <p>
              TREKLERS is dedicated to a future of sustainability. We pledge to
              transition to a fully electric, zero- emission platform by 2040,
              leading the charge in combating climate change. With every ride,
              we&#39;re not just moving you forward; we&#39;re moving towards a
              greener tomorrow.
            </p>
            <p className="font-bold">Beyond transportation</p>
            <p>
              At TREKLERS, we focus on enhancing your lifestyle beyond just
              getting you from point A to point B. We are committed to providing
              seamless travel experiences. Every ride is designed with your
              comfort and satisfaction in mind, ensuring that each journey with
              us is smooth, enjoyable, and exceeds your expectations. While we
              currently specialize in ride services, our dedication to
              excellence means we&#39;re always looking for new ways to elevate
              your travel experience.
            </p>
            <p className="font-bold">Safety first, always</p>
            <p>
              Your safety is paramount, when you&#39;re nestled in the back
              seat. We spare no effort in harnessing cutting-edge technology and
              forging partnerships with safety advocates to ensure your
              well-being remains our top priority.
            </p>
            <p className="font-bold">Meet the driving force behind TREKLERS</p>
            <p>
              Our team is the heart and soul of TREKLERS. Committed to integrity
              and excellence, we lead by example, ensuring every interaction
              with us is nothing short of exceptional.
            </p>
            <p className="font-bold">Upholding integrity</p>
            <p>
              Integrity is the cornerstone of everything we do
              atTREKLERS.Through transparent communication channels and
              unwavering adherence to ethical standards, we strive to maintain
              the trust and confidence of our esteemed clientele.
            </p>
            <p className="font-bold">Embracing diversity</p>
            <p>
              We celebrate the richness of diversity, both within our workforce
              and the vibrant tapestry of the cities we serve in. By fostering
              an inclusive environment where every voice is valued, we enrich
              not only our company but the communities we serve.
            </p>
            <p className="font-bold">Contact Us</p>
            <p>
              Should you have any enquiries regarding our company or the
              information provided in our About Us section, please don&#39;t
              hesitate to reach out to us using the following contact details:
            </p>
            <div className="addRess">
              <p>Neil Cross</p>
              <p>+44 7787 120633</p>
              <p>TREKLERS Limited</p>
              <p>A3, 11, Vista Centre</p>
              <p>50 Salisbury Road, Hounslow</p>
              <p> Middlesex TW4 6JQ</p>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-4">
        <Footer />
      </div>
    </>
  );
};

export default AboutUs;
