import React, { useState, useEffect, useContext } from "react";
import { useForm, Controller, set } from "react-hook-form";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Header from "./header/Header";
import { Leftbar } from "./sidebar/Leftbar";
import Footer from "./footer/Footer";
import { axiosInstance } from "../base/api/axios.util";
import { URLConstants } from "../base/api/url.constants";
import axios, * as others from "axios";
import toast, { Toaster } from "react-hot-toast";
import Loader from "react-loader";
import { ClientContext } from "../base/contexts/UserContext";

const CreateOperator = () => {
  const [loaded, setLoaded] = useState(true);
  const { client, setClient } = useContext(ClientContext);
  const [vatStatus, setVatStatus] = useState(true);
  const navigate = useNavigate();
  var defaultValue = {
    companyName: "",
    address: "",
    city: "",
    email: "",
    mobileNo: "",
    zipCode: "",
    // companyLicense: { expiryDateCompLic: "" },
    // otherDoc: { expiryDateAddDoc: "" },
  };

  var driverDataAssignment = {};
  const location = useLocation();

  if (location.state != undefined) {
    defaultValue = location.state;
    let newValues = location.state;

    driverDataAssignment = newValues;
  }

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValue,
  });
  useEffect(() => {
    if (!client) {
      navigate("/");
    }
  });
  return (
    <>
      <Header isAdmin />
      <div className="grid lg:grid-cols-6">
        <Leftbar />
        <Helmet>
          <title>Driver List - Treklers - Admin</title>
          <meta name="description" content="Login page" />
        </Helmet>
        <div className="p-8 lg:col-span-5">
          <div className="col-md-12">
            <div className="table-responsive">
              <div className="p-4 grid grid-cols-2 pb-8">
                <div>
                  <span className="text-2xl font-bold">Create Operator</span>
                  <span className="font-bold p-4">
                    <Link to="/">
                      <i /> Home
                    </Link>
                  </span>
                </div>
                <div className="col-auto float-end ml-auto"></div>
              </div>
              <form
                onSubmit={handleSubmit((data) => {
                  console.log("Data submitted", data);
                  setLoaded(false);
                  alert(JSON.stringify(data));
                  var formData = new FormData();
                  formData.append("companyName", data.companyName);
                  formData.append("address", data.address);
                  formData.append("clientType", "OPERATOR");
                  formData.append("mobileNo", data.mobileNo.toString());
                  formData.append("email", data.email);
                  formData.append("city", data.city);
                  formData.append("zipCode", data.zipCode);
                  formData.append("optLandline", data.optLandline.toString());
                  formData.append("vatRegistration", data.vatRegistration);
                  formData.append(
                    "expiryDateCompanyLicense1",
                    data.expiryDateCompanyLicense1
                  );
                  formData.append(
                    "expiryDateCompanyLicense2",
                    data.expiryDateCompanyLicense2
                  );
                  formData.append(
                    "expiryDateCompanyInsurance",
                    data.expiryDateCompanyInsurance
                  );
                  formData.append(
                    "issueDateCompanyLicense1",
                    data.issueDateCompanyLicense1
                  );
                  formData.append(
                    "issueDateCompanyLicense2",
                    data.issueDateCompanyLicense2
                  );
                  formData.append(
                    "issueDateCompanyInsurance",
                    data.issueDateCompanyInsurance
                  );
                  formData.append("contactPerson", data.contactPerson);
                  formData.append("companyLicense2", data.companyLicense2[0]);
                  formData.append("companyLicense1", data.companyLicense1[0]);
                  formData.append("companyInsurance", data.companyInsurance[0]);

                  var config = {
                    method: "post",
                    url: URLConstants.createOperator(),
                    headers: {
                      headers: { "content-type": "multipart/form-data" },
                    },
                    data: formData,
                  };

                  axios(config)
                    .then(function (response) {
                      toast.success("Your data is submitting successfully!");
                      setLoaded(true);
                      reset();
                      navigate("/operatorlist");
                    })
                    .catch(function (error) {
                      toast.success(
                        "Something went wrong!! Please try again later"
                      );
                      setLoaded(true);
                    });
                })}
              >
                <div className="grid grid-cols-2 gap-8 gap-y-4 mb-8">
                  <div>
                    <label>Operator Name</label>
                    <input {...register("companyName", { required: true })} />
                    {errors.companyName && <p>Operator Name is required</p>}
                  </div>

                  <div>
                    <label>Address</label>
                    <input {...register("address", { required: true })} />
                    {errors.address && <p>Address field is required</p>}
                  </div>

                  <div>
                    <label>City</label>
                    <input {...register("city", { required: true })} />
                    {errors.city && <p>City field is required</p>}
                  </div>

                  <div>
                    <label>Email</label>
                    <input {...register("email", { required: true })} />
                    {errors.email && <p>Email is required</p>}
                  </div>

                  <div>
                    <label>Contact Number</label>
                    <input {...register("mobileNo", { required: true })} />
                    {errors.mobileNo && <p>Contact No. is required</p>}
                  </div>

                  <div>
                    <label>Landline Number</label>
                    <input {...register("optLandline", { required: true })} />
                    {errors.optLandline && <p>Landline Number is required</p>}
                  </div>

                  <div>
                    <label>VAT Registered</label>
                    <div className="w-full border border-1 border-dark rounded p-2">
                      <select
                        className="w-full border-0 outline-0"
                        defaultValue={defaultValue.vatRegistration}
                        {...register("vatRegistration")}
                        onChange={(e) => {
                          console.log("Event target the vakue", e.target.value);
                          setVatStatus(e.target.value === "true");
                        }}
                      >
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>
                    {errors.vatRegistration && (
                      <p>VAT Registration field is required</p>
                    )}
                  </div>
                  {vatStatus ? (
                    <div>
                      <label>VAT Number</label>
                      <input {...register("vatNumber")} />
                      {errors.vatNumber && <p>VAT Number field is required</p>}
                    </div>
                  ) : null}
                  <div>
                    <label>Postal Code</label>
                    <input {...register("zipCode", { required: true })} />
                    {errors.zipCode && <p>Zip Code field is required</p>}
                  </div>
                  {/* <div> <label>Operator Id</label>
                                        <input
                                            {...register("operatorId", { required: true, })}
                                        />
                                        {errors.operatorId && <p>Operator ID is required</p>}
                                    </div> */}

                  <div className="">
                    <label>Contact Person</label>
                    <input {...register("contactPerson", { required: true })} />
                    {errors.contactPerson && <p>Contact Person is required</p>}
                  </div>
                </div>

                <div className="flex flex-row gap-4 ">
                  <div className="w-3/4">
                    <label>Comapny License 1</label>
                    <input
                      type="file"
                      // ref={register}
                      placeholder="Comapny License 1"
                      {...register("companyLicense1")}
                    />
                    {errors.companyLicense1 && (
                      <p>Comapny License 1 is required</p>
                    )}
                  </div>
                  <div className="w-1/3 ">
                    <label>Issue Date</label>
                    <input
                      type="date"
                      // ref={register}
                      className="pt-[12px] pb-[14px]"
                      defaultValue={
                        driverDataAssignment?.companyLicense1?.issueDate
                          ? new Date(
                              driverDataAssignment?.companyLicense1?.issueDate
                            )
                          : null
                      }
                      placeholder="Issue Date"
                      max={new Date().toISOString().split("T")[0]}
                      {...register("issueDateCompanyLicense1", {})}
                    />
                    {errors.issueDateCompanyLicense1 && (
                      <p>Issue Date is required</p>
                    )}
                  </div>
                  <div className="w-1/4">
                    <label>Expiry Date</label>
                    <input
                      type="date"
                      // ref={register}
                      className="pt-[12px] pb-[14px]"
                      defaultValue={
                        driverDataAssignment?.companyLicense1?.expiryDate
                          ? new Date(
                              driverDataAssignment?.companyLicense1?.expiryDate
                            )
                          : null
                      }
                      placeholder="Expiry Date"
                      min={new Date().toISOString().split("T")[0]}
                      {...register("expiryDateCompanyLicense1")}
                    />
                    {errors.expiryDateCompanyLicense1 && (
                      <p>Expiry Date is required</p>
                    )}
                  </div>
                </div>

                <div className="flex flex-row gap-4 mt-4">
                  <div className="w-3/4">
                    <label>Operator License 2</label>
                    <input
                      type="file"
                      // ref={register}
                      placeholder="Operator License 2"
                      {...register("companyLicense2", {})}
                    />
                    {errors.companyLicense2 && (
                      <p>Operator License 2 is required</p>
                    )}
                  </div>
                  <div className="w-1/3 ">
                    <label>Issue Date</label>
                    <input
                      type="date"
                      // ref={register}
                      className="pt-[12px] pb-[14px]"
                      defaultValue={
                        driverDataAssignment?.companyLicense2?.issueDate
                          ? new Date(
                              driverDataAssignment?.companyLicense2?.issueDate
                            )
                          : null
                      }
                      placeholder="Issue Date"
                      max={new Date().toISOString().split("T")[0]}
                      {...register("issueDateCompanyLicense2", {})}
                    />
                    {errors.issueDateCompanyLicense2 && (
                      <p>Issue Date is required</p>
                    )}
                  </div>
                  <div className="w-1/4">
                    <label>Expiry Date</label>
                    <input
                      type="date"
                      // ref={register}
                      className="pt-[12px] pb-[14px]"
                      defaultValue={
                        driverDataAssignment?.companyLicense2?.expiryDate
                          ? new Date(
                              driverDataAssignment?.companyLicense2?.expiryDate
                            )
                          : null
                      }
                      placeholder="Expiry Date"
                      min={new Date().toISOString().split("T")[0]}
                      {...register("expiryDateCompanyLicense2", {})}
                    />
                    {errors.expiryDateCompanyLicense2 && (
                      <p>Expiry Date is required</p>
                    )}
                  </div>
                </div>
                <div className="flex flex-row gap-4 mt-4">
                  <div className="w-3/4">
                    <label>Operator Insurance</label>
                    <input
                      type="file"
                      // ref={register}
                      placeholder="Operator Insurance"
                      {...register("companyInsurance", {})}
                    />
                    {errors.companyInsurance && (
                      <p>Operator Insurance is required</p>
                    )}
                  </div>
                  <div className="w-1/3 ">
                    <label>Issue Date</label>
                    <input
                      type="date"
                      // ref={register}
                      className="pt-[12px] pb-[14px]"
                      defaultValue={
                        driverDataAssignment?.companyInsurance?.issueDate
                          ? new Date(
                              driverDataAssignment?.companyInsurance?.issueDate
                            )
                          : null
                      }
                      placeholder="Issue Date"
                      max={new Date().toISOString().split("T")[0]}
                      {...register("issueDateCompanyInsurance", {})}
                    />
                    {errors.issueDateCompanyInsurance && (
                      <p>Issue Date is required</p>
                    )}
                  </div>
                  <div className="w-1/4">
                    <label>Expiry Date</label>
                    <input
                      type="date"
                      // ref={register}
                      className="pt-[12px] pb-[14px]"
                      defaultValue={
                        driverDataAssignment?.companyInsurance?.expiryDate
                          ? new Date(
                              driverDataAssignment?.companyInsurance?.expiryDate
                            )
                          : null
                      }
                      placeholder="Expiry Date"
                      min={new Date().toISOString().split("T")[0]}
                      {...register("expiryDateCompanyInsurance", {})}
                    />
                    {errors.expiryDateCompanyInsurance && (
                      <p>Expiry Date is required</p>
                    )}
                  </div>
                </div>

                <div className="mt-8">
                  <input className="w-auto m-auto mt-8" type="submit" />
                </div>
                <div className="spinner">
                  <Loader
                    loaded={loaded}
                    lines={13}
                    length={20}
                    width={10}
                    radius={30}
                    corners={1}
                    rotate={0}
                    direction={1}
                    color="#000"
                    speed={1}
                    trail={60}
                    shadow={false}
                    hwaccel={false}
                    className="spinner"
                    zIndex={2e9}
                    top="50%"
                    left="50%"
                    scale={1.0}
                    loadedClassName="loadedContent"
                  />
                </div>
              </form>
              <div>
                <Toaster />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CreateOperator;
