// // import React, { useState } from "react";
// // import { Transition } from "@headlessui/react";

// // const CardList = () => {
// //   const [userCards, setUserCards] = useState([
// //     { id: 1, number: "**** **** **** 1234", expiry: "12/23" },
// //     // ... other userCards
// //   ]);

// //   const removeCard = (id) => {
// //     setUserCards(userCards.filter((card) => card.id !== id));
// //   };

// //   const addCard = (newCard) => {
// //     setUserCards([...userCards, newCard]);
// //   };

// //   return (
// //     <div className="p-4 max-w-2xl mx-auto">
// //       <h1 className="text-2xl mb-4">Your Cards</h1>
// //       <div className="space-y-4">
// //         {userCards.map((card) => (
// //           <Transition
// //             as={React.Fragment}
// //             key={card.id}
// //             appear={true}
// //             show={true}
// //             enter="transition-all transform duration-500"
// //             enterFrom="translate-y-4 opacity-0"
// //             enterTo="translate-y-0 opacity-100"
// //             leave="transition-all transform duration-500"
// //             leaveFrom="translate-y-0 opacity-100"
// //             leaveTo="translate-y-4 opacity-0"
// //           >
// //             <div className="relative p-6 bg-gradient-to-r from-blue-500 to-indigo-500 rounded-lg shadow-md text-white">
// //               <p className="text-lg">Card Number: {card.number}</p>
// //               <p className="mb-4">Expiry: {card.expiry}</p>
// //               <button
// //                 className="absolute top-2 right-2 px-2 py-1 bg-red-500 rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
// //                 onClick={() => removeCard(card.id)}
// //               >
// //                 Remove
// //               </button>
// //             </div>
// //           </Transition>
// //         ))}
// //       </div>
// //       <button
// //         className="mt-4 px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
// //         onClick={() =>
// //           addCard({
// //             id: userCards.length + 1,
// //             number: "**** **** **** 5678",
// //             expiry: "01/25",
// //           })
// //         }
// //       >
// //         Add Card
// //       </button>
// //     </div>
// //   );
// // };

// // export default CardList;

// import React, { useState } from "react";
// import { Transition } from "@headlessui/react";

// const CardList = () => {
//   const [userCards, setUserCards] = useState([
//     { id: 1, number: "**** **** **** 1234", expiry: "12/23" },
//     // ... other userCards
//   ]);

//   const removeCard = (id) => {
//     setUserCards(userCards.filter((card) => card.id !== id));
//   };

//   const addCard = (newCard) => {
//     setUserCards([...userCards, newCard]);
//   };

//   return (
//     <div className="p-4 max-w-2xl mx-auto">
//       <h1 className="text-2xl mb-4">Your Cards</h1>
//       <div className="space-y-4">
//         {userCards.map((card) => (
//           <Transition
//             as={React.Fragment}
//             key={card.id}
//             appear={true}
//             show={true}
//             enter="transition-all transform duration-500"
//             enterFrom="translate-y-4 opacity-0"
//             enterTo="translate-y-0 opacity-100"
//             leave="transition-all transform duration-500"
//             leaveFrom="translate-y-0 opacity-100"
//             leaveTo="translate-y-4 opacity-0"
//           >
//             <div className="relative p-6 bg-gradient-to-r from-blue-500 to-indigo-500 rounded-lg shadow-lg text-white transform transition-transform duration-500 hover:scale-105">
//               <p className="text-lg">Card Number: {card.number}</p>
//               <p className="mb-4">Expiry: {card.expiry}</p>
//               <button
//                 className="absolute top-2 right-2 px-2 py-1 bg-red-500 rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
//                 onClick={() => removeCard(card.id)}
//               >
//                 Remove
//               </button>
//             </div>
//           </Transition>
//         ))}
//       </div>
//       <button
//         className="mt-4 px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
//         onClick={() =>
//           addCard({
//             id: userCards.length + 1,
//             number: "**** **** **** 5678",
//             expiry: "01/25",
//           })
//         }
//       >
//         Add Card
//       </button>
//     </div>
//   );
// };

// export default CardList;

// import React, { useState } from "react";

// const Card = ({ number, expiry }) => {
//   const [isFlipped, setIsFlipped] = useState(false);

//   return (
//     <div
//       className="w-80 h-48 mx-auto my-4 perspective"
//       onClick={() => setIsFlipped(!isFlipped)}
//     >
//       <div className={`card ${isFlipped ? "flipped" : ""}`}>
//         <div className="card-face front bg-gradient-to-r from-blue-500 to-indigo-500 text-white p-4 rounded-lg shadow-lg flex flex-col justify-between">
//           <div className="flex justify-between items-center">
//             <div className="logo">YourBank</div>
//             <div className="chip" />
//           </div>
//           <div className="number">{number}</div>
//           <div className="flex justify-between items-center">
//             <div className="info">
//               <div className="label">Expires</div>
//               <div className="value">{expiry}</div>
//             </div>
//             <div className="logo">VISA</div>
//           </div>
//         </div>
//         <div className="card-face back bg-gray-300 p-4 rounded-lg shadow-lg flex flex-col justify-between">
//           <div className="stripe bg-black h-6" />
//           <div className="signature bg-white h-12 p-2" />
//           <div className="flex justify-between items-center px-2">
//             <div className="cvv">{`CVV: ***`}</div>
//             <div className="logo">VISA</div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// const CardList = () => {
//   const userCards = [
//     { id: 1, number: "**** **** **** 1234", expiry: "12/23" },
//     // ... other userCards
//   ];

//   return (
//     <div className="p-4">
//       <h1 className="text-2xl mb-4">Your Cards</h1>
//       {userCards.map((card) => (
//         <Card key={card.id} {...card} />
//       ))}
//     </div>
//   );
// };

// export default CardList;

import { useState, useContext, useEffect } from "react";
import Loader from "react-loader";
import { Leftbar } from "./sidebar/Leftbar";
import { Helmet } from "react-helmet";
import axios, * as others from "axios";
import { useForm, Controller } from "react-hook-form";
import { AiOutlineCamera } from "react-icons/ai";
import { axiosInstance } from "../base/api/axios.util";
import toast, { Toaster } from "react-hot-toast";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { AuthTokenHandler } from "../base/api/auth-token.util";
import { ClientContext } from "../base/contexts/UserContext";

const Card = ({ id, number, expiry, brand, onDelete }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  // Define colors for different card providers
  const providerColors = {
    visa: "from-blue-500 to-indigo-500",
    mastercard: "from-red-500 to-yellow-500",
    amex: "from-purple-500 to-pink-500",
  };

  const handleDelete = (e) => {
    e.stopPropagation(); // Prevent triggering card flip when deleting
    onDelete(id);
  };

  return (
    <div
      className="w-80 h-48 mx-auto my-4 perspective relative"
      onClick={() => setIsFlipped(!isFlipped)}
    >
      <div className={`card ${isFlipped ? "flipped" : ""}`}>
        <div
          className={`card-face front text-white p-4 rounded-lg shadow-lg flex flex-col justify-between bg-gradient-to-r ${providerColors[brand]}`}
        >
          {/* Front of the Card */}
          <div className="flex justify-between items-center">
            <div className="logo">{brand?.toUpperCase()}</div>
            <div className="chip" />
          </div>
          <div className="number">{number}</div>
          <div className="flex justify-between items-center">
            <div className="info">
              <div className="label">Expires</div>
              <div className="value">{expiry}</div>
            </div>
            <div className="logo">{brand?.toUpperCase()}</div>
          </div>
          {/* <div className="holder">{holder?.toUpperCase()}</div> */}
        </div>
        <div className="card-face back bg-gray-300 p-4 rounded-lg shadow-lg flex flex-col justify-between">
          {/* Back of the Card */}
          <div className="stripe bg-black h-6" />
          <div className="signature bg-white h-12 p-2" />
          <div className="flex justify-between items-center px-2">
            <div className="cvv">{`CVV: ***`}</div>
            <div className="logo">{brand?.toUpperCase()}</div>
          </div>
        </div>
      </div>
      <button
        onClick={handleDelete}
        className="absolute top-2 right-2 text-lg font-bold"
      >
        &times;
      </button>
    </div>
  );
};

const AddCardModal = ({ onAddCard, onClose }) => {
  const [newCardNumber, setNewCardNumber] = useState("");
  const [newCardExpiry, setNewCardExpiry] = useState("");
  const [newCardHolder, setNewCardHolder] = useState("");
  const [newCardProvider, setNewCardProvider] = useState("Visa");
  const [newCardCVV, setNewCardCVV] = useState("");

  const handleAddCard = () => {
    onAddCard(
      newCardNumber,
      newCardExpiry,
      newCardHolder,
      newCardProvider,
      newCardCVV
    );
    setNewCardNumber("");
    setNewCardExpiry("");
    setNewCardHolder("");
    setNewCardProvider("Visa");
    setNewCardCVV("");
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-4 rounded-lg shadow-lg relative w-80">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-lg font-bold "
        >
          &times;
        </button>
        <h2 className="text-xl mb-4">Add New Card</h2>
        <input
          type="text"
          placeholder="Card Number"
          value={newCardNumber}
          onChange={(e) => setNewCardNumber(e.target.value)}
          className="p-2 border rounded mb-2 w-full"
        />
        <input
          type="text"
          placeholder="Expiry Date (MM/YY)"
          value={newCardExpiry}
          onChange={(e) => setNewCardExpiry(e.target.value)}
          className="p-2 border rounded mb-2 w-full"
        />
        <input
          type="text"
          placeholder="Card Holder"
          value={newCardHolder}
          onChange={(e) => setNewCardHolder(e.target.value)}
          className="p-2 border rounded mb-2 w-full"
        />
        <input
          type="text"
          placeholder="CVV"
          value={newCardCVV}
          onChange={(e) => setNewCardCVV(e.target.value)}
          className="p-2 border rounded mb-2 w-full"
        />
        <select
          value={newCardProvider}
          onChange={(e) => setNewCardProvider(e.target.value)}
          className="p-2 border rounded mb-2 w-full"
        >
          <option value="Visa">Visa</option>
          <option value="Mastercard">Mastercard</option>
          <option value="Amex">American Express</option>
        </select>
        <button
          onClick={handleAddCard}
          className="p-2 bg-[#404298] text-white rounded w-full"
        >
          Add Card
        </button>
      </div>
    </div>
  );
};

const CardList = () => {
  // const [userCards, setUserCards] = useState([
  //   {
  //     id: 1,
  //     number: "**** **** **** 1234",
  //     expiry: "12/23",
  //     brand: "visa",
  //     holder: "John Doe",
  //   },
  //   // ... other userCards
  // ]);
  const [loaded, setLoaded] = useState(true);
  const [userCards, setUserCards] = useState([]);
  const { client, setClient } = useContext(ClientContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (client.logInType == "Passenger" && client?.loginData?.id) {
      setLoaded(false);
      axiosInstance
        .get(
          `https://treklersthirdpartyservice.treklers.in/v1/payment/cards/${client?.loginData?.id}`
        )
        .then((res) => {
          setLoaded(true);
          for (let i = 0; i < res.length; i++) {
            res[i].number = "**** **** **** " + res[i].last4;
            res[i].expiry =
              res[i].exp_month +
              "/" +
              res[i].exp_year.toString().substring(2, 4);
          }
          console.log("Respinse from payment server", res);
          setUserCards(res);
        })
        .catch((err) => {
          setLoaded(true);
          console.log(err);
        });
    }
  }, []);

  const handleAddCard = (number, expiry, holder, brand, cvc) => {
    setUserCards([
      ...userCards,
      { id: userCards.length + 1, number, expiry, holder, brand },
    ]);
    setIsModalOpen(false);
  };

  // const handleDeleteCard = (id) => {
  //   setUserCards(userCards.filter((card) => card.id !== id));
  // };
  const handleDeleteCard = (id) => {
    console.log("ID received", id);
    // Confirm deletion from the user
    if (window.confirm("Are you sure you want to delete this card?")) {
      setLoaded(false);
      const accessToken = AuthTokenHandler.getAccessToken();

      var config = {
        method: "delete",
        url: `https://treklersthirdpartyservice.treklers.in/v1/payment/card/${client?.loginData?.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        data: JSON.stringify({
          cardId: id,
        }),
      };

      axios(config)
        .then((res) => {
          setLoaded(true);
          toast.success("Deleted Card Successfully");
          setUserCards(userCards.filter((card) => card.id !== id));
        })
        .catch((err) => {
          setLoaded(true);
          console.log(err);
          toast.error("Could not delete card! Please try again later");
        });
    }
  };

  async function addCart() {
    if (client.logInType == "Passenger" && client?.loginData?.id) {
      setLoaded(false);
      axiosInstance
        .get(
          `https://treklersthirdpartyservice.treklers.in/v1/payment/addCardLink/${client?.loginData?.id}`
        )
        .then((res) => {
          setLoaded(true);
          console.log("Response from add card url", res);
          window.location = res.url;
        })
        .catch((err) => {
          console.log(err);
          setLoaded(true);
        });
    }
  }

  return (
    <>
      <Header isAdmin />
      <div className="grid lg:grid-cols-6">
        <Leftbar />
        <Helmet>
          <title>Edit Profile - Treklers - Admin</title>
          <meta name="description" content="Login page" />
        </Helmet>

        <div className="p-8 lg:col-span-5">
          <div className="col-md-12">
            <div className="table-responsive">
              <div className="p-4 grid grid-cols-2 pb-8 mb-3">
                <div>
                  <span className="text-2xl font-bold">Saved Cards</span>
                  <span className="font-bold p-4">
                    <Link to="/">Home</Link>{" "}
                  </span>
                </div>
              </div>

              <div className="p-4">
                {/* <button
                  onClick={() => setIsModalOpen(true)}
                  className="p-2 bg-blue-500 text-white rounded mb-4"
                >
                  Add Card
                </button> */}

                {/* <div className="modal-header bg-[#404298] text-white text-center">
                  <h5 className="modal-title text-center font-bold">
                    Add Card
                  </h5>
                  <button
                    type="button"
                    className="close text-white opacity-100"
                    onClick={() => setIsModalOpen(true)}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div> */}
                <div className="col-auto float-end ml-auto mb-6">
                  <div
                    className="inline-flex items-center bg-[#404298] font-semibold border-0 py-1 px-3 focus:outline-none hover:bg-gray-700 rounded-full text-white md:mt-0"
                    onClick={() => addCart()}
                  >
                    <i className="fa fa-plus mr-2" /> Add Card
                  </div>
                </div>

                {userCards.length == 0 ? (
                  <div className="text-red-500 font-semibold text-3xl">
                    No saved cards found
                  </div>
                ) : null}
                {userCards.map((card) => (
                  <Card
                    key={card.id}
                    id={card.id}
                    number={card.number}
                    expiry={card.expiry}
                    brand={card.brand}
                    onDelete={handleDeleteCard}
                  />
                ))}
                {isModalOpen && (
                  <AddCardModal
                    onAddCard={handleAddCard}
                    onClose={() => setIsModalOpen(false)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="spinner">
        <Loader
          loaded={loaded}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#000"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />
      </div>
      <Footer />
    </>
  );
};

export default CardList;
