export default function Hero({ title }) {
  return (
    <>
      <div className='w-screen h-80 bg-[url("images/taxi-driver-female-client-interacting-formal-way.jpg")] bg-no-repeat bg-cover'>
        <div className='w-screen h-80 opacity-75  text-white  bg-[url("images/rit1mehk.jpg")] bg-no-repeat bg-cover'>
          <div className="flex flex-col items-center justify-center h-full">
            <h1 className="text-5xl font-bold text-center tracking-wider">
              {title}
            </h1>
          </div>
        </div>
      </div>
      <div>
        <svg
          className="blog-svg brightness-100"
          xmlns="http://www.w3.org/2000/svg"
          width="1932"
          height="159"
          viewBox="0 0 1932 159"
        >
          <ellipse
            id="Ellipse_360"
            data-name="Ellipse 360"
            cx="966"
            cy="79.5"
            rx="966"
            ry="79.5"
            fill="#fff"
          />
        </svg>
      </div>
      <div className="pt-20" />
    </>
  );
}
