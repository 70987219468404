import React, { useEffect, useContext } from "react";
import Header from "./header/Header";
import { Leftbar } from "./sidebar/Leftbar";
import { useForm, Controller, set } from "react-hook-form";
import { Helmet } from "react-helmet";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { URLConstants } from "../base/api/url.constants";
import { axiosInstance } from "../base/api/axios.util";

import { ClientContext } from "../base/contexts/UserContext";
import Loader from "react-loader";

import Footer from "./footer/Footer";

const BlockDriver = () => {
  const [checked, setChecked] = React.useState(false);
  const [select, setSelect] = React.useState(false);
  const [loaded, setLoaded] = React.useState(true);
  const { client, setClient } = useContext(ClientContext);
  const navigate = useNavigate();

  var driverDataAssignment = {};
  const location = useLocation();
  var defaultValue = {
    name: "",
    mobileNo: "",
    email: "",
    gender: "",
  };
  if (location.state != undefined) {
    defaultValue = location.state;
    driverDataAssignment = location.state;
  }

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValue,
  });

  const handleChange = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    if (!client) {
      navigate("/");
    }
  });

  return (
    <>
      <Header isAdmin />
      <div className="grid lg:grid-cols-6">
        <Leftbar />
        <Helmet>
          <title>Driver List - Treklers - Admin</title>
          <meta name="description" content="Login page" />
        </Helmet>
        <div className="p-8 lg:col-span-5">
          <div className="col-md-12">
            <div className="table-responsive">
              <div className="p-4 grid grid-cols-2 pb-8">
                <div>
                  <span className="text-2xl font-bold">Block Driver</span>
                </div>
                <div className="col-auto float-end ml-auto"></div>
              </div>
              <form
                onSubmit={handleSubmit((data) => {
                  setLoaded(false);
                  const reason = data.reasonToBlock;

                  // alert(JSON.stringify(data));

                  axiosInstance
                    .post(URLConstants.blockDriver(data._id), { reason })
                    .then((response) => {
                      setLoaded(true);
                      alert("Successfully blocked driver");
                      navigate("/driverlist");
                    })
                    .catch((err) => {
                      setLoaded(true);
                    });
                })}
              >
                <div className="grid grid-cols-2 gap-8 gap-y-4 mb-8">
                  <div>
                    <label>Driver Name</label>
                    <input {...register("name", { required: true })} />
                    {errors.name && <p>Name field is required</p>}
                  </div>

                  <div>
                    <label>Contact Number</label>
                    <input {...register("mobileNo", { required: true })} />
                    {errors.mobileNo && <p>Contact Number is required</p>}
                  </div>

                  <div>
                    <label>Gender</label>
                    <div className="w-full border border-1 border-dark rounded p-2">
                      <select
                        className="w-full border-0 outline-0"
                        {...register("gender", { required: true })}
                      >
                        <option value="Female">Female</option>
                        <option value="Male">Male</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    {errors.gender && <p>Gender field is required</p>}
                  </div>

                  <div>
                    <label>Email</label>
                    <input {...register("email", { required: true })} />
                    {errors.email && <p>Email field is required</p>}
                  </div>

                  <div className="flex flex-col">
                    <label>Write valid reason to block a driver:</label>
                    <textarea
                      className="border"
                      name="postContent"
                      rows={4}
                      cols={40}
                      {...register("reasonToBlock", { required: true })}
                    />
                    {errors.reasonToBlock && <p>Valid reason is required</p>}
                  </div>
                  {/* <div className='flex flex-col'>
                    <label>
                     Reason to Unblock a driver:
                    </label>
                    <textarea className='border' name="postContent" rows={4} cols={40}{...register("textarea", { required: true, })} />
                    {errors.textarea && <p>Valid reason is required</p>}

                  </div> */}

                  {/* <div className="flex">
                    <span className="flex mr-8">
                      <label>Block Driver</label>
                      <input
                        className="w-4 h-4 mt-1 ml-2"
                        type="checkbox"
                        checked={checked}
                        onChange={handleChange}
                      />
                    </span> */}

                  {/* <span className='flex'>
                      <label>
                        Unlock Driver
                      </label>
                      <input className='w-4 h-4 mt-1 ml-2'
                        type="checkbox"
                        checked={select}
                        onChange={handleUnChange}
                      />
                    </span> */}
                  {/* </div> */}
                </div>
                <div>
                  <input className="w-auto m-auto mt-8" type="submit" />
                </div>
                <div className="spinner">
                  <Loader
                    loaded={loaded}
                    lines={13}
                    length={20}
                    width={10}
                    radius={30}
                    corners={1}
                    rotate={0}
                    direction={1}
                    color="#000"
                    speed={1}
                    trail={60}
                    shadow={false}
                    hwaccel={false}
                    className="spinner"
                    zIndex={2e9}
                    top="50%"
                    left="50%"
                    scale={1.0}
                    loadedClassName="loadedContent"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlockDriver;
