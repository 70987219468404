import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  Input,
  SkeletonText,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
import { URLConstants } from "../base/api/url.constants";
import { axiosInstance } from "../base/api/axios.util";
import { Fragment } from "react";
import { FaLocationArrow, FaTimes } from "react-icons/fa";
import { IoArrowBackOutline } from "react-icons/io5";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import Loader from "react-loader";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  OverlayView,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { ClientContext } from "../base/contexts/UserContext";
import Header from "./header/Header";
import { useNavigate } from "react-router-dom";

const center = { lat: 51.5072, lng: 0.1276 };

const occupiedCar = {
  path: "M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759   c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z    M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713   v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336   h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805",
  fillColor: "black",
  fillOpacity: 2,
  strokeWeight: 1,
  rotation: 0,
  scale: 1,
};
const unoccupiedCar = {
  path: "M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759   c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z    M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713   v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336   h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805",
  fillColor: "white",
  fillOpacity: 2,
  strokeWeight: 1,
  rotation: 0,
  scale: 1,
};
function ukDateFormatter(date) {
  return date.split("T")[0].split("-").reverse().join("-");
}

const DesignScreen = ({ path, updateTripInfo }) => {
  const [showTripInfo, setShowTripInfo] = useState(false);
  const [showVehicleNo, setShowVehicleNo] = useState(true);
  const occupiedCar = {
    path: "M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759   c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z    M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713   v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336   h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805",
    fillColor: "black",
    fillOpacity: 2,
    strokeWeight: 1,
    rotation: 0,
    scale: 1,
  };
  const unoccupiedCar = {
    path: "M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759   c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z    M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713   v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336   h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805",
    fillColor: "white",
    fillOpacity: 2,
    strokeWeight: 1,
    rotation: 0,
    scale: 1,
  };
  function ukDateFormatter(date) {
    return date.split("T")[0].split("-").reverse().join("-");
  }

  return (
    <div>
      <Marker
        position={path.location}
        icon={path?.onTrip?.status ? occupiedCar : unoccupiedCar}
      />
      <OverlayView
        position={path.location}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        // getPixelPositionOffset={(x, y) =>
        //   getPixelPositionOffset(x, y, { x: -30, y: -15 })
        // }
      >
        <div
          style={{
            background: `#203254`,
            padding: `7px 12px`,
            fontSize: "11px",
            color: `white`,
            borderRadius: "4px",
            position: "absolute",
            top: "-30px", // Adjust this value to position the label above the icon
            left: "-15px", // Adjust this value to center the label horizontally
            whiteSpace: "nowrap", // Prevent text from wrapping
          }}
          onClick={() => {
            console.log("Clciked on car", path);

            if (path?.onTrip?.status) {
              updateTripInfo(
                path?.onTrip?.tripId,
                path?.onTrip?.status,
                path?.driverInfo
              );
            } else {
              console.log("Hiteed");
              updateTripInfo({}, path?.onTrip?.status, path?.driverInfo);
            }
          }}
        >
          {path.vehicleNo}
          <br />
          Last updated at {ukDateFormatter(path.lastUpdatedTime)}{" "}
          {new Date().toISOString().split("T")[1].substring(0, 5)}
        </div>
      </OverlayView>
    </div>
  );
};

const Maps = ({ vehiclesLocation, updateTripInfo, central }) => {
  console.log("FOund vehilce", vehiclesLocation);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDSvqFVfMDtPftyvZJMrEYeqF5R5dXc6nE",
    libraries: ["places"],
  });
  // const center = useMemo(
  //   () => ({ lat: 12.969016141570492, lng: 77.62609273195267 }),
  //   []
  // );
  console.log("CENTRAL", central);
  const center = useMemo(() => central, [central.lat]);
  const [map, setMap] = useState(/** @type google.maps.Map */ (null));

  useEffect(() => {}, [vehiclesLocation]);

  /** @type React.MutableRefObject<HTMLInputElement> */
  const originRef = useRef();
  /** @type React.MutableRefObject<HTMLInputElement> */
  const destiantionRef = useRef();

  console.log("I AM GETTING TRIGGEERED", vehiclesLocation);
  const getPixelPositionOffset = (offsetWidth, offsetHeight, labelAnchor) => {
    return {
      x: offsetWidth + labelAnchor.x,
      y: offsetHeight + labelAnchor.y,
    };
  };
  if (!isLoaded) {
    return <SkeletonText />;
  }

  return (
    //       <GoogleMap
    //         center={center}
    //         zoom={15}
    //         mapContainerStyle={{ width: "100%", height: "100%" }}
    //         options={{
    //           zoomControl: false,
    //           streetViewControl: false,
    //           mapTypeControl: false,
    //           fullscreenControl: false,
    //         }}
    //         onLoad={(map) => setMap(map)}
    //       >
    //         {/* <Marker
    //   position={center}
    //   icon={{
    //   // path: google.maps.SymbolPath.CIRCLE,
    //   url: "https://img.icons8.com/3d-fluency/94/map-pin.png",
    //   fillColor: "#EB00FF",
    //   scale: 7,
    //   }}
    //   /> */}
    //         {vehiclesLocation.length > 0 &&
    //           vehiclesLocation.map((path, index) => {
    //             return (
    //               <>
    //                 <Marker key={index} position={path.location} icon={occupiedCar} />
    //                 <OverlayView
    //                   key={index}
    //                   position={path.location}
    //                   mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    //                   // getPixelPositionOffset={(x, y) =>
    //                   //   getPixelPositionOffset(x, y, { x: -30, y: -15 })
    //                   // }
    //                 >
    //                   <div
    //                     style={{
    //                       background: `#203254`,
    //                       padding: `7px 12px`,
    //                       fontSize: "11px",
    //                       color: `white`,
    //                       borderRadius: "4px",
    //                       position: "absolute",
    //                       top: "-30px", // Adjust this value to position the label above the icon
    //                       left: "-15px", // Adjust this value to center the label horizontally
    //                       whiteSpace: "nowrap", // Prevent text from wrapping
    //                     }}
    //                   >
    //                     {" "}
    //                     {path.vehicleNo}
    //                     <br />
    //                     Last updated at {ukDateFormatter(path.lastUpdatedTime)}
    //                   </div>
    //                 </OverlayView>
    //               </>
    //             );
    //           })}
    //       </GoogleMap>

    <GoogleMap
      center={center}
      zoom={15}
      mapContainerStyle={{ width: "100%", height: "100%" }}
      options={{
        zoomControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
      }}
      onLoad={(map) => setMap(map)}
    >
      {vehiclesLocation.length > 0 &&
        vehiclesLocation.map((path, index) => {
          return <DesignScreen path={path} updateTripInfo={updateTripInfo} />;
          // return (
          //   <>
          //     <Marker
          //       position={path.location}
          //       icon={path?.onTrip?.status ? occupiedCar : unoccupiedCar}
          //     />
          //     <OverlayView
          //       position={path.location}
          //       mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          //       // getPixelPositionOffset={(x, y) =>
          //       //   getPixelPositionOffset(x, y, { x: -30, y: -15 })
          //       // }
          //     >
          //       <div
          //         style={{
          //           background: `#203254`,
          //           padding: `7px 12px`,
          //           fontSize: "11px",
          //           color: `white`,
          //           borderRadius: "4px",
          //           position: "absolute",
          //           top: "-30px", // Adjust this value to position the label above the icon
          //           left: "-15px", // Adjust this value to center the label horizontally
          //           whiteSpace: "nowrap", // Prevent text from wrapping
          //         }}
          //       >
          //         {path.vehicleNo}
          //         {/* <br />
          //                 Last updated at {ukDateFormatter(path.lastUpdatedTime)} */}
          //       </div>
          //     </OverlayView>
          //   </>
          // );
        })}
    </GoogleMap>
  );
};

function VehicleNewLocation() {
  const [showTripInfo, setShowTripInfo] = useState(false);
  const [tripInfo, setTripInfo] = useState({});
  const [driverInfo, setDriverInfo] = useState({});
  const [vehicleNumber, setVehicleNumber] = useState("ALL");
  const { client, setClient } = useContext(ClientContext);
  const [filter, setFilter] = useState({});
  const [vehicleList, setVehicleList] = useState([]);
  const [vehiclesLocation, setVehiclesLocation] = useState([]);

  const [onGoingRides, setOngoingRides] = useState([]);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDSvqFVfMDtPftyvZJMrEYeqF5R5dXc6nE",
    libraries: ["places"],
  });
  const [query, setQuery] = useState("");
  const [loaded, setLoaded] = useState(true);
  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [central, setCentral] = useState({
    lat: 12.969016141570492,
    lng: 77.62609273195267,
  });
  const navigate = useNavigate();

  function getRoute(result) {
    console.log("ON Route change", result);
  }
  async function updateTripInfo(tripData, status, driverData) {
    console.log("TIP DATA ", tripData);
    // setShowTripInfo(status);
    setDriverInfo(driverData);
    if (status) {
      setTripInfo(tripData);
    }
  }
  const abortController = new AbortController();

  async function initialData() {
    setLoaded(false);
    try {
      const operatorVehicles = await axiosInstance.get(
        URLConstants.getOperatorVehicleNo(client?.loginData.client.id)
      );
      console.log("IOperator vehicles", operatorVehicles);
      // response?.operatorVehicles[0]?.vehicleNumbers.unshift("ALL");
      // setVehicleAllList(response?.operatorVehicles[0]?.vehicleNumbers);
      // setVehicleList(response?.operatorVehicles[0]?.vehicleNumbers);
      operatorVehicles?.operatorVehicles[0]?.vehicleNumbers.unshift("ALL");

      setVehicleList(operatorVehicles?.operatorVehicles[0]?.vehicleNumbers);

      const ongoingTrips = await axiosInstance.post(
        URLConstants.getOnGoingRides(),
        filter
      );
      setOngoingRides(ongoingTrips);
      console.log("ongoing trips", ongoingTrips);

      if (vehicleNumber == "ALL") {
        console.log("Abort controller", abortController);
        if (abortController) {
          abortController.abort();
        }
        console.log("Inside vehilceNumber", vehicleNumber);
        getVehiclesLocation(
          operatorVehicles?.operatorVehicles[0]?.vehicleNumbers,
          ongoingTrips
        );
      } else {
        setVehiclesLocation([]);

        console.log("Abort controller", abortController);
        if (abortController) {
          abortController.abort();
        }
        console.log("Inside vehilceNumber", vehicleNumber);
        let finalVehicle = [vehicleNumber];
        getVehiclesLocation(finalVehicle, ongoingTrips);
      }
      // setLoaded(true);
    } catch (err) {
      console.log("Error Triggered", err);

      setLoaded(true);
      console.log(err);
    }
  }

  async function getVehiclesLocation(vehicleArr, ongoingTrips) {
    console.log("Vehilce arr received,", vehicleArr);
    let start = vehicleArr.length == 1 ? 0 : 1;

    let allot = false;
    for (let i = start; i < vehicleArr.length; i++) {
      console.log("Before in for loop", vehicleArr);
      const response = await axiosInstance.get(
        URLConstants.getVehicleLocation(vehicleArr[i])
      );

      if (!allot) {
        setCentral({
          lat: Number(response.vehicleLocation.vehicleLocation[0]?.latitude),
          lng: Number(response.vehicleLocation.vehicleLocation[0]?.longitude),
        });
        allot = true;
      }
      console.log("vehicle arr after", response);

      if (
        response?.vehicleLocation &&
        response.vehicleLocation?.vehicleLocation[0]?.latitude
      ) {
        let tripStatus = ongoingTrips.find(
          (trip) => trip?.vehicleDetails?.vehicleNo === vehicleArr[i]
        );

        let arr = {
          vehicleNo: vehicleArr[i],
          location: {
            lat: Number(response.vehicleLocation.vehicleLocation[0]?.latitude),
            lng: Number(response.vehicleLocation.vehicleLocation[0]?.longitude),
          },
          lastUpdatedTime: new Date(
            Number(response.vehicleLocation.lastUpdatedTime)
          ).toISOString(),
          onTrip: {
            status: tripStatus?.tripId ? true : false,
            tripId: tripStatus?.tripId ? tripStatus : null,
          },
          driverInfo:
            response.vehicleLocation?.driverInfo?.length > 0
              ? response.vehicleLocation.driverInfo[0]
              : {},
        };
        console.log("Arr being pushed", arr);
        if (vehicleNumber !== "ALL") {
          let dummyArr = [];
          setVehiclesLocation([...dummyArr]);
          let finalArr = [arr];
          console.log("Final ar for ALL", finalArr);
          setVehiclesLocation([...finalArr]);
          // setVehicleLocationNotFound(response.locationNotExist);
        } else {
          //   let finalArr = vehiclesLocation;
          //   finalArr.push(arr);
          //   setVehiclesLocation(finalArr);
          //   //  setVehicleLocationNotFound(response.locationNotExist);
          //   setLoaded(true);
          let testArr = vehiclesLocation;
          testArr.push(arr);
          //   vehiclesLocation.push(arr);
          setVehiclesLocation([...testArr]);
          console.log("Final ar nor for ALL", testArr);
        }
      }
    }
    setLoaded(true);
  }

  useEffect(() => {
    console.log("LOGGEd IN CLIENT", client);

    if (!client) {
      navigate("/");
    } else {
      console.log("ABCD");
      initialData();
    }
  }, [vehicleNumber]);

  /** @type React.MutableRefObject<HTMLInputElement> */
  const originRef = useRef();
  /** @type React.MutableRefObject<HTMLInputElement> */
  const destiantionRef = useRef();

  if (!isLoaded) {
    return <SkeletonText />;
  }

  console.log("Driver infp", driverInfo);
  console.log("VALUE OF VEHICLE", vehicleNumber);

  const chooseVehicleNumber = (event) => {
    console.log("EVENT ***", event);
    const optId = event;

    setVehicleNumber(optId);
  };
  const filteredVehicleNoList =
    query === ""
      ? vehicleList
      : vehicleList?.filter((state) => {
          return state?.toLowerCase().includes(query.toLowerCase());
        });
  console.log(
    "Veh  filteredVehicleNoList ))))^^^^^^",
    filteredVehicleNoList,
    vehicleList
  );

  const restrictions = {
    country: "uk",
  };

  const options = {
    strictBounds: true,
  };
  const occupiedCar = {
    path: "M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759   c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z    M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713   v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336   h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805",
    fillColor: "black",
    fillOpacity: 2,
    strokeWeight: 1,
    rotation: 0,
    scale: 1,
  };
  const unoccupiedCar = {
    path: "M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759   c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z    M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713   v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336   h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805",
    fillColor: "white",
    fillOpacity: 2,
    strokeWeight: 1,
    rotation: 0,
    scale: 1,
  };
  return (
    <div className="flex flex-col">
      {" "}
      {/*       <Header isAdmin /> */}
      <Flex
        position="relative"
        flexDirection="column"
        alignItems="center"
        h="100vh"
        w="100vw"
      >
        <Box position="absolute" left={0} top={0} h="100%" w="100%">
          {/* Google Map Box */}
          {/* {vehiclesLocation?.length == 0 || !vehiclesLocation ? (
            <div className="text-red-500 font-bold text-2xl">
              No Vehicles Found
            </div>
          ) : ( */}
          <Maps
            vehiclesLocation={vehiclesLocation}
            updateTripInfo={updateTripInfo}
            central={central}
          />
          {/* )} */}
        </Box>
        <Box
          position="absolute"
          left={5}
          top={5}
          bgColor="white"
          shadow="base"
          borderRadius="lg"
          w={10}
          h={2}
        >
          <IconButton
            aria-label="center back"
            icon={<IoArrowBackOutline />}
            onClick={() => navigate("/loginscreen")}
          />
        </Box>

        <Box
          p={4}
          borderRadius="lg"
          m={4}
          bgColor="white"
          shadow="base"
          //   minW="container.md"
          zIndex="1"
        >
          <HStack spacing={2} justifyContent="space-between">
            <Box flexGrow={2}>
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                />
              </svg> */}

              {/* <Autocomplete restrictions={restrictions}> */}
              <div className="flex flex-row w-full">
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                  />
                </svg> */}
                {/* <Input
                  type="text"
                  placeholder="Enter vehicle no."
                  ref={originRef}
                  isInvalid={errorMsg.length > 1}
                  errorBorderColor="red.300"
                /> */}

                <Combobox
                  value={vehicleNumber ?? ""}
                  onChange={(event) => chooseVehicleNumber(event)}
                  className="w-full"
                >
                  <div className="relative mt-1 w-full">
                    <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md  2 sm:text-sm w-full">
                      <Combobox.Input
                        className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                        autocomplete="off"
                        displayValue={(state) => {
                          console.log("state inside state", vehicleNumber);

                          return vehicleNumber;
                        }}
                        onChange={(event) => setQuery(event.target.value)}
                      />
                      <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </Combobox.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      afterLeave={() => setQuery("")}
                    >
                      <Combobox.Options
                        className="absolute w-full mt-1 max-h-60  overflow-auto rounded-md bg-white py-1 text-base shadow-lg   sm:text-sm"
                        style={{
                          padding: "0px",
                          margin: "0px",
                          textAlign: "center",
                          zIndex: "2",

                          whiteSpace: "nowrap",
                        }}
                      >
                        {(filteredVehicleNoList?.length === 0 ||
                          !filteredVehicleNoList) &&
                        query !== "" ? (
                          <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                            Nothing found.
                          </div>
                        ) : (
                          filteredVehicleNoList?.map((vehicleNo) => (
                            <Combobox.Option
                              key={vehicleNo}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 w-full ${
                                  active
                                    ? "bg-[#404298] text-white"
                                    : "text-gray-900"
                                }`
                              }
                              style={{ padding: "15px" }}
                              value={vehicleNo}
                            >
                              {({ selected, active }) => {
                                return (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "100%",
                                        // flexDirection: "column",
                                      }}
                                    >
                                      <span
                                        className={`block truncate ${
                                          selected
                                            ? "font-medium"
                                            : "font-normal"
                                        }`}
                                      >
                                        {vehicleNo}
                                      </span>
                                      {vehicleNo === vehicleNumber ? (
                                        <span
                                          className={`pl-3 ${
                                            active
                                              ? "text-white"
                                              : "text-teal-600"
                                          }`}
                                        >
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </div>
                                  </>
                                );
                              }}
                            </Combobox.Option>
                          ))
                        )}
                      </Combobox.Options>
                    </Transition>
                  </div>
                </Combobox>
              </div>
            </Box>
            <div className="absolute">
              {/*   <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                />
              </svg> */}
            </div>

            <ButtonGroup>
              <Button colorScheme="pink" type="submit">
                Find
              </Button>
              {/* <IconButton aria-label="center back" icon={<FaTimes />} /> */}
            </ButtonGroup>
          </HStack>
          <div className="text-red-500 font-bold">{errorMsg}</div>
          <div
            className=""
            onClick={() => {
              setTripInfo({});
              setDriverInfo({});
            }}
          >
            <div className="ml-4 flex flex-col justify-center ">
              {tripInfo?.tripId ? (
                <>
                  <div>
                    <span className="text-[#404298] font-bold mr-2">
                      Trip Id :{" "}
                    </span>
                    <span className=" font-semibold ml-2">
                      {tripInfo?.tripId}
                    </span>
                  </div>
                  <div>
                    <span className="text-[#404298] font-bold mr-2">
                      Destination :{" "}
                    </span>
                    <span className=" font-semibold ml-2">
                      {tripInfo?.destination[0]?.address}
                    </span>
                  </div>
                  <div>
                    <span className="text-[#404298] font-bold mr-2">
                      Source :{" "}
                    </span>
                    <span className=" font-semibold ml-2">
                      {tripInfo?.source[0]?.address}
                    </span>
                  </div>
                </>
              ) : null}

              {driverInfo?.driverInfo?.mobileNo ? (
                <>
                  <div className="pt-3">
                    <span className="text-[#404298] font-bold mr-2">
                      Driver Mobile No. :{" "}
                    </span>
                    <span className=" font-semibold ml-2">
                      {driverInfo?.driverInfo?.mobileNo}
                    </span>
                  </div>

                  <div>
                    <span className="text-[#404298] font-bold mr-2">
                      Driver Email :{" "}
                    </span>
                    <span className=" font-semibold ml-2">
                      {driverInfo?.driverInfo?.email}
                    </span>
                  </div>

                  <div>
                    <span className="text-[#404298] font-bold mr-2">
                      Vehicle Number :{" "}
                    </span>
                    <span className=" font-semibold ml-2">
                      {driverInfo?.vehicleNo}
                    </span>
                  </div>

                  <div>
                    <span className="text-[#404298] font-bold mr-2">
                      Vehicle Name :{" "}
                    </span>
                    <span className=" font-semibold ml-2">
                      {driverInfo?.vehicleName}
                    </span>
                  </div>

                  <div>
                    <span className="text-[#404298] font-bold mr-2">
                      No. of Seats :{" "}
                    </span>
                    <span className=" font-semibold ml-2">
                      {driverInfo?.noOfSeats}
                    </span>
                  </div>

                  <div>
                    <span className="text-[#404298] font-bold mr-2">
                      No. of Buggies :{" "}
                    </span>
                    <span className=" font-semibold ml-2">
                      {driverInfo?.noOfBuggies}
                    </span>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </Box>
      </Flex>
      <div className="spinner">
        <Loader
          loaded={loaded}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#000"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />
      </div>
    </div>
  );
}
export default VehicleNewLocation;
